import React from 'react';
import {Tooltip,List,Row } from 'antd';
import '../App.css';
import axios from 'axios';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';

import {encryptString,decryptString,getUserToken,getUserID} from '../store/utility';

function textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
  if (str.length > maxLength) {
    switch (side) {
      case "start":
        return ellipsis + str.slice(-(maxLength - ellipsis.length));
      case "end":
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
  }
  return str;
}


class Simulations extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    //this store data on the webpage, change on a variable can be catch in componentDidUpdate()
    state = {
        user_tags: [],
        clicked_simulation_name: "",
        simulations: [],
        filtered_simulations: [],
        sessions: [],
        loading: false,
        sortCount: 0,
        buttons_state: new Map(),
        resetSimulations: false,
        display_mode: "",
        sessions_list: [],
        selected_session_id: -1,
        selected_session: {},
        connected_user_id: -1,
        user_contact: [],
        simulation_size: -1,
        refreshSimulation: false,

        encrypted_message: "",
        decrypted_message: "",
        token: "",
        staff: false,
        lastSimulationCliked: "",

        simulations_loaded: false,
        public_simulation: false,
        user_settings: [],
        unity_loaded: false,
        updated_once: false,
        tutorial_ready: false,
        gif_by_simulation: [],
        refresh: false,
        duplicate: false,
    }
    loadedRowsMap = {};

    //executed before first render
    componentDidMount() {
        this.state.connected_user_id = getUserID();
        this.setState({ token: getUserToken() });

        window.addEventListener('disconnect', () => { this.componentDidMount(); })
        this.setState({ unity_loaded: this.props.unityLoaded ? this.props.unityLoaded : false });
        this.setState({ encrypted_message: encryptString("themessage") });


        //Remove simulation test here , and find  away to check if simulations if different or no
        if (this.props.data !== null) {
            const simulations_copy = [];
            Object.assign(simulations_copy, this.props.data);

            this.state.simulations_list = ""

            simulations_copy.forEach(element => {
                element.privacy = this.SimulationIsPublic(element.lpy)?"public":"private";
            })
            this.setState({ simulations: simulations_copy });

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

    }

    //When receiving data, do something
    componentDidUpdate(prevProps, prevState) {
        if (this.props.refreshSimulation !== this.state.refreshSimulation) {
            this.setState({ refreshSimulation: this.props.refreshSimulation });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1)
        }

        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }

        if (this.props.gif_by_simulation !== this.state.gif_by_simulation && this.props.gif_by_simulation !== undefined) {
            this.setState({ gif_by_simulation: this.props.gif_by_simulation });
        }

        if (this.props.simulationClicked !== prevProps.simulationClicked) {
            this.handleClick(undefined);

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (this.props.nameLoaded !== prevProps.nameLoaded) {

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (!this.state.updated_once) {

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
            this.setState({ updated_once: true });
        }
        //when unity is loaded -> store it 
        if (this.props.unityLoaded !== prevProps.unityLoaded) {

            this.setState({ unity_loaded: this.props.unityLoaded });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        //when you click somewhere to reset this component layout
        if (prevProps.layoutReset !== this.props.layoutReset) {

            this.handleClick(undefined);
        }

        if (prevState.encrypted_message !== this.state.encrypted_message) {
            this.setState({ decrypted_message: decryptString(this.state.encrypted_message) });
        }

        //Remove simulation test here , and find  away to check if simulations if different or no
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) || (this.props.refresh !== prevProps.refresh)) {
            const simulations_copy = [];
            this.setState({ refresh: this.props.refresh });
            Object.assign(simulations_copy, this.props.data);
            simulations_copy.forEach(element => {
                element.privacy = this.SimulationIsPublic(element.lpy)?"public":"private";
            })
            this.state.simulations_list = ""

            this.setState({ simulations: simulations_copy });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (this.props.refresh !== this.state.refresh) {
            this.setState({ refresh: this.props.refresh });
        }

        if (prevState.display_mode !== this.state.display_mode) {
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (JSON.stringify(prevProps.usettings)  !== JSON.stringify(this.props.usettings)) {
            this.setState({ user_settings: this.props.usettings });
        }

        if (prevProps.sortCount  !== this.state.sortCount) {
            this.renderItem = this.renderItem.bind(this);
            this.setState({ sortCount: prevProps.sortCount });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1)
        }


    }

    getUserSettingsValue(p_setting) {
        let value = -1;
        if (this.state.user_settings !== undefined) {
            this.state.user_settings.map(sett => {
                if (sett.setting === p_setting) {
                    value = sett.value;
                }
            });
        }
        return value;
    }

    // if a simulation is selected > clear values to go back to the simulation list
    // if not, choose the clicked simulation as the selected simulation
    handleClick = (simulation) => {
        if (simulation === undefined) {
            this.props.unSelectSimulation();
        }
        else {
            this.props.selectASimulation(simulation);
        }
    }

    handleInfiniteOnLoad = ({ startIndex, stopIndex }) => {
        const data = this.state.filtered_simulations;
        this.setState({
            loading: true,
        });
        for (let i = startIndex; i <= stopIndex; i++) {
            // 1 means loading
            this.loadedRowsMap[i] = 1;
        }
        if (data.length > 19) {
            //  message.warning('Virtualized List loaded all');
            this.setState({
                loading: false,
            });

        }
    };

    SimulationIsPublic = (lpy) => {
        const path = lpy;
        if (path !== null) {
            if (path.startsWith('simulations_public/'))
                return true;
        }
        return false;
    }

    isRowLoaded = ({ index }) => !!this.loadedRowsMap[index];

    renderItem = ({ index, key, style }) => {
        const data = this.state.filtered_simulations;
        const item = data[index];
        const row_options = undefined;
        return (
            <div >
                <div >
                    <List.Item key={key} style={style}>
                        <Tooltip placement="bottom" title={this.state.unity_loaded || this.getUserSettingsValue("standalone_start") === "true" ? <span>Load this simulation in the visualizer</span> : <span>Viewer is still loading ...</span>}>
                            <img alt="simulation load" onContextMenu={(evt) => { evt.preventDefault(); localStorage.setItem('unityProps', encryptString(JSON.stringify(item))); window.open(window.location.href, '_blank'); return false; }}
                                onClick={() => {
                                    localStorage.setItem('unityProps', encryptString(JSON.stringify(item))); window.location.href = "/morphosimul"; /*window.open(window.location.href, '_blank');*/ return false;
                                }} style={{ cursor: 'pointer', marginLeft: '0px', borderRadius: '10px', marginRight: '2px' }} width={50} src={"/images/refonte/load_small.png"}></img>
                        </Tooltip>

                        <List.Item.Meta
                            description={
                                <div>
                                    {
                                        row_options !== undefined &&
                                        <Row justify="start" >{row_options}</Row>
                                    }
                                </div>
                            }
                            title={
                                this.state.clicked_simulation_name === "" ?
                                    <div>
                                        <span onClick={() => {
                                            this.props.sendTracking(item.id, "Web,Simulation,click");
                                            this.handleClick(item)
                                        }} style={{ width: '800px', cursor: 'pointer', paddingBottom: '0px' }} className='content-text'>{window.innerWidth < 1200 ? textEllipsis(item.name, 30) : window.innerWidth < 600 ? textEllipsis(item.name, 10) : item.name} </span></div>
                                    :
                                    <span></span>
                            }

                        />
                        {
                            this.SimulationIsPublic(item.lpy) ?
                                <div><Tooltip placement="bottom" title={<span>This simulation is public</span>}><img alt="Public simulation" style={{ float: 'left', marginRight: '4px' }} width={35} src="/images/refonte/opendata.png" /></Tooltip></div>
                                :
                                <span></span>
                        }

                    </List.Item></div>

            </div>

        );
    };

    render() {
        this.state.filtered_simulations = this.state.simulations;
        const data = this.state.filtered_simulations;
        const vlist = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered, width }) => (
            <VList
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                overscanRowCount={2}
                rowCount={data.length}
                rowHeight={73}
                rowRenderer={this.renderItem}
                onRowsRendered={onRowsRendered}
                scrollTop={scrollTop}
                width={width}
            />
        );
        const autoSize = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered }) => (
            <AutoSizer disableHeight>
                {({ width }) =>
                    vlist({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        onRowsRendered,
                        width,
                    })
                }
            </AutoSizer>
        );
        const infiniteLoader = ({ height, isScrolling, onChildScroll, scrollTop }) => (
            <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.handleInfiniteOnLoad}
                rowCount={data.length}
            >
                {({ onRowsRendered }) =>
                    autoSize({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        onRowsRendered,
                    })
                }
            </InfiniteLoader>
        );
        return (
            <div style={{ paddingTop: '1%' }}>
                <List ref='item_div'>
                    {data.length > 0 && <WindowScroller>{infiniteLoader}</WindowScroller>}
                </List>
            </div>);


    }
}

export default Simulations;