import React from "react";
import {connect} from "react-redux";
import {DeleteGroupReload, RenameGroupReload, ShareGroupReload} from "../utils/api_callbacks";
import Search from "antd/es/input/Search";
import {getUserID} from "../utils/CookieManagement";
import {NavLink} from "react-router-dom";

class GroupInstance extends React.Component {

    state = {
        current_group_rename: -1,
        show_rename_panel: false,
        show_delete_panel: false,
        show_delete_panel_all: false,
        current_group_delete: -1,
    }

    deleteGroup = (id_group) => {
        DeleteGroupReload(id_group);
        this.closeDeletePanel();
    }

    onRenameGroup = (new_name,id_group) => {
        RenameGroupReload(new_name, id_group);
        this.closeRenamePanel();
    }

    shareGroup = (group_object) => {
        const privacy = group_object.privacy === "public" ? "private" : "public";
        ShareGroupReload(group_object, privacy);
        this.closeDeletePanel();
    }

    canDeleteOrRename = (group) => {
        return (getUserID() === group.id_owner || this.props.is_staff);
    }

    openRenamePanel = (id_set) => {
        this.setState({current_info_rename: id_set});
        this.setState({show_rename_panel: true});
    }

    closeRenamePanel = () => {
        this.setState({current_info_rename: -1});
        this.setState({show_rename_panel: false});
    }

    openDeletePanel = (id_set) => {
        this.setState({current_info_delete: id_set});
        this.setState({show_delete_panel: true});
    }

    closeDeletePanel = () => {
        this.setState({current_info_delete: -1});
        this.setState({show_delete_panel: false});
    }

    render() {
        const item = this.props.group;
        return <div >
            {/* Left part of the info rendering (name or rename)*/}
            {
                this.state.show_rename_panel === true && this.state.current_info_rename === item.id ?
                    //if we chose to rename the info
                    <div style={{display:"inline"}}>
                        <Search
                            placeholder="New group name..."
                            disabled={!this.canDeleteOrRename(item)}
                            allowClear
                            enterButton="Apply"
                            defaultValue={item.name}
                            size="default"
                            style={{width: "20%"}}
                            onSearch={(e) => this.onRenameGroup(e,item.id)}
                        />
                        <img alt="Close" style={{
                            filter: "grayscale(" + (this.canDeleteOrRename(item) ? "0%)" : "60%)"),
                            display: 'inline',
                            marginLeft: '5px',
                            cursor: 'pointer'
                        }}
                             onClick={() => {
                                 if (this.canDeleteOrRename(item)) {
                                     this.closeRenamePanel()
                                 }
                             }} width={30} src="/images/refonte/window_close.png"></img>
                    </div>
                    :
                    //normal display of infos
                    <div style={{display:"inline"}}>
                        <img alt="Rename group"
                             style={{
                                 filter: "grayscale(" + (this.canDeleteOrRename(item) ? "0%)" : "60%)"),
                                 display: 'inline',
                                 cursor: 'pointer',
                                 marginRight: '20px'
                             }} onClick={() => {
                            if (this.canDeleteOrRename(item)) this.openRenamePanel(item.id)
                        }} width={30} src="/images/refonte/rename.png"></img>
                        <span style={{color: '#44D4FF', fontSize: '18px'}} className='layout-text'>
                            <NavLink to={'/group/' + item.id + "/update/"}>
                                {item.name} ({item.date.replace('T', ' ').replace('Z', ' ')})
                            </NavLink>
                        </span>
                    </div>
            }

            {
                this.state.show_delete_panel === true && this.state.current_info_delete === item.id ?
                    <div style={{display: "inline", width: "30%", float: "right"}}>
                        <span>Delete group {item.name} ?</span>
                        <img alt="Delete group" style={{display: 'inline', marginLeft: '20%', cursor: 'pointer'}}
                             onClick={() => this.deleteGroup(item.id)} width={30} src="/images/valid.png"></img> <img
                        alt="Close delete panel" style={{display: 'inline', marginLeft: '20%', cursor: 'pointer'}}
                        onClick={() => this.closeDeletePanel()} width={30} src="/images/refonte/window_close.png"></img>
                    </div>
                    :
                    <div style={{display: "inline", width: "30%", float: "right"}}>
                        {/* Right part of the info (delete or share) */}
                        <img alt="Delete group" style={{display: 'inline', marginLeft: '20%', cursor: 'pointer'}}
                             onClick={() => {
                                 if (this.canDeleteOrRename(item)) {
                                     this.openDeletePanel(item.id);
                                 }
                             }} width={30} src="/images/garbage.png"></img>
                        <img alt="Share information" style={{
                            filter: "grayscale(" + (this.canDeleteOrRename(item) ? "0%)" : "60%)"),
                            display: 'inline',
                            marginLeft: '12px',
                            cursor: 'pointer'
                        }}
                             onClick={() => {
                                 if (this.canDeleteOrRename(item)) this.shareGroup(item)
                             }} width={30}
                             src={item.privacy === "public"? "/images/refonte/share_on.png" : "/images/refonte/share_off.png"}></img>
                    </div>
            }
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        rights_by_dataset_id: state.user.rights_by_dataset_id,
        owner_by_info: state.infos.owner_by_info,
        is_staff: state.user.is_staff,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupInstance);
