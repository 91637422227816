import React from 'react';
import axios from 'axios';
import Simulations_Mobile from '../components/Simulation_mobile';
import { Menu, Tooltip } from 'antd';
import {getUserToken,getUserID} from '../store/utility';
import ReactDOM from 'react-dom';


class SimulationList_Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.handleSimulationClick = this.handleSimulationClick.bind(this);
    }
    state = {
        users_tag: [],
        fullsimulations: [],
        privatesimulations: [],
        simulations: [],
        filtersimulations: [],
        updater: false,
        sortCount: 0,
        private_updated: false,
        clicked_date: false,
        clicked_name: false,
        clicked_privacy: false,
        selected_simulation: "",
        filter_type: "",
        filter_privacy: "",
        sort_method: "",
        reset_simulations: false,
        token: "",
        simulations_list: "",
        source_string_created: false,
        copy_created: false,

        user_settings: [],
        reset_simulation_layout_child: false,
        name_loaded: false,
        tutorial_ready: false,
    }

    getUserSettingsValue(p_setting) {
        let value = -1;
        if (this.state.user_settings !== undefined) {
            this.state.user_settings.map(sett => {
                if (sett.setting === p_setting) {
                    value = sett.value;
                }
            });
        }
        return value;
    }

    async componentDidUpdate(nextProps, nextState) {
        if (this.props.tutorialReady !== this.state.tutorial_ready && this.props.tutorialReady === true && !this.state.tutorial_ready) {
            this.setState({ tutorial_ready: true });
            setTimeout(() => {
                const new_node = ReactDOM.findDOMNode(this.refs.resetsortMenu);
                const new_node2 = ReactDOM.findDOMNode(this.refs.sortDateMenu);
                const new_node3 = ReactDOM.findDOMNode(this.refs.sortNameMenu);
                const new_node4 = ReactDOM.findDOMNode(this.refs.typeFilterMenu);

                this.props.displayTutorial(undefined, 'center', 'center', `This page is the list of the simulations`, 'tutorial-simulation', false);
                this.props.displayTutorial(new_node2, 'center', 'top', `You can sort using dates!`, 'tutorial-simulation', false);
                this.props.displayTutorial(new_node3, 'left', 'top', `Or using names!`, 'tutorial-simulation', false);

                this.props.displayTutorial(new_node, 'center', 'top', `You can reset filter and sort by click this!`, 'tutorial-simulation', false);

                if (this.state.token !== "" && this.state.token !== null) {
                    const new_node9 = ReactDOM.findDOMNode(this.refs.upload);
                    this.props.displayTutorial(new_node9, 'center', 'center', `Access simulation upload page`, 'tutorial-simulation', true);
                }
            }, 1000);
        }


        if (this.props.simulationClicked !== nextProps.simulationClicked) {

            this.setState({ filter_privacy: "All" });
            this.state.filter_type = "all";
            this.setState({ sort_method: "DateAsc" });
            this.ApplysimulationsSorting();
        }

        if (nextState.fullsimulations !== this.state.fullsimulations) {
            const simulation_name_list = [];
            this.state.fullsimulations.map(simulation => {
                if (!simulation_name_list.includes(simulation.name)) {
                    simulation_name_list.push(simulation.name);
                }
            });

            this.state.simulations_list = ""
            this.state.fullsimulations.map(simulation => {
                if (simulation !== undefined) {
                    this.state.simulations_list += simulation.name + ","
                }
            });
        }

        if (this.state.source_string_created !== nextState.source_string_created && this.state.source_string_created) {
            this.setState({ copy_created: true });
        }

        if (nextState.fullsimulations !== this.state.fullsimulations) {
            const simulationNames = [];
            this.state.fullsimulations.map(simulation => {
                simulationNames.push(simulation.name);
            });
        }

        if (nextState.private_updated !== this.state.private_updated) {
            if ((this.state.private_updated) || ((this.state.token === null || this.state.token === ""))) {
                this.state.simulations = this.state.privatesimulations;
                this.state.simulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
                    }.bind(this));
                this.setState({ fullsimulations: this.state.simulations });
            }
        }
    }

    componentDidMount() {
        this.state.token = getUserToken();
        this.state.connected_user_id = getUserID();
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        window.addEventListener('disconnect', () => { this.componentDidMount(); })
        if (this.state.token !== undefined && this.state.token !== "") {
            axios.get('/api/usersimulations/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    const the_simulations = res.data.map(simulation => {
                        return simulation;
                    });
                    this.setState({
                        privatesimulations: the_simulations,
                        private_updated: true,
                    });
                });

            axios.get('/api/usertags/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ users_tag: res.data });
                });
        }

        if (this.state.token !== "" && this.state.token !== null) {
            axios.get(`/api/settings/`, { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        user_settings: res.data,
                    });
                });
        }
    }

    applyFilterSimulation() {
        this.state.filtersimulations = this.state.fullsimulations;

        switch (this.state.sort_method) {
            case "DateAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "DateDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate < bdate ? -1 : adate > bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "NameAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
            case "NameDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
        }

        this.state.simulations = this.state.filtersimulations;

        //test
        let sets = this.state.simulations.filter(simulation => simulation['name'].toString().toLowerCase().includes(document.getElementById('custom_input_text').value.toLowerCase()));
        if (document.getElementById('custom_input_text') !== null && document.getElementById('custom_input_text').value !== null && document.getElementById('custom_input_text').value !== "") {
            sets = this.state.simulations.filter(simulation => simulation !== undefined && simulation['name'].toString().toLowerCase().includes(document.getElementById('custom_input_text').value.toLowerCase()));
        }

        return this.state.simulations;
    }

    filterSimulation() {
        let result = undefined;
        result = this.applyFilterSimulation();
        return result;
    }

    simulationsorting() {
        switch (this.state.sort_method) {
            case "DateAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "DateDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate < bdate ? -1 : adate > bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "NameAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
            case "NameDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
        }

        this.state.simulations = this.state.filtersimulations.filter(
            simulation => (simulation.id_people === this.state.connected_user_id)
        )

        this.setState({ reset_simulations: !this.state.reset_simulations });
    }

    ApplysimulationsSorting() {
        this.simulationsorting();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    SortKeys() {
        const result = [];
        switch (this.state.sort_method) {
            case 'DateAsc':
                result.push('1');
                break;
            case 'DateDesc':
                result.push('2');
                break;
            case 'NameAsc':
                result.push('3');
                break;
            case 'NameDesc':
                result.push('4');
                break;
            default:
                break;
        }
        return result;
    }

    TypeKeys() {
        return '2';
    }

    PrivacyKeys() {
        return '2';
    }

    getTypeKeyOn = () => {
        return "2";
    }

    render() {
        let search_input = true;
        if (document.getElementById('custom_input_text') !== null) {
            search_input = (document.getElementById('custom_input_text').value === null || document.getElementById('custom_input_text').value === "");
        }
        return (
            <div>
                {
                    this.state.selected_simulation === "" ?
                        <div style={{ width: '100%', alignContent: 'center' }}>
                            <Menu selectedKeys={this.SortKeys()} mode="horizontal" >
                                <Menu.Item ref='sortDateMenu' style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="1" onClick={() => {
                                    this.props.sendTracking(-1, "Web,SimulationList,Sort,DateAsc"); this.state.sort_method = "DateAsc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_date: !this.state.clicked_date }); this.ApplysimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by date (new first)</span>}><img alt="Login" width='30' src="/images/refonte/date.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>

                                <Menu.Item style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="2" onClick={() => {
                                    this.props.sendTracking(-1, "Web,SimulationList,Sort,DateDesc"); this.state.sort_method = "DateDesc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_date: !this.state.clicked_date }); this.ApplysimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by date (old first)</span>}><img alt="Login" width='30' src="/images/refonte/invdate.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>

                                <Menu.Item ref='sortNameMenu' style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="3" onClick={() => {
                                    this.props.sendTracking(-1, "Web,SimulationList,Sort,NameAsc"); this.state.sort_method = "NameAsc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_name: !this.state.clicked_name }); this.ApplysimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by alphabetic order</span>}><img alt="Login" width='30' src="/images/refonte/sort-by-alphabet.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>

                                <Menu.Item style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="4" onClick={() => {
                                    this.props.sendTracking(-1, "Web,SimulationList,Sort,NameDesc"); this.state.sort_method = "NameDesc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_name: !this.state.clicked_name }); this.ApplysimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by reverse alphabetic order</span>}><img alt="Login" width='30' src="/images/refonte/sort-reverse-alphabetical-order.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>
                            </Menu>
                        </div>
                        :
                        <span></span>
                }
                <div style={{}}>

                    {
                        true &&
                        <Simulations_Mobile  {...this.props} nameLoaded={this.state.name_loaded} layoutReset={this.state.reset_simulation_layout_child} usettings={this.state.user_settings} resetSimulations={this.state.reset_simulations} onSelectedSimulation={this.handleSimulationClick} sortCount={this.state.sortCount} data={this.filterSimulation()}></Simulations_Mobile>

                    }
                </div>
            </div>
        );
    }
}

export default SimulationList_Mobile;