import React from 'react';
import {Input,Icon,Form,Button} from 'antd';
import '../App.css';
import axios from 'axios';
import {getUserToken,getUserID,postNotification} from '../store/utility';
const { TextArea } = Input;

class SimulationSettings extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.state.connected_user_id = getUserID();
        this.setState({ token: getUserToken() });
    }

    //this store data on the webpage, change on a variable can be catch in componentDidUpdate()
    state = {
        clicked_simulation: null,
        updated_simulation: null,
        token: "",
        connected_user_id: -1,
        simulation_size: -1,
        staff: false,
        users_tag: [],
    }

    PatchSimulationChange = () => {
        const error = false;
        if (this.state.updated_simulation.name === "" || this.state.updated_simulation.name === undefined) {
            this.state.update_simulation_name = "New simulation not named";
        }

        if (this.state.updated_simulation.description === undefined) {
            this.state.updated_simulation.description = "";
        }

        if (this.state.updated_simulation.script === undefined) {
            this.state.updated_simulation.script = "";
        }

        if (!error) {
            //this.props.sendTrackingSimulation(this.state.clicked_simulation.name, "Web,Simulation,Settings,update");
            //post new group to django
            axios({
                method: 'post',
                url: `/api/updatesimulationwebsite/`,
                data: {
                    name: this.state.updated_simulation.name,
                    name_simulation: this.state.updated_simulation.name,
                    description: this.state.updated_simulation.description,
                    lpy_script: this.state.updated_simulation.script
                },
                headers: { Authorization: `Token ${this.state.token}` }
            }).then(res => {
                postNotification(<h1 style={{ color: '#44D4FF', fontSize: '18px' }}>Simulation updated.</h1>, 'The  simulation : ' + this.state.updated_simulation.name + ' has been updated ');
                this.props.updateASimulation(this.state.updated_simulation, this.state.clicked_simulation.name);

            });
        }
    }

    DeleteSelectedSimulation = () => {
        if (window.confirm('Are you sure you wish to delete this simulation ?')) {
            //this.props.sendTrackingSimulation(this.state.clicked_simulation.name, "Web,Simulation,Settings,delete");
            axios({
                method: 'get',
                url: `/api/deletesimulationweb/`,
                params: { name_simulation: this.state.clicked_simulation.name },
                headers: { Authorization: `Token ${this.state.token}` }
            }).then(res => {
                postNotification(<h1 style={{ color: '#44D4FF', fontSize: '18px' }}>Simulation deleted.</h1>, 'The  simulation : ' + this.state.clicked_simulation.name + ' has been deleted ');
                window.location.reload(false);
            });
        }
    }

    onChangeUpdateSimulationname = ({ target: { value } }) => {
        const new_updated_simulation = {...this.state.updated_simulation};
        new_updated_simulation.name = value;
        this.setState({ updated_simulation: new_updated_simulation });
    };

    onChangeUpdateSimulationDescription = ({ target: { value } }) => {
        const new_updated_simulation = {...this.state.updated_simulation};
        new_updated_simulation.description = value;
        this.setState({ updated_simulation: new_updated_simulation });
    };

    onChangeUpdateSimulationScript = ({ target: { value } }) => {
        const new_updated_simulation = {...this.state.updated_simulation};
        new_updated_simulation.script = value;
        this.setState({ updated_simulation: new_updated_simulation });
    };

    UpdateValues = () => {
        if (this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }

        if (this.props.simulation_size !== -1) {
            this.setState({ simulation_size: this.props.simulation_size });
        }

        if (this.props.users_tag !== null && this.props.users_tag.length > 0) {
            this.setState({ users_tag: this.props.users_tag });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }

        if (this.props.clicked_simulation !== this.state.clicked_simulation && this.props.clicked_simulation !== null) {
            this.setState({ clicked_simulation: this.props.clicked_simulation });
            this.setState({ updated_simulation: this.props.clicked_simulation });
        }

        if (this.props.simulation_size !== this.state.simulation_size && this.props.simulation_size !== null && this.props.simulation_size !== -1) {
            this.setState({ simulation_size: this.props.simulation_size });
        }

        if (this.props.users_tag !== null && this.props.users_tag.length > 0 && this.props.users_tag !== this.state.users_tag) {
            this.setState({ users_tag: this.props.users_tag });
        }
    }

    getUserStatus = (user_id) => {
        const status = "norights";
        if (this.state.clicked_simulation.id_people === user_id) {
            return "manager";
        }
        return status;
    }

    render() {
        const selected_options = undefined;
        return (
            <div>
                <Form>
                    {
                        this.state.clicked_simulation  !== undefined && this.state.clicked_simulation  !== null && this.state.clicked_simulation.name  !== undefined ?
                            <div>  <p style={{ display: 'inline', fontSize: '24px' }} className={'content-text'}>{this.state.clicked_simulation.name}</p><br></br><br></br></div>
                            :
                            <span></span>
                    }
                    <span style={{ fontSize: '21px', color: 'black' }}>Update a simulation  </span>
                    <br></br>
                    <br></br>
                    <Form.Item>
                        <h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Name:</h2>
                        <TextArea
                            autoSize={{ minRows: 1, maxRows: 6 }}
                            value={this.state.updated_simulation !== null ? this.state.updated_simulation.name : this.state.clicked_simulation !== null ? this.state.clicked_simulation.name : ""}
                            disabled={false}
                            onChange={this.onChangeUpdateSimulationname}
                            style={{ width: '50%', marginLeft: '10px' }}
                            prefix={<Icon type="font-colors" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Simulation name"
                        />
                    </Form.Item>
                    <Form.Item>
                        <h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Description:</h2>
                        <TextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={false}
                            onChange={this.onChangeUpdateSimulationDescription}
                            value={this.state.updated_simulation !== null ? this.state.updated_simulation.description : ""}
                            prefix={<Icon type="font-colors" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Simulation description"
                        />
                    </Form.Item>
                    <Form.Item>
                        <h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Script lpy:</h2>
                        <TextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={false}
                            onChange={this.onChangeUpdateSimulationScript}
                            value={this.state.updated_simulation !== null ? this.state.updated_simulation.script : this.props.clicked_simulation !== null ? this.props.clicked_simulation.script : ""}
                            prefix={<Icon type="font-colors" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Simulation script"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" disabled={false} onClick={this.PatchSimulationChange} style={{ borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px' }} >
                            Update simulation
                        </Button>

                        <Button type="danger" disabled={false} onClick={this.DeleteSelectedSimulation} style={{ marginRight: '10px' }} >
                            Delete simulation
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default SimulationSettings;