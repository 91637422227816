import React from 'react';
import {Redirect} from "react-router-dom";


class RedirectToMorphoQuest extends React.Component {

    state = {}

    componentDidMount() {
        window.location.href = 'https://apps.apple.com/fr/app/morphoquest/id6464156847';
    }

    render (){
        return ""
    }
}

export default RedirectToMorphoQuest;