import React, { Component } from "react";
import {getUserToken, getUserID, decryptString} from '../store/utility';
import axios from 'axios';
import '../App.css';

class MorphoSimule extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        id_user: -1,
        token: "",
        redirect: false,
        user_got: false,
        unity_loaded: false,
        port_send: 9200,
        //port_send: 9875,
        port: 9200,
        //port: 9875,
        port_receive: 9201,
        //port_receive: 9876,
        adress_default: "http://physioscope.lirmm.fr",
        preChosenSimulation: this.props.location.preChoosenSimulation,
        simulator: false
    }


    componentDidMount() {
        this.setState({preChosenSimulation:JSON.parse(decryptString(localStorage.getItem('unityProps')))});
        //update view on resize
        this.state.id_user = getUserID();
        this.state.token = getUserToken();
        const port_send = 9200;
        //var port_send = 9875;
        const port_receive = 9201;
        //var port_receive = 9876;
        if (window.location.search !== "" && window.location.search !== undefined) {
            const param_list = window.location.search.replace('?', '').split('&');
            for (let param in param_list) {
                const value = param_list[param].split('=');
                if (value[0] === "port_send") {
                    this.setState({ port_send: parseInt(value[1]) });
                }

                if (value[0] === "plot_host") {
                    this.setState({ adress_default: value[1] });
                }

                if (value[0] === "port") {
                    this.setState({ port: parseInt(value[1]) });
                }

                if (value[0] === "port_receive") {
                    this.setState({ port_receive: parseInt(value[1]) });
                }
            }
        }

        if (this.state.token !== "" && this.state.token !== null) {
            axios.get(`/api/people/${this.state.id_user}`, { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    res.data.password = "";
                    this.setState({
                        user: res.data,
                    });
                    this.setState({ user_got: true });
                });

            axios.get('/api/people-simulator/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulator: res.data['simulator'] });
                });

        }
        else {
            this.setState({ user_got: true });
        }


    }



    componentDidUpdate(prevProps, prevState) {
        if (this.state.simulator) {
            if (this.state.unity_loaded && this.state.user_got && !this.state.redirect) {
                this.props.openUnityFromAway();
                axios.get("/api/findports/", { params: { 'path': this.state.preChosenSimulation.lpy }, headers: { Authorization: `Token ${this.state.token}` } })
                    .then(res => {
                        this.setState({ redirect: true });
                        axios.get("/api/getports/", { params: { 'path': this.state.preChosenSimulation.lpy }, headers: { Authorization: `Token ${this.state.token}` } })
                            .then(ports => {
                                this.setState({ port_send: ports.data['ports'].port_send });
                                this.setState({ port_receive: ports.data['ports'].port_receive });
                                axios.get("/api/startsimulation/", { params: { 'path': this.state.preChosenSimulation.lpy, 'port_send': this.state.port_send, 'version': this.state.preChosenSimulation.version }, headers: { Authorization: `Token ${this.state.token}` } })
                                    .then(res => {
                                        this.setState({ redirect: true });
                                    }).catch(err => {
                                        this.setState({ redirect: true });
                                    });
                                this.props.unityLoadFuncMax({
                                    id: 0, type: "lpy", name: "Plot", bundle: 0, maxtime: 0, id_dataset_type: 0, mintime: 0, time: 0, u_right: 1, id_people: parseInt(this.state.id_user),
                                    u_ID: parseInt(this.state.id_user), u_name: "MorphoNet", u_surname: "Simulation", port: this.state.port, port_send: this.state.port_send,
                                    port_recieve: this.state.port_receive, plot_host: this.state.adress_default
                                });
                            }).catch(err => {
                                this.setState({ redirect: true });
                            });
                    }).catch(err => {
                        this.setState({ redirect: true });
                    });
                this.setState({ redirect: true });

            }

            if (this.props.unityLoaded !== prevProps.unityLoaded) {
                this.setState({ unity_loaded: this.props.unityLoaded });
            }
        }
    }





    render() {
        return (
            <div>
                {
                    this.state.simulator ?
                        <div>
                            <h1 style={{ color: '#292e37', fontFamily: 'Baskerville', fontSize: '34px', textAlign: 'center', fontWeight: "bold", minWidth: '750px', marginTop: '5%' }}> MorphoSimul</h1>
                            <p style={{ color: '#292e37', fontFamily: 'Arial', fontSize: '25px', fontWeight: "bold", textAlign: 'center' }}> The 3D viewer is loading, you will be redirected when it's ready</p>
                        </div>
                        :
                        <div>
                            <p style={{ color: '#292e37', fontFamily: 'Arial', fontSize: '25px', fontWeight: "bold", textAlign: 'center' }}> You are not authorized to access this page</p>
                        </div>
                }
            </div>
        );
    }
}

export default MorphoSimule;