import React from 'react';
import axios from 'axios';
import { List,Input  } from 'antd';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import {getUserToken,getUserID} from '../store/utility';

const { Search } = Input;

class UserList extends React.Component {

    state = {
      //all user managed by this user
        all_users : [],
        unfiltred_all_users: [],
        groups : [],
        staff : false,
        //signal to update render auto (just change this using setState() for refresh view)
        updater : false
        
    }
    user_id = -1;
    
    //retrieve user + list of users managed by this user
    componentDidMount() {
      //timer for display
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
      //connected user
      this.state.connected_user_id =  getUserID();
      //token for auth
      this.setState({token:getUserToken()});
      //get users list
    }

    //get user name inside html objects
    getUserName(id){
        const userName = {};
        this.state.all_users.map((user) => {
          if (user.id === id)
          {
            userName.name = user.name;
            userName.surname = user.surname;
          }
          
      });
      return userName;
    }

    componentDidUpdate(prevProps, prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });

        axios.get('/api/people/',{headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
              all_users: res.data,
              unfiltred_all_users: res.data
            });
        });
      }
      //when users are up, get groups depending on the user (admin = all, owner = owned group)
      if (prevState.all_users !== this.state.all_users){
        axios.get('/api/group/',{headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                  groups: res.data
                });
            })

        this.state.all_users.map(user => {
          axios.get(`/api/institution/${user.id_institution}/`,{ headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
              user.name_institution = res.data.name;
          })
        });
        this.setState({updater:true});
      }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
  }
  

    //called everytime the DOM has changed
    render() {
        return (
          this.state.token === null || this.state.token === ""?
          <div>Please register to access this page</div>
          :
            <div className="globaldiv">
            <div className="floatingright">
            {
            this.state.all_users.length > 1?
            <h2>Users</h2>
            :
            <h2>Yourself</h2>
            } 
            <Search placeholder="Enter user name here..."  id='username_search' />
<List
    itemLayout="horizontal"
    dataSource={this.state.all_users.filter(user => user['name'].includes(document.getElementById('username_search').value) || 
                user['login'].toString().includes(document.getElementById('username_search').value))}
    renderItem={item => (
      <List.Item>
      {

        this.state.staff || this.user_id === item.id?
        <List.Item.Meta
                title={<NavLink exact to={{pathname:`/display-user/`,aboutProps:item}}>
                  {
                    item.name || item.surname?
                    <div><h3>{item.name} {item.surname}</h3>  {item.id_institution !== 0 ? item.name_institution : <div></div>}</div>
                    :
                    <div><h3>{item.login}</h3>  {item.id_institution}</div>
                  }
                  </NavLink>}
                  description={<div>
                    {
                      item.email?
                      <div>{item.email}</div>
                      :
                      <div></div>
                    }
                  {
                  item.fonction?
                  <div>Fonction : {item.fonction}</div>
                  :
                  <div></div>
                  }
                </div>}
        />    
        :
        <List.Item.Meta
          title={<div>
            {
              item.name || item.surname?
              <div><h3>{item.name} {item.surname}</h3> {item.id_institution !== 0 ? item.name_institution : <div></div>} </div>
              :
              <div><h3>{item.login}</h3></div>
            }
            </div>}
            description={<div>
              {
                item.email?
                <div>{item.email}</div>
                :
                <div></div>
              }
            {
            item.fonction?
            <div>Fonction : {item.fonction}</div>
            :
            <div></div>
            }
          </div>}
        />           
      }
      </List.Item>
    )}
  />
<br></br>
  </div>
  <div className="floatingleft">
  <h2>Your groups</h2>

<List
    itemLayout="horizontal"
    dataSource={this.state.groups}
    renderItem={item => (
      <div>
      {
        <List.Item>
        <List.Item.Meta
          title={<NavLink exact to={`/group/${item.id}/`}><h3>{item.name}</h3>  created the : {item.date}  by : {this.getUserName(item.id_owner).name} {this.getUserName(item.id_owner).surname}</NavLink>}
        />
      </List.Item>
      }
      </div>
    )}
  />
            </div>
            </div>
           );
    }
}

//allow the compoennt to be accessed everywhere else
const mapStateToProps = state => {
  return {
      isAuthenticated: state.token !== null,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps) (UserList);
