import {createEntityAdapter, createSlice,} from '@reduxjs/toolkit'

const initialState = {
    sessions_by_set: {}
}

const sessionAdapter = createEntityAdapter({
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => a.date.localeCompare(b.date),
})

const sessionSlice = createSlice({
    name: 'session', initialState: sessionAdapter.getInitialState(initialState), reducers: {
        // How to use each action of the reducer
        // dispatch(datasetAdded({ id: '', ... }))
        sessionAdded: sessionAdapter.addOne, // dispatch(datasetReceived({ id: '', ... }))
        sessionListAdded: sessionAdapter.addMany, //dispatch(datasetUpdated({ id: '', changes: {... } }))
        sessionUpdated: sessionAdapter.updateOne,
        sessionListUpdated: sessionAdapter.setAll,
        sessionClearAll : sessionAdapter.removeAll,
        LoadMultipleSessionSets: (state, param) => {
            const {payload} = param;
            payload.map(tag_set => {
                const session_id = tag_set[0]
                const set_id = tag_set[1]
                if (!(set_id in state.sessions_by_set)) {
                    state.sessions_by_set[set_id] = []
                }
                state.sessions_by_set[set_id].push(session_id);
            });
        },
        LoadSessionSet: (state, param) => {
            const {payload} = param;
            const session_id = payload[0]
            const set_id = payload[1]
            if (!(set_id in state.sessions_by_set)) {
                state.sessions_by_set[set_id] = []
            }
            state.sessions_by_set[set_id].push(session_id);
        },
    },
})

export const {
    sessionAdded, sessionClearAll,sessionListAdded, sessionUpdated, sessionListUpdated, LoadSessionSet, LoadMultipleSessionSets
} = sessionSlice.actions
// ??
export default sessionSlice.reducer