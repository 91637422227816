import {createSlice,} from '@reduxjs/toolkit'

const initialState = {
    is_staff: false,
    is_connected: false,
    is_beta: false,
    unity_loaded: false,
    server_list: [],
    rights_by_dataset_id: {},
    user_contacts: [],
    favourite_by_dataset_id: {},
    current_choosen_group: -1,
    current_choosen_study: -1,
    group_list: {},
    studies_list : [],
    studies_by_group: {},
    datasets_by_study : {},
    users_by_group: {},
    users_by_study : {},
    people_groups_by_group: {},
    people_study_by_study : {},
    settings: {},
    error_message : "",
    current_redirected_url : "",
}

const userSlice = createSlice({
    name: 'user', initialState, reducers: {
        // Input is a boolean , user being staff or not
        BindStaff: (state, param) => {
            const {payload} = param;
            state.is_staff = payload;
            state.is_connected = true;
        }, // Input is a boolean , user being beta or not
        StoreContacts: (state, param) => {
            const {payload} = param;
            state.user_contacts = payload.filter(user => user.id !== 0);
        },
        BindBeta: (state, param) => {
            const {payload} = param;
            state.is_beta = payload;
            state.is_connected = true;
        }, LoadGroups: (state, param) => {
            const {payload} = param;
            for (let [key, value] of Object.entries(payload)) {
                state.group_list[key] = value;
            }
        }, // Input is an id , set , and the right
        // if set is in state, update , if not add it
        LoadSetRight: (state, param) => {
            const {payload} = param;
            const set_id = payload[0]
            state.rights_by_dataset_id[set_id] = payload[1];
        },

        LoadMultipleSetRight: (state, param) => {
            const {payload} = param;
            const datasets = payload[0]
            const is_admin = payload[1]
            const user_id = payload[2]
            datasets.map(set => {
                let max_rights = 3;
                if (set.id in state.rights_by_dataset_id) {
                    max_rights = Math.min(max_rights, state.rights_by_dataset_id[set.id]);
                }
                if (is_admin) {
                    max_rights = Math.min(max_rights, 0);
                }
                if (set.id_people === user_id) {
                    max_rights = Math.min(max_rights, 1);
                }
                state.rights_by_dataset_id[set.id] = max_rights;
            })

        }, // Input is a dictionary , id_set => favourite status
        // if set is in state, update , if not add it
        LoadMultipleSetsFavourite: (state, param) => {
            const {payload} = param;
            state.favourite_by_dataset_id = {}
            for (let [key, value] of Object.entries(payload)) {
                state.favourite_by_dataset_id[key] = value;
            }
        },
        LoadMultiplesStudyGroup: (state, param) => {
            const {payload} = param;
            for (let [key, value] of Object.entries(payload)) {
                state.studies_by_group[key] = value;
                value.map(study => {
                    if (!state.studies_list.includes(study)) {
                        state.studies_list.push(study);
                    }
                });
            }
        },
        // Input is a an id , set , and the favourite status
        // if set is in state, update , if not add it
        LoadSetFavourite: (state, param) => {
            const {payload} = param;
            const set_id = payload[0]
            state.favourite_by_dataset_id[set_id] = payload[1];
        }, UnityLoaded: (state, param) => {
            state.unity_loaded = true;
        }, ConnectUser: (state, param) => {
            state.connected = true;
        }, DisconnectUser: (state, param) => {
            state.connected = false;
        }, SetUserSettings: (state, param) => {
            const {payload} = param;
            state.settings = payload;
        }, SetServerList: (state, param) => {
            const {payload} = param;
            state.server_list = payload;
        }, chooseGroup: (state, param) => {
            const {payload} = param;
            state.current_choosen_group = payload;
        }, unChooseGroup: (state) => {
            state.current_choosen_group = -1;
        }, LoadMultiplePeopleGroup: (state, param) => {
            const {payload} = param;
            for (let [key, value] of Object.entries(payload)) {
                state.people_groups_by_group[key] = value;
            }
        }, LoadMultipleGroupUsers: (state, param) => {
            const {payload} = param;
            for (let [key, value] of Object.entries(payload)) {
                state.users_by_group[key] = value;
            }
        },chooseStudy: (state, param) => {
            const {payload} = param;
            state.current_choosen_study = payload;
        }, unChooseStudy: (state) => {
            state.current_choosen_study = -1;
        },LoadMultiplePeopleStudy: (state, param) => {
            const {payload} = param;
            for (let [key, value] of Object.entries(payload)) {
                state.people_study_by_study[key] = value;
            }
        },SetErrorMessage : (state,param) => {
            const {payload} = param;
            state.error_message = payload;
        },SetRedirectedUrl : (state,param) => {
            const {payload} = param;
            state.current_redirected_url = payload;
        }
    },
})


export const {
    SetRedirectedUrl,
    SetErrorMessage,
    LoadMultiplePeopleStudy,
    chooseStudy,
    unChooseStudy,
    LoadMultiplePeopleGroup,
    LoadMultipleGroupUsers,
    chooseGroup,
    unChooseGroup,
    LoadMultiplesStudyGroup,
    StoreContacts,
    SetUserSettings,
    DisconnectUser,
    ConnectUser,
    UnityLoaded,
    LoadMultipleSetRight,
    LoadGroups,
    BindStaff,
    LoadMultipleSetsRight,
    BindBeta,
    LoadSetRight,
    LoadSetFavourite,
    LoadMultipleSetsFavourite,
    SetServerList
} = userSlice.actions
export default userSlice.reducer