import React, { Component } from "react";
import {getUserToken,getUserID} from '../store/utility';
import axios from 'axios';
import '../App.css';

class MorphoPlot extends Component {
    constructor(props){
      super(props);
    }

    state = {
        id_user : -1,
        token : "",
        redirect : false,
        user_got : false,
        unity_loaded : false,
        port_send : 9875,
        port : 9875,
        port_receive : 9876,
        adress_default : "http://localhost",
    }


    componentDidMount(){
        //update view on resize
        this.state.id_user = getUserID();
        this.state.token = getUserToken();
        const port_send = 9875;
        const port_receive = 9876;
        if (window.location.search !== "" && window.location.search !== undefined){
            const param_list = window.location.search.replace('?', '').split('&');
            for (let param in param_list){
                const value = param_list[param].split('=');
                if (value[0] === "port_send"){
                    this.setState({port_send:parseInt(value[1])});
                    //console.log(parseInt(value[1]));
               }

               if (value[0] === "plot_host"){
                   this.setState({adress_default:value[1]});
               }

               if (value[0] === "port"){
                this.setState({port:parseInt(value[1])});
                //console.log(parseInt(value[1]));
           }

               if (value[0] === "port_receive"){
                this.setState({port_receive:parseInt(value[1])});
                //console.log(parseInt(value[1]));
           }
            }
        //console.log(this.props.params.match.port);
        //this.setState({port: this.props.match.params.port}); 
    }

        if (this.state.token !== "" && this.state.token !== null){
            axios.get(`/api/people/${this.state.id_user}`,{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
              res.data.password = "";
                this.setState({
                    user: res.data,
                });
                this.setState({user_got:true});
            });
        }
        else {
            this.setState({user_got:true});
        }
    }

  

    componentDidUpdate(prevProps,prevState){
        if (this.state.unity_loaded && this.state.user_got && !this.state.redirect){
                this.props.openUnityFromAway();
                this.props.unityLoadFuncMax(this.state.token === null || this.state.token === ""?
                {id:0,name:"Plot",bundle:0,maxtime:0,id_dataset_type:0,mintime:0,time:0,u_right:1,id_people:0,u_ID:0,u_name:"Public",u_surname:"",port:this.state.port,port_send:this.state.port_send,port_receive:this.state.port_receive,plot_host:this.state.adress_default}
                :
                {id:0,name:"Plot",bundle:0,maxtime:0,id_dataset_type:0,mintime:0,time:0,u_right:1,id_people:this.state.id_user,u_ID:this.state.id_user,u_name:this.state.user.name,u_surname:this.state.user.surname,port:this.state.port,port_send:this.state.port_send,port_receive:this.state.port_receive,plot_host:this.state.adress_default});
                this.setState({redirect : true});
        }

        if (this.props.unityLoaded !== prevProps.unityLoaded){
            this.setState({unity_loaded:this.props.unityLoaded});
        }
    }

    render(){
        return (
            <div>
                <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px',marginTop:'5%'}}> MorphoPlot</h1>
                <p style={{color:'#292e37',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold",textAlign:'center'}}> The 3D viewer is loading, you will be redirected when it's ready</p>
            </div>
        );
    }
}

export default MorphoPlot;