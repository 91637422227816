import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';
import {getUserToken,getUserID} from '../store/utility'
import axios from 'axios';

class HelpCurationKilian extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
        staff : false,
        token : "",
        subfolder_link : "",
        connected_user_id : -1,
        source_link : "helpfiles/curation_backup.md",
    }
    
    componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });
        }
    }

    componentDidMount(){
        this.state.connected_user_id = getUserID();
        this.setState({token : getUserToken()});
        this.props.sendTracking(-1,"Web,Help,Curation,view");
    }


    render(){
        return(
			<div>
            <div style={{margin:'auto'}}>
            <h1 className='main-title' style={{marginLeft:'33%'}}> MorphoCuration Backup help </h1>
                <br></br><br></br>
                {
                    this.state.source_link !== "" &&
                    <MorphoMDEditor subfolderName={this.state.subfolder_link === undefined || this.state.subfolder_link === "" ? "curation" :this.state.subfolder_link} sourceLink={this.state.source_link} > </MorphoMDEditor>
                }
        	</div>
			</div>
        );
    }
}

export default HelpCurationKilian;