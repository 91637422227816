import React from 'react';

class PrivacyPolicy extends React.Component {
    render(){
         /*

                PUT THIS INSIDE RETURN FOR LOGO
            <div>            
           <input type="image" width="150px;"  src="images/logo.png" onclick="window.open('index.php')" />         */ 
           
        return (        
            <div>            
                    	  <div style={{marginRight: 'auto',  marginLeft: 'auto', marginTop:'10px', marginBottom:'auto'}}>
            <div style={{width:'800px',textAlign:'justify',marginLeft:'auto',marginRight:'auto',padding:'10px',border:'3px solid #DBF0FC'}} > 
<h1 className="title" style={{fontWeight:'bold',fontSize:'20px', textDecoration:'underline', textAlign:'center'}} >Privacy policy</h1>
<div className="version-subtitle">Version 2.1 dated 2021-05-07</div>

                <div className="notice">
                    <h2>What is a cookie ?</h2>
                    <p>
                        Cookies are small text files that are placed on your computer or device when you visit our websites.
                    </p>
                </div>

                <div className="notice">
                    <h2>Purpose of Cookies</h2>
                    <p>
                        We use cookies to make your visits and use of our site more efficient.
                        Cookies also allow us to improve navigation on our sites and to understand your user behavior.
                    </p>
                </div>

                <div className="notice">
                    <h2>Our use of Cookies</h2>
                    <p>
                        We use cookies to :
                        <ul>
                            <li> Remember your login information.</li>
                            <li>  Identify you when you log in to our websites.</li>
                            <li> To remember your browsing preferences such as language, font size, page layout or color preferences.
                                All of this is done to make browsing our site faster and easier for you.</li>
                            <li> Collect analytical information to, for example,
                                count the number of users to our websites and identify the pages of our sites that you visit most often.
                                We use the analytical data we collect for statistical and resource planning purposes.</li>
                            <li> To determine how easily our users adapt to changes we may make to our websites.</li>
                        </ul>
                    </p>
                </div>

                 <div className="notice">
                    <h2>Information collected through cookies</h2>
                        <p>
                        Cookies help us gather information about how you use our website, but we do not store any personally identifiable information in our cookie data about you.
                        We only store a unique session ID that will help us to retrieve a user profile and your preferences the next time you visit.
                        </p>
                </div>

                  <div className="notice">
                    <h2>Types of cookies :</h2>
                        <p>
                        <ul>
                            <li> Session cookies: Cookies of this type are temporarily stored on your computer or device during a browsing session.
                                They are deleted from your computer or device at the end of the browsing session.</li>
                            <li> Persistent cookies: Cookies of this type remain on your computer longer.
                                Persistent cookies help us recognize you when we need to know who you are for more than one browsing session.
                            </li>
                        </ul>
                        </p>
                </div>

                <div className="notice">
                    <h2>Cookie Management</h2>
                        <p>
                        Most browsers have options in the menu that allow you to manage your cookies by adjusting or configuring your cookie settings.
                        </p>
                        <p>
                            Typically, the browser will give you the ability to:
                            <ul>
                                <li>Display your cookies</li>
                                <li>Allow cookies</li>
                                <li>Disable all cookies, or only specific cookies</li>
                                <li>Disable all cookies when you close your browser</li>
                                <li>Block cookies</li>
                                <li>To be notified when you receive a cookie.</li>

                            </ul>
                        </p>
                        <p>
                            Please note that if you choose to block our cookies, most of the functionality of this site will be disabled and your browsing experience may also be affected.
                            If you set your browser options to delete all cookies, your browsing preferences will be erased when you close your browser.
                            Please note that unless you have adjusted your browser settings to refuse cookies, our system will issue cookies whenever you visit one of our sites.
                        </p>
                </div>

                <div className="notice">
                    <h2>Additional information on cookies</h2>
                        <p>
                        To learn more about cookies, we invite you to consult the <a target="_blank" href="http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/"><tt> CNIL page</tt></a>
                        </p>
                </div>

</div>
	  </div>
            </div>
        );
    }
}

export default PrivacyPolicy;

