import React from 'react';

class License extends React.Component {
    render(){
         /*

                PUT THIS INSIDE RETURN FOR LOGO
            <div>            
           <input type="image" width="150px;"  src="images/logo.png" onclick="window.open('index.php')" />         */ 
           
        return (        
            <div>            
                    	  <div style={{marginRight: 'auto',  marginLeft: 'auto', marginTop:'10px', marginBottom:'auto'}}>
            <div style={{width:'800px',textAlign:'justify',marginLeft:'auto',marginRight:'auto',padding:'10px',border:'3px solid #DBF0FC'}} > 
<h1 className="title">CeCILL FREE SOFTWARE LICENSE AGREEMENT</h1>
<div className="version-subtitle">Version 2.1 dated 2013-06-21</div>



<div className="notice">
<h2>Notice</h2>
<p>
This
Agreement is a Free Software
license agreement that is the result of 
discussions between its authors in order to ensure compliance with
the two main principles guiding its drafting:</p>

<ul>
<li> firstly,
compliance with the principles governing the distribution of Free Software:
access to source code, broad rights granted to users,
</li>
<li>
secondly, the election of a governing law, French law, with which it is
conformant, both as regards the law of torts and
intellectual property law, and the protection that it offers to
both authors and holders of the economic rights over software. 
</li>
</ul>

<p>The
authors of the 
CeCILL<sup><a href="#footnote1">1</a></sup>


license are:</p> 

<p>Commissariat
à l'énergie atomique et aux énergies alternatives - CEA, a public
scientific, technical and industrial research establishment, having its
principal place of business at 25 rue Leblanc, immeuble Le Ponant D, 75015
Paris, France.</p>

<p>Centre
National de la Recherche Scientifique - CNRS,
a public scientific and technological establishment, having its
principal place of business at
3 rue Michel-Ange, 75794 Paris cedex 16, France.</p> 

<p>Institut
National de Recherche en Informatique et en Automatique - Inria,
a public scientific and technological establishment, having its
principal place of business at Domaine de Voluceau, Rocquencourt, BP
105, 78153 Le Chesnay cedex, France.</p>

</div>
<div className="preamble">
<h2>Preamble</h2>


<p>The
purpose of this Free Software 
license
agreement 
is to grant users
the right to modify and redistribute the software governed by this
license within the framework of an open source
distribution model.  
</p>

<p>The
exercising of this right is conditional upon certain obligations
for users so as to preserve this status 
for all subsequent
redistributions.</p>






<p>In consideration of access to
the source code and the rights to copy,
modify and redistribute granted by the license, users are provided only
with a limited warranty and the
software's author, the holder of the economic rights, and the
successive licensors only have limited liability.
</p>

<p>In
this respect, 
the risks
associated with loading, using, modifying and/or developing or
reproducing the software by the user 
are brought to the user's attention,
given its  Free Software 
status, 
which may make it
complicated to use, 
with the result that its use is 
reserved for developers and experienced professionals having in-depth 
computer knowledge. 
Users are therefore encouraged to load and test the suitability of the
software as regards their requirements in conditions enabling the security of
their systems and/or data to be ensured and, more generally, to use and
operate it in the same conditions of security. This Agreement may be freely
reproduced and published, provided it is not altered, and that no 
provisions are either added or removed
herefrom.</p> 

<p>This
Agreement may apply to any or all software for which the holder of
the economic rights decides to submit the use
thereof to its provisions.</p>

<p>Frequently asked questions can be
found on the official website of the CeCILL licenses family
(<a href="&gt;http://www.cecill.info/index.en.html"><tt>http://www.cecill.info/index.en.html</tt></a>)
for any necessary clarification. 
</p>

</div>
<div className="article">
<h2> Article <span className="numbering">1 </span> - DEFINITIONS</h2>

	<p>For
	the purpose of this Agreement, 
        when the following expressions
	commence with a capital letter, they shall have the following
	meaning:</p>

        <p className="definition"><span className="definition">Agreement</span>:
        
	means this license
        agreement, and 
        its possible subsequent versions and annexes.
        
        </p>

	<p className="definition"><span className="definition">Software</span>:
        
	means the software in its Object Code and/or Source Code form and,
	where applicable, its documentation, "as is" 
	when the Licensee accepts the Agreement. 
        
        </p>

	<p className="definition"><span className="definition">Initial Software</span>:
        
        means the Software in its Source Code and 
        possibly its Object Code form and, where applicable, its
        documentation, "as is" when it is
        first distributed
        under the terms and conditions of the
        Agreement.  
        
        </p>

        
        <p className="definition"><span className="definition">Modified Software</span>:
        
        means the Software modified by at least one Contribution.
        
        </p>
        

        

	<p className="definition"><span className="definition">Source Code</span>:
        
        means all the Software's instructions and program lines to which
        access is required so as to modify the Software. 
        
        </p>

	<p className="definition"><span className="definition">Object Code</span>:
        
        means the binary files originating from the
	compilation of the Source Code. 
        
        </p>

	<p className="definition"><span className="definition">Holder</span>:
        
	means the holder(s) of the economic rights over the Initial Software. 
        
        </p>

	<p className="definition"><span className="definition">Licensee</span>:
        
	means
        the Software user(s) having accepted the Agreement. 
         
        </p>

	<p className="definition"><span className="definition">Contributor</span>:
        
	means a Licensee having made at least one 
        Contribution.
        
        
        </p>

	<p className="definition"><span className="definition">Licensor</span>:
        
	means the Holder, or any  other individual or legal 
        entity,	who distributes the Software under
        the Agreement.  
        
        </p>

        
	<p className="definition"><span className="definition">Contribution</span>:
        
	means any or all modifications,
        corrections, translations, adaptations and/or new 
        functions
        integrated into the Software by any or all Contributors, 
        as well as any or all
        Internal Modules.
        
        </p>

	<p className="definition"><span className="definition">Module</span>:
        
	means a set of sources files including their documentation 
        that enables
        supplementary 
	functions or services  in
        addition to those offered by the Software.
        
        </p>

	<p className="definition"><span className="definition">External Module</span>:
        
        means any or all Modules, 
        not derived from the Software, 
        so that this Module 
        and the	Software run in
	separate address spaces, with one calling the other when they are
	run.
        
        </p>

	<p className="definition"><span className="definition">Internal Module</span>: 
        
        means any or all
        Module, connected to the Software so that they both execute
        in the same address space.
        
        </p>

        

        

        
        <p className="definition"><span className="definition">GNU GPL</span>:
        
        means the GNU General Public License version 2 or any subsequent
        version, as published by the Free Software Foundation Inc.
        
        </p>
        

        
        <p className="definition"><span className="definition">GNU Affero GPL</span>:
        
	means the GNU Affero General Public License version 3 or any subsequent
        version, as published by the Free Software Foundation Inc.
        
        </p>
        

        
        <p className="definition"><span className="definition">EUPL</span>:
        
	means the European Union Public License version 1.1 or any
	subsequent version, as published by the European Commission.
        
        </p>
        

	<p className="definition"><span className="definition">Parties</span>:
        
	mean both the Licensee and the Licensor.
        
        </p>

	<p>These
	expressions may be used both in singular and plural form.</p>

</div>
<div className="article">
<h2> Article <span className="numbering">2 </span> - PURPOSE</h2>

	<p>The
	purpose of the Agreement is the grant
        by the Licensor to  the
	Licensee of a non-exclusive, transferable 
        and worldwide license 
        for the	Software as set forth in Article <a href="#scope"><span className="numbering">5</span></a>
        hereinafter for the whole term of the
        protection 
        granted by the rights over said Software. 
	</p>

</div>
<div className="article">
<h2> Article <span className="numbering">3 </span> - ACCEPTANCE</h2>

        <div className="clause">
	<p><a name="accepting"></a><span className="numbering">3.1 </span>The
	Licensee shall be deemed as having accepted
	the terms and conditions of this Agreement 
        upon the occurrence of the
	first of the following events:</p>
        <ul>
	<li> (i)
	loading the Software by any or all means, notably, by downloading
	from a remote server, or by loading from a physical medium;</li>
        <li> (ii)
	the first time the Licensee exercises any of the rights granted
	hereunder.</li>
        </ul>
        </div>

        <div className="clause">
	<p><span className="numbering">3.2 </span>One
	copy of the Agreement, containing a notice relating to the 
        characteristics
        of the  Software, to the limited warranty, and to the
	fact that its use is
        restricted to
        experienced users has been provided to the
	Licensee prior to its acceptance as set forth in Article 
        <a href="#accepting"><span className="numbering">3.1</span></a>
	hereinabove, and the Licensee hereby acknowledges that it 
        has read and understood it.</p>
        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">4 </span> - EFFECTIVE DATE AND TERM</h2>


        <div className="clause">
        <h3>
<span className="numbering">4.1 </span>EFFECTIVE DATE</h3>

	<p>The
	Agreement shall become effective on the date when it is accepted by
	the Licensee as set forth in Article <a href="#accepting"><span className="numbering">3.1</span></a>.</p>
        </div>

	<div className="clause">
        <h3>
<a name="term"></a><span className="numbering">4.2 </span>TERM</h3>

	<p>The
	Agreement shall remain in force 
        for the entire legal term of
        protection of the economic rights over the Software.</p>

        </div>

</div>
<div className="article">
<h2>
<a name="scope"></a> Article <span className="numbering">5 </span> - SCOPE OF RIGHTS GRANTED</h2>

	<p>The
	Licensor hereby grants to the Licensee, who
        accepts, the
	following rights over the Software
        for any or all use, and for 
	the term of the Agreement, on the basis of the terms and conditions
	set forth hereinafter. 
	</p>
	<p>
        Besides, if the Licensor owns or comes to
        own one or more patents
        protecting all or part of the 
        functions of the Software
        or of its components,
        the Licensor undertakes not to enforce the rights granted by these
        patents against  successive Licensees using, exploiting or
        modifying the Software. If these patents are
        transferred,  the Licensor
        undertakes to have the transferees subscribe to the
        obligations set forth in this
        paragraph.
        </p>

        <div className="clause">
        <h3>
<span className="numbering">5.1 </span>RIGHT OF USE</h3>

	<p>The
	Licensee is authorized to use the Software, without any limitation as
        to its fields 
        of application, with it being hereinafter 
	specified that this comprises:
        </p>
	<ol>
	<li><p>permanent
   	or temporary reproduction of all or part of the Software by any or
	all means and in any or all form. 
	</p></li>
	<li><p>loading,
	displaying, running, or storing the Software on any or all
        medium.</p></li> 
	<li><p>entitlement
		to observe, study or test 
                its operation
                so as to
                determine
		the ideas and principles behind any or all
		constituent elements of said Software. This shall apply when
                the 
		Licensee carries out any or all loading, displaying, running,
		transmission or storage operation as regards the Software,
                that it is entitled to carry out hereunder.</p></li>
	</ol>

        </div>

        
        <div className="clause">
	<h3>
<span className="numbering">5.2 </span>ENTITLEMENT TO MAKE CONTRIBUTIONS</h3>

	<p>
	The
	right to make Contributions includes the right to translate, adapt,
	arrange, or make any or all modifications to the Software,
        and the 
	right to reproduce the resulting software.</p>
	<p>The
	Licensee is authorized to make any or all Contributions to
        the Software provided that it 
        includes an explicit notice that it is the
        author 
	of said Contribution and indicates the date of the 
        creation thereof.</p>

        </div>
        

        

        <div className="clause">
        <h3>
<span className="numbering">5.3 </span>RIGHT OF DISTRIBUTION</h3>

	<p>In
	particular, the right of distribution includes the 
	right to publish, transmit and communicate
        the Software to the general public 
	on any or all medium, and by any or all means, and the right to
	market, either in consideration of a fee, or free of charge, 
        one or more copies of the Software 
        by any
        means.</p>
	<p>The
	Licensee is further authorized to 
        distribute copies of the
	modified or unmodified Software to third parties according to the
	terms and conditions set forth hereinafter.</p>

        <div className="subclause">
	<h4>
<span className="numbering">5.3.1 </span>DISTRIBUTION OF SOFTWARE
        WITHOUT MODIFICATION</h4>

	<p>The
	Licensee is authorized to distribute 
        true copies of the Software
	in Source Code or Object Code form, provided that
	said distribution 
        complies with all the provisions of the
	Agreement and is accompanied by:</p>
	<ol>
		<li><p>a
		copy of the Agreement,</p></li>
		<li><p>a
		notice relating to the limitation of both the Licensor's
		warranty and liability as set forth in Articles 8 and 9,
                </p></li>
        </ol>
	<p>and
	that, in the event that only the Object Code
        of the Software is redistributed, the Licensee 
        allows effective access to the 
        full Source Code of the Software  
	for a period of at least three years from the distribution of
	the Software,
	 it being 
        understood that the additional 
	acquisition cost of
	 
	the Source Code shall not exceed the cost of 
	the data transfer.
	
	</p>

        </div>

        <div className="subclause">
        <h4>
<span className="numbering">5.3.2 </span>DISTRIBUTION OF MODIFIED  SOFTWARE</h4>

        
	<p>When
	the Licensee makes a Contribution to the Software, the terms and
	conditions for the distribution 
        of the resulting Modified Software become subject to all the provisions 
        of this Agreement. 
	</p>
	<p>The
	Licensee is authorized to distribute 
        the Modified Software, in
	source code or object code form, provided that said 
        distribution
	complies with all the provisions of the Agreement and is accompanied
	by: 
	</p>
	<ol>
		<li><p>a
		copy of the Agreement,</p></li>
		<li>
<p>a
		notice relating to the limitation of both the Licensor's
		warranty and liability as set forth in Articles 8 and 9,</p>
                </li>
	</ol>
	<p>and, in the event that only the 
        object code of the Modified Software
        is redistributed, 
	</p>
	<ol start="3">
	   <li>
<p>a note stating the conditions of effective
	   access to the full source code of the Modified Software
	   for a period of at least three years from the distribution
	   of the Modified Software,
	   it being understood that the additional acquisition cost of
	   the source code shall not exceed the cost of 
	   the data transfer.</p>
           </li>
	</ol>
        

        

        

        </div>

        
        <div className="subclause">
        <h4>
<span className="numbering">5.3.3 </span>DISTRIBUTION OF EXTERNAL MODULES</h4>

	<p>When
	the Licensee has developed 
        an External Module, the terms and
	conditions 
        of this Agreement do not apply to said 
        External Module, that may be
	distributed under a separate 
        license agreement.</p>

        </div>
        
 
        

        


        <div className="subclause">

        
        <h4>
<a name="compatibility"></a><span className="numbering">5.3.4 </span>COMPATIBILITY WITH OTHER LICENSES</h4>

        <p> The Licensee can include a code that is subject to the provisions
            of one of the versions of the GNU GPL, GNU Affero GPL
	    and/or EUPL in the Modified or unmodified
	    Software, and distribute that entire code under the terms of the
	    same version of the GNU GPL, GNU Affero GPL and/or EUPL.</p>

	<p> The Licensee can include the Modified or unmodified Software in a
	    code that is subject to the provisions of one of the versions of
            the GNU GPL, GNU Affero GPL and/or EUPL and distribute
	    that entire code under the terms of the same version of
	    the GNU GPL, GNU Affero GPL and/or EUPL.
        </p>
        

        

        

        </div>

        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">6 </span> - INTELLECTUAL PROPERTY</h2>

        <div className="clause">
        <h3>
<span className="numbering">6.1 </span>OVER THE INITIAL SOFTWARE</h3>

	<p>The
	Holder owns the economic rights over the Initial Software. Any or
	all use of the Initial Software is subject to compliance with the
	terms and conditions under which the Holder has elected to
	distribute its work and no one shall be entitled to modify the terms
	and conditions for the distribution of said Initial Software.</p>
	<p>The
	Holder undertakes that the Initial 
	Software will remain ruled at least by this Agreement,
        for the duration set 
	forth in Article <a href="#term"><span className="numbering">4.2</span></a>.</p>

        </div>

        
        <div className="clause">
	<h3>
<span className="numbering">6.2 </span>OVER THE CONTRIBUTIONS</h3>

	<p>
        The Licensee who develops a Contribution is the owner 
        of the intellectual property rights over this Contribution
        as defined by applicable law.</p>

        </div>

        <div className="clause">
        <h3>
<span className="numbering">6.3 </span>OVER THE EXTERNAL MODULES</h3>

	<p>
        The Licensee 
        who develops an External Module
        is the owner of the intellectual property rights over
        this External Module as defined by applicable law and is
        free to	choose the type of agreement that shall govern its
        distribution.</p> 

        </div>
        

        

        <div className="clause">
        
        <h3>
<a name="mention"></a><span className="numbering">6.4 </span>JOINT PROVISIONS</h3>
        
        

        <div className="subclause">
	<p>The
	Licensee expressly undertakes:</p>
	<ol>
        <li><p>not to remove, or modify, in any manner, the
               intellectual property notices attached to the Software;</p></li>
	<li>
<p>to reproduce said notices, in an identical manner, in the copies of
	       the Software modified or 
               not.
               </p>
               </li>
        
	</ol>
        </div>

        <div className="subclause">
	<p>The
	Licensee undertakes not to directly or indirectly infringe
	the intellectual property rights on the Software of the Holder
	and/or Contributors,
	and to take, where applicable, vis-à-vis its staff, any and
	all measures required to ensure respect of
        said intellectual 
	property rights of the Holder and/or Contributors.</p>
        </div>

        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">7 </span> - RELATED SERVICES</h2>

        <div className="clause">

	<p><span className="numbering">7.1 </span>Under
	no circumstances shall the Agreement oblige the Licensor to provide
	technical assistance or maintenance services for the Software.</p>

	<p>However,
	the Licensor is entitled to offer this type of 
        services. The terms
	and conditions of such technical assistance, and/or such
	maintenance, shall be set forth in a separate
        instrument. Only 
	the Licensor offering said maintenance and/or technical assistance
	services shall incur liability therefor.</p>

        </div>

        <div className="clause">

	<p><span className="numbering">7.2 </span>Similarly,
	any Licensor is entitled to offer to its
	licensees, under its 
        sole responsibility,  
        a warranty, that shall only
	be binding upon itself, for the redistribution of the Software
	and/or the Modified Software, under terms and conditions 
        that it is free to decide. Said warranty, and the financial
        terms and conditions of its application, shall be subject 
        of a separate
	instrument executed between the Licensor and the Licensee.</p>

        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">8 </span> - LIABILITY</h2>

        <div className="clause">
	<p><span className="numbering">8.1 </span>Subject
	to the provisions of Article 8.2, 
        the Licensee shall be entitled to claim compensation for
        any direct
        loss  it may have suffered from the Software
        as a result of a fault on the part of the relevant
        Licensor, subject to 
        providing evidence thereof. 
	</p>
        </div>

        <div className="clause">
	<p><span className="numbering">8.2 </span>The
	Licensor's liability is limited to the commitments made under
	this Agreement and shall not be incurred as a result of in
	particular: (i) loss due the Licensee's total or partial
	failure to fulfill its obligations, (ii) direct or consequential
	loss that is suffered by the Licensee due to the
        use or performance of the Software,
        and (iii) 
        more generally, any
        consequential loss.  
        In particular
        the Parties expressly
	agree that any or all pecuniary or business loss (i.e. loss of data,
	loss of profits, operating loss, loss of customers or orders,
	opportunity cost, any disturbance to business activities) or any or
	all legal proceedings instituted against the Licensee by a third
	party, shall constitute consequential loss and shall not provide
	entitlement to any or all compensation from the Licensor.
	</p>
        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">9 </span> - WARRANTY</h2>

        <div className="clause">
	<p><span className="numbering">9.1 </span>The
	Licensee acknowledges that the 
	scientific and technical 
        state-of-the-art when the Software
        was 
	distributed did not enable all possible uses to be tested and
	verified, nor for the presence of 
        possible defects to be detected.
	In this respect, the Licensee's attention has been drawn to
	the risks associated with loading, using, modifying and/or
	developing and reproducing the Software
        which are reserved for 
	experienced users.</p>
	<p>The
	Licensee shall be responsible for verifying, by any or all means,
	the suitability of the product for its requirements, its good working
        order, and for ensuring that it shall not cause damage
	to either persons or 
        properties. 
	</p>
        </div>

        <div className="clause">
	<p><a name="good-faith"></a><span className="numbering">9.2 </span>The Licensor
	hereby represents, in good faith, that it is entitled to grant all
	the rights over the  Software (including in
        particular the rights set 
	forth in Article <a href="#scope"><span className="numbering">5</span></a>).</p>
        </div>

        <div className="clause">
	<p><span className="numbering">9.3 </span>
	The
	Licensee acknowledges that the Software is supplied "as is"
	by the Licensor without any other express or tacit
        warranty, 
	other than that provided for in Article 
        <a href="#good-faith"><span className="numbering">9.2</span></a> and, in particular,
	without any  warranty as to its 
        commercial value, its secured, safe,
        innovative or relevant nature. 
	</p>
	<p>Specifically,
	the Licensor does not warrant that the Software is free from any 
        error, that it will
        operate without interruption, 
        that it will be 
	compatible with the Licensee's own equipment and 
        software configuration, 
        nor that it will meet the
        Licensee's requirements.    
	</p>
        </div>
    
        <div className="clause">
	<p><span className="numbering">9.4 </span>The
	Licensor does not either expressly or tacitly warrant that the
	Software does not infringe any  third party
        intellectual 
        property right
	relating to a patent, software or any 
        other property right. 
        Therefore, the Licensor disclaims any and all liability towards the
        Licensee arising out of any or all proceedings for
        infringement that may be 
	instituted in respect of the use, modification and redistribution of
	the Software. Nevertheless, should such proceedings be instituted
	against the Licensee, the Licensor shall provide it with technical
	and legal expertise for its defense. Such technical and legal
	expertise shall be decided  on a case-by-case basis
        between the 
	relevant Licensor and the Licensee pursuant to a memorandum of
	understanding. The Licensor disclaims any and
        all liability as
	regards the Licensee's use of the 
        name of the Software. No
	warranty is given as regards
        the existence of prior rights 
	over the name of the Software or as regards
        the existence of a trademark.</p>
        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">10 </span> - TERMINATION</h2>

        <div className="clause">
	<p><span className="numbering">10.1 </span>
	In the event of a breach by the
	Licensee of its obligations hereunder, the Licensor may
	automatically terminate this Agreement thirty (30)
	days after
	notice has been sent to the Licensee and has remained ineffective.</p>
        </div>

        <div className="clause">
	<p><span className="numbering">10.2 </span>A
	Licensee whose Agreement is terminated shall no longer be authorized
	to use, modify or distribute the Software. However, any
	licenses that it may have granted prior to termination of the
	Agreement shall remain valid subject to their having been granted in
	compliance with the terms and conditions hereof. 
	</p>
        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">11 </span> - MISCELLANEOUS</h2>

        <div className="clause">
        <h3>
<span className="numbering">11.1 </span>EXCUSABLE EVENTS</h3>

	<p>Neither
	Party shall be liable for any or all delay, or failure to perform
	the Agreement, that may be attributable to an event of force
	majeure, an act of God or an outside cause, such as
	defective functioning or interruptions 
        of the electricity or
	telecommunications networks, network
        paralysis following a virus attack, intervention by
        government authorities,	natural disasters, water damage, 
        earthquakes, fire, explosions, strikes and labor unrest, war, etc.
        </p>
        
        </div>

        <div className="clause">
	<p><span className="numbering">11.2 </span>Any failure by either 
        Party, on one or more
        occasions, to invoke one or more of the 
        provisions hereof, shall under no
	circumstances be interpreted as being a waiver by the interested
	Party of its right to invoke said
        provision(s) subsequently.</p> 
        </div>

        <div className="clause">
	<p><span className="numbering">11.3 </span> The
	Agreement cancels and replaces
	any or all previous agreements, whether written or oral,
        between the 
	Parties and having the same purpose, and constitutes the entirety of
	the agreement between said Parties concerning said purpose. No
	supplement or modification to the terms and conditions hereof shall
	be effective as between the Parties 
        unless it is made in writing and
	signed by their duly authorized representatives. 
	</p>
        </div>

        <div className="clause">
	<p><span className="numbering">11.4 </span>In
	the event that one or more
	of the provisions hereof were to conflict with a current or future
	applicable act or legislative text, said act or legislative text
	shall prevail, and the Parties
        shall make the necessary 
	amendments so as to comply with
        said act or legislative
	text. All  other provisions shall remain
        effective. Similarly, 
	invalidity of a provision of the
        Agreement, 
	for any reason whatsoever, shall not cause the Agreement as a whole
	to be invalid. 
	</p>
        </div>

        <div className="clause">
        <h3>
<span className="numbering">11.5 </span>LANGUAGE</h3>

	<p>The
	Agreement is drafted in both French and English
        and both versions are
        deemed authentic.
	</p>
        </div>

</div>
<div className="article">
<h2> Article <span className="numbering">12 </span> - NEW VERSIONS OF THE AGREEMENT</h2>
 
        <div className="clause">
	<p><span className="numbering">12.1 </span>Any
	person 
        is authorized to duplicate and distribute copies of
	this Agreement.</p>
        </div>
 
        <div className="clause">
	<p><span className="numbering">12.2 </span>So
	as to ensure coherence, the wording of this Agreement is protected
	and may only be modified by the authors of the License, 
        who reserve
	the right to periodically publish updates or new versions of the
	Agreement, each with a separate number. These subsequent versions
	may 
        address new issues encountered by Free Software.
	</p>
        </div>

        
        <div className="clause">
	<p><span className="numbering">12.3 </span>Any
        Software distributed under a given version of the Agreement
	may only be subsequently distributed under the same version of the
	Agreement or a subsequent version, subject to the provisions of
	Article <a href="#compatibility"><span className="numbering">5.3.4</span></a>. 
	</p>
        </div>
        

        

</div>
<div className="article">
<h2> Article <span className="numbering">13 </span> - GOVERNING LAW AND JURISDICTION</h2>

        <div className="clause">
	<p><span className="numbering">13.1 </span>
	The Agreement is governed by French law.  The
	Parties agree to endeavor to seek an amicable
        solution to any disagreements or disputes
	that may arise during the performance of 
        the Agreement.
	</p>
        </div>

        <div className="clause">
	<p><span className="numbering">13.2 </span>Failing an amicable solution
        within two (2) months as from their
	occurrence, and unless emergency proceedings are necessary, the
	disagreements
	or disputes shall be referred to the Paris Courts having
	jurisdiction, by the more diligent
        Party. 
	</p>
        </div>

</div>

<div className="footnote">
	<p><a name="footnote1">
        1 CeCILL stands for Ce(a) C(nrs) I(nria) L(ogiciel) L(ibre)
        </a></p> 
</div>
</div>
	  </div>
            </div>
        );
    }
}

export default License;

