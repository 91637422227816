import {isAndroid, isChrome, isEdge, isFirefox, isIOS, isMobile, isTablet} from "react-device-detect";
import store from "../store/store";
import {getUserID} from "./CookieManagement";

export const filter_panel_pixel_size="400px"
export const filter_panel_pixel_size_dataset="410px"

export function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0 || bytes === -1) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function GetSetInUrl(url) {
    return parseInt(url.match(/^\d+|\d+\b|\d+(?=\w)/g)[0])
}

export const morphonet_blue = "#44D4FF";
export const isOnStandalone = () => {
    return (isTablet || isMobile || isIOS || isAndroid || (!isFirefox && !isEdge && !isChrome));
}

export const RetrieveSettings = (user_settings, name) => {
    let value = -1;
    if (user_settings !== undefined && Object.keys(user_settings).length > 0) {
        user_settings.map(sett => {
            if (sett.setting === name) {
                value = sett.value;
            }
        });
    }
    return value;
}

export const SecondsToDisplay = (secs) =>
{
    var hours   = Math.floor(secs / 3600)
    var minutes = Math.floor(secs / 60) % 60
    var seconds = secs % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}
export const IsAdminOrCreator = (set_id) => {
    return GetUserRights(set_id) < 1;
}

export const IsAtLeastManager = (set_id) => {
    return GetUserRights(set_id) < 2;
}

export const IsAtLeastReader = (set_id) => {
    return GetUserRights(set_id) < 3;
}

// 0 : admin or owner , 1 : manager , 2 : reader , 3 : public
export const GetUserRights = (set_id) => {
    const state = store.getState();
    const user_id = getUserID();
    const dataset = state.dataset.entities[set_id];
    if (state.user.is_staff) {
        return 0;
    }
    if (dataset !== undefined) {
        if (parseInt(dataset.id_people) === parseInt(user_id)) {
            return 0;
        }

        if (Object.keys(state.user.rights_by_dataset_id).includes(set_id.toString())) {
            return state.user.rights_by_dataset_id[set_id];
        }
    }
    return 3;
}

export const getDefaultQuality = (quality_count) => {
    if (quality_count <= 2) return 0;
    if (quality_count <= 4) {
        return 1;
    }
    if (quality_count <= 5) {
        return 2;
    }
    return 0;
}

export const ReturnEmptyIfUndefined = (data) => {
    if (data === undefined || data === null) {
        return []
    }
    return data;
}

export function textEllipsis(str, maxLength, {side = "end", ellipsis = "..."} = {}) {
    if (str.length > maxLength) {
        switch (side) {
            case "start":
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            case "end":
            default:
                return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
    }
    return str;
}

export const qualityformatter = (value, quality_count) => {
    let display = "Quality: ";
    if (quality_count === 2) {
        switch (value) {
            case 0:
                display += "Low";
                break;
            case "0":
                display += "Low";
                break;
            case 1:
                display += "High";
                break;
            case "1":
                display += "High";
                break;
        }
    }

    if (quality_count === 3) {
        switch (value) {
            case 0:
                display += "Low";
                break;
            case "0":
                display += "Low";
                break;
            case 1:
                display += "Medium";
                break;
            case "1":
                display += "Medium";
                break;
            case 2:
                display += "High";
                break;
            case "2":
                display += "High";
                break;
        }
    }

    if (quality_count === 4) {
        switch (value) {
            case 0:
                display += "Very low";
                break;
            case "0":
                display += "Very low";
                break;
            case 1:
                display += "Low";
                break;
            case "1":
                display += "Low";
                break;
            case 2:
                display += "High";
                break;
            case "2":
                display += "High";
                break;
            case 3:
                display += "Very high";
                break;
            case "3":
                display += "Very high";
                break;
        }
    }

    if (quality_count === 5) {
        switch (value) {
            case 0:
                display += "Very low";
                break;
            case "0":
                display += "Very low";
                break;
            case 1:
                display += "Low";
                break;
            case "1":
                display += "Low";
                break;
            case 2:
                display += "Medium";
                break;
            case "2":
                display += "Medium";
                break;
            case 3:
                display += "High";
                break;
            case "3":
                display += "High";
                break;
            case 4:
                display += "Very high";
                break;
            case "4":
                display += "Very high";
                break;
        }
    }
    return display;
}