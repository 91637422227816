import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {encryptString,decryptString,getUserToken,getUserID} from '../store/utility'
import {NavLink} from 'react-router-dom';
import * as actions from '../store/actions/auth';

import {
    Form,
    Input,
    Icon,
    Button,
    InputNumber,
    Typography
  } from 'antd';
  

  const { Title } = Typography;

  class UserDisplayForm extends React.Component {

    //current component variables, used to store or retrieve values
    state = {
      user_id: -1,
      confirmDirty: false,
      institutions: [],
      laboratories: [],
      teams: [],
      newInstitutionDisplay: false,
      newLaboratoryDisplay: false,
      newTeamDisplay: false,
      institution_selected: false,
      choosen_institution: "",
      laboratory_selected: false,
      choosen_laboratory: "",
      team_selected: false,
      choosen_team: "",
      quota : -1,
      user: {},
      connected_user_id: -1,
      token : "",
      staff : false,
      displayDeleteWindow : false,
      outline_laboratory: false,
      outline_institution: false,
      outline_team: false,
      error_message_mail : "",
    };

    staff = false;

    //send user changes to django
    handleSubmit = e => {
      e.preventDefault();
      this.props.sendTracking(-1,"Web,Users,EditUser,update");
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (this.state.institution_selected && this.state.laboratory_selected && this.state.team_selected && !err) {
            //get auth token from cache
           axios({
            method: 'post',
            url: `/api/updateusersite/`,
            data: {
                id:this.state.user.id,
                name:values.name,
                surname:values.surname,
                login: values.username,
                email: values.email,
                date: this.state.user.date,
                exist: 1,
                id_right: 3,
                tel:values.phone,
                adress:values.adress,
                fonction:values.function,
                id_institution:0,
                id_laboratory:0,
                id_team:0,
                institution_text:this.state.choosen_institution,
                laboratory_text:this.state.choosen_laboratory,
                team_text:this.state.choosen_team,
                quota:values.quota,
            },
            headers :{ Authorization: `Token ${this.state.token}` }
          }).
          then(res => {if (res.data === "Failed") {this.setState({error_message_mail:"This mail is used, please use another one"});} else {this.props.history.push('/users');}}).
          catch(err => {});
          //redirect the page for consistency
           
          }
      });
      if (!this.state.laboratory_selected) {
        this.setState({outline_laboratory:true});
      }
      if (!this.state.institution_selected) {
        this.setState({outline_institution:true});
      }
      if (!this.state.team_selected) {
        this.setState({outline_team:true});
      }
    };

    //after receiving data (old data is given as params)
    componentDidUpdate(prevProps,prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });

        axios.get('/api/institution',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
              institutions: res.data
            });
        })
      //list of laboratories for dropdowns
        axios.get('/api/laboratory',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
              laboratories: res.data
            });
        })
        //list of teams
        axios.get('/api/team',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
              teams: res.data
            });
        })
    }
    
      //if data changed, preload institution, laboratory and team in form
      if (JSON.stringify(this.state.laboratories) !== JSON.stringify(prevState.laboratories)){

        if (this.state.user.id_laboratory !== null){this.setState({
          laboratory_selected:true,
          choosen_laboratory: this.state.laboratories.filter(lab => lab.id === this.state.user.id_laboratory)[0].name
        });}
  
      }

      if (JSON.stringify(this.state.teams) !== JSON.stringify(prevState.teams)){
      if (this.state.user.id_team !== null){this.setState({
        team_selected:true,
        choosen_team: this.state.teams.filter(team => team.id === this.state.user.id_team)[0].name
      });}
    }

    if (JSON.stringify(this.state.institutions) !== JSON.stringify(prevState.institutions)){
      if (this.state.user.id_institution !== null){this.setState({
        institution_selected:true,
        choosen_institution: this.state.institutions.filter(inst => inst.id === this.state.user.id_institution)[0].name
      });}
    }
    }

    //when component is loaded, but not rendered
    componentDidMount() {
    //staff value in firefox cache, as string
    this.setState({token:getUserToken()});
    this.setState({connected_user_id:getUserID()});
    if (localStorage.getItem('userProps') !== undefined && this.props.location.aboutProps === undefined){
      this.props.location.aboutProps = JSON.parse(decryptString(localStorage.getItem('userProps')));
    }
    let savedUser =  JSON.stringify(this.props.location.aboutProps)
    localStorage.setItem('userProps', encryptString(savedUser));
    //get user through webpage
    this.setState({user: this.props.location.aboutProps});
      //timer to refresh display (button click)
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
      //list of institutions for dropdowns
    
  }

  
  hideDeletePanel = e =>{
    e.preventDefault();
    this.setState({displayDeleteWindow:false});
}

//send user deletion (change user status)
deleteUser = e =>{
  e.preventDefault();
  this.props.sendTracking(-1,"Web,Users,EditUser,delete");

  axios({
    method: 'patch',
    url: `/api/user/${this.state.user.id}/`,
    data: {
      active_status: false,
    },
    headers :{ Authorization: `Token ${this.state.token}` }
  });
  //redirect the page
  this.props.history.push('/');
}
  //stop timer
  componentWillUnmount() {
    clearInterval(this.interval);
    //save user in the firefox cache in case of reload 
    let savedUser =  JSON.stringify(this.props.location.aboutProps)
    localStorage.setItem('userProps', encryptString(savedUser));
  }

    handleConfirmBlur = e => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  
    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    };
  
    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    };

    validatePhone = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(['phone'], { force: true });
      }
      callback();
    };
  
    //when change the institution dropdown
    handleInstitutionChange = (e) => {
      this.setState({
        institution_selected:true,
        choosen_institution: e.target.value
      });
    }
    
    //when change the laboratory dropdown
    handleLaboratoryChange = (e) => {
      this.setState({
        laboratory_selected:true,
        choosen_laboratory: e.target.value
      });
    }

    //when change the team dropdown
    handleTeamChange = (e) => {
      this.setState({
        team_selected:true,
        choosen_team: e.target.value
      });
    }
    
    //when click '+' for institution
    displayInstitutionNamePanel = () => {
      this.setState({newInstitutionDisplay: !this.state.newInstitutionDisplay});
    }

    //when click '+' for laboratory
    displayLaboratoryNamePanel = () => {
      this.setState({newLaboratoryDisplay: !this.state.newLaboratoryDisplay});
    }

    //when click '+' for team
    displayTeamNamePanel = () => {
      this.setState({newTeamDisplay: !this.state.newTeamDisplay});
    }

    
  changeQuota = (value) => {
    this.setState({quota:value})
  }

    render() {
      const { getFieldDecorator } = this.props.form;

  
      return (

  
          <div >
    {
          this.state.user !== undefined && (this.state.staff || this.state.connected_user_id === this.state.user.id) && (this.state.token !== null && this.state.token !== "") ?
          <div>
          <Title style={{marginLeft:'45%'}}> User update </Title>
        {
          this.state.user.id === this.state.connected_user_id || this.state.staff ?
          <div style={{marginLeft:'31%',marginTop:'7%'}}>If you wish to change your password, follow this <NavLink to={{pathname:`/reset-password/`,aboutProps:this.state.user}}>link </NavLink>.</div>
          :
          <div></div>
        }
        <br></br>
        <div>
        <Form style={{marginLeft:'31%'}} onSubmit={this.handleSubmit}>
        <Form.Item>
          Username :
          {getFieldDecorator('username', {
            initialValue:this.state.user.login,rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              disabled={true}
            />,
          )}
        </Form.Item>
          <Form.Item>
            E-mail : 
            {getFieldDecorator('email', {
              initialValue:this.state.user.email,
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
            })(<Input style={{width:'300px',marginLeft: '10px'}} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)'}} />}
            placeholder="Email"/>)}
          </Form.Item>

          <Form.Item>
          Name :
          {getFieldDecorator('name', {
              initialValue:this.state.user.name,
            rules: [{ required: true, message: 'Please input your name!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Name"
            />,
          )}
        </Form.Item>

        <Form.Item>
          Surname :
          {getFieldDecorator('surname', {
              initialValue:this.state.user.surname,
            rules: [{ required: true, message: 'Please input your surname!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Surname"
            />,
          )}
        </Form.Item>

          <Form.Item>
          Phone number : 
          {getFieldDecorator('phone',{initialValue:this.state.user.tel,rules: [{ required: true, message: 'Please input your phone!' },
                {
                  validator: this.validatePhone,
                },],})(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Phone number"
            />,
          )}
        </Form.Item>

        <Form.Item>
          Adress :
          {getFieldDecorator('adress', {
              initialValue:this.state.user.adress,
            rules: [{ required: true, message: 'Please input your adress!' }],
          })(
            <Input
              style={{width:'500px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Adress"
            />,
          )}
        </Form.Item>

          <Form.Item>
            Institution :  
            <Input 
            style={this.state.outline_institution && !this.state.institution_selected?{ width: 200 ,outline:'solid',outlineWidth:1,outlineColor:'red' }:{width: 200}} 
            value={this.state.choosen_institution}
            onChange={this.handleInstitutionChange}>
            </Input>
          </Form.Item>

          <Form.Item>
            Laboratory : 
            <Input 
            style={this.state.outline_laboratory && !this.state.laboratory_selected?{ width: 200,outline:'solid',outlineWidth:1,outlineColor:'red' }:{ width: 200 }} 
            value={this.state.choosen_laboratory}
            onChange={this.handleLaboratoryChange}>
            </Input>
          </Form.Item>

          <Form.Item>
            Team : 
            <Input 
            style={this.state.outline_team && !this.state.team_selected?{ width: 200,outline:'solid',outlineWidth:1,outlineColor:'red' }:{ width: 200 }} 
            value={this.state.choosen_team} 
            onChange={this.handleTeamChange}>
            </Input>
          </Form.Item>
          
          <Form.Item>
          Function :
          {getFieldDecorator('function', {
            initialValue:this.state.user.fonction,
            rules: [{ required: true, message: 'Please input your function!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Function"
            />,
          )}
        </Form.Item>

        <Form.Item>
          Quota :
          {getFieldDecorator('quota', {
            initialValue:this.state.user.quota,rules: [{ required: true, message: 'Please input the quota!' }],
          })(
            <InputNumber  style={{width:'200px',marginLeft: '10px'}} min={0} max={1073741824} disabled={!this.state.staff} />,
          )}
        </Form.Item>
        
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF'}} >
                Update
            </Button>
        </Form.Item>


        </Form>
        {
          this.state.error_message_mail !== "" &&  this.state.error_message_mail !== undefined &&
          <div style={{marginTop:'1%',color:'red',marginLeft:'31%'}}>{this.state.error_message_mail}</div>
        }
        <Title style={{marginLeft:'31%'}}> Delete user </Title>
        <Form style={{marginLeft:'31%'}} onSubmit={this.handleSubmit}>
          <Form.Item>
            <Button type="primary" onClick={(event) =>  this.setState({displayDeleteWindow : true,})} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF'}} >
                Delete
            </Button>
        </Form.Item>
        </Form>
            {
                this.state.displayDeleteWindow?           
                <div>
                <h1>Are you sure to delete this user ?</h1>
                <Button type="danger" onClick={this.deleteUser} >Yes</Button>
                <Button type="primary" onClick={this.hideDeletePanel} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF'}}>No</Button>
                </div>
                :
                <div></div>
            }
        </div>
            </div>
        
        :
        <div>No user selected , access this page only using User Management, or verify you have permissions to see this page !</div>
      }
        </div>

      );
    }
  }
  
  const WrappedDisplayForm = Form.create({ name: 'update' })(UserDisplayForm);

  //get data from app
  const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        isAuthenticated: state.token !== null,
        error : state.error
    }
}

//bind auth function
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedDisplayForm);