import React from "react";
import SharingListView from "./sharingListContainer";
import {ChooseSetFromUrl} from "../utils/api_callbacks";
import {connect} from "react-redux";


class SharingListDummy extends React.Component {

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    render() {
        return (<div><SharingListView></SharingListView></div>);
    }
}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,

    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SharingListDummy);