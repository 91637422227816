import React from 'react';
import axios from 'axios';
import Unity from "react-unity-webgl";
import {getUserToken} from '../store/utility';
import { List,Button } from 'antd';


class MorphoAPI extends React.Component {
    constructor(props){
        super(props);
    }

    state = {
        token : "",
        url_params : {},
        parameter : [
            {name:'id_dataset',description:'Open a specific dataset',example:'id_dataset=1062'},
            {name:'menu',description:'Open a specific menu by default',example:'menu=Genetic'},
            {name:'reset',description:'Specific Action on color or selection',example:'reset:color or reset=selection'},
            {name:'reset+number',description:'Multiple Specific Action on color or selection',example:'reset1=color&reset2=selection'},
            {name:'stage',description:'Developmental stage number',example:'stage=5a or stage=11'},
            {name:'gene',description:'Expression Gene to visualize',example:'gene=Cirobu.g00002212 \n Required Parameter : menu'},
            {name:'gene+number',description:'Multiple Expression Genes to visualize',example:'gene1=Cirobu.g00002212&gene2=Cirobu.g00011105&gene3=Cirobu.g00012743'},
            {name:'fate',description:'Fate Selection',example:'Fate Name; Example : fate=Epidermis (replace " " by "_")'},
            {name:'territory',description:'Territories Selection',example:'territory=b_line (replace " " by "_")'},
            {name:'mintime',description:'Select the starting time point to load',example:'mintime=1'},
            {name:'maxtime',description:'Select the finishing time point to load',example:'maxtime=2'}
        ],
        params : {},
        id_dataset : -1,
        dataset: {},
        stages : {},
        loaded:false,
        dataset_loaded:false,
        dataset_treated:false,
        stages_treated:false,
        dataset_loading:false,
        stages_got:false,
        done:false,
        url_pattern: "",
    }

    
    componentDidMount() {
        //////console.log("API page params : ");
        let url_pattern = this.props.location.search;
        this.setState({url_pattern:url_pattern});
        url_pattern = url_pattern.replace('?','');
        ////console.log(url_pattern);
        if (url_pattern !== undefined && url_pattern !== "" ){
            const url_params = url_pattern.split('&');
            for (let params in url_params){
                const values = url_params[params].split('=');
                if (values[1] !== "")
                    this.state.url_params[values[0]] = values[1];
                //////console.log(url_params[params]);
            }
            window.scrollTo(window.scrollX+1,window.scrollY+1);
        }
        else {
            this.props.showUnity(false);
        }
        
        this.setState({token : getUserToken()});
        ////console.log("URL params get");
      //  if (!isTablet && !isMobile && !isAndroid && !isIOS  && !isSafari && !isIE) window.addEventListener("message",this.receiveMessage,false);
    }

    receiveMessage = (event) => {
        if (event.data !== "")
            this.props.catchMessage(event);
    }

    componentDidUpdate(nextState,nextProps){
        if (this.state.url_params !== nextState.url_params && !this.state.dataset_loading && !this.state.dataset_loaded && this.state.url_pattern !== undefined && this.state.url_pattern !== ""){
            this.state.id_dataset = 1062;
            this.state.params.id = 1062;
            for (let key in this.state.url_params){
                //////console.log("url["+key+"] = "+this.state.url_params[key]);
                this.state.params[key] = this.state.url_params[key];
                if (key === "id_dataset"){
                    this.state.id_dataset =this.state.url_params[key];
                    this.state.params.id = this.state.url_params[key];
                }
                else if (key === "mintime"){
                    this.state.params['api_mintime'] = this.state.url_params[key];
                }
                else if (key === "maxtime"){
                    this.state.params['api_maxtime'] = this.state.url_params[key];
                }
            }
            this.setState({dataset_loading:true});

            axios({
                method: 'get',
                url: `/api/sitedataset/${this.state.id_dataset}/`,
              }
            ).then(res => {
                ////console.log(res.data);
                    this.setState({dataset:res.data}); 
                    this.setState({dataset_loaded:true});
                    ////console.log("Site dataset loading");
            });

        }
  
        if (this.state.dataset !== nextState.dataset && this.state.dataset_loaded && !this.state.datasettreated){
            //console.log("Dataset treated");
            this.state.params['u_ID']=0;
            this.state.params['u_right'] = 3;
            this.state.params['u_name'] = "Public";
            this.state.params['u_surname'] = "";
            for (let dataset_param in this.state.dataset){
                //console.log("trying : "+dataset_param);
                if (this.state.params[dataset_param] === undefined || this.state.params[dataset_param] === null)
                {
                    if (dataset_param === "id_dataset"){
                        this.state.params['id'] = this.state.dataset[dataset_param];
                    }
                    else{
                        //console.log("bound : "+dataset_param)
                        this.state.params[dataset_param] = this.state.dataset[dataset_param];
                    }
                }
            }

            if (this.state.params['quality'] === undefined || this.state.params['quality'] === null){
                    this.state.params['quality'] = 0;
            }
           
            axios({
            method: 'get',
            url: `/api/sessionmeshid/`,
            params : { dataset_id:this.state.id_dataset},
            }).then(res => { 
                for (let elem in res.data){
                    this.state.stages[res.data[elem]['name']] = res.data[elem]['t'];
                }
                if (this.state.params.id === null || this.state.params.id === undefined){
                    this.state.params.id = 1062;
                }
                if (this.state.params.time === null || this.state.params.time === undefined){
                    if (Object.keys(this.state.params["stage"]).includes("Stage "+this.state.params["stage"])){
                        this.state.params.time = parseInt(this.state.stages["Stage "+this.state.params["stage"]]);
                    }
                    else {
                        if (this.state.params['time'] === undefined || this.state.params['time'] === null){
                            this.state.params['time'] = this.state.params['maxtime'];
                        }
                    }

                }
                //this.props.openUnityFromAway();
               /* //console.log("Stages : ");
                //console.log(this.state.stages);
                //console.log("Params : ");
                //console.log(this.state.params);*/

                this.props.receiveStage(this.state.stages);
                this.props.unityLoadFunc(this.state.params);
                this.setState({loaded:true});
                ////console.log("stages got")
            });
            


            this.setState({datasettreated:true});
        }
/*
        if (JSON.stringify(this.state.stages) !== JSON.stringify(nextState.stages) && this.state.stages_got && !this.state.stages_treated && this.state.params['stage'] !== undefined && this.state.params['stage'] !== null){
            //console.log("Treating stages");
            var fullname = "Stage "+this.state.params['stage'];
            if (this.state.stages[fullname] !== undefined && this.state.stages[fullname] !== null){
                this.state.params['time'] = this.state.stages[fullname];
            }
            else if (this.state.stages[this.state.params['stage']] !== null && this.state.stages[this.state.params['stage']] !== undefined){
                this.state.params['time'] = this.state.stages[this.state.params['stage']];
            }
            else if (this.state.stages[fullname.toLowerCase()] !== undefined && this.state.stages[fullname.toLowerCase()] !== null){
                this.state.params['time'] = this.state.stages[fullname.toLowerCase()];
            }
            ////console.log("stages treated");
            this.setState({stages_treated:true});
        }
*/
        if (this.state.datasettreated && this.state.loaded &&!this.state.done){
            ////console.log("send infos");
            //send params
            this.setState({done:true});
            //this.API_Load(this.state.url_params);
            //this.props.unityLoadFunc(this.state.params);
            //send stages
            //this.props.receiveStage(this.state.stages);
            //this.setState({infos_sent:true});
        }
    }

    render() {
        return (
            <div>
                
                    {
                        this.props.location.search !== "" &&
                        <div>
                        {
                            !this.state.loaded && false &&
                            <h1 style={{fontWeight:'bold',fontSize:'21px'}}>3D viewer is loading ...</h1>
                        }
                        </div>
                    }
                <br></br>
                {
                    this.props.location.search === ""  &&
                    <div>
                        <img alt={"logo"} style={{margin:'auto'}} width={150} src='images/logo.png'></img>
                        <h1 style={{fontWeight:'bold',fontSize:'25px',textAlign:'center', textDecoration:'underline'}}> How to request the unity frame using parameters : </h1>
                        <br></br>
                        <ol style={{marginLeft:'10%'}}>
                            <li style={{fontSize:'17px',whiteSpace:'pre-line'}}>You have to send a request to this url : {window.location.href}</li>
                            <br></br>
                            <li style={{fontSize:'17px',whiteSpace:'pre-line'}}>Then you add the first parameter like this : ?<i>parameter_name</i>=<i>parameter_value</i></li>
                            <br></br>
                            <li style={{fontSize:'17px',whiteSpace:'pre-line'}}>Then you can add extra parameters like this : &<i>parameter_name</i>=<i>parameter_value</i></li>
                            <br></br>
                            <li style={{fontSize:'17px',whiteSpace:'pre-line'}}>At the end, all together it will looks like : <br></br> Wrapped up  : {window.location.href}?<i>parameter1_name</i>=<i>parameter1_value</i>&<i>parameter2_name</i>=<i>parameter2_value </i></li>
                            <br></br>
                            <li style={{fontSize:'17px',whiteSpace:'pre-line'}}>Let's look at an example : <br></br> Need a real requete</li>

                        </ol>
                        <br></br>
                        <br></br>

                        <h4 style={{fontWeight:'bold',fontSize:'18px', textDecoration:'underline', marginLeft:'10%'}}> Let's see which parameters are available and what their meaning:</h4>
                        <br></br>


                        <List
                            style={{margin:'auto', marginLeft:'10%'}}
                            itemLayout="horizontal"
                            dataSource={this.state.parameter}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta

                                        title= {
                                            <div>
                                                <span style={{marginLeft:'10px',fontSize:'15px',color:'#44D4FF',fontWeight:'bold',whiteSpace:'pre-line'}}>{item.name}</span> <span style={{marginLeft:'10px',fontSize:'14px',whiteSpace:'pre-line'}}>: {item.description}</span>
                                            </div>
                                        }
                                        description={
                                            <span style={{marginLeft:'10x',fontSize:'16px',whiteSpace:'pre-line'}}>Example of use : <i>{item.example}</i></span>
                                        }
                                    />
                                </List.Item>
                    )}
                />
                 { /*
                <br></br>
                <h1 style={{fontWeight:'bold',fontSize:'21px',textAlign:'center'}}> How to request the unity frame using parameters : </h1>
                <h1 style={{fontSize:'17px',color:'#44D4FF',fontWeight:'bold',whiteSpace:'pre-line',textAlign:'center'}}>Send a request to this url : {window.location.href}</h1>
                <h1 style={{fontSize:'17px',color:'#44D4FF',fontWeight:'bold',whiteSpace:'pre-line',textAlign:'center'}}>Then you add the first parameter like this : ?<i>parameter_name</i>=<i>parameter_value</i></h1>
                <h1 style={{fontSize:'17px',color:'#44D4FF',fontWeight:'bold',whiteSpace:'pre-line',textAlign:'center'}}>Then you add parameters like this : &<i>parameter_name</i>=<i>parameter_value</i></h1>
                <br></br>
                <h1 style={{fontSize:'17px',color:'#44D4FF',fontWeight:'bold',whiteSpace:'pre-line',textAlign:'center'}}>Wrapped up  : {window.location.href}?<i>parameter1_name</i>=<i>parameter1_value</i>&<i>parameter2_name</i>=<i>parameter2_value</i></h1>
                <br></br>
                */ }

                    </div>
                }


            </div>
        );
    }
}

export default MorphoAPI;