import React from 'react';
import {List} from 'antd';
import '../App.css';
import axios from 'axios';
import {getUserToken,getUserID} from '../store/utility';


class SimulationDescription extends React.Component {
    constructor(props) {
        super(props);

    }

    //this store data on the webpage, change on a variable can be catch in componentDidUpdate()
    state = {
        clicked_simulation: {},
        token: "",
        connected_user_id: -1,
        simulation_size: -1,
        staff: false,
        simulation_versions: [],
        nb_versions: 0,
    }

    //User status for a specific simulation, using sharings data
    getUserStatus = (user_id) => {
        const status = "norights";
        if (this.state.clicked_simulation.id_people === user_id) {
            return "manager";
        }
        this.state.user_shared.map(user => {
        })
        return status;
    }

    componentDidMount() {
        this.state.connected_user_id = getUserID();
        this.setState({ token: getUserToken() });
    }

    bytesToSize = bytes => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0 || bytes === -1) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    SimulationIsPublic = (lpy) => {
        const path = lpy;
        if (path !== null) {
            if (path.startsWith('simulations_public/'))
                return true;
        }
        return false;
    }
/*
    UpdateValues = () => {
        if (this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }

        if (this.props.clicked_simulation !== null && !this.SimulationIsPublic(this.props.clicked_simulation.lpy)) {
            this.setState({ clicked_simulation: this.props.clicked_simulation });
            axios.get('/api/simulationsize/', { params: { name_simulation: this.props.clicked_simulation.name } })
                .then(res => {
                    this.setState({ simulation_size: res.data });
                });

            axios.get('/api/simulationversions/', { params: { name_simulation: this.props.clicked_simulation.name }, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulation_versions: res.data['versions'].reverse() });
                    this.state.clicked_simulation.version = res.data['versions'].reverse()[res.data['versions'].length-1]
                   // this.props.clicked_simulation.version = res.data['versions'].reverse()[res.data['versions'].length-1]
                    this.setState({ nb_versions: this.state.simulation_versions.length });
                });
        }

        if (this.props.clicked_simulation !== null && this.SimulationIsPublic(this.props.clicked_simulation.lpy)) {
            this.setState({ clicked_simulation: this.props.clicked_simulation });
            axios.get('/api/simulationpublicsize/', { params: { name_simulation: this.props.clicked_simulation.name }, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulation_size: res.data });
                });
        }

        if (this.props.simulation_size !== null && this.props.simulation_size !== -1) {
            this.setState({ simulation_size: this.props.simulation_size });
        }
    }
*/
    componentDidUpdate(prevProps, prevState) {
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }

        if (this.props.clicked_simulation !== this.state.clicked_simulation && this.props.clicked_simulation !== null && !this.SimulationIsPublic(this.props.clicked_simulation.lpy)) {
            this.setState({ clicked_simulation: this.props.clicked_simulation });
            axios.get('/api/simulationsize/', { params: { name_simulation: this.props.clicked_simulation.name }, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulation_size: res.data });
                });
          
            axios.get('/api/simulationversions/', { params: { name_simulation: this.props.clicked_simulation.name }, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulation_versions: res.data['versions'] });
                    this.state.clicked_simulation.version = res.data['versions'][res.data['versions'].length-1].date;
                    this.setState({ nb_versions: this.state.simulation_versions.length });
                });
            
        }

        if (this.props.clicked_simulation !== this.state.clicked_simulation && this.props.clicked_simulation !== null && this.SimulationIsPublic(this.props.clicked_simulation.lpy)) {
            this.setState({ clicked_simulation: this.props.clicked_simulation });
            axios.get('/api/simulationpublicsize/', { params: { name_simulation: this.props.clicked_simulation.name }, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulation_size: res.data });
                });
        }

        if (this.props.simulation_size !== this.state.simulation_size && this.props.simulation_size !== null && this.props.simulation_size !== -1) {
            this.setState({ simulation_size: this.props.simulation_size });
        }
    }

    handleChange = e => {
        this.state.clicked_simulation.version =  e.target.value;
    };

    render() {
        const selected_options = undefined;
        return (
            <div>
                {
                    this.state.clicked_simulation !== undefined && this.state.clicked_simulation !== null ?
                        <div><p style={{ display: 'inline', fontSize: '24px' }}
                            className={'content-text'}>{this.state.clicked_simulation.name}{this.state.staff ? ` (name ${this.state.clicked_simulation.name})` : ""}</p>
                            <br></br><br></br>

                            <img alt="No simulation preview gif available."
                                style={{ cursor: 'pointer', float: 'right', marginRight: '5px' }} width={300}
                                src={"/GIF/SIMULATION_" + this.state.clicked_simulation.name + "-300.gif"}></img>


                            {this.state.clicked_simulation !== null && this.state.clicked_simulation.date !== null && <div><h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Upload the
                                : </h2>
                                <p style={{ display: 'inline' }}
                                    className={'content-text'}>{this.state.clicked_simulation.date.replace("T", " ").replace("Z", " ")}</p>
                                <br></br><br></br></div>
                            }

                            {this.state.clicked_simulation !== null && this.state.clicked_simulation.description !== null && this.state.clicked_simulation.description !== undefined && this.state.clicked_simulation.comments !== "" &&
                                <div><h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Description
                                    : </h2>
                                    <p style={{ display: 'inline' }}
                                        className={'content-text'}>{this.state.clicked_simulation.description}</p>
                                    <br></br><br></br>
                                </div>}
                            <br></br><br></br>

                            <div><h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Simulation size (script + description)
                                : </h2>
                                <span className={'content-text'}>{this.bytesToSize(this.state.simulation_size)}</span>
                                <br></br> <br></br></div>
                            <br></br>

                            
                            
                            {
                                !this.SimulationIsPublic(this.state.clicked_simulation.lpy) &&
                                <div><h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Simulation versions : </h2>
                                    <br></br> <br></br>
                                </div>

                            }

                            <br></br>
                                    
                            {
                                !this.SimulationIsPublic(this.state.clicked_simulation.lpy) && this.state.simulation_versions !== undefined && this.state.simulation_versions !== [] && this.state.simulation_versions.length > 0 ?
                                    <div >
                                        <List
                                            itemLayout="horizontal"
                                            style={{ width: '60%' }}
                                            dataSource={this.state.simulation_versions}
                                            renderItem={element => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={<div><input type="radio" name="version" id={element.name}
                                                            value={element.date} checked={element.date === this.state.clicked_simulation.version}
                                                            onChange={this.handleChange} />
                                                            <label htmlFor={element.name} style={{ marginLeft: '10px' }}>{element.name}</label></div>}
                                                        description={
                                                            <div style={{ marginLeft: '30px' }}>
                                                                <span>date of version : {element.date}</span>
                                                                <br></br>
                                                                <span>comment : {element.comment}</span></div>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                    :

                                    <span style={{ marginLeft: '10px' }}>no saved version</span>

                            }
                            
                            
                        </div>
                        :
                        <div></div>
                }
            </div>
        );
    }
}

export default SimulationDescription;