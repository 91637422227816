import React from 'react';

class Technos extends React.Component {
    render(){
                    /* 
            <input type="image" width="150px;"  src="images/logo.png" onclick="window.open('index.php')" />
            */
        return (

            <div>

                <div style={{marginRight: 'auto',  marginLeft: 'auto', marginTop:'10px', marginBottom:'auto'}}>

                    <div style={{width:'800px',textAlign:'center',marginLeft:'auto',marginRight:'auto',padding:'10px',border:'3px solid #DBF0FC',fontSize:'20px'}} >

                        <p style={{fontWeight:'bold', fontSize:'25px',color:'black',margin:'auto', textDecoration:'underline'}} >Technology used </p>
                        <br></br>

                        <table style={{backgroundColor:'white',textAlign:'left',padding:'50px'}}>
                            <tr style={{backgroundColor:'white',marginTop:'50px'}}>
                                <td style={{padding:'20px'}}>
                                    <img width="50px" alt="Django" src="https://static.djangoproject.com/img/logos/django-logo-positive.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://www.djangoproject.com/')}} /></td>
                                <td> Django (Version 1.5) [Computer Software]. (2013). Retrieved from https://djangoproject.com.  </td>
                            </tr>

                            <tr style={{backgroundColor:'white',marginTop:'50px'}}>
                                <td style={{padding:'20px'}}>
                                    <img width="50px" alt="React" src="https://reactjs.org/logo-og.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://reactjs.org/')}} /></td>
                                <td> Website created using ReactJS  </td>
                            </tr>

                            <tr style={{backgroundColor:'white'}}>
                                <td style={{padding:'20px'}}>
                                    <img width="70px" alt="Ant Design" src="https://gw.alipayobjects.com/zos/rmsportal/rlpTLlbMzTNYuZGGCVYM.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://ant.design/')}} /></td>
                                <td> Interface using elements from Ant Design   </td>
                            </tr>

                            <tr style={{backgroundColor:'white'}}>
                                <td style={{padding:'20px'}}>
                                    <img width="70px" alt="Unity" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Unity_Technologies_logo.svg/275px-Unity_Technologies_logo.svg.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://unity.com/')}} /></td>
                                <td> 3D Viewer developed using Unity 3D   </td>
                            </tr>
                        </table>

                        <br></br>
                        <br></br>
                        <p style={{fontWeight:'bold',fontSize:'25px',color:'black',margin:'auto', textDecoration:'underline'}} >Credentials of used logos </p>
                        <br></br>

                        <table style={{backgroundColor:'white',textAlign:'left'}}>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                                <td style={{width:'100%'}}> <div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></td>                                
                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                               <td style={{padding: '20px',width:'100%'}}><img alt={"data manipulation"} width="40px"  src='images/logo_data_manip.svg' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} /></td>
                                
                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                                <td style={{width:'100%'}}> <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></td>
                                
                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                                <td style={{padding: '20px',width:'100%'}}><img alt="format" width="40px"  src='images/logo_format_new.png' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} />
                                <img width="40px" alt={"video"} src='images/logo_demo_video.svg' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} />
                                <img width="40px"  alt="integration" src='images/logo_integration_morpho.svg' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} />
                                <img width="40px" alt={"twitter"} src='images/logo_twitter.svg' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} />
                                <img width="40px"  alt="user" src='images/unisex_best.png  ' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} />
                                </td>
                                
                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                            <td style={{width:'100%'}}>
                                <div>Icons made by <a href="https://www.flaticon.com/authors/iconixar" title="iconixar">iconixar</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></td>
                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                            <td style={{padding: '20px',width:'100%'}}>
                                    <img alt="Converter" width="40px"  src='images/logo_converter_new.svg' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} /></td>
                            </tr>
                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>

                            <td style={{width:'100%'}}>
                                <div>Icons made by <a href="" title="surang">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>                            </td>

                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                            <td style={{padding: '20px',width:'100%'}}>
                                <img alt="documentation" width="40px"  src='images/logo_api_documentation.svg' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px',padding:'5px'}} /></td>
                        </tr>



                            
                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                                <td style={{width:'100%'}}>Avatar maker : <a href="https://avatarmaker.com/" title="Avatar_maker">https://avatarmaker.com</a>  </td>
                            </tr>

                            <tr style={{backgroundColor: 'white', marginTop: '50px'}}>
                            <td style={{padding: '20px',width:'100%'}}>
                                    <img alt={"Marine"} width="40px"  src='images/Marine.png' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '10px'}} /></td>
                            </tr>

                        </table>

                    </div>
                </div>
            </div>
        );
    }
}

export default Technos;

