import axios from "axios";
import store from "../store/store"
import {
    BindBeta,
    BindStaff,
    chooseGroup, chooseStudy,
    ConnectUser,
    LoadGroups,
    LoadMultipleGroupUsers,
    LoadMultiplePeopleGroup, LoadMultiplePeopleStudy,
    LoadMultipleSetRight,
    LoadMultipleSetsFavourite,
    LoadMultiplesStudyGroup,
    LoadSetRight, SetRedirectedUrl,
    SetServerList,
    SetUserSettings,
    StoreContacts,
    UnityLoaded
} from "../store/reducers/userSlice";
import { redirect } from "react-router-dom";
import {LoadMultipleTagSets, tagListAdded, tagListUpdated} from "../store/reducers/tagSlice";
import {
    AddCategorieById,
    AddGifsById,
    AddLinksById,
    AddQualitiesById,
    AddSetOwnerById,
    AddSizeById,
    chooseSet, datasetClear,
    datasetListAdded, datasetListUpdated
} from "../store/reducers/datasetSlice";
import {LoadMultiplePrioritiesDataset, priorityListAdded} from "../store/reducers/prioritySlice";
import {ClearInfos, infosListAdded, infosListUpdated, LoadMultipleInfosOwners} from "../store/reducers/infosSlice";
import {sessionClearAll, sessionListAdded, sessionListUpdated} from "../store/reducers/sessionSlice";
import {getCookie, getUserID, getUserToken} from "./CookieManagement";
import {sharingListAdded, sharingListUpdated} from "../store/reducers/sharingSlice";
import {GetSetInUrl} from "./tools";


function AddToken(config) {
    const token = getUserToken();
    return {...config, headers: {Authorization: `Token ${token}`}}
}

export function LoadTags(set_list){
    const notokenset = {params: {id_datasets: set_list}};
    const config = AddToken(notokenset);
    axios.get('/api/getdatasettags/', config)
        .then(response => {
            store.dispatch(LoadMultipleTagSets(response.data));
        });
}
export function RemoveTag(id_set, id_tag) {
    if (id_tag !== -1) {
        const state = store.getState();
        const token = AddToken({params: {tag_id: id_tag, dataset_id: id_set}});
        axios.get('/api/untagadataset/', token).then(() => {
            LoadTags(id_set+",");
        })
    }

}

export function ChangeDatasetPriority(priority, id_set) {
    const realprio = priority === -1 ? undefined : priority;
    const token = AddToken({});
    axios({
        method: 'post',
        url: '/api/updatedatasetsetting/',
        data: {
            id_dataset: id_set,
            value: realprio,
            setting: 'priority',
        },
        headers: {Authorization: `Token ${getUserToken()}`}
    })
        .then((res) => {
            const state = store.getState();
            const setid_list = Object.values(state.dataset.entities).map(set => set.id).toString();
            GetPriority(setid_list);
        }).catch((res) => {
        const state = store.getState();
        const setid_list = Object.values(state.dataset.entities).map(set => set.id).toString();
        GetPriority(setid_list);
    });
}


export function GetPriority(set_list){
    const token = AddToken({params:{id_datasets:set_list}})
    axios.get(`/api/datasetpriority/`, token)
        .then(res2 => {
            var priorities = [];
            res2.data.map(prio => {
                priorities.push([prio.id_dataset,prio]);
            });
            store.dispatch(LoadMultiplePrioritiesDataset(priorities));
        })
}
export function DeleteLink(id_links, id_set) {
    axios({
        method: 'delete',
        url: `/api/links/${id_links}/`,
        params: {id_dataset: id_set},
        headers: {Authorization: `Token ${getUserToken()}`}
    }).then(() => {
        LoadDatasetLinks(id_set);
    });
}

export function CreateLink(link, description, id_set) {
    let error = false;
    if (link === "" || link === undefined) {
        error = true;
        // do something
    }

    if (!error) {
        //send data to django !
        //post new group to django
        axios({
            method: 'post',
            url: '/api/links/',
            data: {
                link: link,
                description: description,
                id_dataset: id_set,
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(() => {
            LoadDatasetLinks(id_set);
        });
    }

}

export function ChangeSessionDefault(checked, session_id, set_id) {
    // console.log("Update session defaut");
    //axios request
    const token = AddToken({params: {id_session: session_id, defaut: checked ? 1 : 0}});
    axios.get('/api/sessiondefaut/', token)
        .then(() => {
            ReLoadSessions(set_id);
        });
}

export function DeleteSelectedSession(session, set_id) {
    if (window.confirm('Are you sure you wish to delete this session ?')) {
        SendTracking(set_id, "Web,Dataset,Session,delete");
        //post new group to django
        axios({
            method: 'delete',
            url: `/api/sessionmanagement/${session.id}/`,
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(() => {
            ReLoadSessions(set_id);
            store.dispatch(SetRedirectedUrl("/dataset/"+set_id+"/sessions/"));
        });
    }
}

export function LoadUserServers() {
    const token = AddToken({});
    axios.get(`/api/distantserver/`, token)
        .then(res => {
            SetServerList(res.data);
        });
}

export function UpdateExistingSession(name, url, session, set_id) {
    if (name === "" || name === undefined) {
        name = session.name;
        // do something
    }
    if (url === undefined || url === "") {
        url = session.url;
        // do something
    }
    if (url.includes("id_dataset")) {
        console.log("url ok")
    } else {
        if (url.startsWith("&")){
            url = "id_dataset=" + set_id + url;
        }
        else {
            url = "id_dataset=" + set_id + "&" + url;
        }

    }


    SendTracking(set_id, "Web,Dataset,Session,update");
    //post new group to django
    axios({
        method: 'patch',
        url: `/api/sessionmanagement/${session.id}/`,
        data: {
            name: name,
            url: url,
        },
        headers: {Authorization: `Token ${getUserToken()}`}
    }).then(() => {
        ReLoadSessions(set_id);
        store.dispatch(SetRedirectedUrl("/dataset/"+set_id+"/sessions/"));
    });
}

export function AddTag(id_set, id_tag) {
    if (id_tag !== -1) {
        const state = store.getState();
        const token = AddToken({params: {tag_id: id_tag, dataset_id: id_set}});
        axios.get('/api/tagadataset/', token).then(() => {
            LoadTags(id_set+",");

        })
    }

}

export function LoadUserSettings() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const config = AddToken({});
        axios.get('/api/settings/', config).then(res => {
            store.dispatch(SetUserSettings(res.data));
        });
    }
}

export function ReloadInfos(set_id){
    store.dispatch(ClearInfos());
    const config = {params: {id_dataset: set_id}};
    let infos = [];
    axios.get('/api/publiccorrespondence/', config).then(res => {
        return res.data;
    }).then(the_infos => {
        the_infos.map(info => {
            infos.push(info);
        })
        //If we're not connected, we can post infos to store now.
        if (getUserToken() === undefined || getUserToken() === "" || getUserToken() === null) {
            store.dispatch(infosListUpdated(infos));
        }
    }).then( () => {
        // if connected, get non public info
        if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
            const config = AddToken({params: {id_dataset: set_id}});
            axios.get('/api/correspondence/', config).then(res => {
                return res.data;
            }).then(the_infos => {
                Object.values(the_infos).map(info => {
                    infos.push(info);
                })
                store.dispatch(infosListUpdated(infos));
            });
        }
    });
}
export function LoadPublicInfos(set_id) {
    const config = {params: {id_dataset: set_id}};
    axios.get('/api/publiccorrespondence/', config).then(res => {
        store.dispatch(infosListAdded(res.data));
        return res.data;
    }).then(the_infos => {
        LoadInfosOwner(the_infos);
    });
}

export function PostUpdateStudy(new_name,new_description,studyid,groupid) {
    //updatestudy
    const config = {params: {new_name: new_name,new_description:new_description,id_study:studyid}};
    axios.get('/api/updatestudy/', config).then(res => {
        store.dispatch(infosListAdded(res.data));
        return res.data;
    }).then(the_infos => {
        LoadUserGroups();
    }).then(() => {
        store.dispatch(SetRedirectedUrl("/group/"+groupid+"/update"));
    });
}
export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function PostNewSession(name, url, urlprefix, set_id) {
    let error = false;
    if (name === "" || name === undefined) {
        error = true;
        // do something
    }
    if (url === undefined) {
        url = "";
        // do something
    }

    url = urlprefix + url
    if (url.includes("id_dataset")) {
        console.log("url ok")
    } else {
        url = "id_dataset=" + set_id + "&" + url;
    }

    if (!error) {
        SendTracking(set_id, "Web,Dataset,Session,create");
        //send data to django !
        //post new group to django
        axios({
            method: 'post',
            url: '/api/sessionmanagement/',
            data: {
                id_dataset: set_id,
                id_people: getUserID(),
                name: name,
                url: url,
                date: new Date(),
                shortcut: makeid(8),
                defaut: 0
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(() => {
            ReLoadSessions(set_id);
        }).then(() => {
            store.dispatch(SetRedirectedUrl("/dataset/"+set_id+"/sessions/"));
        });
    }
}

export function SendTracking(id_dataset, feature_list) {
    //console.log("func send tracking : "+feature_list);
    if (getCookie('rpgd_track') === undefined || getCookie('rpgd_track') === "true") {
        if (id_dataset === -1) {
            //console.log("send tracking -1 : "+feature_list);
            axios({
                method: 'post', url: '/api/storeuserinteraction/', data: {
                    user_id: getUserID(), feature_list: feature_list,
                }
            });
        } else {
            //console.log("send tracking 0 : "+feature_list);
            axios({
                method: 'post', url: '/api/storeuserinteraction/', data: {
                    user_id: getUserID(), id_dataset: id_dataset, feature_list: feature_list,
                }
            });
        }
    }
}

export function ChooseGroup(group_id) {
    if (group_id !== undefined) {
        store.dispatch(chooseGroup(group_id));
        const grouptoken = AddToken({params: {'groupid': group_id}});

        axios.get('/api/peoplebygroup/', grouptoken)
            .then(res => {
                let groups = {};
                groups[group_id] = res.data;
                store.dispatch(LoadMultipleGroupUsers(groups));
            });

        axios.get('/api/people-group/', grouptoken)
            .then(res => {
                let groups = {};
                groups[group_id] = res.data;
                store.dispatch(LoadMultiplePeopleGroup(groups));
            })
    }
}

export function ChooseStudy(study_id) {
    if (study_id !== undefined) {
        store.dispatch(chooseStudy(study_id));
        LoadPeopleStudy(study_id);
    }
}

export function LoadPeopleStudy(study_id){
    const grouptoken = AddToken({params: {'studyid': study_id}});
    axios.get('/api/people-study/', grouptoken)
        .then(res => {
            let groups = {};
            groups[study_id] = res.data;
            store.dispatch(LoadMultiplePeopleStudy(groups));
        });
}

export function UnlinkDatasetStudy(set_id){
    const grouptoken = AddToken({params: {'id_dataset': set_id}});
    axios.get('/api/unlinksetstudy/', grouptoken)
        .then(res => {
            const state = store.getState();
            const is_staff = state.user.is_staff;
            LoadUserDatasets(is_staff);
        });
}

export function LoadInfosOwner(info_list) {
    const token = {params: {id_infos: info_list.map(set => set.id).toString()}}
    axios.get('/api/getinfosowners/', token).then(ownerinfo => {
        const info_owner = {};
        for (let info in ownerinfo.data) {
            info_owner[info] = ownerinfo.data[info];
        }
        store.dispatch(LoadMultipleInfosOwners(info_owner));
    })
}

export function LoadPrivateInfos(set_id) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const config = AddToken({params: {id_dataset: set_id}});
        axios.get('/api/correspondence/', config).then(res => {
            store.dispatch(infosListAdded(res.data));
            return res.data;
        }).then(the_infos => {
            LoadInfosOwner(the_infos);
        });
    }
}

export function UnityLoadedFunc() {
    store.dispatch(UnityLoaded());
}

export function  ReloadDatasets(){
    store.dispatch(datasetClear());
    DownloadPublicSets();
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        DownloadPrivateSets();
    }
}

export function DownloadPublicSets(){
    return axios.get('/api/publicdatasets/').then(response => {
        const sets = response.data.map(set => {
            set.public = true;
            set.categorie = "Unclassified";
            set.backup_mintime = set.mintime;
            set.backup_maxtime = set.maxtime;
            return set;
        });
        store.dispatch(datasetListUpdated(sets));
        handleSetsWritten();
        return sets;
    })
}

export function DownloadPrivateSets(){
    const token = AddToken({params: {}});
    return axios.get('/api/userdatasets/', token)
        .then(res => {
            return res.data.map(set => {
                set.backup_mintime = set.mintime;
                set.categorie = "Unclassified";
                set.backup_maxtime = set.maxtime;
                set.public = false;
                return set;
            });
        }).then(the_sets => {
        store.dispatch(datasetListAdded(the_sets));
        return the_sets;})
}

export function LoadPublicSets() {
   DownloadPublicSets().then(sets => {
        const setid_list = sets.map(set => set.id).toString();
        LoadGifsForSets(setid_list);
        LoadQualitiesForSets(setid_list);
    })
}


export function AddToFavourite(id_set) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const config = AddToken({params: {id_dataset: id_set}});
        axios.get('/api/addsetfavourite/', config).then(() => {
            LoadFavouritesForUser();
        });
    }
}

export function RemoveFromFavourite(id_set) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const config = AddToken({params: {id_dataset: id_set}});
        axios.get('/api/removesetfavourite/', config).then(() => {
            LoadFavouritesForUser();
        });
    }
}

export function LoadFavouritesForUser() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({});
        axios.get('/api/favourites/', token)
            .then(res => {
                const favourites = {};
                res.data.map((favinstance) => {
                    favinstance.params.split('&').map(parm => {
                        if (parm.split('=')[0] === "id_dataset") {
                            favourites[parseInt(parm.split('=')[1])] = true;
                        }
                    })
                });
                store.dispatch(LoadMultipleSetsFavourite(favourites));
            });
    }
}

export function LoadSessions(set_id) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const config = AddToken({params: {id_dataset: set_id}});
        axios.get('/api/session/', config).then(session => {
            store.dispatch(sessionListAdded(session.data));
        });
    }
}

export function ReLoadSessions(set_id) {
    store.dispatch(sessionClearAll());
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const config = AddToken({params: {id_dataset: set_id}});
        axios.get('/api/session/', config).then(session => {
            store.dispatch(sessionListUpdated(session.data));
        });
    }
}

export async function LoadAdminStatus() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {}});
        let final_staff = false;
        await axios.get('/api/people-admin/', token).then(res => {
            let is_staff = res.data['staff'];
            store.dispatch(BindStaff(Boolean(is_staff)));
            final_staff = Boolean(is_staff);
        });
        return final_staff;
    }
}

export function LoadBetaStatus() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {}});
        axios.get('/api/people-betatest/', token)
            .then(res => {
                let is_beta = res.data['beta']
                store.dispatch(BindBeta(Boolean(is_beta)));
            });
    }
}


export function LoadUserTags() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {}});
        axios.get('/api/usertags/', token)
            .then(res => {
                store.dispatch(tagListAdded(res.data));
            });
    }
}

export function ReLoadUserTags() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {}});
        axios.get('/api/usertags/', token)
            .then(res => {
                store.dispatch(tagListUpdated(res.data));
            });
    }
}

export function InviteUserToGroup(id_group,email){
    var return_text = "";
    if (id_group !== undefined && id_group !== null && id_group > 0){
        if (email !== undefined && email !== null && email !== "" && email.includes("@")){
            axios({
                method: 'post',
                url: '/api/people-group-create/',
                data: {
                    id_group: id_group,
                    email: email,
                },
                headers: {Authorization: `Token ${getUserToken()}`}
            }).then(res => {
                return_text = res.message;
            });
        }
        else {
                 return_text = "email format is incorrect , please verify and try again";
             }
    }
    else {
        return_text = "Group is incorrect, please verify your internet connexion or reload the page";
    }
    return return_text;
}
export function LoadUserGroups() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {}});
        axios.get('/api/group/', token)
            .then(res => {
                store.dispatch(LoadGroups(res.data));
                const token2 = AddToken({params: {groups: res.data.map(group => group.id).toString()}});
                axios.get("/api/studiesbygroup/", token2).then
                (res => {
                    store.dispatch(LoadMultiplesStudyGroup(res.data));
                    if (store.getState().user.current_choosen_study !== -1){
                        ChooseStudy(store.getState().user.current_choosen_study)
                    }
                })
            });
    }
}

export function LoadSetOwner(set_id) {
    const config = {params: {id_datasets: set_id}};
    axios.get('/api/getdatasetowners/', config).then(res => {
        store.dispatch(AddSetOwnerById(res.data))
    });
}

export function LoadDatasetSize(set_id) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {id_dataset: set_id}});
        axios.get('/api/datasetsize/', token)
            .then(res => {
                const val = [set_id, res.data];
                store.dispatch(AddSizeById(val));
            });
    }
}

export function LoadDatasetLinks(set_id) {
    const token = {params: {id_dataset: set_id}};
    axios.get('/api/links/', token)
        .then(res => {
            store.dispatch(AddLinksById([set_id, res.data]));
        });
}

export function AddNewInfo(name, type, set_id, file) {
    let formData = new FormData();
    formData.append('infos', name);
    formData.append('datatype', type.toLowerCase());
    formData.append('id_dataset', set_id)
    formData.append('r_field', file)
    formData.append('type', '3')
    axios({
        method: 'post',
        url: `/api/createcorrespondencesite/`,
        headers: {Authorization: `Token ${getUserToken()}`},
        data: formData,
    }).then(() => {
        ReloadInfos(set_id);

    }).then(() => {
        store.dispatch(SetRedirectedUrl("/dataset/"+set_id+"/properties/"));
    })
}


export function DeleteInfoReload(id_set, id_info) {
    const config = {params: {id_dataset: id_set, id_infos: id_info, id_people: getUserID()}};
    axios.delete('/api/deletecorrespondence/', config).then(() => {
        ReloadInfos(id_set);

    }).then(() => {
        store.dispatch(SetRedirectedUrl("/dataset/"+id_set+"/properties/"));
    });
}

export function DeleteGroupReload(id_group) {
    SendTracking(-1, "Web,Group,delete");
    axios({
        method: 'delete',
        url: `/api/group/${id_group}/`,
        headers: {Authorization: `Token ${getUserToken()}`}
    }).then(() => {
        LoadUserGroups();
    });
}
export function CreateGroupReload(name,privacy) {
    SendTracking(-1, "Web,Group,create");
    axios({
        method: 'post',
        url: '/api/group/',
        data: {
            name : name,
            privacy: privacy,
            date : new Date(),
            status: 0,
            id_owner:getUserID()
        },
        headers :{ Authorization: `Token ${getUserToken()}` }
    }).then(res => {
        LoadUserGroups();

    });
}

export function ShareInfoReload(id_set, info_object) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const bodyFormData = new FormData();
        const token = AddToken({})
        bodyFormData.append("id_infos", info_object.id);
        bodyFormData.append("id_dataset", id_set);
        bodyFormData.append("common", info_object.common === 1 ? "False" : "True");
        axios.post('/api/sharecorrespondence/', bodyFormData, token).then(() => {
            ReloadInfos(id_set);

        });
    }
}


export function ChangePeoopleStudy(e, peoplestudys, id_user,id_study) {
    let status = false;
    peoplestudys.map((people_study) => {
        if (people_study.id_user === id_user && people_study.id_study === id_study && !status) {
            status = true;
            axios({
                method: 'put',
                url: `/api/putpeoplestudy/${people_study.id}/`,
                data: {
                    id_user: people_study.id_user,
                    id_study: people_study.id_study,
                    status: e.target.value,
                    date:new Date(),
                },
                headers: {Authorization: `Token ${getUserToken()}`}
            }).then(() => {
                LoadPeopleStudy(id_study);
            });
        }
    });
    if (!status && e.target.value !== "norights") {

        axios({
            method: 'post',
            url: `/api/peoplestudy/`,
            data: {
                id_user: id_user,
                id_study : id_study,
                date:new Date(),
                status: e.target.value,
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(() => {
            LoadPeopleStudy(id_study);
        });
    }

}


export function ChangePeopleGroupState(group_object, e, peoplegroups, id) {
    let status = false;
    peoplegroups.map((peoplegroup) => {
        if (peoplegroup.id_people === id && peoplegroup.id_group === group_object.id) {
            status = true;
            axios({
                method: 'put',
                url: `/api/putpeoplegroup/${peoplegroup.id}/`,
                data: {
                    id_people: peoplegroup.id_people,
                    id_group: peoplegroup.id_group,
                    date: peoplegroup.date,
                    status: e.target.value.toLowerCase() === "deleted" ? "waiting" : e.target.value,
                },
                headers: {Authorization: `Token ${getUserToken()}`}
            }).then(() => {
                LoadUserGroups();
                chooseGroup(group_object.id)
            });
        }
    });
    if (!status && e.target.value.toLowerCase() !== "deleted") {
        axios({
            method: 'post',
            url: `/api/peoplegroup/`,
            data: {
                id_people: id,
                id_group: group_object.id,
                date: new Date(),
                status: e.target.value,
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(() => {
            LoadUserGroups();
            chooseGroup(group_object.id)
        });
    }

}

export function ShareGroupReload(group_object, privacy) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const bodyFormData = new FormData();
        const token = AddToken({})
        bodyFormData.append("group_id", group_object.id);
        bodyFormData.append("privacy", privacy);
        axios.post('/api/sharegroup/', bodyFormData, token).then(() => {
            LoadUserGroups();
            chooseGroup(group_object.id)
        });
    }
}

export function RenameInfoReload(new_name, id_info, id_set) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({});
        const bodyFormData = new FormData();
        bodyFormData.append("id_infos", id_info);
        bodyFormData.append("name", new_name);
        axios.post('/api/renameinfo/', bodyFormData, token).then(() => {
            ReloadInfos(id_set);
        });
    }
}

export function RenameGroupReload(new_name, id_group) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({});
        const bodyFormData = new FormData();
        bodyFormData.append("id_group", id_group);
        bodyFormData.append("name", new_name);
        axios.post('/api/renamegroup/',bodyFormData,token).then(() => {
            LoadUserGroups();
            chooseGroup(id_group)
        });
    }
}

export function LoadDatasetRight(set_id) {
    let max_rights = 2;
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {id_dataset: set_id}});
        axios.get('/api/sharingsbyuserdataset/', token)
            .then(res => {
                //dispatch max rights among the sharings in higherrights
                res.data.map(right => {
                    max_rights = Math.min(max_rights, (right.how === 0 ? 2 : 1));
                });
                store.dispatch(LoadSetRight([set_id, max_rights]));
            });
    }
}

export function ShareSetWithPublic(public_status, id_set) {
    //send data to django !
    //post new group to django
    if (public_status) {
        axios({
            method: 'post',
            url: '/api/sharing/',
            data: {
                id_people: getUserID(),
                base: "dataset",
                id_base: id_set,
                id_who: 0,
                how: 0,
                date: new Date()
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(() => {
            LoadSharings();
        });
    } else {
        const token = AddToken({params: {id_dataset: id_set}});
        axios.get('/api/removedatasetpublicsharing/', token)
            .then(() => {
                LoadSharings(id_set+","+id_set);
            });
    }
}


export function ChangeUserState(id, e, id_set) {
    axios({
            method: 'get',
            url: `/api/datasetusersharing/`,
            params: {
                id_user: id,
                id_dataset: id_set,
                statut: e.target.value,
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }
    ).then(() => {
        LoadSharings();
    });
}

export function ChangeGroupState(id_group, e, id_set) {
    axios({
            method: 'get',
            url: `/api/datasetgroupsharing/`,
            params: {
                id_group: id_group,
                id_dataset: id_set,
                statut: e.target.value,
            },
            headers: {Authorization: `Token ${getUserToken()}`}
        }
    ).then(() => {
        LoadSharings();
    });
}


export function LoadSharings() {
    const state = store.getState();
    const setid_list = Object.values(state.dataset.entities).map(set => set.id).toString();
    const token = AddToken({params:{id_datasets:setid_list}})
    axios.get('/api/sharing',token)
        .then(res => {
            store.dispatch(sharingListUpdated(res.data));
        });
}

export function LoadUserData() {
    if (getUserToken() !== undefined) {
        ConnectUser();
        LoadUserSettings();
        LoadUserContacts();
        LoadBetaStatus();
        LoadUserServers();
        LoadAdminStatus().then(admin => {
            LoadUserTags();
            LoadUserGroups();
            LoadFavouritesForUser();
            LoadUserDatasets(admin);
        });

    }
}

export function ChooseSetFromUrl(url, chosen_set) {
    const set = GetSetInUrl(url);
    if (chosen_set !== set) {
        ChooseADataset(set);
    }
}

export function ChooseADataset(dataset) {
    store.dispatch(chooseSet(dataset));
    LoadSessions(dataset);
    LoadDatasetRight(dataset);
    LoadPublicInfos(dataset);
    LoadPrivateInfos(dataset);
    LoadSetOwner(dataset);
    LoadDatasetSize(dataset);
    LoadDatasetLinks(dataset);

}

export function LoadUserContacts() {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        const token = AddToken({params: {}});
        axios.get(`/api/usercontacts/`, token)
            .then(res => {
                store.dispatch(StoreContacts(res.data));
            });
    }
}

export function DeleteStudy(study_id,group_id){
    if (window.confirm('Are you sure you wish to delete this study ?')) {
        axios({
            method: 'get',
            url: `/api/deletestudy/`,
            params: {id_study: study_id},
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(res => {
            LoadUserGroups();

        }).then(() => {
            store.dispatch(SetRedirectedUrl("/group/"+group_id+"/update/"));
        });
    }
}

export function LoadQualitiesForSets(sets_id) {
    axios.get('/api/datasetqualities/', {params: {id_datasets: sets_id}})
        .then(res => {
            store.dispatch(AddQualitiesById(res.data));
        });
}

export function LoadGifsForSets(sets_id) {
    axios.get('/api/listgif/', {params: {id_datasets: sets_id}})
        .then(res => {
            store.dispatch(AddGifsById(res.data));
        }).catch(err => {
            console.log("Gifs not found");
    })
}

const handleSetsWritten = () => {
    window.dispatchEvent(new Event("storage"));
};

export function PostNewStudy(group_id, name, description) {
    const bodyFormData = new FormData();
    const token = AddToken({})
    bodyFormData.append("group_id", group_id);
    bodyFormData.append("name", name);
    bodyFormData.append("description", description);
    axios({
        method: 'post',
        url: '/api/createstudy/',
        data: {
            group_id: group_id,
            name: name,
            description: description,
        },
        headers: {Authorization: `Token ${getUserToken()}`}
    }).then(() => {
        LoadUserGroups();
    }).then(() => {
        store.dispatch(SetRedirectedUrl("/group/"+group_id+"/update/"));
    });

}

export function LoadNcbiInfos(set_id_list){
    axios.get('/api/ncbiinfos/',{ params : {id_datasets: set_id_list }})
        .then(res => {
            var cat = [];
            Object.keys(res.data).map(k => {
                cat.push([k,res.data[k].tree]);
            })
            store.dispatch(AddCategorieById(cat));
        });

}

export function LoadUserDatasets(user_staff,redirect_to_id=null) {
    if (getUserToken() !== undefined && getUserToken() !== "" && getUserToken() !== null) {
        DownloadPrivateSets().then( the_sets => {
            store.dispatch(LoadMultipleSetRight([the_sets, user_staff, getUserID()]))
            const state = store.getState();
            handleSetsWritten();
            if (redirect_to_id !== null)
                store.dispatch(SetRedirectedUrl("/dataset/"+redirect_to_id));
            const setid_list = Object.values(state.dataset.entities).map(set => set.id).toString();
            LoadGifsForSets(setid_list);
            LoadQualitiesForSets(setid_list);
            GetPriority(setid_list);
            LoadSharings();
            LoadTags(setid_list);
            LoadNcbiInfos(setid_list);

        });
    }
}
