import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';
import { Menu } from 'antd';
import {getUserID} from '../store/utility';

class HelpPythonAPI extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
        staff : false,
        subfolder_link : "",
        source_link : "helpfiles/pythonapi.md",
        connected_user_id : -1,
	}

    componentDidMount(){
        this.state.connected_user_id = getUserID();
        this.props.sendTracking(-1,"Web,Help,API,view");
        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("menu")
        if (type !== undefined && type !== "" && type !== null){
            this.setState({source_link:"helpfiles/"+type+".md"});
        }
        else{
            this.setState({source_link:"helpfiles/pythonapi.md"});
        }
    }

    handleClick = e => {
        this.setState({
            source_link: e.key,
        });
        switch(e.key){
            case "helpfiles/pythonapi.md":
                this.setState({subfolder_link:"pythonapi"});
                break;
            case "helpfiles/morphonetnet.md":
                this.setState({subfolder_link:"morphonetnet"});
                break;
            case "helpfiles/morphonetplot.md":
                this.setState({subfolder_link:"morphonetplot"});
                break;   
        }
      };

    render(){
        return(
			<div>
            <div style={{margin:'auto',marginTop:'1%'}}>
            <h1 className='main-title' style={{marginLeft:'33%'}}> MorphoNet API help </h1>
                <br></br><br></br>
                <div style={{width:'100%'}}>
                <Menu onClick={this.handleClick} style={{display:'flex',justifyContent: 'center'}} defaultSelectedKeys={this.state.source_link} mode="horizontal">
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/pythonapi.md">
                   <span className='new-content-text'> Python API </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/morphonetnet.md">
                <span className='new-content-text'> MorphoNet.Net </span>
                </Menu.Item>
                <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/morphonetplot.md">
                <span className='new-content-text'> MorphoNet.Plot </span>
                </Menu.Item>
                </Menu>
                </div>
                <br></br>
                {
                    this.state.source_link !== "" &&
                    <MorphoMDEditor subfolderName={this.state.subfolder_link === undefined || this.state.subfolder_link === "" ? "pythonapi" :this.state.subfolder_link} sourceLink={this.state.source_link} > </MorphoMDEditor>
                }
        	</div>
			</div>
        );
    }
}

export default HelpPythonAPI;