import {createEntityAdapter, createSlice,} from '@reduxjs/toolkit'

const initialState = {
    current_choosen_set: 0,
    size_by_datasets: {},
    gif_by_dataset: {},
    overwrote_time_by_id: {},
    sets_quality_by_id: {},
    current_quality_by_id: {},
    set_owner_by_id: {},
    set_categorie_by_id: {},
    current_phylotree: "",
    current_sort_type: 0,
    sort_types_list: [],
    current_filter_type: 1,
    current_filtered_publics: false,
    current_filtered_orphans:false,
    current_filtered_tags: [],
    current_filtered_groups: [],
    current_only_favourites : false,
    current_only_user : false,
    current_only_others : false,
    current_filtered_studies: [],
    current_filtered_tree_branch: -1,
    current_filter_search_name: "",
    current_filter_search_tag: "",
    current_filter_search_group: "",
    current_filter_search_study: "",
    links_by_dataset: {},
    unity_loaded : false,
}

function removeItemOnce(arr, value) {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

const datasetAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.date.localeCompare(b.date),
})

const datasetSlice = createSlice({
    name: 'dataset', initialState: datasetAdapter.getInitialState(initialState), reducers: {
        // How to use each action of the reducer
        // dispatch(datasetAdded({ id: '', ... }))
        datasetAdded: datasetAdapter.addOne, // dispatch(datasetReceived({ id: '', ... }))
        datasetListAdded: datasetAdapter.addMany, //dispatch(datasetUpdated({ id: '', changes: {... } }))
        datasetUpdated: datasetAdapter.updateOne, datasetListUpdated: datasetAdapter.setAll, //choose a set
        datasetClear: datasetAdapter.removeAll,
        chooseSet: (state, param) => {
            const {payload} = param;
            state.current_choosen_set = payload;
        }, unChooseSet: (state) => {
            state.current_choosen_set = -1;
        }, LoadSortValues: (state, param) => {
            const {payload} = param;
            state.sort_types_list = payload;
        }, chooseSorting: (state, param) => {
            const {payload} = param;
            state.current_sort_type = payload;
        }, chooseFilterType: (state, param) => {
            const {payload} = param;
            state.current_filter_type = payload;
        }, AddTagInFilters: (state, param) => {
            const {payload} = param;
            if (!state.current_filtered_tags.includes(payload)) {
                state.current_filtered_tags.push(payload);
            }
        }, RemoveTagInFilters: (state, param) => {
            const {payload} = param;
            if (state.current_filtered_tags.includes(payload)) {
                state.current_filtered_tags = removeItemOnce(state.current_filtered_tags, payload)
            }
        }, AddGroupInFilters: (state, param) => {
            const {payload} = param;
            if (!state.current_filtered_groups.includes(payload)) {
                state.current_filtered_groups.push(payload);
            }
        }, RemoveGroupInFilters: (state, param) => {
            const {payload} = param;
            if (state.current_filtered_groups.includes(payload)) {
                state.current_filtered_groups = removeItemOnce(state.current_filtered_groups, payload)
            }
        }, AddStudyInFilters: (state, param) => {
            const {payload} = param;
            if (!state.current_filtered_studies.includes(payload)) {
                state.current_filtered_studies.push(payload);
            }
        }, RemoveStudyInFilters: (state, param) => {
            const {payload} = param;
            if (state.current_filtered_studies.includes(payload)) {
                state.current_filtered_studies = removeItemOnce(state.current_filtered_studies, payload)
            }
        }, AddSetOwnerById: (state, param) => {
            const {payload} = param;
            state.set_owner_by_id[Object.keys(payload)[0]] = Object.values(payload)[0];

        }, AddSizeById: (state, param) => {
            const {payload} = param;
            state.size_by_datasets[payload[0]] = payload[1];

        }, AddCategorieById: (state, param) => {
            const {payload} = param;
            payload.map(tagset => {
                state.set_categorie_by_id[tagset[0]] = tagset[1];
                return tagset;
            });
            state.set_categorie_by_id[payload[0]] = payload[1];

        }, AddLinksById: (state, param) => {
            const {payload} = param;
            const set_id = payload[0];
            payload.map(link => {
                state.links_by_dataset[set_id] = link;
            });


        }, AddGifsById: (state, param) => {
            const {payload} = param;
            for (const [key, value] of Object.entries(payload)) {
                state.gif_by_dataset[key] = value;
            }


        }, AddQualitiesById: (state, param) => {
            const {payload} = param;
            for (const [key, value] of Object.entries(payload)) {
                state.sets_quality_by_id[key] = value;
            }


        }, AddCurrentQualitiesById: (state, param) => {
            const {payload} = param;
            for (const [key, value] of Object.entries(payload)) {
                state.current_quality_by_id[key] = value;
            }


        }, AddOverwriteById: (state, param) => {
            const {payload} = param;
            for (const [key, value] of Object.entries(payload)) {
                state.overwrote_time_by_id[key] = value;
            }


        }, SetTreeFilter: (state, param) => {
            const {payload} = param;
            state.current_filtered_tree_branch = payload;
        }, SetPhyloTree: (state, param) => {
            const {payload} = param;
            state.current_phylotree = payload;
        }, SearchByName: (state, param) => {
            const {payload} = param;
            state.current_filter_search_name = payload;
        }, SearchByGroup: (state, param) => {
            const {payload} = param;
            state.current_filter_search_group = payload;
        }, SearchByTag: (state, param) => {
            const {payload} = param;
            state.current_filter_search_tag = payload;
        }, SearchByStudy: (state, param) => {
            const {payload} = param;
            state.current_filter_search_study = payload;
        }, UnityIsLoaded: (state, param) => {
            state.unity_loaded = true;
        }, UnityIsUnLoaded: (state, param) => {
        state.unity_loaded = false;
        }, SetPublicFiltered: (state, param) => {
            const {payload} = param;
            state.current_filtered_publics = true;
        }, unSetPublicFiltered: (state, param) => {
            const {payload} = param;
            state.current_filtered_publics = false;
        }, SetOrphansFiltered: (state, param) => {
            const {payload} = param;
            state.current_filtered_orphans = true;
        }, unSetOrphansFiltered: (state, param) => {
            const {payload} = param;
            state.current_filtered_orphans = false;
        }, SetOnlyFavourite: (state, param) => {
            const {payload} = param;
            state.current_only_favourites = !state.current_only_favourites;
        }, SetOnlyUser: (state, param) => {
            const {payload} = param;
            state.current_only_user = !state.current_only_user;
        }, SetOnlyOthers: (state, param) => {
            const {payload} = param;
            state.current_only_others = !state.current_only_others;
        }, ResetSortsAndFilters: (state,param) => {
            state.current_sort_type= 0;
            state.current_filter_type= 1;
            state.current_filtered_publics= false;
            state.current_filtered_orphans=false;
            state.current_filtered_tags= [];
            state.current_filtered_groups= [];
            state.current_only_favourites = false;
            state.current_only_user = false;
            state.current_only_others = false;
            state.current_filtered_studies= [];
            state.current_filtered_tree_branch= -1;
            state.current_filter_search_name= "";
            state.current_filter_search_tag= "";
            state.current_filter_search_group= "";
            state.current_filter_search_study= "";

        }
    }
})

export const {
    ResetSortsAndFilters,
    SetOnlyOthers,
    SetOnlyUser,
    SetOnlyFavourite,
    SetPublicFiltered,
    unSetPublicFiltered,
    unSetOrphansFiltered,
    SetOrphansFiltered,
    UnityIsLoaded,
    SearchByStudy,
    AddStudyInFilters,
    RemoveStudyInFilters,
    AddCurrentQualitiesById,
    AddOverwriteById,
    AddQualitiesById,
    AddGifsById,
    SearchByGroup,
    SearchByName,
    SearchByTag,
    SetPhyloTree,
    SetTreeFilter,
    AddLinksById,
    AddCategorieById,
    AddSizeById,
    AddSetOwnerById,
    AddGroupInFilters,
    RemoveGroupInFilters,
    chooseFilterType,
    RemoveTagInFilters,
    AddTagInFilters,
    LoadSortValues,
    chooseSorting,
    chooseSet,
    unChooseSet,
    datasetAdded,
    datasetListAdded,
    datasetListUpdated,
    datasetUpdated,
    loadPublicDatasets,
    datasetClear
} = datasetSlice.actions
// ??
export default datasetSlice.reducer