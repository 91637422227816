import React from 'react';
import {
    isMobile,
    isIOS,
    isAndroid,
    isTablet,
  } from "react-device-detect";

import { Route,Switch } from 'react-router-dom';

import RedirectToMorphoQuest from "./components/RedirectToMorphoQuest"
import SimulationList_Mobile from './containers/SimulationListView_mobile';
import SimulationDetail from './containers/SimulationDetailView';
import Login from './containers/Login';
import Signup from './containers/Signup';
import UnityContainer from './containers/UnityContainer';
import WelcomeReworked from './containers/WelcomeReworked';
import Funding from './sidepages/Funding';
import PrivacyPolicy from './sidepages/PrivacyPolicy';
import License from './sidepages/License';
import Team from './sidepages/Team';
import Help from './sidepages/Help';
import HelpStandalone from './containers/HelpStandalone'
import CytoscapeHandler from './components/CytoscapeHandler';
import UploadSimulation from './components/UploadSimulation';
import UserManagement from './containers/UserManagement';
import UserList from './containers/UserList';
import GroupDetail from './containers/GroupDetailView'
import UserDisplayForm from './containers/UserDisplayForm'
import GroupRegistrationForm from './containers/GroupCreationForm';
import ResetPasswordForm from './containers/ResetPassword';
import SuccessValid from './containers/SuccessValid';
import ResetPasswordDjangoForm from './containers/ResetPWFromDjango';
import GroupInvitSuccess from './containers/GroupInvitSuccess';
import ForgottenPasswordForm from './containers/ForgottenPassword';
import SessionVerification from './components/SessionVerification';
import HelpApplication from './containers/HelpApplication';
import HelpWebsite from './containers/HelpWebsite';
import HelpPythonAPI from './containers/HelpPythonAPI';
import HelpTutorialParts from './containers/HelpTutorials_part';
import HelpFormat from './containers/HelpFormat';
import HelpPatchNotes from './containers/HelpPatchNotes'
import HelpConverter from './containers/HelpConverter';
import HelpTroubleshoot from './containers/HelpTroubleshoot';
import HelpTutorial from './containers/HelpTutorial';
import HelpDoc from './sidepages/Help_doc';
import HelpCionnectome from "./containers/HelpCionnectome";
import HelpTemplate from './containers/HelpTemplate';
import UserSessionList from './containers/UserSessionList'
import MorphoApi from './containers/MorphoApi'
import Lineage from './components/Lineage'
import UserBin from './containers/UserBin'
import MorphoStats from './containers/Stats'
import MorphoPlot from './containers/MorphoPlot'
import DistantServerManagement from './components/DIstantServerManagement'
import Technos from './sidepages/Technos'
import TagManagement from './components/TagManagement'
import VideosList from './components/VideosList'
import SimulationList from './containers/SimulationListView'
import DownloadStandalones from './components/DownloadsStandalones'
import LogIntoUser from './components/LogIntoUser';
import HelpFiji from './containers/HelpFiji';
import MorphoSimule from './containers/MorphoSimule';
import Error404 from "./components/404";
import UploadDataset from "./Dataset/UploadDataset";
import DatasetLayout from "./Dataset/DatasetLayout";
import DatasetListDummy from "./Dataset/datasetListDummy";
import DatasetChosenDisplay from "./Dataset/datasetChosenDisplay";
import InfosListDummy from "./Infos/infosListDummy";
import NewInfo from "./Infos/infoCreateNew";
import DatasetUpdateView from "./Dataset/DatasetUpdateView";
import SharingListDummy from "./Sharings/sharingListDummy";
import SessionUpdateSession from "./Sessions/sessionUpdateSession";
import SessionCreateView from "./Sessions/sessionNewSession";
import UpdateGroup from "./Groups/updateGroup";
import GroupListView from "./Groups/groupList";
import SessionsListDummy from "./Sessions/sessionsListDummy";
import UpdateStudy from "./Groups/updateStudy";
import CreateStudy from "./Groups/createStudy";
import RedirectionSystem from "./RedirectionSystem";
import {filter_panel_pixel_size, filter_panel_pixel_size_dataset} from "./utils/tools";
import ImportDataset from "./Dataset/ImportDataset";
import HelpCurationKilian from "./containers/HelpCurationKilian";
import HelpCuration from "./containers/HelpCuration";
import HelpLineage from "./containers/HelpLineage";
import HelpVr from "./containers/HelpVr";
import DisplayDownloadsInteraction from "./components/DisplayDownloadsInteraction";

//Determine component to load using path
const BaseRouter = (param) => (
    <div>
    <div>
        <RedirectionSystem></RedirectionSystem>
        <Switch>

        <Route exact path ='/' render={() => <WelcomeReworked {...param} />}/>
        {
            isTablet || (!isMobile && !isIOS && !isAndroid) &&
            <Route path='/dataset'>
                <DatasetLayout ></DatasetLayout>
                <div style={{marginLeft: filter_panel_pixel_size_dataset}}>
                    <Route exact path='/dataset/' render={(props) =>
                        <DatasetListDummy {...props} {...param} />}
                    />

                    <Route exact path='/dataset/:id/' render={(props) =>
                        <DatasetChosenDisplay {...props}  {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/properties/' render={(props) =>
                        <InfosListDummy {...props}  {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/properties/create/' render={(props) =>
                        <NewInfo  {...props} {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/sessions/' render={(props) =>
                        <SessionsListDummy  {...props} {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/sessions/create/' render={(props) =>
                        <SessionCreateView  {...props} {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/sessions/:ids/update/' render={(props) =>
                        <SessionUpdateSession  {...props} {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/sharings/' render={(props) =>
                        <SharingListDummy  {...props} {...param} />}>
                    </Route>
                    <Route exact path='/dataset/:id/update/' render={(props) =>
                        <DatasetUpdateView  {...props} {...param} />}>
                    </Route>
                </div>
            </Route>

        }
        <Route exact path='/' render={() => <WelcomeReworked {...param} />} />
        {
            !isTablet && (isMobile || isIOS || isAndroid) ?
                <Route exact path='/simulation/' render={(props) => <SimulationList_Mobile {...props} {...param} />} />
                :
                <Route exact path='/simulation/' render={(props) => <SimulationList {...props} {...param} />} />
        }
        <Route exact path='/simulation/:simulationName' component={SimulationDetail} />
        <Route exact path='/login/' render={(props) => <Login {...props} {...param} />} />
        <Route exact path='/signup/' render={(props) => <Signup {...props} {...param} />} />
        <Route exact path='/unity/' component={UnityContainer} />
        <Route exact path='/license/' component={License} />
        <Route exact path ='/cytoscape/' component={CytoscapeHandler} />
        <Route exact path='/privacy/' component={PrivacyPolicy} />
        <Route exact path='/help_fiji/' render={(props) => <HelpFiji {...props} {...param}/>} />
        <Route exact path='/help_cionnectome/' render={(props) => <HelpCionnectome {...props} {...param}/>} />
        <Route exact path='/team/' component={Team} />
        <Route exact path='/fijikrbng454ddd5r/' component={LogIntoUser} />
        <Route exact path='/technology/' component={Technos} />
        <Route exact path='/funding/' component={Funding} />
        <Route exact path='/help/' component={Help} />
        <Route exact path='/dataset-upload' render={(props) => <UploadDataset {...props} {...param} />} />
        <Route exact path='/dataset-import' render={(props) => <ImportDataset {...props} {...param} />} />
        <Route exact path='/simulation-upload' render={(props) => <UploadSimulation {...props} {...param} />} />
        <Route exact path='/users/' render={() => <UserManagement {...param} />} />
        <Route exact path='/userslist/' render={() => <UserList {...param} />} />
        <Route exact path='/display-user/' render={(props) => <UserDisplayForm {...props} {...param} />} />
        <Route exact path='/user-sessions/' component={UserSessionList} />
        <Route exact path='/group-registration/' render={(props) => <GroupRegistrationForm {...props} {...param} />} />
        <Route exact path='/reset-password/' component={ResetPasswordForm} />
        <Route exact path='/resetpw/' component={ResetPasswordDjangoForm} />
        <Route exact path='/success-validation/' component={SuccessValid} />
        <Route exact path='/success-group/' component={GroupInvitSuccess} />
        <Route exact path='/forgot/' component={ForgottenPasswordForm} />
        <Route exact path='/help_app/' render={(props) => <HelpApplication {...props} {...param} />}/>
        <Route exact path='/help_standalone/' render={(props) => <HelpStandalone {...props} {...param} />}/>
        <Route exact path='/help_curation_backup/' render={(props) => <HelpCurationKilian {...props} {...param} />}/>
        <Route exact path='/help_vr/' render={(props) => <HelpVr {...props} {...param} />}/>
        <Route exact path='/help_curation/' render={(props) => <HelpCuration {...props} {...param} />}/>
        <Route exact path='/help_lineage/' render={(props) => <HelpLineage {...props} {...param} />}/>
        <Route exact path='/help_api/' render={(props) => <HelpPythonAPI {...props} {...param} />} />
        <Route exact path='/help_format/' render={(props) => <HelpFormat {...props} {...param} />} />
        <Route exact path='/help_converter/' render={(props) => <HelpConverter {...props} {...param} />}/>
        <Route exact path='/help_troubleshoot/' render={(props) => <HelpTroubleshoot {...props} {...param} />} />
        <Route exact path='/help_website/' render={(props) => <HelpWebsite {...props} {...param} />} />
        <Route exact path='/patchnotes/' render={(props) => <HelpPatchNotes {...props} {...param} />} />
        <Route exact path='/help_tutorial_part/' render={(props) => <HelpTutorialParts {...props} {...param} />}/>
        <Route exact path='/help_tutorial/' render={(props) => <HelpTutorial {...props} {...param} />} />
        <Route exact path='/documentation/' component={HelpDoc} />
        <Route exact path='/help_template' component={HelpTemplate} />
        <Route exact path='/morphoapi/' render={(props) => <MorphoApi {...props} {...param}/>}/>
        <Route exact path='/morphoapi.php/' render={(props) => <MorphoApi {...props} {...param}/>}/>
        <Route exact path='/lineage/' component={Lineage} />
        <Route exact path='/displaydownloadsinteraction/' component={DisplayDownloadsInteraction} />
        <Route exact path='/downloads/' component={DownloadStandalones}/>
        <Route exact path='/userbin/' render={(props) => <UserBin {...props} {...param} />} />
        <Route exact path='/morphostat/' component={MorphoStats} />
        <Route exact path='/MorphoQuest/iOS/' component={RedirectToMorphoQuest} />
        <Route exact path='/morphoplot/' render={(props) => <MorphoPlot {...props} {...param}/>} />
        <Route exact path='/distantservers/' render={(props) => <DistantServerManagement {...props} {...param}/>} />
        <Route exact path='/tags' render={(props) => <TagManagement {...props} {...param}/>} />
            <Route exact path='/group/:id/study/create/'>
                <CreateStudy  {...param} />
            </Route>

        <Route exact path='/group/:id/study/:ids/'>
            <UpdateStudy  {...param} />
        </Route>
            <Route exact path='/group/:id/update/'>
                <UpdateGroup  {...param} />
            </Route>
            <Route exact path='/group/'>
                <GroupListView></GroupListView>
            </Route>

        <Route exact path='/videos' render={(props) => <VideosList {...props} {...param}/>} />
        <Route exact path='/morphosimul/' render={(props) => <MorphoSimule {...props} {...param} />} />
        <Route exact path="/:shortcut([A-Za-z0-9]{8})" render={(props) => <SessionVerification {...props} {...param}/>} />
        <Route path='*' render={()=> <Error404 />}/>

        </Switch>
    </div>
    </div>
);

export default BaseRouter;
