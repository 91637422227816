import React from "react";
import {ChooseSetFromUrl, PostNewSession} from "../utils/api_callbacks";
import {connect} from "react-redux";
import {Button, Checkbox, Icon, InputNumber, List, Radio, Slider} from "antd";
import TextArea from "antd/es/input/TextArea";
import * as UnityInfos from './UnityInfos';
import {getDefaultQuality, IsAdminOrCreator, IsAtLeastManager, IsAtLeastReader, qualityformatter} from "../utils/tools";
import ChosenSetTopPanel from "../Dataset/ChosenSetTopPanel";

class SessionCreateView extends React.Component {

    state = {
        newsession_name: "",
        new_time: -1,
        new_mintime: -1,
        corCount: 0,
        new_maxtime: -1,
        bttnCount: 0,
        new_sessions_quality: -1,
        newssession_url_prefix: "",
        newsession_url: "",
    }

    onChangeNewSessionname = ({target: {value}}) => {
        this.setState({newsession_name: value});
        this.setState({newssession_url_prefix: "id_dataset=" + this.props.chosen_set});
    };


    UpgradeUrlNewSession = (type, p_value, obj_id) => {
        let alreadyexist = false;
        switch (type) {
            case "cor":

                // console.log(obj_id);
                var parameters = this.state.newsession_url.split('&').map(param => {
                    if (param.includes("cor") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        return oldparam + "=" + p_value + "_" + obj_id;
                    }
                    return param;
                });

                this.state.newsession_url = parameters.join('&')

                if (!alreadyexist) {
                    this.state.newsession_url += "&cor" + this.state.corCount + "=" + p_value + "_" + obj_id;
                    this.state.corCount++;
                }
                break;

            case "bttn":
                var parameters = this.state.newsession_url.split('&').map(param => {
                    if (param.includes("bttn") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        return oldparam + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    }
                    return param;
                });
                this.state.newsession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.newsession_url += "&bttn" + this.state.bttnCount + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    this.state.bttnCount++;
                }
                break;

            case "quality":
                var parameters = this.state.newsession_url.split('&').map(param => {
                    if (param.includes("quality")) {
                        alreadyexist = true;
                        return "quality=" + p_value;
                    }
                    return param;
                });
                this.state.newsession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.newsession_url += "&quality=" + p_value;
                }
                this.setState({selected_quality: p_value});
                break;

            case "deftime":
                var parameters = this.state.newsession_url.split('&').map(param => {
                    if (param.includes("time")) {
                        alreadyexist = true;
                        this.setState({new_time: p_value});
                        return "time=" + p_value;
                    }
                    return param;
                });
                this.state.newsession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.newsession_url += "&time=" + p_value;
                }
                break;

            case "minmax":
                var parameters = this.state.newsession_url.split('&').map(param => {
                    if (param.includes("minTime")) {
                        alreadyexist = true;
                        this.setState({new_mintime: p_value[0]});
                        return "minTime=" + p_value[0];
                    }
                    if (param.includes("maxTime")) {
                        alreadyexist = true;
                        this.setState({new_maxtime: p_value[1]});
                        return "maxTime=" + p_value[1];
                    }
                    return param;
                });
                this.state.newsession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.newsession_url += "&minTime=" + p_value[0] + "&maxTime=" + p_value[1];
                }
                break;
        }
    }

    getButtonFromNewUrl = (buttn_name) => {
        const parameters = this.state.newsession_url.split('&');
        let parameter = null;
        parameters.map(param => {
            if (param.includes("bttn") && param.includes("_" + buttn_name)) {
                parameter = param.split('=')[1];
            }
        });
        let type = "show";
        if (parameter !== null)
            type = parameter.split('_')[0];
        return type;
    }

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    render() {
        const datasets = this.props.datasets;
        if (datasets === undefined) return <div></div>
        const dataset = datasets[this.props.chosen_set]
        if (dataset === undefined) return <div></div>
        const quality_count = this.props.set_quality_by_id[dataset.id];
        const correspondence_list = Object.values(this.props.infos).filter(info => info.id_dataset === this.props.chosen_set);
        const is_public = Object.values(this.props.sharings).some(sharing => sharing.id_base === dataset.id && sharing.base === "dataset" && sharing.id_who === 0) || dataset.public;
        return <div>
            <ChosenSetTopPanel {...this.props} set={dataset}></ChosenSetTopPanel>
            <br></br>
            {dataset!==undefined &&<p className={"DatasetDescriptionName"}>{dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${dataset.id})` : ""}</p>}
            {
                this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                <div >
                    <div style={{marginTop:"1%"}}>
                            <span style={{fontSize: '21px', color: 'black'}}>Create a session</span>
                            <br/>
                            <br/>
                        <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Session name</h2> <br></br>
                        <TextArea
                            autoSize={{minRows: 1, maxRows: 6}}
                            disabled={!this.props.connected && !IsAtLeastManager(this.props.chosen_set)}
                            onChange={this.onChangeNewSessionname}
                            style={{width: '50%', marginLeft: '10px'}}
                            prefix={<Icon type="font-colors" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Session name"
                        />
                    </div>
                    <div style={{marginTop:"1%"}}>
                        <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Buttons</h2>
                        <List
                            itemLayout="horizontal"
                            style={{width: '20%'}}
                            disabled={!this.props.connected && !IsAtLeastManager(this.props.chosen_set)}
                            dataSource={UnityInfos.unity_buttons}
                            renderItem={item => (
                                <List.Item>
                                    <Checkbox
                                        checked={this.state.newsession_url === "" || this.state.newsession_url === null ? true : this.getButtonFromNewUrl(item.toLowerCase()) === "show"}
                                        onChange={(e) => this.UpgradeUrlNewSession("bttn", e.target.checked, item.toLowerCase())}></Checkbox>
                                    <List.Item.Meta
                                        title={<span style={{color: 'grey', marginLeft: '10px'}}>{item === "Infos"?"Annotations":item==="Objects"?"Labels":item}  </span>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    {dataset.maxtime > dataset.mintime && <div style={{marginTop:"1%"}}>
                        <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Time steps</h2>
                        <Slider style={{width: '80%'}}
                                onChange={value => {
                                    this.UpgradeUrlNewSession("minmax", value, -1);
                                    this.setState({new_mintime: value[0]});
                                    this.setState({new_maxtime: value[1]});
                                }}
                                range min={dataset.mintime} max={dataset.maxtime}
                                disabled={!this.props.connected && !IsAtLeastManager(this.props.chosen_set)}
                                value={[this.state.new_mintime !== -1 ? this.state.new_mintime : dataset.mintime, this.state.new_maxtime !== -1 ? this.state.new_maxtime : dataset.maxtime]}
                                defaultValue={[dataset.mintime, dataset.maxtime]}/>
                        <span className='content-text'> Default time point</span>
                        <InputNumber
                            min={this.state.new_mintime !== -1? this.state.new_mintime:dataset.mintime}
                            max={this.state.new_maxtime !== -1? this.state.new_maxtime:dataset.maxtime}
                            disabled={!this.props.connected && !IsAtLeastManager(this.props.chosen_set)}
                            value={this.state.new_time !== -1 ? this.state.new_time : dataset.mintime}
                            onChange={value => {
                                this.UpgradeUrlNewSession("deftime", value, -1);
                                this.setState({new_time: value})
                            }}
                            defaultValue={dataset.mintime}
                            style={{width: '200px', marginLeft: '10px'}}
                            placeholder="Default time step"
                        />
                    </div>
                    }
                    <div style={{marginTop:"1%"}}>
                        {
                            quality_count > 1 &&
                            <div style={{
                                marginRight: dataset.mintime < dataset.maxtime ? '4%' : (is_public) ? '26%' : '23%',
                                width: "50%"
                            }}>
                                <span>
                                    {qualityformatter(this.state.new_sessions_quality !== -1 ? this.state.new_sessions_quality : getDefaultQuality(1), quality_count)}
                                </span>
                                <Slider
                                    style={{
                                        webkitUserSelect: "none",
                                        msUserSelect: "none", /* IE 10 and IE 11 */
                                        userSelect: "none" /* Standard syntax */
                                    }}
                                    tipFormatter={(value) => qualityformatter(value, quality_count)}
                                    min={0}
                                    onChange={(value) => {
                                        this.UpgradeUrlNewSession("quality", value, -1);
                                        this.setState({new_sessions_quality: value});
                                    }}
                                    max={quality_count - 1}
                                    value={this.state.new_sessions_quality !== -1 ? this.state.new_sessions_quality : getDefaultQuality(1)}
                                />
                            </div>
                        }
                    </div>
                    <div style={{marginTop:"1%"}}>
                        <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Parameters</h2>
                        <List
                            itemLayout="horizontal"
                            style={{width: '60%'}}
                            dataSource={correspondence_list}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<span style={{
                                            color: 'grey',
                                            marginLeft: '10px'
                                        }}>{item.infos} ({item.datatype})</span>}
                                    />
                                    <Radio.Group
                                        onChange={(e) => this.UpgradeUrlNewSession("cor", e.target.value, item.id)}
                                        defaultValue="show">
                                        <Radio value={"show"}>Show</Radio>
                                        <Radio value={"hide"}>Hide</Radio>
                                        <Radio value={"load"}>Load</Radio>
                                        <Radio value={"apply"}>Apply</Radio>
                                    </Radio.Group>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{marginTop:"1%"}}>
                        <Button type="primary"
                                onClick={() => {
                                    PostNewSession(this.state.newsession_name, this.state.newsession_url, this.state.newssession_url_prefix, this.props.chosen_set)
                                }}
                                style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                            Create session
                        </Button>
                    </div>
                </div>
            }
        </div>;

    }
}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set, sessions: state.session.entities,
        datasets: state.dataset.entities,
        infos: state.infos.entities,
        connected: state.user.is_connected,
        sharings: state.sharing.entities,
        set_quality_by_id: state.dataset.sets_quality_by_id,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionCreateView);