import React from 'react';
import axios from 'axios';
import { Card } from 'antd';

class SimulationDetail extends React.Component {

    state = {
        simulation: {}
    }

    componentDidMount() {
        const simulationName = this.props.match.params.simulationName;
        if (simulationName !== undefined || simulationName !== null) {
            axios.get(`/api/simulation_utilisateur/`, { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        simulation: res.data
                    });
                })
        }
    }


    render() {
        return (
            <Card title={this.state.simulation.name}>
                <p>{this.state.simulation.description}</p>
            </Card>
        );
    }
}

export default SimulationDetail;