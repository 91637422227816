import React from "react";
import {Slider, Tooltip} from "antd";
import 'antd/dist/antd.css';
import {isTablet} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {AddOverwriteById} from "../store/reducers/datasetSlice";

export const TimeSlider = (props) => {
    const dispatch = useDispatch();
    const overwrote_times = useSelector(state => state.dataset.overwrote_time_by_id);
    const min_time = overwrote_times[props.item.id] !== undefined ? parseInt(overwrote_times[props.item.id][0]) : parseInt(props.item.backup_mintime);
    const max_time = overwrote_times[props.item.id] !== undefined ? parseInt(overwrote_times[props.item.id][1]) : parseInt(props.item.backup_maxtime);
    return (
        <div style={{
            float: 'right',
            marginRight: props.is_public ? '10px' : '45px',
            width: props.is_public ? "20%" : '20%'
        }}>
            <Tooltip destroyTooltipOnHide={true} placement="bottom"
                     title={<span>Select time points to load to visualizer</span>}>
                <span className='content-text'
                   style={{marginLeft: isTablet ? 'unset' : '25%'}}>Time: {min_time} - {max_time} </span>
            </Tooltip>
            <Tooltip destroyTooltipOnHide={true} placement="bottom"
                     title={<span>Select time points to load to visualizer</span>}>
                <Slider
                    style={{
                        WebkitUserSelect: "none",
                        msUserSelect: "none", /* IE 10 and IE 11 */
                        userSelect: "none" /* Standard syntax */
                    }}
                    range
                    onChange={(value) => {
                        const object = {};
                        object[props.item.id] = value;
                        dispatch(AddOverwriteById(object));
                    }}
                    min={props.item.backup_mintime}
                    max={props.item.backup_maxtime}
                    value={[min_time, max_time]}
                    defaultValue={[min_time, max_time]}/>
            </Tooltip>
        </div>
    );

}