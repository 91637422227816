import React from "react";
import {connect} from "react-redux";
import {
    AddGroupInFilters,
    AddStudyInFilters,
    RemoveGroupInFilters,
    RemoveStudyInFilters, SetOrphansFiltered, SetPublicFiltered, unSetOrphansFiltered, unSetPublicFiltered
} from "../store/reducers/datasetSlice";


class DatasetGroupFilter extends React.Component {


    componentDidMount() {
    }

    isChecked = (groupid) => {
        let flag = true;
        if (this.props.user.studies_by_group === undefined || this.props.user.studies_by_group[groupid] === undefined) {
            return this.props.dataset.current_filtered_studies.includes(groupid + "_orphans");
        }
        this.props.user.studies_by_group[groupid].map(study => {
            if (!this.props.dataset.current_filtered_studies.includes(study.id)) {
                flag = false;
            }
        });

        if (!this.props.dataset.current_filtered_studies.includes(groupid + "_orphans")) {
            flag = false;
        }

        return flag
    }

    isCheckedStudy = (groupid) => {
        const groupchecked = this.props.dataset.current_filtered_studies;
        return groupchecked.includes(groupid);
    }

    handleChangePublic = () => {


        if (this.props.dataset.current_filtered_publics){
            this.props.LocalunSetPublicFiltered();
        }
        else {
            this.props.LocalSetPublicFiltered();
        }
    }

    handleChangeOrphans = () => {

        if (this.props.dataset.current_filtered_orphans){
            this.props.LocalunSetOrphansFiltered();
        }
        else {
            this.props.LocalSetOrphansFiltered();
        }
    }

    handleChange = (group_id) => {
        if (this.isChecked(group_id)) {
            if (this.props.dataset.studies_by_group !== undefined || this.props.user.studies_by_group[group_id] !== undefined) {
                this.props.user.studies_by_group[group_id].map(study => {
                    if (this.props.dataset.current_filtered_studies.includes(study.id)) {
                        this.props.RemoveStudyInFilters(study.id);
                    }
                });
            }
            this.props.RemoveStudyInFilters(group_id + "_orphans")
        } else {
            //this.props.AddGroupInFilters(group_id);
            if (this.props.dataset.studies_by_group !== undefined || this.props.user.studies_by_group[group_id] !== undefined) {
                this.props.user.studies_by_group[group_id].map(study => {
                    if (!this.props.dataset.current_filtered_studies.includes(study.id)) {
                        this.props.AddStudyInFilters(study.id);
                    }
                });
            }
            this.props.AddStudyInFilters(group_id + "_orphans")
        }


    };

    handleChangeStudy = (group_id) => {
        if (this.isCheckedStudy(group_id)) {
            this.props.RemoveStudyInFilters(group_id);
        } else {
            this.props.AddStudyInFilters(group_id);
        }


    };

    render() {
        const groups = Object.values(this.props.user.group_list);
        const studies = this.props.user.studies_by_group;
        return <div className={"GroupFilteringList"}>
            <h3 style={{fontSize:"20px",marginLeft:"5px",marginTop:"10px"}}> Filter by studies and groups</h3>
            {
            groups.map(group =>
                <div style={{width: "100%"}} key={group.name}><label
                    className={"GroupFilterText"}
                ><input
                    className={"CheckboxFilter"}
                    onChange={() => this.handleChange(group.id)}
                    checked={this.isChecked(group.id) || this.isChecked(group.id)}
                    name={group.id} type="checkbox"></input> {group.name}</label><br/>
                    {
                        studies === undefined ?
                            <div>
                                <div style={{marginLeft: "20px"}} key={group.id + "_orphans"}>

                                    <label className={"StudyFilterText"}><input style={{marginTop:"10px"}}
                                                                                onChange={() => this.handleChangeStudy(group.id + "_orphans")}
                                                                                checked={this.isCheckedStudy(group.id + "_orphans")}
                                                                                name={"orphan"} type="checkbox"></input> Orphans</label>


                                </div>
                            </div>
                            :
                            <div>{studies[group.id] !== undefined && studies[group.id].map(studie =>
                                <div key={studie.name}>

                                    <label className={"StudyFilterText"}><input style={{marginTop:"7px",marginRight:"5px"}}
                                                                                onChange={() => this.handleChangeStudy(studie.id)}
                                                                                checked={this.isCheckedStudy(studie.id)}
                                                                                name={studie.id} type="checkbox"></input> {studie.name}</label>

                                </div>
                            )}
                                <div key={group.id + "_orphans"}>
                                    <label className={"StudyFilterText"}><input style={{marginTop:"7px",marginRight:"5px"}}
                                                                                onChange={() => this.handleChangeStudy(group.id + "_orphans")}
                                                                                checked={this.isCheckedStudy(group.id + "_orphans")}
                                                                                name={"orphan"} type="checkbox"></input> Orphans</label>

                                </div>
                            </div>

                    }

                    <br/>
                </div>)}
            <div key={"publics"}>
                <label className={"GroupFilterText"}><input style={{marginTop:"7px",marginRight:"5px"}}
                                                            onChange={this.handleChangePublic}
                                                            checked={this.props.dataset.current_filtered_publics===true}
                                                            name={"publics"} type="checkbox"></input> Publics</label>

            </div>
            <br></br>
            <div key={"orphansgroup"}>
                <label className={"GroupFilterText"}><input style={{marginTop:"7px",marginRight:"5px"}}
                                                            onChange={this.handleChangeOrphans}
                                                            checked={this.props.dataset.current_filtered_orphans===true}
                                                            name={"orphansgroup"} type="checkbox"></input> Orphans</label>

            </div>
            <br></br>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        dataset: state.dataset,
        public_filtered : state.dataset.current_filtered_publics,
        orphan_filtered : state.dataset.current_filtered_orphans,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        AddGroupInFilters: (groupid) => dispatch(AddGroupInFilters(groupid)),
        RemoveGroupInFilters: (groupid) => dispatch(RemoveGroupInFilters(groupid)),
        AddStudyInFilters: (studyid) => dispatch(AddStudyInFilters(studyid)),
        RemoveStudyInFilters: (studyid) => dispatch(RemoveStudyInFilters(studyid)),
        LocalSetPublicFiltered : () => dispatch(SetPublicFiltered()),
        LocalunSetPublicFiltered : () => dispatch(unSetPublicFiltered()),
        LocalSetOrphansFiltered : () => dispatch(SetOrphansFiltered()),
        LocalunSetOrphansFiltered : () => dispatch(unSetOrphansFiltered()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetGroupFilter);