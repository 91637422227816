import React from "react";
import SortingPanel from "./SortingPanel";
import DatasetFilteringPanel from "./FilteringPanel";
import {Tooltip} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {SendTracking} from "../utils/api_callbacks";
import {filter_panel_pixel_size} from "../utils/tools";

class DatasetLayout extends React.Component {

    state = {
        location : "",
    }
    componentDidMount() {
        this.state.location = window.location.pathname;

        window.addEventListener('hashchange', function(){
            this.setState({location:window.location.pathname})
            //console.log('onhashchange event occurred!');
        })
        //window.onload = () => new MutationObserver(mutations => mutations.forEach((oldHref) => oldHref !== document.location.pathname && (oldHref = document.location.pathname,this.setState({location:window.location.pathname})))).observe(document.querySelector("body"), { childList: true, subtree: true });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <div>

                <br></br>
                <br></br>
                <div style={{marginTop:"5%",float:"left",width:filter_panel_pixel_size,height:"100%"}}>
                {
                    this.props.connected &&
                    <div ref='upload'><h1 className='sub-titles-text'
                                          style={{
                                              fontSize: '34px',
                                              verticalAlign: "center",
                                              marginLeft: "1%",
                                          }}> Dataset </h1>
                        <Tooltip placement="bottom" title={<span>Upload a dataset</span>}>
                            <Link exact="true" to={{pathname: `/dataset-upload`}}><img alt="Logout" onClick={() => {
                                SendTracking(-1, "Web,Dataset,Upload,view");
                            }} height='50' src="/images/refonte/add.png" style={{
                                paddingBottom:"10px",
                                marginLeft: "1%",
                                verticalAlign: "center",
                                mixBlendMode: 'multiply',
                                cursor: 'pointer',
                            }}/></Link>
                        </Tooltip>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                }
                <DatasetFilteringPanel></DatasetFilteringPanel></div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        connected: state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(DatasetLayout);
