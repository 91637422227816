import React from 'react';

class GroupInvitSuccess extends React.Component {
    render(){
        return(
            <div style={{margin:'auto',textAlign:'center'}}><h1>You have been successfully registered to the group.</h1></div>
        );
    }
}

export default GroupInvitSuccess;