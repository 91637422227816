import React, { Component } from "react";
import { List,Button,Select } from 'antd';
import {getUserToken,getUserID} from '../store/utility';
import axios from 'axios';
import '../App.css';
import * as THREE from "three";

const d3 = require("d3");

const { Option } = Select;

let spaceBetweenT = 10;   //Space between each time points
let spaceBetweenDaughter = 10; //Space between the last branches at max T
const colorDefault = "gray"; //default color for cells


class Cell {
    constructor(t, id) {
      this.t = t;
      this.id = id;
      this.x=0; 
      this.y=-this.t*spaceBetweenT;
      this.daughters=[];
      this.selected = false;
      this.mothers=[];
      this.visibility=true;
      this.color=colorDefault;
     // this.selection=1;
      this.asMother=0;
      this.toshow={};
      this.selection =[];
    
      
    }

    removeSelection(select){
        if (this.selection === undefined || this.selection === [] || select === -1)
        {
            return ;
        }
        if (this.selection.includes(select)){
            this.selection = this.selection.filter(function(item) {
                return item  !== select
            });
        }
    }
    clearSelections(){
        this.selection = [];
    }
    addSelection(select){
        if (this.selection === undefined){
            this.selection = [];
        }
        if (select !== -1 && !this.selection.includes(select)){
            this.selection.push(select);
        }
    }

    addDaughter(d){
        this.daughters.push(d);
        d.asMother=1;
    }

    addMother(d){
        this.mothers.push(d);
        this.asMother=1;
    }

    name(){ return this.t+","+this.id;}
  }

class Lineage extends Component {
    constructor(props){
      super(props);
      this.ColorizeMultiples = this.ColorizeMultiples.bind(this);
      this.myRef = React.createRef();
      this.addCreateBranch = this.addCreateBranch.bind(this);
      this.animate = this.animate.bind(this);
      this.scene = null;
      this.camera = null;
      this.renderer = null;
      this.raycaster = null;
      this.fov = 40;
      this.spaceTop = 100;
      this.height = window.innerHeight;
      this.width = window.innerWidth;
      this.nextXCell = 0;
      this.maxY = -10000;
      this.minY = 10000000;
      this.maxX = -10000;
      this.minX = 10000000;
      this.StartX = 100;
      this.StartY = -100;
      this.points = null;
      this.activeCell = null;
      this.lines = null;
      this.near = 200;
      this.far = 17000;
      this.initial_scale = this.getScaleFromZ(this.far);
      this.current_scale = this.initial_scale;
      this.backup_scale = undefined;
      this.circle_sprite= new THREE.TextureLoader().load(  "https://fastforwardlabs.github.io/visualization_assets/circle-sprite.png" );
      this.cellsMaterial = new THREE.PointsMaterial({
        size: 8,
        sizeAttenuation: false,
        vertexColors: THREE.VertexColors,
        map: this.circle_sprite,
        transparent: true
      });
      this.handleZoom = this.handleZoom.bind(this);

      this.bigcellsMaterial = new THREE.PointsMaterial({
      size: 26,
      sizeAttenuation: false,
      vertexColors: THREE.VertexColors,
      map: this.circle_sprite,
      transparent: true
        });
        
        this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x606060 });
        this.cellGeometry = null;
        this.zoom = null;
        this.view = null;
    }

    state = {
        clearCellsParams : undefined,
        color_by_selection : {},
        receiveColorInfo : null,
        first_displayed : false,
        id_set : -1,
        backup_id_set : -1,
        info_list : [],
        id_user : -1,
        activeCell : null,
        show_cells : false,
        parsed_cells : [],
        temp_info_lineage : "",
        temp_info_cmap : "",
        temp_info_colors : "",
        cells : [],
        cells_visibility : [],
        colors : [],
        cells_treated : false,
        lineage : "",
        changeColorSelection : undefined,
        min_time : -1,
        user_token : undefined,
        user_id : undefined,
        clearLineage : false,
        max_time : -1,
        backgroundColor : '#44D4FF',
        //0 = tree,  1 = circular
        graph_form : 0,
        hide_cells : false,
        hide_lines : false,
        pickCellsParams : undefined,
        height_offset : 0,
        width_offset : 0,
        hideLineageT : -1,
        hideLineageID : -1,
        showLineageT : -1,
        showLineageID : -1,
        hideAllTimesCellsV:-1,
        hideAllTimesCellsListCells:-1,
        colorizeT : -1,
        colorizeID : -1,
        uncolorizeT : -1,
        uncolorizeID: -1,
        colorizeRGB : -1,
        highlight_division : false,
        highlight_birth : false,
        highlight_death : false,
        order_by : "default",
        last_info_key : "",
        time_catch : false,
        lineage_got : false,
        nb_cellMaxTime : 0,
        mouse_pos: null,
        ApplySelectRGB:-1,
        ApplySelectListCells:-1,
        ApplySelectListCellsMap:-1,
        resetselection_listcells:-1,
        hidecellsParams:-1,
        PropagateThisV:-1,
        PropagateThisRGB:-1,
        PropagateThisListCells:-1,
        loaded_infos : [],
        resetSelectionLineage : false,
        applySelectionLineage : false,
        backup_color_by_cell : [],
        uncolorize_listcells : undefined,
        colorize_listcells : undefined,
        last_info_received : null,
        deploy_info_id : -1,
        multipleSelection : undefined,
        curration_got : false,
        resetLineage :false,
        curration_list : [],
        idLineage : -1,
        HideCellsList : undefined,
        ShowCellList : undefined,
        SendColors : undefined,
        temp_send_colors : "",
        temp_show_cells : "",
        temp_hide_cells : "",
        verticesIndexByCell : [],
        colorsIndexByCell : [],
        linesIndexesByCell : [],
        backup_pos_x : undefined,
        backup_pos_y : undefined,
        backup_pos_z : undefined,
        send_background_color : undefined,
    }

    resetComponent = () => 
    {
        this.ColorizeMultiples = this.ColorizeMultiples.bind(this);
        this.myRef = React.createRef();
        this.addCreateBranch = this.addCreateBranch.bind(this);
        this.animate = this.animate.bind(this);
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.raycaster = null;
        this.fov = 40;
        this.spaceTop = 100;
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        this.nextXCell = 0;
        this.maxY = -10000;
        this.minY = 10000000;
        this.maxX = -10000;
        this.minX = 10000000;
        this.StartX = 100;
        this.StartY = -100;
        this.points = null;
        this.activeCell = null;
        this.lines = null;
        this.near = 200;
        this.far = 17000;
        this.initial_scale = this.getScaleFromZ(this.far);
        this.current_scale = this.initial_scale;
        this.circle_sprite= new THREE.TextureLoader().load(  "https://fastforwardlabs.github.io/visualization_assets/circle-sprite.png" );
        this.cellsMaterial = new THREE.PointsMaterial({
          size: 8,
          sizeAttenuation: false,
          vertexColors: THREE.VertexColors,
          map: this.circle_sprite,
          transparent: true
        });
        this.handleZoom = this.handleZoom.bind(this);
  
        this.bigcellsMaterial = new THREE.PointsMaterial({
        size: 26,
        sizeAttenuation: false,
        vertexColors: THREE.VertexColors,
        map: this.circle_sprite,
        transparent: true
          });
          
          this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x606060 });
          this.cellGeometry = null;
          this.zoom = null;
          this.view = null;

          this.state = {
            clearCellsParams : undefined,
            color_by_selection : {},
            verticesIndexByCell : [],
            colorsIndexByCell : [],
            linesIndexesByCell : [],
            first_displayed : false,
            id_set : -1,
            info_list : [],
            id_user : -1,
            backgroundColor : '#44D4FF',
            activeCell : null,
            show_cells : false,
            parsed_cells : [],
            cells : [],
            cells_visibility : [],
            colors : [],
            cells_treated : false,
            lineage : "",
            min_time : -1,
            clearLineage : false,
            max_time : -1,
            //0 = tree,  1 = circular
            graph_form : 0,
            hide_cells : false,
            hide_lines : false,
            height_offset : 0,
            width_offset : 0,
            changeColorSelection : undefined,
            hideLineageT : -1,
            hideLineageID : -1,
            showLineageT : -1,
            showLineageID : -1,
            hideAllTimesCellsV:-1,
            hideAllTimesCellsListCells:-1,
            colorizeT : -1,
            colorizeID : -1,
            uncolorizeT : -1,
            uncolorizeID: -1,
            colorizeRGB : -1,
            highlight_division : false,
            highlight_birth : false,
            highlight_death : false,
            order_by : "default",
            last_info_key : "",
            time_catch : false,
            lineage_got : false,
            nb_cellMaxTime : 0,
            mouse_pos: null,
            ApplySelectRGB:-1,
            ApplySelectListCells:-1,
            resetselection_listcells:-1,
            hidecellsParams:-1,
            PropagateThisV:-1,
            PropagateThisRGB:-1,
            PropagateThisListCells:-1,
            loaded_infos : [],
            resetSelectionLineage : false,
            applySelectionLineage : false,
            backup_color_by_cell : [],
            uncolorize_listcells : undefined,
            colorize_listcells : undefined,
            last_info_received : null,
            deploy_info_id : -1,
            multipleSelection : undefined,
            curration_got : false,
            resetLineage :false,
            curration_list : [],
            selected_cell_lineage : false,
            temp_info : "",
            reset_selection_cell_list: "",
            HideCellsList : undefined,
            ShowCellList : undefined,
            SendColors : undefined,
            temp_send_colors : "",
            temp_show_cells : "",
            temp_hide_cells : "",
            backup_pos_x : undefined,
            backup_pos_y : undefined,
            backup_pos_z : undefined,
            send_background_color : undefined,
        }

        this.componentDidMount();
    }
    //Return the index value in the points list for a specific cell
getCell(t,id){
    ////console.log("getcell");
    return this.state.cells[t+","+id]; 
}

getListCellsInLineage = (source_cell,time_type,time_range=undefined,direction=undefined) => {
    const list_cells = [source_cell];
    if (time_type === "all"){
        let result = []
        if (direction === undefined){
            let mothers = this.getAllCellsInMothers(source_cell);
            let daughters = this.getAllCellsInDaughters(source_cell);
            if (mothers === undefined && daughters === undefined){
                result = list_cells;
            }
            else if (mothers === undefined) result = daughters;
            else if (daughters === undefined) result = mothers;
            else result = mothers.concat(daughters);
        }
        else if (direction === 1){
            const daughters = this.getAllCellsInDaughters(source_cell);
            //console.log(daughters);
            if (daughters === undefined) result = list_cells;
            else result = daughters;
        }
        else {
            const mothers = this.getAllCellsInMothers(source_cell);
            if (mothers === undefined) result = list_cells;
            else result = mothers;
        }

        return result;
    }
    else if (time_type === "range"){
        if (time_range === undefined){
            time_range = [this.min_time,this.max_time]
        }  
        var result = []
        if (direction === undefined){
            let mothers = this.getRangeCellsInMothers(source_cell,time_range);
            let daughters = this.getRangeCellsInDaughters(source_cell,time_range);
            if (mothers === undefined && daughters === undefined){
                result = list_cells;
            }
            else if (mothers === undefined) result = daughters;
            else if (daughters === undefined) result = mothers;
            else result = mothers.concat(daughters);
        }
        else if (direction === 1){
            let daughters = this.getRangeCellsInDaughters(source_cell,time_range);
            if (daughters === undefined) result = list_cells;
            else result = daughters;
        }
        else {
            const mothers = this.getRangeCellsInMothers(source_cell,time_range);
            if (mothers === undefined) result = list_cells;
            else result = mothers;
        }

        return result;
    }
    else if (time_type === "life"){
        var result = []
        if (direction === undefined){
            var mothers = this.getLifeCellsInMothers(source_cell);
            var daughters = this.getLifeCellsInDaughters(source_cell);
            if (mothers === undefined && daughters === undefined){
                result = list_cells;
            }
            else if (mothers === undefined) result = daughters;
            else if (daughters === undefined) result = mothers;
            else result = mothers.concat(daughters);
        }
        else if (direction === 1){
            var daughters = this.getLifeCellsInDaughters(source_cell);
            if (daughters === undefined) result = list_cells;
            else result = daughters;
        }
        else {
            var mothers = this.getLifeCellsInMothers(source_cell);
            if (mothers === undefined) result = list_cells;
            else result = mothers;
        }

        return result;
    }
    
    return list_cells;
}

getRangeCellsInMothers = (current_cell,time_range) => {
    if (current_cell !== undefined){
        const list_cells = [];
        if (parseInt(time_range[0]) <= parseInt(current_cell.t) && parseInt(time_range[1]) >= parseInt(current_cell.t)){
            list_cells.push(current_cell)
        }
        if (current_cell.mothers === undefined || current_cell.mothers.length === 0){
            return list_cells;
        }
        else {
            let mothers = [];
            current_cell.mothers.forEach(function(mother) {
                mothers = mothers.concat(this.getRangeCellsInMothers(mother,time_range));
            }.bind(this));
            return list_cells.concat(mothers);
        }
    }
}

getRangeCellsInDaughters = (current_cell,time_range) => {
    if (current_cell !== undefined && time_range !== undefined){
        const list_cells = [];
        if (parseInt(time_range[0]) <= parseInt(current_cell.t) && parseInt(time_range[1]) >= parseInt(current_cell.t)){
            list_cells.push(current_cell)
        }
        if (current_cell.daughters === undefined || current_cell.daughters.length === 0){
            return list_cells;
        }
        else {
            let daughters = [];
            current_cell.daughters.forEach(function(daughter) {
                daughters = daughters.concat(this.getRangeCellsInDaughters(daughter,time_range));
            }.bind(this));
            //console.log("daughters : "+daughters.length);

            return list_cells.concat(daughters);
        }
    }
}

getAllCellsInMothers = (current_cell) => {
    if (current_cell !== undefined){
        const list_cells = [current_cell];
        if (current_cell.mothers === undefined || current_cell.mothers.length === 0){
            return list_cells;
        }
        else {
            let mothers = [];
            current_cell.mothers.forEach(function(mother) {
                mothers = mothers.concat(this.getAllCellsInMothers(mother));
            }.bind(this));
            return list_cells.concat(mothers);
        }
    }
}

getLifeCellsInMothers = (current_cell) => {
    if (current_cell !== undefined){
        const list_cells = [current_cell];
        if (current_cell.mothers === undefined || current_cell.mothers.length === 0 || (current_cell.mothers[0].daughters !== null && current_cell.mothers[0].daughters.length > 1)){
            return list_cells;
        }
        else {
            let mothers = [];
            current_cell.mothers.forEach(function(mother) {
                mothers = mothers.concat(this.getLifeCellsInMothers(mother));
            }.bind(this));
            return list_cells.concat(mothers);
        }
    }
}

getLifeCellsInDaughters = (current_cell) => {
    if (current_cell !== undefined){
        const list_cells = [current_cell];
        if (current_cell.daughters === undefined || current_cell.daughters.length === 0 || current_cell.daughters.length > 1){
            return list_cells;
        }
        else {
            let daughters = [];
            current_cell.daughters.forEach(function(daughter) {
                daughters = daughters.concat(this.getLifeCellsInDaughters(daughter));
            }.bind(this));
            return list_cells.concat(daughters);
        }
    }
}

getAllCellsInDaughters = (current_cell) => {
    if (current_cell !== undefined){
        const list_cells = [current_cell];
        if (current_cell.daughters === undefined || current_cell.daughters.length === 0){
            return list_cells;
        }
        else {
            let daughters = [];
            current_cell.daughters.forEach(function(daughter) {
                daughters = daughters.concat(this.getAllCellsInDaughters(daughter));
            }.bind(this));
            return list_cells.concat(daughters);
        }
    }
}

    //Create The Cell 
    createCells(c){
        ////console.log("createcell");
        //Create the Cell Objet
        const ce = new Cell(parseInt(c.t), parseInt(c.id));
        this.state.cells[ce.name()]=ce;
        c.daughters.forEach(function(daughter) {
            const d = this.createCells(daughter);
            ce.addDaughter(d);
                daughter.addMother(ce);
        }.bind(this));
        return ce;
    }
    
    getColorHex(col){
        ////console.log("getcolorHex");
        return new THREE.Color(col).getHexString () 
    }

    orderby(what){
        ////console.log("orderby");
        //We Fisrt List The Colors
        this.setState({order_by:what});
        this.replot();
        
    }

    componentWillUnmount() {
        this.props.closeLineage();
        this.state= [];
        this.props = [];
 
     }  

    //Create the tree wit calculating all cells positions 
    createBranch(c){
        ////console.log("create branch");
        switch (c.daughters.length) {
            case 0://NO DAUGHTERS
                c.x=this.StartX+this.nextXCell*spaceBetweenDaughter
                this.nextXCell+=1;
            break;
            case 1: //ONE DAUGHTERS
                c.daughters.forEach(function(daughter) {
                    c.x=this.createBranch(daughter);					
                }.bind(this));
            break;
            default: //MULTIPLE DAUGHTERS+v+
            ////////console.log("this.state.order_byh : "+this.state.order_by);
                let minX = 10000000;
                let maxX = -100000;
                if(this.state.order_by==="color"){
                        c.daughters.sort((a,b) => {return this.getColorHex(a.color) < this.getColorHex(b.color);}).forEach(function(daughter) {
                       // c.daughters.filter(c => { return col===this.getColorHex(c.color);}).forEach(function(daughter) {
                            const dx = this.createBranch(daughter);
                            if(minX>dx)minX=dx;
                                if(maxX<dx)maxX=dx;
      
                        }.bind(this));
                   // }.bind(this));		
                    
                    
                  //  c.daughters.filter(c => { return c.color===colorDefault;}).forEach(function(daughter) {
                  //      var dx=this.createBranch(daughter);
                //        if(minX>dx)minX=dx;
                //        if(maxX<dx)maxX=dx;
                    /*    ////console.log("couleur : "+daughter);
                        ////console.log(dx);
                        ////console.log(minX);
                        ////console.log(maxX);*/
           //     }.bind(this));

                }
                else if(this.state.order_by==="default"){
                  //  ////console.log("Order by default");
                   // //////console.log("Daughters length : "+c.daughters.length);
                    c.daughters.forEach(function(daughter) {
                        const dx = this.createBranch(daughter);
                        //  //////console.log("Daughter dx : "+dx);
                        if(minX>dx)minX=dx;
                        if(maxX<dx)maxX=dx;
                        }.bind(this));
                }else{ //ORDER BY AN INFOS
                  //  ////console.log("Order by infos");
                    let UniqueInfos=[];

                        c.daughters.sort((a,b) => {
                            if ((a.toshow === null || a.toshow[this.state.order_by] === null) && (b.toshow === null || b.toshow[this.state.order_by] === null)) {return 1;}
                            if ((b.toshow === null || b.toshow[this.state.order_by] === null)) {return 1;}
                            if ((a.toshow === null || a.toshow[this.state.order_by] === null)) {return -1;}
                            const a_value = a.toshow[this.state.order_by].replace(".",",");
                            const b_value = b.toshow[this.state.order_by].replace(".",",");
                
                            const a_float = parseFloat(a_value);
                            const b_float = parseFloat(b_value)
                            if (!isNaN(a_float) && !isNaN(b_float)){
                                return a_float - b_float;
                            }
                            const a_int = parseInt(a_value,10);
                            const b_int = parseInt(b_value,10);
                            if (!isNaN(a_int) && !isNaN(b_int)){
                                return a_int - b_int;
                            }
                
            
                            return a.toshow[this.state.order_by] > b.toshow[this.state.order_by] ? 1:b.toshow[this.state.order_by] > a.toshow[this.state.order_by] ?-1:0;
                        
                        }).forEach(function(daughter) {
                            // c.daughters.filter(c => { return col===this.getColorHex(c.color);}).forEach(function(daughter) {
                            const dx = this.createBranch(daughter);
                            if(minX>dx)minX=dx;
                                     if(maxX<dx)maxX=dx;
                                    /* ////console.log("couleur : "+daughter);
                                     ////console.log(dx);
                                     ////console.log(minX);
                                     ////console.log(maxX);*/
                             }.bind(this));
                   // }.bind(this));	
                }
                    
                
                c.x=minX+(maxX-minX)/2;
            break;
        }				
        ////////console.log("Cell "+c.name()+ "->"+c.x+ ":" +c.t);
        c.y=this.StartY-(c.t-this.state.min_time)*spaceBetweenT;
        if (this.maxY < c.y){
            this.maxY = c.y;
        }
        if (this.minY > c.y){
            this.minY = c.y
        }
        if (this.maxX < c.x){
            this.maxX = c.x;
        }
        if (this.minX > c.x){
            this.minX = c.x
        }
        return c.x;
    }
            
    toRadians (angle) {
        ////console.log("this.toRadians");
        return angle * (Math.PI / 180);
      }

    width_up(){
        spaceBetweenDaughter+=1;
        this.replot();
    }
    width_down(){
        spaceBetweenDaughter-=1;
        this.replot();
    }
    size_up(){
        spaceBetweenT+=10;
        
        this.replot();
    }
    size_down(){
        spaceBetweenT-=10;
        this.replot();
    }


            
    setUpZoom() {
        ////console.log("setupzoom");
        ////////console.log("Width : "+this.width+" and height : "+this.height);
        this.view.call(this.zoom);    
        this.initial_scale = this.getScaleFromZ(this.far);
       // //////console.log(this.initial_scale);
        const initial_transform = d3.zoomIdentity;/*.translate(this.width/2, this.height/2);  */
        ////////console.log(initial_transform);  
        this.zoom.transform(this.view, initial_transform);
    
        let x = (this.maxX + this.minX)/2;
        let y =  (this.maxY + this.minY)/2;
        //this.camera.position.set(x, y, this.far-1);
        this.camera.position.set(0, 0, this.far-1);
     } 

                 
    setUpZoomUsingXY() {
        ////console.log("setupzoomxy");
        ////////console.log("Width : "+this.width+" and height : "+this.height);
        this.view.call(this.zoom);    
        this.initial_scale = this.getScaleFromZ(this.far);
       // //////console.log(this.initial_scale);
        const initial_transform = d3.zoomIdentity.translate(this.maxX / 2, this.maxY / 2).scale(this.initial_scale);
        // //////console.log(initial_transform);
        this.zoom.transform(this.view, initial_transform);
        
        let x = (this.maxX + this.minX)/2;
        let y =  (this.maxY + this.minY)/2;
        //this.camera.position.set(x, y, this.far-1);
        this.camera.position.set(0, 0, this.far-1);
     } 

    zoomHandler(d3_transform) {
        ////console.log("zoomHandler");
        this.current_scale=d3_transform.k;
        if (this.backup_scale === undefined){
            this.backup_scale = this.current_scale;
        }
        let x = -(d3_transform.x - this.width/2) / this.current_scale;
        let y = (d3_transform.y - this.height/2) / this.current_scale;
        let z = this.getZFromScale(this.current_scale);
       // //////console.log("scale==="+this.current_scale + " x="+x + " y="+y + " z="+z);
        if(z>=this.far)z=this.far-1;
        if (this.state.backup_pos_x === undefined){
            this.setState({backup_pos_x:this.camera.position.x});
        }

        if (this.state.backup_pos_y === undefined){
            this.setState({backup_pos_y:this.camera.position.y});
        }

        if (this.state.backup_pos_z === undefined){
            this.setState({backup_pos_z:this.camera.position.z});
        }

        this.camera.position.set(x, y, z);
        //////console.log("Call to zomHandler took " + (t1 - t0) + " milliseconds.")
    }

    resetZoom = () => {
        if (d3.event !== undefined && d3.event.transform !== undefined) d3.event.transform = d3.zoomIdentity;
        let x = (this.width)/2 / this.current_scale;
        let y = -(this.height)/2 / this.current_scale;
        let z = this.getZFromScale(this.current_scale);
        //this.camera.position.set(this.state.backup_pos_x,this.state.backup_pos_y,this.state.backup_pos_z);
       // //////console.log("scale==="+this.current_scale + " x="+x + " y="+y + " z="+z);
        if(z>=this.far)z=this.far-1;

        this.camera.position.set(x, y, z);
        this.zoom.transform(this.view,d3.zoomIdentity);
        if (d3.event !== undefined && d3.event.transform !== undefined)this.zoomHandler(d3.event.transform);
    }
  

    resetLayout = () => {

        //if (this.state.backup_pos_x !== undefined) 
        
        if (d3.event !== undefined && d3.event.transform !== undefined) d3.event.transform = d3.zoomIdentity;
        this.setState({graph_form:0});
        this.setState({spaceBetweenDaughter:10});
        this.setState({spaceBetweenT:10});
        this.setState({hide_cells:false});
        this.setState({hide_lines:false});
        this.setState({highlight_division:false});
        this.setState({highlight_birth:false});
        this.setState({highlight_death:false});
        this.setState({order_by:false});
        this.replot();

        let x = (this.width)/2 / this.current_scale;
        let y = -(this.height)/2 / this.current_scale;
        let z = this.getZFromScale(this.current_scale);
        //this.camera.position.set(this.state.backup_pos_x,this.state.backup_pos_y,this.state.backup_pos_z);
       // //////console.log("scale==="+this.current_scale + " x="+x + " y="+y + " z="+z);
        if(z>=this.far)z=this.far-1;

        this.camera.position.set(x, y, z);
        this.zoom.transform(this.view,d3.zoomIdentity);
        if (d3.event !== undefined && d3.event.transform !== undefined)this.zoomHandler(d3.event.transform);
        

    }

    // Three.js render loop
    animate() {
        ////console.log("animate");
        requestAnimationFrame(this.animate);
        this.renderer.render(this.scene, this.camera);
      }
   
        componentWillUnmount(){
           cancelAnimationFrame(this.animate);
        }
              
    getScaleFromZ (camera_z_position) {
        ////console.log("getscalefromz");
        let half_fov = this.fov/2;
        let half_fov_radians = this.toRadians(half_fov);
        let half_fov_height = Math.tan(half_fov_radians) * camera_z_position;
        let fov_height = half_fov_height * 2;
        return this.height / fov_height;
      }
      
   
      
    getZFromScale(scale) {
        ////console.log("get z from scale");
        let half_fov =  this.fov/2;
        let half_fov_radians = this.toRadians(half_fov);
       // //////console.log("half fov radians : "+half_fov_radians);
        let scale_height = this.height / scale;
        return scale_height / (2 * Math.tan(half_fov_radians));
      }

    addCell(infos){
        ////console.log("addcell");
        const c = null;
        const info = infos.split(',');
        var t = 0;
        ////////console.log("Infos add cels : "+infos);
        let id = -1;
        if(info.length===1) var idc = parseInt(info[0], 10); //CELL
        if(info.length>=2) {t=parseInt(info[0],10); id=parseInt(info[1],10); } //TIME, CELL,(CHANNEL)
        ////////console.log("Idc : "+idc+" t : "+t+" id : "+id);
        if(id===-1 || t===-1) return c;
        if(t<this.state.min_time) return c;
        if(t>this.state.max_time) return c;
        ////////console.log("went through returns");
        
        if (this.state.cells === undefined){
            this.state.cells = [];
        }
        if (this.state.cells[t+","+id] !== undefined){
            return this.state.cells[t+","+id];
        }
        const ce = new Cell(parseInt(t), parseInt(id));
        this.state.cells[ce.name()]=ce;
        return ce;
        
    }

    hideCell(c){
       ////console.log("hidecell ");
       if (c !== undefined)
            c.visibility = false;
    }

    showCell(c){
       ////console.log("showcell");
       if (c !== undefined)
            c.visibility = true;
    }

    hideCellFutur(c){
        ////console.log("hideCellFutur");
        if (c !== undefined){
            this.hideCell(c);
            c.daughters.forEach(function(daughter){this.hideCellFutur(daughter);}.bind(this));      }
    }

    showCellFutur(c){
        ////console.log("show cell futur");
        this.showCell(c)
        if (c !== undefined)
            c.daughters.forEach(function(daughter){this.showCellFutur(daughter);}.bind(this));
    }

    hideCellPast(c){
        ////console.log("hide cell past: ");
        this.hideCell(c);
        if (c !== undefined){
            c.mothers.forEach(function(daughter){this.hideCellFutur(daughter);}.bind(this));   
            //this.state.cells.forEach(cell => {//////console.log("Found cell : "); //////console.log(cell);if (cell.daughters.includes(c)){this.hideCellPast(cell)}});
        }
    }

    showCellPast(c){
        ////console.log("show clel past");
        this.showCell(c);
        if (c !== undefined){

            c.mothers.forEach(function(daughter){this.showCellFutur(daughter);}.bind(this));

           // this.state.cells.forEach(cell => {//////console.log("Found cell : "); //////console.log(cell);if (cell.daughters.includes(c)){}});
        }
    }
    
    colorizeCell(c,color){
       // //////console.log("colorize "+c+ " for color : "+color);
        ////////console.log(c);
        color = color.replace(':','');
        if (c !== undefined){
            if (c.color !== "white" && c.color !== "rgb(255,255,255)"){
                this.state.backup_color_by_cell[c.t+","+c.id] = c.color;
            }
            c.color = color;
        }
    }

    resetColor(c){
        ////console.log("resetcolor");
        if (c !== undefined && this.state.backup_color_by_cell !== undefined &&  this.state.backup_color_by_cell[c.t+","+c.id] !== undefined){
            c.color = this.state.backup_color_by_cell[c.t+","+c.id];
           // //////console.log(this.state.backup_color_by_cell[c.t+","+c.id]);
        }
        else if (c !== undefined){
            c.color = colorDefault;
        }
    }

    colorizeCellFutur(c,color){
        ////console.log("colorize futur");
        this.colorizeCell(c,color);
        if (c !== undefined)
            c.daughters.forEach(function(daughter){ this.colorizeCellFutur(daughter,color);}.bind(this));
    }

    colorizeCellPast(c,color){
        ////console.log("colorize past");
        this.colorizeCell(c,color);
        if (c !== undefined){
            c.mothers.forEach(function(daughter){ this.colorizeCellPast(daughter,color);}.bind(this));
            //this.state.cells.forEach(cell => {//////console.log("Found cell : "); //////console.log(cell);if (cell.daughters.includes(c)){this.hideCellPast(cell)}});
        }
    }

    getColor(r,g,b){
        ////console.log("getcolor");
        return "rgb("+r+","+g+","+b+")";
    }

    getVector(c){
        ////console.log("getvector");
        if(this.state.graph_form===1){
            const angle = 2 * Math.PI / ((this.nextXCell * spaceBetweenDaughter) / (c.x - this.StartX));
            return new THREE.Vector3(c.y*Math.cos(angle)/2.1, c.y*Math.sin(angle)/2.1, 0);
        }else return new THREE.Vector3(c.x, c.y, 0);
    }

    updateCellColor= (cell_time,cell_id,cellcolor) => {
        const cell_name = cell_time + "," + cell_id;
        if(this.state.colorsIndexByCell[cell_name] !== -1){
            this.state.colors[this.state.colorsIndexByCell[cell_name]] = new THREE.Color(cellcolor);
        }   
    }
            
    drawCell(c){
        const cell_name = c.t + "," + c.id;
        this.state.verticesIndexByCell[cell_name] = -1
        this.state.colorsIndexByCell[cell_name] = -1
        this.state.linesIndexesByCell[cell_name] = []
        ////console.log("drawcell");			
        if(c.visibility) {  
            let cv=this.getVector(c);
           ////////console.log(cv);
            this.state.verticesIndexByCell[cell_name] = this.cellGeometry.vertices.push(cv) - 1; //DEFINE POSITION
                c.daughters.forEach(function(daughter) { //DRAW LINES WITH NEXT CELLS 
                    this.state.linesIndexesByCell[cell_name].push(this.lines.vertices.push(cv)-1);
                    this.state.linesIndexesByCell[cell_name].push(this.lines.vertices.push(this.getVector(daughter))-1);
                }.bind(this));
        } else  {
          //  //////console.log("Not Draw cell");
          this.state.verticesIndexByCell[cell_name] = this.cellGeometry.vertices.push(new THREE.Vector3(-1000, -1000, 0)) - 1; //DEFINE POSITION}

        }
        let new_color = c.color;
        if (c.selected === true){new_color="white";}
        this.state.colorsIndexByCell[cell_name] = this.state.colors.push(new THREE.Color(new_color)) - 1; //DEFINE COLOR
                    
    }
    
    draw(){
        ////console.log("draw");
        if (this.state.cells !== undefined) {
            Object.values(this.state.cells).filter(c => {return c !== undefined;}).forEach(function(c){
                this.drawCell(c);
            }.bind(this));
        }
    }

    partial_redraw= () => {
                /*while(this.scene.children.length > 0){ 
            this.scene.remove(this.scene.children[0]); 
        }*/
        //this.setUpZoom();
      //  console.error("partialredraw");
        //console.log(this.cellGeometry.vertices);
    const vertices = this.cellGeometry.vertices;
    const line_vertices = this.lines.verices;
    this.scene.remove(this.points);
    this.scene.remove(this.lines);
    
    this.cellGeometry = new THREE.Geometry();
    this.lines= new THREE.Geometry();
    //this.state.colors = [];
    //this.draw();
    for (let vert in vertices){
        this.cellGeometry.vertices.push(vertices[vert])
    }
    //console.log(this.cellGeometry.vertices);
    for (let line in line_vertices){
        this.lines.vertices.push(line_vertices[line])
    }
    if(!this.state.hide_cells){
        this.points = new THREE.Points(this.cellGeometry, this.cellsMaterial);
        if(this.state.graph_form === 1) 
            this.points.position.set((this.minX+this.maxX)/2,(this.minY+this.maxY)/2, 0)
        this.scene.add(this.points);
   }
    if(!this.state.hide_lines){
        this.lines = new THREE.LineSegments(this.lines, this.lineMaterial);
        if(this.state.graph_form === 1) 
            this.lines.position.set((this.minX+this.maxX)/2,(this.minY+this.maxY)/2, 0)
        this.scene.add(this.lines);
   }
    }
    redraw(){
        /*while(this.scene.children.length > 0){ 
            this.scene.remove(this.scene.children[0]); 
        }*/
        //this.setUpZoom();

    //console.error("ReDraw");
        this.scene.remove(this.points);
        this.scene.remove(this.lines);
        this.cellGeometry = new THREE.Geometry();
        this.lines= new THREE.Geometry();
        this.state.colors = [];
        this.draw();
        this.cellGeometry.colors = this.state.colors;
        if(!this.state.hide_cells){
            this.points = new THREE.Points(this.cellGeometry, this.cellsMaterial);
            if(this.state.graph_form === 1) 
                this.points.position.set((this.minX+this.maxX)/4,(this.minY+this.maxY)/2, 0)
            this.scene.add(this.points);
       }
        if(!this.state.hide_lines){
            this.lines = new THREE.LineSegments(this.lines, this.lineMaterial);
            if(this.state.graph_form === 1) 
                this.lines.position.set((this.minX+this.maxX)/4,(this.minY+this.maxY)/2, 0)
            this.scene.add(this.lines);
       }
       
   }

   replot(){
    this.nextXCell=0;
    if(this.state.order_by==="color"){
        ////////console.log("Order by color");
    //    let UniqueColors=[];
     //   Object.values(this.state.cells).filter(c => {return c.asMother===0;}).forEach(function(c){
               // let d=this.getColorHex(c.color);
    // //           if(!UniqueColors.includes(d)) {
                    ////////console.log("Add color :");
     //               ////////console.log(d);
     //               UniqueColors.push(d);
     //           }
    //    }.bind(this));
   //     UniqueColors.sort();
        const cells = Object.values(this.state.cells).filter(c => {
            return c.asMother === 0
        }).sort((a, b) => {
            return this.getColorHex(a.color) < this.getColorHex(b.color);
        });
        cells.forEach(function(c){
                this.createBranch(c);
            }.bind(this));
    }
    else if(this.state.order_by==="default"){
        Object.values(this.state.cells).filter(c => {return c.asMother === 0;}).forEach(function(c){
                this.createBranch(c);
            }.bind(this));
    }
    else{ //BY AN INF
       // ////console.log("Order by info2");
        let UniqueInfos=[];
        Object.values(this.state.cells).filter(c => {return c.asMother===0;}).sort((a,b) => {
            if ((a.toshow === null || a.toshow[this.state.order_by] === null) && (b.toshow === null || b.toshow[this.state.order_by] === null)) {return 1;}
            if ((b.toshow === null || b.toshow[this.state.order_by] === null)) {return 1;}
            if ((a.toshow === null || a.toshow[this.state.order_by] === null)) {return -1;}
            const a_value = a.toshow[this.state.order_by].replace(".",",");
            const b_value = b.toshow[this.state.order_by].replace(".",",");

            const a_float = parseFloat(a_value);
            const b_float = parseFloat(b_value)
            if (!isNaN(a_float) && !isNaN(b_float)){
                return a_float - b_float;
            }
            const a_int = parseInt(a_value,10);
            const b_int = parseInt(b_value,10);
            if (!isNaN(a_int) && !isNaN(b_int)){
                return a_int - b_int;
            }

            return a.toshow[this.state.order_by] > b.toshow[this.state.order_by] ? 1:b.toshow[this.state.order_by] > a.toshow[this.state.order_by] ?-1:0;
        
        }).forEach(function(c){
                this.createBranch(c);
            }.bind(this));
    
                
    }
    this.redraw();
}

    onUnload = () => {
        this.props.clearMorphoPlotLineage();
    }

    componentDidMount(){
        ////console.log("componentDidMont");
        //update view on resize
        window.addEventListener('resize', () => {
         //   //////console.log("resize");
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            this.renderer.setSize(this.width, this.height);
            this.camera.aspect = this.width / this.height;
            this.camera.updateProjectionMatrix();
            //this.redraw();
          })
          this.state.id_user = getUserID();
          //console.log("id user = "+this.state.id_user);
          this.state.user_token = getUserToken();
          window.addEventListener('beforeunload', this.onUnload);
          
          this.scene = new THREE.Scene();
          this.camera = new THREE.PerspectiveCamera( this.fov, this.width/this.height, this.near, this.far );
          this.renderer = new THREE.WebGLRenderer({alpha:true,antialias:true});
         // this.height = this.myRef.current.clientHeight;
         // this.width = this.myRef.current.clientWidth;
          this.renderer.setSize( this.width,this.height );
          this.myRef.current.appendChild(this.renderer.domElement);
            // document.body.appendChild( renderer.domElement );
          this.renderer.render(this.scene,this.camera);
          
          if (this.props.max_time !== undefined && this.props.max_time !== this.state.max_time)
          {
              ////////console.log("Received max time : "+this.props.max_time);
              this.setState({max_time:this.props.max_time});
              //if (this.props.min_time !== -1 && this.props.min_time !== undefined){
               // this.state.time_catch = true;
            //}
          }
  
          if (this.props.min_time !== undefined && this.props.min_time !== this.state.min_time)
          {
              ////////console.log("Received min time : "+this.props.min_time);
              this.setState({min_time:this.props.min_time});
              if (this.props.max_time !== -1 && this.props.max_time !== undefined){
                this.state.time_catch = true;
            }
          }

          if (this.props.id_set !== undefined && this.props.id_set !== this.state.id_set){
            ////////console.log("Received id set (mount) : "+this.props.id_set);
            this.setState({id_set:this.props.id_set});
        }
        //DEBUG
      /*  this.setState({id_set:1});
        this.setState({min_time:1});
        this.setState({max_time:180});
        this.setState({id_dataset:1});
        this.setState({time_catch:true});*/

              // === THREE.JS CODE START ===


    // === THREE.JS EXAMPLE CODE END ===
    }

    handleInfoChange = (value) => {
        ////console.log("handleinfochange");
       // //////console.log(this.state.last_info_key);
        if (!this.state.loaded_infos.includes(this.state.last_info_key)){
            this.state.loaded_infos.push(this.state.last_info_key);
        }

        const id = this.state.last_info_key.id;
        const name = this.state.last_info_key.infos;

        if (this.state.id_set > 0){
            axios.get('/api/infofield/',{ params : {id_info: id}})
            .then(res => {
                if (res.data.status === "failed"){
                    //////console.log("Error during info field check");
                }
                else {
                    const vname_infos = name.trim().replace(' ', '_');
                    //this.state.order_by = vname_infos;
                    const field = res.data;
                    let tab = undefined;
                    if (this.state.id_set === 0 || this.state.id_set === "0"){
                        tab=field.split('\\n'); // info split should be on ('\n) for direct plot
                    }
                    else 
                    {
                        tab=field.split('\n'); // info split should be on ('\n) for direct plot
                    }
                    tab.forEach(function(l){
                        if(l.length>0 && l[0]!=='#'){
                            const elt = l.split(':');
                            if(elt[0]!=='type'){
                                const tid = this.parseCell(elt[0]);
                                if(tid.length===2){
                                    const c = this.getCell(tid[0], tid[1]);
                                    if(c!==undefined) {
                                        c.toshow[vname_infos] = undefined;
                                        if(c.toshow[vname_infos]===undefined)c.toshow[vname_infos]=name_infos+" : ";
                                        c.toshow[vname_infos]+=elt[1]+"\n";
                                    }
                                }
                            }
                        }
                    }.bind(this));
                    //this.replot();
                }
        });
        }
        else {
            var name_infos=name;
            var vname_infos=name_infos.trim().replace(' ','_');
           // this.state.order_by = vname_infos;
            var field = this.state.info_list.filter(info => info.id === id)[0].lines;
            var tab=field.split('\n');
            tab.forEach(function(l){
                if(l.length>0 && l[0]!=='#'){
                    const elt = l.split(':');
                    if(elt[0]!=='type'){
                        const tid = this.parseCell(elt[0]);
                        if(tid.length===2){
                            const c = this.getCell(tid[0], tid[1]);
                            if(c!==undefined) {
                                c.toshow[vname_infos] = undefined;
                                if(c.toshow[vname_infos]===undefined)c.toshow[vname_infos]=name_infos+" : ";
                                c.toshow[vname_infos]+=elt[1]+"\n";
                            }
                        }
                    }
                }
            }.bind(this));
          //  this.replot();
        }
    }

    clearACell = (cell,selection) => {
        cell.removeSelection(selection);
        if (cell.selection !== undefined && cell.selection !== [] && cell.selection.length > 0){
            //console.log("still at least one cell selection");
            this.colorizeCell(cell,this.state.color_by_selection[cell.selection[0]]);
        }
        else {
            //console.log("no more cell selection");
            this.colorizeCell(cell,colorDefault);
        }
    }

    clearAllCell = (cell) => {
        cell.clearSelections();
        this.colorizeCell(cell,colorDefault);
    }

    pickCells = (listcells,selection_type)=>{
        //console.log("clear");

        const tabCells = listcells.split(":");
        for (let i = 0; i < tabCells.length; i++) {
                const cell = tabCells[i].split(";");
                if(cell.length===2) {
                    if (this.getCell(cell[0],cell[1])!== undefined){
                        this.getCell(cell[0],cell[1]).selected = parseInt(selection_type) === 1;
                    }
                }
            }



        //            if(v===1) this.colorizeCellFutur(this.getCell(cell[0],cell[1]),colIUsed);
        //else this.colorizeCellPast(this.getCell(cell[0],cell[1]),colIUsed);
        this.redraw();
    }


    clearCells = (method,listcells,type,id_select,timepoint)=>{
        //console.log("clear");
        let time_method = method;

        let time_range = null;
        if (method.includes("range")){
            time_range = []
            const method_split = method.split('*');
            time_method = method_split[0]
            time_range.push(parseInt(method_split[1]))
            time_range.push(parseInt(method_split[2]))
        }
       /* if (method === "normal" || method === ""){
            time_method = "all";
            //if propagate, default behaviour (no times) is all propagate
        }*/
        const listCells_final = [];
        if (type === "picked"){
            const tabCells = listcells.split(":");
            for (let i = 0; i < tabCells.length; i++) {
                var cell=tabCells[i].split(";");
                if(cell.length===2) {
                    if (this.getCell(cell[0],cell[1])!== undefined){
                        var elements = this.getListCellsInLineage(this.getCell(cell[0],cell[1]),time_method,time_range,undefined);
                        for (var  j = 0; j < elements.length; j++){
                            var flag = this.checkCellCondition(type,elements[j],id_select);
                            if (flag){
                                if (type === "all" || type === "multiple" || type === "picked"){
                                    this.clearAllCell(elements[j]);
                                }
                                else {
                                    this.clearACell(elements[j],id_select);
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            Object.values(this.state.cells).filter(cell => parseInt(cell.t) === parseInt(timepoint)).forEach(cell => {
                const elements = this.getListCellsInLineage(cell, time_method, time_range, undefined);
                for (let j = 0; j < elements.length; j++){
                        const flag = this.checkCellCondition(type, elements[j], id_select);
                        if (flag){
                            if (type === "all" || type === "multiple" || type === "picked"){
                                this.clearAllCell(elements[j]);
                            }
                            else {
                                this.clearACell(elements[j],id_select);
                            }
                        }
                    }
            });
        }

        //            if(v===1) this.colorizeCellFutur(this.getCell(cell[0],cell[1]),colIUsed);
        //else this.colorizeCellPast(this.getCell(cell[0],cell[1]),colIUsed);
        this.redraw();
    }

    cancelCells = (method,listcells,type,id_select,timepoint,direction)=>{
        //console.log("clear");

        //var startTime = performance.now()
        let time_method = method;

        let time_range = null;
        if (method.includes("range")){
            time_range = []
            const method_split = method.split('*');
            time_method = method_split[0]
            time_range.push(parseInt(method_split[1]))
            time_range.push(parseInt(method_split[2]))
        }
        if (method === "normal" || method === ""){
            time_method = "all";
            //if propagate, default behaviour (no times) is all propagate
        }
       /* if (method === "normal" || method === ""){
            time_method = "all";
            //if propagate, default behaviour (no times) is all propagate
        }*/
        //var endTime = performance.now()
       // console.log(`start took ${endTime - startTime} milliseconds milliseconds`)
        const listCells_final = [];
        if (type === "picked"){
            const tabCells = listcells.split(":");
            for (let i = 0; i < tabCells.length; i++) {
                var cell=tabCells[i].split(";");
                if(cell.length===2) {
                    if (this.getCell(cell[0],cell[1])!== undefined){
                        var elements = this.getListCellsInLineage(this.getCell(cell[0],cell[1]),time_method,time_range,direction);
                        for (var  j = 0; j < elements.length; j++){
                            var flag = this.checkCellCondition(type,elements[j],id_select);
                            if (parseInt(elements[j].t) !== parseInt(timepoint)){
                                if (flag){
                                    if (type === "all" || type === "multiple" || type === "picked"){
                                        this.clearAllCell(elements[j]);
                                    }
                                    else {
                                        this.clearACell(elements[j],id_select);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
          //  var startTime = performance.now()
            Object.values(this.state.cells).filter(cell => parseInt(cell.t) === parseInt(timepoint)).forEach(cell => {
                const elements = this.getListCellsInLineage(cell, time_method, time_range, direction);
                for (let j = 0; j < elements.length; j++){
                        const flag = this.checkCellCondition(type, elements[j], id_select);
                        if (parseInt(elements[j].t) !== parseInt(timepoint)){
                            if (flag){
                                if (type === "all" || type === "multiple" || type === "picked"){
                                    this.clearAllCell(elements[j]);
                                }
                                else {
                                    this.clearACell(elements[j],id_select);
                                }
                            }
                        }
                }
            });
          //  var endTime = performance.now()
          //  console.log(`boucle took ${endTime - startTime} milliseconds milliseconds`)
        }

        //            if(v===1) this.colorizeCellFutur(this.getCell(cell[0],cell[1]),colIUsed);
        //else this.colorizeCellPast(this.getCell(cell[0],cell[1]),colIUsed);
      //  var startTime = performance.now()
        this.redraw();
       // var endTime = performance.now()
       // console.log(`boucle took ${endTime - startTime} milliseconds milliseconds`)
    }



    hideCells = (do_hide,method,listcells,type,id_select,timepoint)=>{
        ////////console.log("propagate");
        //var startTime = performance.now()
        let time_method = method;

        let time_range = null;
        if (method.includes("range")){
            time_range = []
            const method_split = method.split('*');
            time_method = method_split[0]
            time_range.push(parseInt(method_split[1]))
            time_range.push(parseInt(method_split[2]))
        }
       /* if (method === "normal" || method === ""){
            time_method = "all";
            //if propagate, default behaviour (no times) is all propagate
        }*/
        const listCells_final = [];
        //var endTime = performance.now()
        //console.log(`Prepare parameters took ${endTime - startTime} milliseconds`)
        if (type === "picked"){
           // var startTime = performance.now()
            const tabCells = listcells.split(":");
            for (let i = 0; i < tabCells.length; i++) {
                var cell=tabCells[i].split(";");
                if(cell.length===2) {
                    if (this.getCell(cell[0],cell[1])!== undefined){
                    var elements = this.getListCellsInLineage(this.getCell(cell[0],cell[1]),time_method,time_range,undefined);
                    for (var  j = 0; j < elements.length; j++){
                        var flag = this.checkCellCondition(type,elements[j],id_select);

                        
                        if (flag){
                            if (do_hide)this.hideCell(elements[j]); else this.showCell(elements[j]);
                            
                        }
                    }
                    
                }
            }
        }
       // var endTime = performance.now()
       // console.log(`boucle took ${endTime - startTime} milliseconds`)
       // var startTime = performance.now()
        this.redraw();
        //var endTime = performance.now()
        // console.log(`redraw took ${endTime - startTime} milliseconds`);
        }
        else {
            //var startTime = performance.now()
            Object.values(this.state.cells).filter(cell => parseInt(cell.t) === parseInt(timepoint)).forEach(cell => {
                const elements = this.getListCellsInLineage(cell, time_method, time_range, undefined);

                for (let j = 0; j < elements.length; j++){
                        const flag = this.checkCellCondition(type, elements[j], id_select);
                        //if (type === "free") {console.log(elements[j].t+","+elements[j].id+" => "+flag)}
                        if (flag){
                            if (do_hide)this.hideCell(elements[j]); else this.showCell(elements[j]);

                           
                        }
                    }

            });
           // var endTime = performance.now()
            //console.log(`foreach took ${endTime - startTime} milliseconds milliseconds`)
           // var startTime = performance.now()
            this.redraw();
            //var endTime = performance.now()
            // console.log(`redraw took ${endTime - startTime} milliseconds`);
        }

        //            if(v===1) this.colorizeCellFutur(this.getCell(cell[0],cell[1]),colIUsed);
        //else this.colorizeCellPast(this.getCell(cell[0],cell[1]),colIUsed);
       
    }

    
hideThis = (do_hide,method,listcells,type,id_select) => {
    if (listcells === undefined){
        return ;
    }

    const listCells_final = [];
    ////////console.log("propagate");
    let time_method = method;
    let time_range = null;
    if (method.includes("range")){
        time_range = []
        const method_split = method.split('*');
        time_method = method_split[0]
        time_range.push(parseInt(method_split[1]))
        time_range.push(parseInt(method_split[2]))
    }
    /*if (method === "normal" || method === ""){
        time_method = "all";
        //if propagate, default behaviour (no times) is all propagate
    }*/
    for (let i = 0; i < tabCells.length; i++) {
        var tabCells=listcells.split(":");
        const cell = tabCells[i].split(";");
        if(cell.length===2) {
            if (this.getCell(cell[0],cell[1])!== undefined){
                const elements = this.getListCellsInLineage(this.getCell(cell[0], cell[1]), time_method, time_range, undefined);
                //console.log(elements);
                for (let j = 0; j < elements.length; j++){
                    const flag = this.checkCellCondition(type, elements[j], id_select);
                    if (flag){
                        if (do_hide)this.hideCell(elements[j]); else this.showCell(elements[j]);
                    }
                }
            }
        }
    }
    //console.log("listclel final : ");
    //console.log(listCells_final);
    //            if(v===1) this.colorizeCellFutur(this.getCell(cell[0],cell[1]),colIUsed);
    //else this.colorizeCellPast(this.getCell(cell[0],cell[1]),colIUsed);
    this.redraw();
}

checkCellCondition = (type,element,id_select) => {
    let flag = false;
    if (type === "multiple" && (element.selection !== undefined && element.selection.length > 1)){
        flag = true;
    }
    else if (type === "select" && (element.selection !== undefined && element.selection.length > 0 && element.selection.includes(id_select))){
        flag = true;
    }
    else if (type === "all" && (element.selection !== undefined && element.selection.length > 0)){
        flag = true;
    }
    else if (type === "free" && (element.selection === undefined || element.selection === [] || element.selection.length === 0)){
        flag = true;
    }
    else if (type === "picked"){
        flag = true;
    }
    return flag;
}

//PROPAGE A COLOR TO PAS OR FUTURS
propagateThis(method,v,r,g,b,listcells,type,id_select,timepoint){
    ////console.log("Propagate");

    const listCells_final = [];
    ////////console.log("propagate");
    let time_method = method;

    let time_range = null;
    if (method.includes("range")){
        time_range = []
        const method_split = method.split('*');
        time_method = method_split[0]
        time_range.push(parseInt(method_split[1]))
        time_range.push(parseInt(method_split[2]))
    }
    if (method === "normal" || method === ""){
        time_method = "all";
        //if propagate, default behaviour (no times) is all propagate
    }
    //console.log("method : "+time_method);
    //console.log("time range : "+time_range);
    //console.log("listcellinit : "+tabCells);
    const colIUsed = this.getColor(r, g, b);
    //console.log("color used : "+colIUsed);
   /* if (tabCells === ";"){
        tabCells =  Object.values(this.state.cells);
    }*/
    if (type === "select" || type === "picked"){
        const tabCells = listcells.split(":");
        for (let i = 0; i < tabCells.length; i++) {
        var cell=tabCells[i].split(";");
        if(cell.length===2) {
            if (this.getCell(cell[0],cell[1])!== undefined){
                var elements = this.getListCellsInLineage(this.getCell(cell[0],cell[1]),time_method,time_range,v);
                //console.log(elements);
                for (var  j = 0; j < elements.length; j++){
                    if (type === "select")
                    {
                            elements[j].addSelection(id_select);
                            this.state.color_by_selection[id_select] = colIUsed;
                            this.colorizeCell(elements[j],colIUsed);
                        
                    }
                    else {
                        if (v===1){
                            if (elements[j].mothers !== undefined && elements[j].mothers.length>0 && elements[j].mothers[0].selection !== undefined && elements[j].mothers[0].selection.length>0){
                                var new_selection = elements[j].mothers[0].selection[0];
                                if (new_selection !== undefined){
        
                                    elements[j].addSelection(new_selection);
                                        this.colorizeCell(elements[j],this.state.color_by_selection[new_selection]);
                                }
                            }
                        }else {
                            if (elements[j].daughters !== undefined && elements[j].daughters.length>0 && elements[j].daughters[0].selection !== undefined && elements[j].daughters[0].selection.length>0){
                                var new_selection = elements[j].daughters[0].selection[0];
                                if (new_selection !== undefined){
        
                                    elements[j].addSelection(new_selection);
                                        this.colorizeCell(elements[j],this.state.color_by_selection[new_selection]);
                                }
                            }   
                        }
                }
                }
                }
            }
        }
    }
    else {
        Object.values(this.state.cells).filter(cell => parseInt(cell.t) === parseInt(timepoint)).forEach(cell => {
            const elements = this.getListCellsInLineage(cell, time_method, time_range, v);
            for (let j = 0; j < elements.length; j++){
                    if (type === "select")
                    {
                            elements[j].addSelection(id_select);
                            this.state.color_by_selection[id_select] = colIUsed;
                            this.colorizeCell(elements[j],colIUsed);
                        
                    }
                    else {
                        if (v===1){
                            if (elements[j].mothers !== undefined && elements[j].mothers.length>0 && elements[j].mothers[0].selection !== undefined && elements[j].mothers[0].selection.length>0){
                                var new_selection = elements[j].mothers[0].selection[0];
                                if (new_selection !== undefined){
        
                                    elements[j].addSelection(new_selection);
                                        this.colorizeCell(elements[j],this.state.color_by_selection[new_selection]);
                                }
                            }
                        }else {
                            if (elements[j].daughters !== undefined && elements[j].daughters.length>0 && elements[j].daughters[0].selection !== undefined && elements[j].daughters[0].selection.length>0){
                                var new_selection = elements[j].daughters[0].selection[0];
                                if (new_selection !== undefined){
        
                                    elements[j].addSelection(new_selection);
                                        this.colorizeCell(elements[j],this.state.color_by_selection[new_selection]);
                                }
                            }   
                        }
                }
            }
        });
    }
    //            if(v===1) this.colorizeCellFutur(this.getCell(cell[0],cell[1]),colIUsed);
    //else this.colorizeCellPast(this.getCell(cell[0],cell[1]),colIUsed);
    this.redraw();
}
 componentToHex = (c) =>  {
     const hex = c.toString(16);
     return hex.length === 1 ? "0" + hex : hex;
  }
  
  rgbToHex = (r, g, b) => {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

    componentDidUpdate(prevProps,prevState){

        if (this.props.user_id !== undefined && this.props.user_id !== this.state.user_id){
            this.setState({user_id:this.props.user_id});
        }
/*
        if (this.props.user_token !== undefined && this.props.user_token !== this.state.user_token){
            this.setState({user_token:this.props.user_token});
        }*/
   /*     if (this.props.max_time !== undefined && this.props.max_time !== this.state.max_time && !this.state.time_catch)
        {
            //////console.log("Received max time : "+this.props.max_time);
            this.setState({max_time:this.props.max_time});
            if (this.state.min_time !== -1){
                this.state.time_catch= true;
            } 
        }

        if (this.props.min_time !== undefined && this.props.min_time !== this.state.min_time && ! this.state.time_catch)
        {
            //////console.log("Received min time : "+this.props.min_time);
            this.setState({min_time:this.props.min_time});
            if (this.state.max_time !== -1){
                this.state.time_catch= true;
            } 
        }*/
       /* if (this.props.HideCellsList !== undefined && this.props.HideCellsList !== -1 && this.props.HideCellsList !== this.state.HideCellsList){
            ////console.log("aply select list");
               //////console.log("aply select list");
               //////console.log(this.props.ApplySelectListCells);
               //var RGB = this.props.ApplySelectRGB;
               //var ListCells = this.props.ApplySelectListCells;
               this.setState({HideCellsList:this.props.HideCellsList});

   
               if (this.props.HideCellsList.startsWith(">COMPLETESTART")){
                   if (this.props.HideCellsList !== null && this.props.HideCellsList !== undefined && this.props.HideCellsList !== ""){
                       var info_text = this.props.HideCellsList.replace(">COMPLETESTART","").split('|');
                       var cells = info_text[0].split(':'); 
                       for (var cell in cells){
                           var cell_data = cells[cell].split(';');
                           if (cell_data[0] !== undefined && cell_data[1] !== undefined){
                            var cell_obj = this.getCell(cell_data[0],cell_data[1])
                            this.hideCell(cell_obj);
                            }
                        }
                       
                      // this.partial_redraw();
                       this.redraw();
                       this.state.temp_hide_cells = "";
                   }
               }
               if (this.props.HideCellsList.includes(">START")){
                this.state.temp_hide_cells = "";
            }
            else if (this.props.HideCellsList.includes("END<")){
                var times = [];
                if (this.state.temp_hide_cells !== null && this.state.temp_hide_cells !== undefined && this.state.temp_hide_cells !== ""){
                    var cells = this.state.temp_hide_cells.split(':'); 
                       for (var cell in cells){
                           var cell_data = cells[cell].split(';');
                           if (cell_data[0] !== undefined && cell_data[1] !== undefined){
                               if (!times.includes(cell_data[0])){
                                   times.push(cell_data[0]);
                               }
                                var cell_obj = this.getCell(cell_data[0],cell_data[1])
                                this.hideCell(cell_obj);
                           }
                        }
                       
                      // this.partial_redraw();
                       this.redraw();
                       console.log("Cleared : "+times.length+" times");
                       this.state.temp_hide_cells = "";
                }
            }
            else {
               // ////console.log(this.props.lastInfoReceived);
                this.state.temp_hide_cells += this.props.HideCellsList.split('|')[0];
            }

            //this.applySelection(params[0],params[1],params[2],params[3],params[4]);
            //this.redraw();
        }
        */
/*
        if (this.props.ShowCellsList !== undefined && this.props.ShowCellsList !== -1 && this.props.ShowCellsList !== this.state.ShowCellList){
   
               if (this.props.ShowCellsList.startsWith(">COMPLETESTART")){
                   if (this.props.ShowCellsList !== null && this.props.ShowCellsList !== undefined && this.props.ShowCellsList !== ""){
                       var info_text = this.props.ShowCellsList.replace('>COMPLETESTART','').split('|');
                       var cells = info_text[0].split(':'); 
                       for (var cell in cells){
                           var cell_data = cells[cell].split(';');
                           if (cell_data[0] !== undefined && cell_data[1] !== undefined){
                                var cell_obj = this.getCell(cell_data[0],cell_data[1])
                                this.showCell(cell_obj);
                           }
                        }
                       
                      // this.partial_redraw();
                       this.redraw();
                       this.state.temp_show_cells = "";
                   }
               }
               if (this.props.ShowCellsList.includes(">START")){
                this.state.temp_show_cells = "";
            }
            else if (this.props.ShowCellsList.includes("END<")){
                if (this.state.temp_show_cells !== null && this.state.temp_show_cells !== undefined && this.state.temp_show_cells !== ""){
                    var cells = this.state.temp_show_cells.split(':'); 
                       for (var cell in cells){
                           var cell_data = cells[cell].split(';');
                           if (cell_data[0] !== undefined && cell_data[1] !== undefined){
                               var cell_obj = this.getCell(cell_data[0],cell_data[1])
                                this.showCell(cell_obj);
                           }
                        }
                       
                      // this.partial_redraw();
                       this.redraw();
                       this.state.temp_show_cells = "";
                }
            }
            else {
               // ////console.log(this.props.lastInfoReceived);
                this.state.temp_show_cells += this.props.ShowCellsList.split('|')[0];
            }

            //this.applySelection(params[0],params[1],params[2],params[3],params[4]);
            //this.redraw();
        }
*/
        if (this.props.send_background_color !== undefined && this.props.send_background_color !== "" && this.props.send_background_color !== this.state.send_background_color){
            this.setState({send_background_color:this.props.send_background_color});
            var filter = this.props.send_background_color.split('|')[0];
            //console.log("changebackground color !! ("+rgb[0]+","+rgb[1]+","+rgb[2]+")");
            const rgb = filter.split(',');
            //console.log("changebackground color !! ("+rgb[0]+","+rgb[1]+","+rgb[2]+")");
            if (rgb[0] !== undefined && rgb[1] !== undefined && rgb[2] !== undefined){
                var color = this.rgbToHex(parseInt(255*rgb[0]),parseInt(rgb[1]*255),parseInt(255*rgb[2]));
                this.setState({ backgroundColor : color});
            }
        }
        if (this.props.SendColors !== undefined && this.props.SendColors !== -1 && this.props.SendColors !== this.state.SendColors){
            ////console.log("aply select list");
               //////console.log("aply select list");
             //  console.log(this.props.SendColors);
               //var RGB = this.props.ApplySelectRGB;
               //var ListCells = this.props.ApplySelectListCells;
               this.setState({SendColors:this.props.SendColors});
               //var params = this.props.ApplySelectListCells.split('/');
              /* var colors = RGB.split(':');
               var r = colors[0];
               var g = colors[1];
               var b = colors[2];*/
   
               if (this.state.SendColors_utile !== this.props.SendColors.split('|')[0] ){
                this.setState({SendColors_utile:this.props.SendColors.split('|')[0]});
                   if (this.props.SendColors.startsWith(">COMPLETESTART")){
                   if (this.props.SendColors !== null  && this.props.SendColors !== ""){
                       const colors = this.props.SendColors;
                       var info_text = colors.replace(">COMPLETESTART","").split('|');
                      // console.log(this.props.SendColors.replace(">COMPLETESTART",""));
                       var selects = info_text[0].split('!'); 
                       for (var select in selects){
                           if (selects[select]!== null && selects[select]!==""){
                               var params = selects[select].split('/'); 
                                if (params[0] !== null && params[1] !== null && params[2] !== null && params[3] !== null && params[4]!==null)
                                {
                                    this.applySelection(params[0],params[1],params[2],params[3],params[4]);
                                }
                        }
                       }
                      // this.partial_redraw();
                       this.redraw();
                       this.state.temp_send_colors = "";
                   }
               }
               if (this.props.SendColors.includes(">START")){
                this.state.temp_send_colors = "";
            }
            else if (this.props.SendColors.includes("END<")){
               // console.log("Received colors !!");
                //console.log(this.state.temp_send_colors);
                if (this.state.temp_send_colors  !== null && this.state.temp_send_colors !== ""){
                    var selects = this.state.temp_send_colors.split('!'); 
                   // console.log(this.state.temp_send_colors);
                    for (var select in selects){
                        if (selects[select]!== null && selects[select]!==""){
                        var params = selects[select].split('/');
                        if (params[0] !== null && params[1] !== null && params[2] !== null && params[3] !== null && params[4] !== null)
                        {
                            this.applySelection(params[0],params[1],params[2],params[3],params[4]);
                        }
                        }
                    }
                    //this.partial_redraw();
                    this.redraw();
                    this.state.temp_send_colors = "";
                }
            }
            else {
               // ////console.log(this.props.lastInfoReceived);
                this.state.temp_send_colors += this.props.SendColors.split('|')[0].replace(">COMPLETESTART","");
            }
            }

            //this.applySelection(params[0],params[1],params[2],params[3],params[4]);
            //this.redraw();
        }

        if (this.props.receiveColorInfo !== this.state.receiveColorInfo && this.props.receiveColorInfo !== null){
            this.setState({receiveColorInfo:this.props.receiveColorInfo});
            const infos_params = this.props.receiveColorInfo.split('!');
            const type = infos_params[0];//"selection" or "colormap "
            const id_info = infos_params[1]; // id
            const additional_data = infos_params[2];//selection = tableau int > color, colormap tableau value > color

            if (type !== null && type !== "" && id_info !== null && id_info !== -1 && additional_data !== null){
                this.loadColorInfo(type,id_info,additional_data);
                //this.redraw();
            }

        }


        if (this.props.max_time !== undefined && this.props.max_time !== this.state.max_time)
          {
              ////console.log("Received max time : "+this.props.max_time);
              this.setState({max_time:this.props.max_time});
              if (this.props.min_time !== -1 && this.props.min_time !== undefined){
                this.state.time_catch = true;
            }
          }
  
          if (this.props.min_time !== undefined && this.props.min_time !== this.state.min_time)
          {
              ////console.log("Received min time : "+this.props.min_time);
              this.setState({min_time:this.props.min_time});
              if (this.props.max_time !== -1 && this.props.max_time !== undefined){
                this.state.time_catch = true;
            }
          }


        if (this.state.order_by !== prevState.order_by){
            ////console.log("order_by");
            this.replot();
        }
        if (this.props.PropagateThisListCells !== undefined && this.props.PropagateThisListCells !== -1 && this.props.PropagateThisListCells !== this.state.PropagateThisListCells){
          //  this.setState({PropagateThisV:this.props.PropagateThisV});
         //   this.setState({PropagateThisRGB:this.props.PropagateThisRGB});
            var filter = this.props.PropagateThisListCells.split('|')[0];
           //console.log("propagate list : "+this.props.PropagateThisListCells);
            this.setState({PropagateThisListCells:this.props.PropagateThisListCells});
            var params = filter.split('/');
           // var colors = this.props.PropagateThisRGB.split(':');
           // var r = colors[0];
           // var g = colors[1];
           // var b = colors[2];
            this.propagateThis(params[0],params[1],params[2],params[3],params[4],params[5],params[6],params[7],params[8]);
        }

        if (this.props.clearCellsParams !== undefined && this.props.clearCellsParams !== -1 && this.props.clearCellsParams !== this.state.clearCellsParams){
            //console.log("received params clear");
            var filter = this.props.clearCellsParams.split('|')[0];
            var params = filter.split('/');
            
            this.setState({clearCellsParams:this.props.clearCellsParams});
            this.clearCells(params[0],params[1],params[2],params[3],params[4]);
        }

        if (this.props.pickCellsParams !== undefined && this.props.pickCellsParams !== -1 && this.props.pickCellsParams !== this.state.pickCellsParams){
            //console.log("received params clear");
            var filter = this.props.pickCellsParams.split('|')[0];
            var params = filter.split('/');
            
            this.setState({pickCellsParams:this.props.pickCellsParams});
            this.pickCells(params[0],params[1]);
        }

        if (this.props.cancelCellsParams !== undefined && this.props.cancelCellsParams !== -1 && this.props.cancelCellsParams !== this.state.cancelCellsParams){
            //console.log("received params clear");
            var filter = this.props.cancelCellsParams.split('|')[0];
            var params = filter.split('/');
            
            this.setState({cancelCellsParams:this.props.cancelCellsParams});
            this.cancelCells(params[0],params[1],params[2],params[3],params[4],params[5]);
        }

        

        if (this.props.changeColorSelection !== undefined && this.props.changeColorSelection !== this.state.changeColorSelection){
            var filter = this.props.changeColorSelection.split('|')[0];
            //console.log("changeselection");
            var params = filter.split('/');
            this.setState({changeColorSelection:this.props.changeColorSelection});
            const old_id = params[0];
            const new_id = params[1];
            const new_color = this.getColor(parseInt(255 * parseFloat(params[2])), parseInt(255 * parseFloat(params[3])), parseInt(255 * parseFloat(params[4])));
            if (old_id !== undefined && new_id !== undefined && new_color !== undefined){
                this.state.color_by_selection[old_id] = new_color;
                Object.values(this.state.cells).forEach(cell => {
                    if (cell.selection !== undefined && cell.selection.length > 0 && cell.selection.includes(old_id)){
                        cell.removeSelection(old_id);
                        cell.addSelection(new_id);
                        this.colorizeCell(cell,new_color);   
                    }
                });
            }
        }

        if (this.props.hideCellsParams !== undefined && this.props.hideCellsParams !== -1 && this.props.hideCellsParams !== this.state.hidecellsParams){
            var filter = this.props.hideCellsParams.split('|')[0];
            //console.log(this.props.hideCellsParams);
            //console.log("hide");
            var params = filter.split('/');
            
            this.setState({hidecellsParams:this.props.hideCellsParams});
            this.hideCells(true,params[0],params[1],params[2],params[3],params[4]);
        }

        if (this.props.ShowCellsList !== undefined && this.props.ShowCellsList !== -1 && this.props.ShowCellsList !== this.state.ShowCellsList){
            var filter = this.props.ShowCellsList.split('|')[0];
            //console.log("show");
            var params = filter.split('/');
            
            this.setState({ShowCellsList:this.props.ShowCellsList});
            this.hideCells(false,params[0],params[1],params[2],params[3],params[4]);
        }

        if (this.props.deployInfoID !== undefined && this.props.deployInfoID !== -1 && this.props.deployInfoID !== this.state.deploy_info_id){
            ////console.log("deploy ! both changed");
               var params = this.props.deployInfoID.split('/');
            //   ////console.log("received lineage update info id : "+this.props.deployInfoID);
               this.setState({deploy_info_id:this.props.deployInfoID});
               this.state.last_info_key = this.state.info_list.filter(info => {return info.id === params[0];})[0];
               this.handleInfoChange(-1);
               //this.hideCells(params[0],params[1]);
           }

        if (this.props.resetselection_listcells !== undefined && this.props.resetselection_listcells !== -1 && this.props.resetselection_listcells !== this.state.resetselection_listcells){
         ////console.log("reset selection");
            //this.setState({resetSelectionLineage:this.props.resetSelectionLineage});
            this.setState({resetselection_listcells:this.props.resetselection_listcells});
            var params = this.props.resetselection_listcells.split('/');
            ////console.log(params[1]);
            if (params[1] !== this.state.reset_selection_cell_list && params[1] !== "" && params[1] !== null){
                this.setState({reset_selection_cell_list:params[1]});
                this.resetSelectionOnSelectedCells(params[1]);
            }
        }

        if (this.props.uncolorizeID !== undefined && this.props.uncolorizeID !== -1 && this.props.uncolorizeID !== this.state.uncolorizeID ){
        ////console.log("uncolorize id");
            //this.setState({uncolorizeT:this.props.uncolorizeT});
            this.setState({uncolorizeID:this.props.uncolorizeID});
            var params = this.props.uncolorizeID.split('/');
            this.uncolorize(params[0],params[1]);
           this.redraw();
            //this.partial_redraw();
        }

        if (this.props.uncolorize_listcells !== undefined && this.props.uncolorize_listcells !== -1 && this.props.uncolorize_listcells !== this.state.uncolorize_listcells){
        ////console.log("uncolorize cell list");
             //this.setState({resetSelectionLineage:this.props.resetSelectionLineage});
             this.setState({uncolorize_listcells:this.props.uncolorize_listcells});
             var params = this.props.uncolorize_listcells.split('/');
             this.UncolorizeMultiples(params[1]);
         }

         if (this.props.colorize_listcells !== undefined && this.props.colorize_listcells !== -1 && this.props.colorize_listcells !== this.state.colorize_listcells){
            ////console.log("colorize cell list");
           // ////console.log(this.props.colorize_listcells);
            //this.setState({resetSelectionLineage:this.props.resetSelectionLineage});
             this.setState({colorize_listcells:this.props.colorize_listcells});
             var params = this.props.colorize_listcells.split('/');
             this.ColorizeMultiples(params[1],params[2],params[3],params[4]);
         }


        if (this.props.ApplySelectListCells !== undefined && this.props.ApplySelectListCells !== -1 && this.props.ApplySelectListCells !== this.state.ApplySelectListCells){
         ////console.log("aply select list");
            //////console.log("aply select list");
            //////console.log(this.props.ApplySelectListCells);
            //var RGB = this.props.ApplySelectRGB;
            //var ListCells = this.props.ApplySelectListCells;
            this.setState({ApplySelectRGB:this.props.ApplySelectRGB});
            this.setState({ApplySelectListCells:this.props.ApplySelectListCells});
            //var params = this.props.ApplySelectListCells.split('/');
           /* var colors = RGB.split(':');
            var r = colors[0];
            var g = colors[1];
            var b = colors[2];*/

            if (this.props.ApplySelectListCells.startsWith(">COMPLETESTART")){
                if (this.props.ApplySelectListCells  !== null && this.props.ApplySelectListCells !== ""){
                    var info_text = this.props.ApplySelectListCells.replace(">COMPLETESTART","")
                    var selects = info_text.split('!'); 
                    for (var select in selects){
                        if (selects[select]!== null && selects[select]!==""){
                            var params = selects[select].split('/'); 
                        this.applySelection(params[0],params[1],params[2],params[3],params[4]);
                        }
                    }
                   // this.partial_redraw();
                    this.redraw();
                    this.state.temp_info_colors = "";
                }
            }

            if (this.props.ApplySelectListCells === ">START"){
                this.state.temp_info_colors = "";
            }
            else if (this.props.ApplySelectListCells === "END<"){
                if (this.state.temp_info_colors !== null && this.state.temp_info_colors !== undefined && this.state.temp_info_colors !== ""){
                    var selects = this.state.temp_info_colors.split('!'); 
                    for (var select in selects){
                        if (selects[select]!== null && selects[select]!==""){
                        var params = selects[select].split('/'); 
                        this.applySelection(params[0],params[1],params[2],params[3],params[4]);
                        }
                    }
                    //this.partial_redraw();
                    this.redraw();
                    this.state.temp_info_colors = "";
                }
            }
            else {
               // ////console.log(this.props.lastInfoReceived);
                this.state.temp_info_colors += this.props.ApplySelectListCells;
            }

            //this.applySelection(params[0],params[1],params[2],params[3],params[4]);
            //this.redraw();
        }

        if (this.props.ApplySelectListCellsMap !== undefined && this.props.ApplySelectListCellsMap !== -1 && this.props.ApplySelectListCellsMap !== this.state.ApplySelectListCellsMap){
            const RGB = this.props.ApplySelectRGB;
            const ListCells = this.props.ApplySelectListCellsMap;
            this.setState({ApplySelectRGB:this.props.ApplySelectRGB});
               this.setState({ApplySelectListCellsMap:this.props.ApplySelectListCellsMap});
               ////console.log("ApplySelectListCellsMap");
              /* var colors = RGB.split(':');
               var r = colors[0];
               var g = colors[1];
               var b = colors[2];*/

               if (this.props.ApplySelectListCellsMap.startsWith(">COMPLETESTART")){
                if (this.props.ApplySelectListCellsMap !== null && this.props.ApplySelectListCellsMap !== undefined && this.props.ApplySelectListCellsMap !== ""){
                    var info_text = this.props.ApplySelectListCellsMap.replace(">COMPLETESTART","")
                  //  //console.log("select map short :"+this.props.ApplySelectListCellsMap);
                    var selects = info_text.split('!'); 
                    for (var select in selects){
                        if (selects[select]!== null && selects[select]!==""){
                            var params = selects[select].split('/'); 
                        this.applySelection(params[0],params[1],params[2],params[3],params[4]);
                        }
                    }
                   // this.partial_redraw();
                    this.redraw();
                    this.state.temp_info_cmap = "";
                }
            }

               if (this.props.ApplySelectListCellsMap === ">START"){
                this.state.temp_info_cmap = "";
            }
            else if (this.props.ApplySelectListCellsMap === "END<"){
                if (this.state.temp_info_cmap !== null && this.state.temp_info_cmap !== undefined && this.state.temp_info_cmap !== ""){
                   // //console.log("select map long :"+this.state.temp_info_cmap);
                    var selects = this.state.temp_info_cmap.split('!'); 
                    for (var select in selects){
                        if (selects[select]!== null && selects[select]!==""){
                        var params = selects[select].split('/'); 
                        //////console.log(params);
                        this.applySelection(params[0],params[1],params[2],params[3],params[4]);
                        }
                    }
                    //this.partial_redraw();
                    this.redraw();
                    this.state.temp_info_cmap = "";
                }
            }
            else {
               // ////console.log(this.props.lastInfoReceived);
                this.state.temp_info_cmap += this.props.ApplySelectListCellsMap;
            }

           }

        if (this.props.lastInfoReceived !== undefined && this.props.lastInfoReceived !== -1 && this.props.lastInfoReceived !== this.state.last_info_received){
            this.setState({last_info_received:this.props.lastInfoReceived});
           ////console.log("Info received : "+this.props.lastInfoReceived);
            //parse info here to json

            if (this.props.lastInfoReceived === ">START"){
                this.state.temp_info = "";
            }
            else if (this.props.lastInfoReceived === "END<"){
                if (this.state.temp_info !== null && this.state.temp_info !== undefined && this.state.temp_info !== "" && this.state.temp_info.startsWith('{') && this.state.temp_info.endsWith('}')){
                    const info_json = JSON.parse(this.state.temp_info);
                    this.state.info_list.push(info_json);
                this.state.temp_info = "";
                }
            }
            else {
               // ////console.log(this.props.lastInfoReceived);
                this.state.temp_info += this.props.lastInfoReceived;
            }
          
          //  //////console.log(this.state.info_list);
          //  ////console.log("fin info recue");
            //add it to "this.state.info_list"
        }

        if (this.props.idLineage !== this.state.idLineage && this.props.idLineage !== undefined){
            this.setState({idLineage:this.props.idLineage});
            ////console.log("id_lineage");
        }

        if (this.props.multipleSelection !== undefined && this.props.multipleSelection !== "" && this.state.multipleSelection !== this.props.multipleSelection){
            // stocker une liste string -> string pour cell -> color 
            ////console.log("multiple selection");
            this.setState({multipleSelection:this.props.multipleSelection});
            var params = this.props.multipleSelection.split('/')[1];
            if (params !== undefined){
                const colorize_list = params.split(';');
                if (colorize_list !== undefined)
                {
                    for (let colorize_item in colorize_list){
                        if (colorize_item !== "" && colorize_item !== undefined){
                            var color = colorize_item.split('-')[1];
                            const cell_list = colorize_item.split('-')[0];
                            if (cell_list !== undefined)
                            {
                                const cells = cell_list.split(':');
                                if (cells !== undefined)
                                {
                                    for (var cell in cells){
                                        if(cell !== "" && cell !== undefined && color !== undefined){
                                            const celltid = cell.split(',');
                                            const color_params = color.split(',');
                                            if (celltid !== undefined && color_params !== undefined)
                                                var c=this.getCell(celltid[0],celltid[1]);
                                                this.colorizeCell(c,this.getColor(color_params[0],color_params[1],color_params[2]));
                                                
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.redraw();
                }
            }
            //format "boolean inutile" / "cellt,cellid:cellt,cellid-colorR,colorG,colorB;cellt,cellid-colorR,colorG,colorB"
        }

        if ( this.props.colorizeRGB !== undefined &&  this.props.colorizeRGB !== -1 && this.props.colorizeRGB !== this.state.colorizeRGB){
            this.setState({colorizeRGB:this.props.colorizeRGB});
        ////console.log("colorize : "+this.props.colorizeRGB);
            //this.setState({colorizeRGB:this.props.colorizeRGB});
            var params = this.props.colorizeRGB.split('/');
            this.colorize(params[0],params[1],params[2],params[3],params[4]);
            //this.partial_redraw();
            this.redraw();
        }
        if (this.props.hideAllTimesCellsListCells !== undefined &&  this.props.hideAllTimesCellsListCells !== -1 && this.props.hideAllTimesCellsListCells !== this.state.hideAllTimesCellsListCells){
        ////console.log("hide cell all times : all changed");
            //this.setState({hideAllTimesCellsV:this.props.hideAllTimesCellsV});
            this.setState({hideAllTimesCellsListCells:this.props.hideAllTimesCellsListCells});
            var params = this.props.hideAllTimesCellsListCells.split('/');
            this.hideCellsAllTime(params[0],params[1]);
        }

        if (this.props.hideLineageID !== undefined && this.props.hideLineageID !== -1 && this.props.hideLineageID !== this.state.hideLineageID)
        {
           ////console.log("hide lineage");
            this.setState({hideLineageID:this.props.hideLineageID});
            //this.setState({hideLineageT:this.props.hideLineageT});
            var params=this.props.hideLineageID.split('/');
            this.hide(params[0],params[1]);
        }

        if (this.props.showLineageID !== undefined && this.props.showLineageID !== -1 && this.props.showLineageID !== this.state.showLineageID)
        {
           ////console.log("show lineage");
            this.setState({showLineageID:this.props.showLineageID});
            // this.setState({showLineageT:this.props.showLineageT});
            var params = this.props.showLineageID.split('/');
            this.show(params[0],params[1]);
        }

        if (this.state.highlight_division !== prevState.highlight_division){
            ////console.log("highlightdivision");
            Object.values(this.state.cells).filter(c => {return c.daughters.length>1;}).forEach(function(c){
                if (this.state.highlight_division){this.colorizeCell(c,"white");}else {this.resetColor(c);}
            }.bind(this));
            this.redraw();
        }

        if (this.state.highlight_birth !== prevState.highlight_birth){
            ////console.log("highlightbirth");
            Object.values(this.state.cells).filter(c => {return c.asMother===0;}).forEach(function(c){
               if (this.state.highlight_birth){this.colorizeCell(c,"white");}else {this.resetColor(c);}
            }.bind(this));
            this.redraw();
        }

        if (this.props.clearLineage !== this.state.clearLineage){
            ////console.log("clear Lineage");
            this.setState({clearLineage:this.props.clearLineage});
            this.clearAll();
        }

        if (this.state.highlight_death !== prevState.highlight_death){
            ////console.log("Hightlight death");
            Object.values(this.state.cells).filter(c => {return c.daughters.length === 0;}).forEach(function(c){
                if (this.state.highlight_death){this.colorizeCell(c,"white");}else {this.resetColor(c);}
            }.bind(this));
            this.redraw();
        }

        if (this.state.graph_form !== prevState.graph_form){
            ////console.log("graph");
            this.redraw();
        }

        if (this.state.hide_cells !== prevState.hide_cells){
            ////console.log("graph hide cells");
            this.redraw();
        }
        
        if (this.state.hide_lines !== prevState.hide_lines){
            ////console.log("graphhide lines");
            this.redraw();
        }

        if (this.state.id_set !== -1 && prevState.id_set !== this.state.id_set && this.props.id_dataset !== undefined){           
         ////console.log("Received id set (update) : "+this.props.id_dataset);
            this.setState({id_set:this.props.id_dataset});
        }

        if (this.props.morphoPlotLineage !== this.state.lineage && this.props.morphoPlotLineage !== undefined){
            ////console.log("morphoPlot LINEAGE");
            this.setState({lineage:this.props.morphoPlotLineage});
               //parse info here to json
   
               if (this.props.morphoPlotLineage === ">START"){
                   this.state.temp_info_lineage = "";
               }
               else if (this.props.morphoPlotLineage === "END<"){
                   if (this.state.temp_info_lineage !== null && this.state.temp_info_lineage !== undefined && this.state.temp_info_lineage !== ""){
                    this.setState({curration_got:false});
                    this.setState({lineage_got:false});
                           
                          if (this.state.lineage_got !== true){
                             // ////console.log("first time");
                              var lineage = this.state.temp_info_lineage;
                            //  ////console.log("Lineage : ");
                           //  ////console.log(lineage);
                          this.setState({lineage:lineage});
                          var lineage_lines = lineage.split('\\n');
                          for (var line in lineage_lines){
                              if (lineage_lines[line].length > 0 && lineage_lines[line][0] !== "#"){
                                  var tab = lineage_lines[line].split(':');
                                  if (tab.length === 2 && tab[0]!== "type"){
                                      var c1 = this.addCell(tab[0]);
                                      var c2 = this.addCell(tab[1]);
                                      if (c1 !== undefined && c2 !== undefined){
                                          c1.addDaughter(c2);
                                          c2.addMother(c1);
                                      }
                                  if (this.state.nb_cellMaxTime < 10) {
                                          this.setState({nb_cellMaxTime:10});
                                  } 
                                  }
                              }
                              ////////console.log(lineage_lines[line]);
                          }
                       //  ////console.log(this.state.cells);
                      this.setState({curration_got:true});
                          this.setState({lineage_got:true});
                      }
                      else {      
                          var lineage = this.state.temp_info_lineage;
                        this.setState({lineage:lineage});
                        var lineage_lines = lineage.split('\\n');
                        for (var line in lineage_lines){
                            if (lineage_lines[line].length > 0 && lineage_lines[line][0] !== "#"){
                                var tab = lineage_lines[line].split(':');
                                if (tab.length === 2 && tab[0]!== "type"){
                                    var c1 = this.addCell(tab[0]);
                                    var c2 = this.addCell(tab[1]);
                                    if (c1 !== undefined && c2 !== undefined){
                                        c1.addDaughter(c2);
                                        c2.addMother(c1);
                                    }
                                if (this.state.nb_cellMaxTime < 10) {
                                        this.setState({nb_cellMaxTime:10});
                                } 
                                }
                            }
                            ////////console.log(lineage_lines[line]);
                        }
          
                        this.setState({curration_got:true});
                        this.setState({lineage_got:true});
                  }
                  this.state.temp_info_lineage = "";
                   }
               }
               else {
                  // ////console.log(this.props.lastInfoReceived);
                   this.state.temp_info_lineage += this.props.morphoPlotLineage;
               }
          //  ////console.log("receive lineage");
          
        }

        if (this.props.resetLineage !== this.state.resetLineage && this.props.resetLineage !== null){
            ////console.log("resetLineage");
            this.setState({resetLineage:this.props.resetLineage});
            this.setState({curration_got:false});
            this.setState({lineage_got:false});
            this.setState({cells_treated:false});
            this.setState({backup_id_set:-1});
        
            //this.componentDidMount();
/*            this.view = null;
            this.scene = null;
            this.camera = null;
            this.renderer = null;
            this.raycaster = null;
            this.fov = 40;
            this.spaceTop = 100;
            this.height = window.innerHeight;
            this.width = window.innerWidth;
            this.nextXCell = 0;
            this.maxY = -10000;
            this.minY = 10000000;
            this.state.colors = [];
            this.state.cells = [];

            this.maxX = -10000;
            this.minX = 10000000;
            this.StartX = 100;
            this.StartY = -100;
            this.points = null;
            this.activeCell = null;
            this.state.activeCell = null;
            this.lines = null;
            this.near = 200;
            this.far = 17000;
            this.initial_scale = this.getScaleFromZ(this.far);
            this.current_scale = this.initial_scale;
            this.circle_sprite= new THREE.TextureLoader().load(  "https://fastforwardlabs.github.io/visualization_assets/circle-sprite.png" );
            this.cellsMaterial = new THREE.PointsMaterial({
              size: 8,
              sizeAttenuation: false,
              vertexColors: THREE.VertexColors,
              map: this.circle_sprite,
              transparent: true
            });
            this.handleZoom = this.handleZoom.bind(this);
      
            this.bigcellsMaterial = new THREE.PointsMaterial({
            size: 26,
            sizeAttenuation: false,
            vertexColors: THREE.VertexColors,
            map: this.circle_sprite,
            transparent: true
              });
              
              this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x606060 });
              this.cellGeometry = null;
              this.zoom = null;
              this.view = null;

              this.scene = new THREE.Scene();
                this.camera = new THREE.PerspectiveCamera( this.fov, this.width/this.height, this.near, this.far );
                this.renderer = new THREE.WebGLRenderer({alpha:true,antialias:true});
                // this.height = this.myRef.current.clientHeight;
                // this.width = this.myRef.current.clientWidth;
                this.renderer.setSize( this.width,this.height );
                while (this.myRef.current.firstChild) {
                    this.myRef.current.removeChild(this.myRef.current.lastChild);
                  }
                this.myRef.current.appendChild(this.renderer.domElement);
                    // document.body.appendChild( renderer.domElement );
                this.renderer.render(this.scene,this.camera);
            ////console.log("Reset Lineage :"+this.props.resetLineage);

            axios.get('/api/correspondence/',{ params : {id_people: this.state.id_user !== -1?this.state.id_user:0,id_dataset:this.state.id_set,hash:"34morpho:"}})
            .then(res => {
              this.setState({info_list:res.data});
            //  //////console.log(this.state.info_list);
          });

          
//this.resetComponent();

          for (var t = this.state.min_time; t <= this.state.max_time; t++){
              this.state.parsed_cells[t] = [];
          }
            axios.get('/api/lineagecurrations/',{ params : {id_dataset:this.state.id_set}})
            .then(res => {
                var curr = res.data;
                for (var curration in curr){
                    if (curr[curration].active === true){
                        var c1 = this.addCell(curr[curration].id_object);
                        var c2 = this.addCell(curr[curration].value);
                        if (c1 !== undefined && c2 !== undefined){
                            c1.addDaughter(c2);
                        }
                       if (this.state.nb_cellMaxTime < 10) {
                            this.setState({nb_cellMaxTime:10});
                       }
                    }
                }
                this.setState({curration_list:res.data});
                this.setState({curration_got:true});
            });

          axios.get('/api/lineagefield/',{ params : {id_dataset:this.state.id_set}})
            .then(res => {
                if (res.data.status === "failed"){
                    //////console.log("Error during lineage fetch");
                }
                else {
                    ////////console.log("Got lineage : ");
                    var lineage = res.data;
                  //  //////console.log("Lineage : ");
                   // //////console.log(lineage);
                    this.setState({lineage:lineage});
                    var lineage_lines = lineage.split('\n');
                    for (var line in lineage_lines){
                        if (lineage_lines[line].length > 0 && lineage_lines[line][0] !== "#"){
                            var tab = lineage_lines[line].split(':');
                            if (tab.length === 2 && tab[0]!== "type"){
                                ////////console.log("2 new cells");
                                var c1 = this.addCell(tab[0]);
                                var c2 = this.addCell(tab[1]);
                                if (c1 !== undefined && c2 !== undefined){
                                    c1.addDaughter(c2);
                                }
                               if (this.state.nb_cellMaxTime < 10) {
                                    this.setState({nb_cellMaxTime:10});
                               } 
                            }
                        }
                    }

                    this.setState({lineage_got:true});
                }
            });
                        ////////console.log(lineage_lines[line]);
                     

*/

        }

        if (this.state.id_set !== this.state.backup_id_set && this.state.time_catch && (this.state.user_token !== undefined || this.state.id_user === 0 || this.state.id_user === "0" || this.state.id_user === undefined) &&  ((this.state.id_set !== 0 && this.state.idLineage !== -1) || this.state.id_set === 0)){    
            ////console.log("time catch");
            this.state.backup_id_set = this.state.id_set;  
            if (this.state.id_set !== 0 && this.state.id_set !== "0")
            {
                if (this.state.id_user === 0 || this.state.id_user === null || this.state.id_user === undefined){
                    axios.get('/api/publiccorrespondence/',{ params : {id_people: this.state.id_user !== -1?this.state.id_user:0,id_dataset:this.state.id_set,hash:"34morpho:"}})
                    .then(res => {
                        this.setState({info_list:res.data});
                      //  //////console.log(this.state.info_list);
                    })
                }
                else {
                    axios.get('/api/correspondence/',{ params : {id_people: this.state.id_user !== -1?this.state.id_user:0,id_dataset:this.state.id_set,hash:"34morpho:"},headers: { Authorization: `Token ${this.state.user_token}` }})
                    .then(res => {
                        this.setState({info_list:res.data});
                      //  //////console.log(this.state.info_list);
                    });
                }
                


          for (var t = this.state.min_time; t <= this.state.max_time; t++){
              this.state.parsed_cells[t] = [];
          }
            axios.get('/api/lineagecurrations/',{ params : {id_dataset:this.state.id_set,id_infos:this.state.idLineage}})
            .then(res => {
                const curr = res.data;
                for (let curration in curr){
                    if (curr[curration].active === true){
                        const c1 = this.addCell(curr[curration].id_object);
                        const c2 = this.addCell(curr[curration].value);
                        if (c1 !== undefined && c2 !== undefined){
                            c1.addDaughter(c2);
                            c2.addMother(c1);
                        }
                       if (this.state.nb_cellMaxTime < 10) {
                            this.setState({nb_cellMaxTime:10});
                       }
                    }
                }
                this.setState({curration_list:res.data});
                this.setState({curration_got:true});
            });

          axios.get('/api/lineagefield/',{ params : {id_dataset:this.state.id_set,id_infos:this.state.idLineage}})
            .then(res => {
                if (res.data.status === "failed"){
                    //////console.log("Error during lineage fetch");
                }
                else {
                    ////////console.log("Got lineage : ");
                    const lineage = res.data;
                    //  //////console.log("Lineage : ");
                   // //////console.log(lineage);
                    this.setState({lineage:lineage});
                    const lineage_lines = lineage.split('\n');
                    for (let line in lineage_lines){
                        if (lineage_lines[line].length > 0 && lineage_lines[line][0] !== "#"){
                            const tab = lineage_lines[line].split(':');
                            if (tab.length === 2 && tab[0]!== "type"){
                                ////////console.log("2 new cells");
                                const c1 = this.addCell(tab[0]);
                                const c2 = this.addCell(tab[1]);
                                if (c1 !== undefined && c2 !== undefined){
                                    c1.addDaughter(c2);
                                    c2.addMother(c1);
                                }
                               if (this.state.nb_cellMaxTime < 10) {
                                    this.setState({nb_cellMaxTime:10});
                               } 
                            }
                        }
                        ////////console.log(lineage_lines[line]);
                     }
                    // //////console.log(this.state.parsed_cells);
                   //  //////console.log("Fin lineage");
                     this.setState({lineage_got:true});
                }       
          })
        }
        else {
            for (var t = this.state.min_time; t <= this.state.max_time; t++){
                this.state.parsed_cells[t] = [];
            }
            this.setState({infos_loaded:true});
            //get Lineage from props
        }
        
        }
          if (this.state.lineage_got && this.state.curration_got && !this.state.cells_treated){
              ////console.log("setup scene");
            this.cellGeometry = new THREE.Geometry();
            this.cellGeometry.colors = this.state.colors;

            this.zoom = d3.zoom()
            .scaleExtent([this.getScaleFromZ(this.far),this.getScaleFromZ(this.near)])
            .on('zoom',this.handleZoom);
            this.view = d3.select(this.renderer.domElement);
            this.setUpZoom();
            this.cellsMaterial = new THREE.PointsMaterial({
                size: 8,
                sizeAttenuation: false,
                vertexColors: THREE.VertexColors,
                map: this.circle_sprite,
                transparent: true
              });
              this.bigcellsMaterial = new THREE.PointsMaterial({
              size: 26,
              sizeAttenuation: false,
              vertexColors: THREE.VertexColors,
              map: this.circle_sprite,
              transparent: true
            });
              
        ////////console.log(this.state.cells);
          //EDGES
          this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x606060 });
              const counter = 0;
              for (var t = this.state.min_time; t <= this.state.max_time; t++){
                ////////console.log(t);
                this.state.cells.forEach(cell => this.addCreateBranch(cell,counter));
            }
           // //////console.log("width : "+this.width+ " height : "+this.height);
           this.spaceBetweenDaughter=(((this.width-10))/this.nextXCell)/this.initial_scale; 
           this.spaceBetweenT=((this.state.max_timeheight-10)/(this.state.max_time-this.state.min_time))/this.initial_scale; 
            this.replot();
            //this.setUpZoomUsingXY();
            this.animate();
            this.raycaster = new THREE.Raycaster();
            this.raycaster.params.Points.threshold = 10;
            this.view.on("mousemove", this.mouseMove.bind(this));  
           /* this.myRef.current.appendChild(tooltip_template); 		
			$tooltip = document.querySelector('#tooltip');
			$point_tip = document.querySelector('#point_tip');
            $color_tip = document.querySelector('#color_tip');*/
            this.view.on("click",this.onClickHandle.bind(this)
            );
           /* var event = new Event('zoom');
            this.myRef.current.dispatchEvent(event);*/
            let x = (this.width)/2 / this.current_scale;
            let y = -(this.height)/2 / this.current_scale;
            let z = this.getZFromScale(this.current_scale);
            ////////console.log("scale==="+this.current_scale + " x="+x + " y="+y + " z="+z);
            if(z>=this.far)z=this.far-1;
            this.camera.position.set(x, y, z);
            if (this.state.backup_pos_x === undefined){
                this.setState({backup_pos_x:x});
            }
    
            if (this.state.backup_pos_y === undefined){
                this.setState({backup_pos_y:y});
            }
    
            if (this.state.backup_pos_z === undefined){
                this.setState({backup_pos_z:z});
            }
            //this.handleZoom();
            //see this.zoomhandler() using d3 transform 
            //this.zoomHandler(d3.select(this.renderer.domElement).attr('transform'));
            //this.camera.position.set(this.camera.position.x,this.camera.position.y,this.camera.position.z-1);
            //window.scrollTo(window.scrollX+1,window.scrollY+1);
            this.setState({cells_treated:true});
         /* axios.get('/api/correspondence/',{ params : {id_people: 1,id_dataset:1,hash:"34morpho:"}})
          .then(res => {
            this.setState({info_list:res.data});
        })*/
    }
}

loadColorInfo = (type,id_info,additional_data) => {
    if (this.state.id_set > 0){
        axios.get('/api/infofield/',{ params : {id_info: id_info}})
        .then(res => {
            if (res.data.status === "failed"){
                //////console.log("Error during info field check");
            }
            else {
                axios.get('/api/infocurrations/',{ params : {id_dataset:this.state.id_set,id_infos:id_info}})
                .then(res2 => {
                    const curr = res2.data;
                    const currations_by_obj = {};
                    for (let curration in curr){
                        if (curr[curration].active === true){
                            currations_by_obj[curr[curration].id_object] = curr[curration].value;
                        }
                    }

                                      //this.state.order_by = vname_infos;
                    const field = res.data;
                    let tab = undefined;
                    if (this.state.id_set === 0 || this.state.id_set === "0"){
                    tab=field.split('\\n'); // info split should be on ('\n) for direct plot
                }
                else 
                {
                    tab=field.split('\n'); // info split should be on ('\n) for direct plot
                }
                if (type === "selection"){

                    //var color_by_selection = {};
                    var color_list = additional_data.split(";");
                    for (var list in color_list){
                        var key_pair=color_list[list];
                        var elt = key_pair.split(":")
                        this.state.color_by_selection[elt[0]]=elt[1];
                    }

                    tab.forEach(function(l){
                        if(l.length>0 && l[0]!=='#'){
                            const elt = l.split(':');
                            if(elt[0]!=='type'){
                                const tid = this.parseCell(elt[0]);
                                if(tid.length===2){
                                    const c = this.getCell(tid[0], tid[1]);
                                    if(c!==undefined) {
                                        let value = elt[1];
                                        if (currations_by_obj[tid[0]+","+tid[1]] !== undefined){
                                            value = currations_by_obj[tid[0]+","+tid[1]];
                                        }
                                        c.addSelection(value);
                                        const params = this.state.color_by_selection[value].split(",");
                                        const color = this.getColor(params[0], params[1], params[2]);
                                        if (color !== "" && color !== undefined){
                                            this.colorizeCell(c,color)
                                        }
                                    }
                                }
                            }
                        }
                    }.bind(this));
                }

                if (type === "colormap"){

                    const add_data = additional_data.split("/");
                    const minC = parseInt(add_data[1]);
                    const maxC = parseInt(add_data[2]);
                    const colors = add_data[0];
                    const minV = parseFloat(add_data[3]);
                    const maxV = parseFloat(add_data[4]);
                    const cells = add_data[5];
                    /* console.log("minC : "+minC);
                     console.log("maxC : "+maxC);
                     console.log("colors : "+colors);
                     console.log("minV : "+minV);
                     console.log("maxV : "+maxV);
                     console.log("cells : "+cells);*/

                    const color_by_x = [];
                    var color_list = colors.split(";");
                    for (var list in color_list){
                        var key_pair=color_list[list];
                        var elt = key_pair.split(":")
                        color_by_x[parseInt(elt[0])]=elt[1];
                    }
                   // console.log(color_by_x);
                    const cell_list = [];
                    if (cells !== "all"){
                        var list = cells.split(';');
                        for(let cell in list){
                            cell_list.push(list[cell].replace(':',','));
                        }
                    }

                    tab.forEach(function(l){
                        if(l.length>0 && l[0]!=='#'){
                            const elt = l.split(':');
                            if(elt[0]!=='type'){
                                const tid = this.parseCell(elt[0]);
                                //console.log(tid);
                                if(tid.length===2 && (cells === "all" || (cells !== "all" && cell_list.includes(tid[0]+","+tid[1])))){
                                    const c = this.getCell(tid[0], tid[1]);
                                    if(c!==undefined) {
                                        let value = elt[1];
                                        if (currations_by_obj[tid[0]+","+tid[1]] !== undefined){
                                            value = currations_by_obj[tid[0]+","+tid[1]];
                                        }
                                        //console.log("x : "+x);
                                        //console.log("elt1 : "+elt[1]);
                                        //console.log("element : "  + parseFloat(elt[1]));
                                        //console.log("division : "+ (parseFloat(elt[1]) - minV) / (maxV - minV));
                                        //console.log(" times : "+(maxC - minC) * (parseFloat(elt[1]) - minV) / (maxV - minV));
                                        //console.log("round : "+parseInt(Math.round((maxC - minC) * (parseFloat(elt[1]) - minV) / (maxV - minV))));
                                        let x = parseInt(minC + parseInt(Math.round((maxC - minC) * (parseFloat(value) - minV) / (maxV - minV))));
                                        if (x < 0) x = 0;
                                        if (x >= color_by_x.length){
                                            x = color_by_x.length-1;
                                        }
                                        //console.log("value : "+x+ " > "+color_by_x[x]);
                                        if (color_by_x[x] !== undefined){
                                            const params = color_by_x[x].split(",");
                                            const color = this.getColor(params[0], params[1], params[2]);
                                            if (color !== "" && color !== undefined){
                                            this.colorizeCell(c,color)
                                        }
                                         } 
                                    }
                                }
                            }
                        }
                    }.bind(this));
                }

                this.redraw();

                });
                       

                //this.replot();
            }
        
        });
        }
}

onClickHandle(){
    ////console.log("onClickHandle");
    if (this.state.activeCell !== null && this.state.activeCell !== undefined)
        this.showSelectedCell(this.state.activeCell.t,this.state.activeCell.id);
}

mouseToThree(mouseX, mouseY) {
    ////console.log("mouseToThree");
    return new THREE.Vector3(
      mouseX / this.width * 2 - 1,
      -(mouseY / this.height) * 2 + 1,
      1
    );
  }
  /*
// HIDE OR SHOW A LIST OF CELL
hideCells(v,listcells){
    //console.log("hidecells");
   //console.log(v);
   //console.log(listcells);
    if (v !== -1){
        var tabCells=listcells.split(":");
        for (var i = 0; i < tabCells.length; i++) {
      //      //////console.log("a cell");
            var cell=tabCells[i].split(";");
            if(cell.length===2){
         //       //////console.log("a cell 2 length");
                var c=this.getCell(cell[0],cell[1]);
                if(v===0 || v==="0") this.showCell(c); else this.hideCell(c);
            }
        }
        this.redraw();
    }
}*/

handleZoom(){
    ////console.log("handle ziil");
    const d3_transform = d3.event.transform;
    this.zoomHandler(d3_transform);
    //////console.log("Call to handleZoom took " + (t1 - t0) + " milliseconds.")
}
  showTooltip(mouse_position, c) {
      //////console.log("showtooltip");
   // //////console.log("Tooltip cell : " + c);
  /* if ($tooltip !== null && $tooltip !== undefined){ $tooltip.style.display = "block";
    $tooltip.style.left = (mouse_position[0] + 30) + 'px';
    $tooltip.style.top = ( mouse_position[1] + 30) + 'px';
    $point_tip.innerText = "Cell "+c.name();
    $point_tip.style.background = "#6CD2FA";
    $color_tip.innerText = "Color : #"+ this.getColorHex(c.color);
    $color_tip.style.background =  c.color;
    Object.keys(c.toshow).forEach(function(kshow) { 
           document.querySelector('#'+kshow+'_tip').innerText=c.toshow[kshow];

    });}*/
  }
  
  selectInfo(id,name){
      ////console.log("select info");

      const name_infos = name;
      const vname_infos = name_infos.trim().replace(' ', '_');
      this.state.order_by = vname_infos;
        this.replot();
}

//RESET SELECTION FOR A LIST OF CELLS
resetSelectionOnSelectedCells(listcells){
////console.log("resetSelectionOnSelectedCells");
    const tabCells = listcells.split(":");
    for (let i = 0; i < tabCells.length; i++) {
    const cell = tabCells[i].split(";");
    if(cell.length===2){this.resetColor(this.getCell(cell[0],cell[1]));} /*this.colorizeCell(this.getCell(cell[0],cell[1]),colorDefault);*/
    
}
this.redraw();

}


UncolorizeMultiples(listcells){
    ////console.log("uncolorize multiples");
    ////////console.log("resetSelectionOnSelectedCells");
   // //////console.log("Uncolorize Multiples : "+listcells);
    const tabCells = listcells.split(":");
    for (let i = 0; i < tabCells.length; i++) {
        const cell = tabCells[i].split(";");
        // //////console.log("go to uncolorize : "+ cell[0]+ " -> "+ cell[1]);
        if(cell.length===2){this.uncolorize(cell[0],cell[1]);} /*this.colorizeCell(this.getCell(cell[0],cell[1]),colorDefault);*/
        
    }
   // this.partial_redraw();
    this.redraw();
    }

    ColorizeMultiples(r,g,b,listcells){
        ////console.log("colorize multiples");
        const tabCells = listcells.split(":");
        for (let i = 0; i < tabCells.length; i++) {
            const cell = tabCells[i].split(";");
            if(cell.length===2){
                const c = this.getCell(cell[0], cell[1]);
                if (c !== undefined)
                 {
                     const new_color = this.getColor(r, g, b);
                     c.color = new_color;
                    this.updateCellColor(c.t,c.id,new_color);
                }
            }
            
        }
       // this.partial_redraw();
        this.redraw();
        }
    

//APPLLY A SPECIFIC COLOR TO A LIST OF CELLS
applySelection(id_select,r,g,b,listcells){
if (listcells !== undefined){
    const times = [];
    const tabCells = listcells.toString().split(":");
    const colIUsed = this.getColor(r, g, b);
    for (let i = 0; i < tabCells.length; i++) {
        if (tabCells[i] !== undefined){
            const cell = tabCells[i].split(";");
            if(cell.length===2) {
                const c = this.getCell(cell[0], cell[1]);
                if (c !== undefined){
                    c.addSelection(id_select);
                    this.state.color_by_selection[id_select] = colIUsed;
                    this.colorizeCell(c,colIUsed);
                    if (!times.includes(cell[0])){
                        times.push(cell[0]);
                    }
                }
            }
        }
    }
    //console.log("Added color for : "+times.length+" timepoints for color : "+r+","+g+","+b+" for : "+listcells.length+" cells");
}

}

  hideTooltip() {
  //////console.log("hideTootltip");
  /*  if ($tooltip !== null && $tooltip !== undefined)
        $tooltip.style.display  = "none";*/
    //this.state.activeCell=null;
  }
  
checkIntersects(mouse_position) {
    ////console.log("checkIntersect");
    let mouse_vector = this.mouseToThree(...mouse_position);
    this.raycaster.setFromCamera(mouse_vector, this.camera);
    //////console.log("Call to setFroMCamera took " + (t3 - t2) + " milliseconds.")
    if (this.points !== null && this.points !== undefined){
        let intersects = this.raycaster.intersectObject(this.points,true);
        //////console.log("Call to intersetObject took " + (t3 - t2) + " milliseconds.")
        if (intersects[0]) {
            const inter_list = intersects.sort((a, b) => {
                if (a.distanceToRay < b.distanceToRay) {
                    return -1;
                }
                if (b.distanceToRay < a.distanceToRay) {
                    return 1;
                }
                return 0;
            });
            //////console.log("Call to sort took " + (t3 - t2) + " milliseconds.")
        //this.setState({raw_mouse_pos:[intersects[0].point.x, intersects[0].point.y]});
        let intersect=inter_list[0];
        ////////console.log(intersect);
        let index = intersect.index;
            //when an object is selected : 
            Object.values(this.state.cells).filter(c => {return (c.color === "white" || c.color === "rgb(255,255,255)") && c !== Object.values(this.state.cells)[index];}).forEach(function(c){
                    this.resetColor(c);
            }.bind(this));
            //////console.log(Object.values(this.state.cells).filter(c => {return (c.color === "white" || c.color === "rgb(255,255,255)");}).length + " for each call");

            //////console.log("Call to foreach took " + (t3 - t2) + " milliseconds.")
            //this.setState({activeCell:Object.values(this.state.cells).filter(c => this.getVector(c) === this.points[index])});
            if (this.state.cells !== undefined && Object.values(this.state.cells)[index] !== undefined){
                this.state.activeCell=Object.values(this.state.cells)[index];
            }
            this.state.mouse_pose =mouse_position;
            //highlightCell(activeCell);
         //   this.showTooltip(mouse_position, Object.values(this.state.cells)[index]);
        
        } else {
        //removeHighlights();
        this.setState({activeCell:null});
        this.setState({mouse_pos:null});
        //this.hideTooltip();
        }
    }
    //////console.log("Call to checkIntersects took " + (t1 - t0) + " milliseconds.")
}

  parseCell(cellname){
    ////console.log("parseCell");
      const cellnames = cellname.split(',');
      return [parseInt(cellnames[0],10),parseInt(cellnames[1],10)];
}


           //CLEAR ALL SELECTION
clearAll(){  
    ////console.log("clearAll"); 
    Object.values(this.state.cells).forEach(function(c){
        this.colorizeCell(c,colorDefault);
    }.bind(this));
    this.redraw();
}

			//CLICK ON A CELL IN UNITY
showSelectedCell(t,id){
    ////console.log("Show selected cell");
    if(t!==undefined && id !== undefined){
        this.props.showInUnity(t,id);
    }

    Object.values(this.state.cells).filter(c => {return c !== undefined && c.selected === true;}).forEach(function(c){
        c.selected = false;
    }.bind(this));
    this.getCell(t,id).selected = true;
    this.redraw();
}

//SHOW A CELL
show(t,id){
    ////console.log("show");
    const c = this.getCell(t, id);
    if (c !== undefined && c!==null){this.showCell(c);  
    this.redraw();}
            
}

//HIDE CELLS ALL TIMES POINTS
hideCellsAllTime(v,listcells){
    ////console.log("hideCellsAlltime");
    ////////console.log("hide all times : "+v+ " "+listcells);
    const tabCells = listcells.split(":");
    for (let i = 0; i < tabCells.length; i++) {
        const cell = tabCells[i].split(";");
        if(cell.length===2){
            const c = this.getCell(cell[0], cell[1]);
            if(v==="0" || v===0)  this.showCellPast(c); else this.hideCellPast(c);
            if(v==="0" || v===0)  this.showCellFutur(c); else this.hideCellFutur(c);
           
        }
    }
    this.redraw();
    
}
     
//COLORZIE CELL
colorize(t,id,r,g,b){
    ////console.log("colorize");
    const c = this.getCell(t, id);
    const new_color = this.getColor(r, g, b);
    this.colorizeCell(c,new_color);
    this.updateCellColor(t,id,new_color);
    
}

colorizeNew(t,id,r,g,b){
    ////console.log("colorize new");
    const c = this.getCell(t, id);
    c.color = this.getColor(r,g,b); 
}

//APPLY DEFAULT COLOR CELL
uncolorize (t,id) { 
    ////console.log("uncolorize");
   // //////console.log("uncolorize : "+t+" "+id);
    if (t !== undefined && id !== undefined) {
        const c = this.getCell(t, id);
        // if (this.state.backup_color_by_cell !== undefined){
   //     this.state.backup_color_by_cell[c.t+","+c.id] =  undefined;}
    this.colorizeCell(c,colorDefault);
    this.updateCellColor(t,id,colorDefault);
    }
}

hide(t,id){
    ////console.log("hide");
    const c = this.getCell(t, id);
    if (c !== undefined && c !== null) {
        this.hideCell(c);
        this.redraw();
    }
   
}
  mouseMove(e){
      ////console.log("mouseMOve");
    let mouse_position = undefined;

      if (d3 !== undefined && this.view !== undefined && this.view.node() !== undefined)
        mouse_position = d3.mouse(this.view.node());
    // = [mouseX, mouseY];
    if (mouse_position !== undefined && this !== undefined && this.checkIntersects !== undefined)
        this.checkIntersects(mouse_position);
    //////console.log("Call to mouseMove took " + (t1 - t0) + " milliseconds.")
}

    addCreateBranch(cell,counter){
        ////console.log("addCreateBranch");
        ////////console.log(cell);
        if (cell.asMother === 0 /*/&& cell.id === 6 && cell.t === 1*/){
            counter ++;
//                var Cellbranch = JSON.parse(cell);
            ////////console.log(cell);
            //var c = this.createCells(cell);
            this.createBranch(cell);
        }
       // //////console.log(this.state.cells);
    }
    render(){
        const options = this.state.info_list.filter(d => d !== undefined).map(d => <Option value={d} key={d.infos}>{d.infos}</Option>);
        const loaded_info = this.state.loaded_infos.filter(d => d !== undefined).map(d => <Button style={{backgroundColor:'grey',borderColor:'grey',margin:'1px'}} onClick={function() {if (d !== undefined && d.id !== undefined && d.infos !== undefined)
        {this.selectInfo(d.id,d.infos);}}.bind(this)} type="primary" shape="round" size={"default"}>
        {d.infos}
    </Button>);
        const tooltip_infos = null;
        const background = this.state.backgroundColor;
       /* if (this.state.activeCell !== null){
            tooltip_infos = this.state.activeCell.toshow.map((value,index) => <span>{index} : {value}</span>);
        }*/
        return (
            <div style={{backgroundColor:background}}>
            <div style={{backgroundColor:background,display:'table',margin:'auto',padding:'20px',alignContent:'center'}}>
            <div style={{backgroundColor:background}} ref={this.myRef}>
                {
                    this.state.activeCell !== null &&
                <div id="tooltip" style={{marginLeft:'2%',clipPath:'circle(95%)',position:'absolute',pointerEvents:'none',left:0,top:this.state.mouse_pos !== null ?this.state.mouse_pos[1]-this.state.loaded_infos.length > 0?50:0:'0',width:'200px',fontSize:'13px',textAlign:'center',lineHeight:'1',background:'white',fontFamily:'sans-serif'}}>
                <div style={{marginTop:'3px',marginBottom:'2px'}} id="point_tip"><span style={{textAlign:'center',fontSize:'18px',fontWeight:'bold'}}>Cell : {this.state.activeCell !== null ? this.state.activeCell.name() : "No cells"}</span></div>
                {
                    this.state.activeCell !== null && this.state.activeCell.color !== "gray" && 
                    <div style={{paddingLeft:'2px',marginBottom:'2px',fontSize:'16px',paddingTop:'2px',paddingRight:'2px'}} id="color_tip">Color : {this.state.activeCell.color}
                    <br></br><br></br>
                    <div style={{margin:'auto',width:'15px',height:'15px',background:this.state.activeCell.color !== colorDefault?this.state.activeCell.color:"white"}} id="carre"></div>
                    
                    {
                        this.state.loaded_infos === undefined || this.state.loaded_infos.length === 0 &&
                            <br></br>
                    }
                    </div>
                }
                {
                    this.state.activeCell.toshow !== undefined && this.state.activeCell.toshow !== {} && this.state.loaded_infos !== undefined && this.state.loaded_infos.length > 0 &&
                    <List
                    dataSource={this.state.loaded_infos}
                    renderItem={item => (
                        <List.Item>
                        <span style={{textAlign:'left',overflow:'hidden',marginLeft:'2px',paddingLeft:'2px',marginRight:'2px',fontSize:'16px'}}>{item.infos !== undefined && this.state.activeCell.toshow[item.infos.trim().replace(' ','_')] !== undefined?this.state.activeCell.toshow[item.infos.trim().replace(' ','_')]:""}</span>
                        </List.Item>
                    )}
                    />
                }
                </div>
                }
            <div style={{display:'inline-block',textAlign:'center',marginLeft: '12%'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Reset layout</h2>
                <Button style={{backgroundColor:'grey',borderColor:'grey'}} onClick={() => {this.resetLayout();}} type="primary" shape="round" size={"default"}>
                    Reset layout
                </Button>
            </div>    
            <div style={{display:'inline-block',textAlign:'center',marginLeft: '20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Visualisation</h2>
                <Button style={{backgroundColor:'grey',borderColor:'grey'}} onClick={() => {this.state.graph_form === 0?this.setState({graph_form:1}):this.setState({graph_form:0});this.resetZoom();this.replot();}} type="primary" shape="round" size={"default"}>
                    {this.state.graph_form === 0?'Circular':'Linear'}
                </Button>
            </div>
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px',marginLeft:'20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Width</h2>
                <Button style={{verticalAlign:'center',backgroundColor:'grey',margin:'1px',borderColor:'grey'}} onClick={() => {spaceBetweenDaughter++;this.replot();}} type="primary" shape="round" size={"small"}>
                    +
                </Button>
                <Button style={{verticalAlign:'center',backgroundColor:'grey',margin:'1px',borderColor:'grey'}} onClick={() => {spaceBetweenDaughter--;this.replot();}} type="primary" shape="round" size={"small"}>
                    -
                </Button>
            </div> 
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px',marginLeft:'20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Height</h2>
                <Button style={{verticalAlign:'center',backgroundColor:'grey',margin:'1px',borderColor:'grey'}} onClick={() => {spaceBetweenT++;this.replot();}} type="primary" shape="round" size={"small"}>
                    +
                </Button>
                <Button style={{verticalAlign:'center',backgroundColor:'grey',margin:'1px',borderColor:'grey'}} onClick={() =>  {spaceBetweenT--;this.replot();}} type="primary" shape="round" size={"small"}>
                    -
                </Button>
            </div> 
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px',marginLeft:'20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Cells</h2>
                <Button style={{backgroundColor:'grey',borderColor:'grey'}} onClick={() => {this.setState({hide_cells:!this.state.hide_cells})}} type="primary" shape="round" size={"default"}>
                    {this.state.hide_cells === false?'Hide':'Show'}
                </Button>
            </div> 
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px',marginLeft:'20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Lines</h2>
                <Button style={{backgroundColor:'grey',borderColor:'grey'}} onClick={() => {this.setState({hide_lines:!this.state.hide_lines})}} type="primary" shape="round" size={"default"}>
                    {this.state.hide_lines === false?'Hide':'Show'}
                </Button>
            </div> 
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px',marginLeft:'20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Highlight</h2>
                <Button style={{backgroundColor:'grey',borderColor:'grey',margin:'1px'}} onClick={() => {this.setState({highlight_division:!this.state.highlight_division})}} type="primary" shape="round" size={"default"}>
                    Division
                </Button>
                <Button style={{backgroundColor:'grey',borderColor:'grey',margin:'1px'}} onClick={() => {this.setState({highlight_birth:!this.state.highlight_birth})}} type="primary" shape="round" size={"default"}>
                    Birth
                </Button>
                <Button style={{backgroundColor:'grey',borderColor:'grey',margin:'1px'}} onClick={() => {this.setState({highlight_death:!this.state.highlight_death})}} type="primary" shape="round" size={"default"}>
                    Death
                </Button>
            </div> 
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px',marginLeft:'20px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Order by</h2>
                <Button style={{backgroundColor:'grey',borderColor:'grey',margin:'1px'}} onClick={() => {this.setState({order_by:'default'});}} type="primary" shape="round" size={"default"}>
                    Default
                </Button>
                <Button style={{backgroundColor:'grey',borderColor:'grey',margin:'1px'}} onClick={() => {this.setState({order_by:'color'});}} type="primary" shape="round" size={"default"}>
                    Color
                </Button>
                {loaded_info}
            </div> 
            <div style={{display:'inline-block',textAlign:'center',margin:'2px',padding:'2px'}}>
                <h2 style={{fontSize:'15px',color:'black'}}>Infos</h2>
                <Select
                    showSearch
                    style={{ width: 300 }}
                    getPopupContainer={() => this.myRef.current}
                    placeholder="Select an info"
                    optionFilterProp="children"
                    value={this.state.last_info_key !== undefined && this.state.last_info_key.infos !== undefined ?this.state.last_info_key.infos : ""}
                    onChange={(value) => {this.setState({last_info_key:value})}}
                    filterOption={(input, option) =>
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                     {options}
                </Select>
                <Button style={{marginLeft:'2px'}} onClick={this.handleInfoChange}>Load</Button>
            </div> 
            </div> 
            {
                this.state.raw_mouse_pos !== undefined && false &&
                                <div style={{margin:'auto',position:'absolute',width:'15px',height:'15px',left:this.state.raw_mouse_pos !== null ?this.state.raw_mouse_pos[0]+"px":'0',top:this.state.raw_mouse_pos !== null ?-this.state.raw_mouse_pos[1]+"px":'0',background:"red"}} id="carre"></div>
            }

            </div>
            </div>
        );
    }
}
    
export default Lineage;