// import react, react-markdown-editor-lite, and a markdown parser you like
import * as React from 'react'
import { NavLink } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import {Button,Upload} from 'antd';
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import htmlParser from 'react-markdown/plugins/html-parser';
import 'react-markdown-editor-lite/lib/index.css';
import axios from 'axios';
import {getUserToken} from '../store/utility';
import MorphoMDDisplay from './MarkdownDisplay';
import FileTreeView from './FileTreeView';

const { Dragger } = Upload;

  class MorphoMDEditor extends React.Component {
    constructor(props){
        super(props);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.PostMDChanges = this.PostMDChanges.bind(this);
        this.changePageState = this.changePageState.bind(this);
        this.fileUploaderProps.onChange= this.fileUploaderProps.onChange.bind(this);


    }



    getSubFolderLink = () => {
        if (this.state === undefined || this.state.subfolder_link === undefined || this.state.subfolder_link === ""){
            return this.props.subfolderName
        }
        else {
            return this.state.subfolder_link;
        }
    }

    fileUploaderProps = {
        name: 'file',
        multiple: false,
        action: '/api/saveimg/',
        method: 'post',
        headers: this.state === undefined || this.state.token === undefined || this.state.token === "" ? {} : { Authorization: `Token ${this.state.token}` },
        data : {subfolder:this.getSubFolderLink()},
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                this.setState({update_child_view:!this.state.update_child_view});
              }
        }
      };

    state = {
        edit_mode : "false",
        error_message_lock : false,
        upload_panel: false,
        source_link : "",
        subfolder_link : "",
        next_content : "",
        update_child_view : false,
        content : "",
        update_content_again : true,
        jumped:false,
        token : "",
        status : "false",
        can_access: true,
        treeData: [{ title: '/var/www/MorphoNet/Rework/helpfiles/images', children: [{ title: 'index.js',children: [] },{ title: 'test.js' }] }],
    }

    // Register plugins if required
    // MdEditor.use(YOUR_PLUGINS_HERE);
    
    componentDidUpdate(prevProps,prevState){

        if (this.state.update_content_again !== prevState.update_content_again && this.state.update_content_again === true){
            this.setState({update_content_again:false});
            axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache'}})
            .then(res => {
                const result = res.data;
                if (res.data.includes("!doctype")){
                this.setState({update_content_again:true});
            }
            else {
                this.setState({next_content:result});
                this.setState({content:result})
                window.scrollTo(window.scrollX+1,window.scrollY+1);window.scrollTo(window.scrollX-1,window.scrollY-1);
            }
            }).catch(err => {

            });
        }

        if (prevProps.sourceLink  !==this.props.sourceLink){
            if (this.state.edit_mode === "true" && !this.error_message_lock){
                 axios.get("/api/removelockfile/",{params:{filename:prevProps.sourceLink},headers:{Authorization: `Token ${this.state.token}` }});
            }
            this.setState({error_message_lock:false});
            this.setState({source_link : this.props.sourceLink});
            axios.get("/api/haslockfile/",{params:{filename:this.props.sourceLink},headers:{Authorization: `Token ${this.state.token}` }})
            .then(res => {
            if (res.data === false){
                if (this.state.edit_mode === "true"){
                    axios.get("/api/lockfile/",{params:{filename:this.props.sourceLink},headers:{Authorization: `Token ${this.state.token}` }});
                }
            }
            else {
                this.setState({edit_mode:false});
                this.setState({error_message_lock:true});
            }});
            axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache'}})
            .then(res => {
                const result = res.data;
                if (res.data.includes("!doctype")){
                this.setState({update_content_again:true});
            }
            else {
                this.setState({next_content:result});
                this.setState({content:result})
                window.scrollTo(window.scrollX+1,window.scrollY+1);window.scrollTo(window.scrollX-1,window.scrollY-1);
            }
            }).catch(err => {});
        }

        if (prevState.token  !== getUserToken() && (getUserToken() === undefined || getUserToken() === "")){
            this.state.edit_mode = false;
            axios.get("/api/removelockfile/",{params:{filename:this.state.source_link},headers:{Authorization: `Token ${this.state.token}` }});
        }

        if (prevProps.subfolderName !== this.props.subfolderName){
            this.fileUploaderProps.data.subfolder = this.props.subfolderName;
            this.setState({subfolder_link : this.props.subfolderName});
        }

        if (this.state.edit_mode  !== prevState.edit_mode){
            this.setState({update_child_view : !this.state.update_child_view});
            this.setState({subfolder_link : this.props.subfolderName});
        }
    }
    // Initialize a markdown parser
    mdParser = new MarkdownIt({html: true,
        linkify: true,
        typographer: true,});
    
          
    // Finish!
    handleEditorChange({html, text}) { 
        //console.log("change");
        this.setState({next_content:text});
    }

    performTimeConsumingTask = async() => {
        return new Promise((resolve) =>
          setTimeout(
            () => { resolve('result') },
            500
          )
        );
      }

    async PostMDChanges(){
        axios({
            method: 'post',
            url: '/api/savemd/',
            data: {
                file_link : this.state.source_link,
                content_file : this.state.next_content
            },
            headers :{ Authorization: `Token ${this.state.token}` }
          }).then(async res =>  {
            const data = await this.performTimeConsumingTask();
            if (data  !== null) {
            axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache'}})
                .then(res => {
                    this.setState({content:res.data})
                    this.changePageState();
                }).catch(err =>{});
          }});  
    }

    changePageState = () => {
        if (this.state.edit_mode === "false"){
            axios.get("/api/haslockfile/",{params:{filename:this.state.source_link},headers:{Authorization: `Token ${this.state.token}` }})
            .then(res => {
            if (res.data === false){
                axios.get("/api/lockfile/",{params:{filename:this.state.source_link},headers:{Authorization: `Token ${this.state.token}` }});
                const nextState = this.state.edit_mode === "true" ? "false" : "true";
                this.setState({next_content:this.state.content});
                this.setState({error_message_lock:false});
                this.setState({update_child_view:!this.state.update_child_view});
                this.setState({edit_mode:nextState});
            }
            else {
                this.setState({edit_mode:false});
                this.setState({error_message_lock:true});
            }
        });
        }
        else {
            axios.get("/api/removelockfile/",{params:{filename:this.state.source_link},headers:{Authorization: `Token ${this.state.token}` }});
            var nextState = this.state.edit_mode==="true"?"false":"true";
            this.setState({error_message_lock:false});
            this.setState({update_child_view:!this.state.update_child_view});
            this.setState({edit_mode:nextState});
        }
    }

    jumpInDiv = (div) => {
        if (!this.state.jumped){
            if (document.getElementById(div) !== null)
            {document.getElementById(div).scrollIntoView();
            this.setState({jumped:true});}
        }

    }
    componentWillUnmount() {
        axios.get("/api/removelockfile/",{params:{filename:this.state.source_link},headers:{Authorization: `Token ${this.state.token}` }});
        window.removeEventListener("beforeunload", this.onUnload);
    }

    onUnload = e => {
        axios.get("/api/removelockfile/",{params:{filename:this.state.source_link},headers:{Authorization: `Token ${this.state.token}` }});
        e.returnValue = ''
    }

    componentDidMount(){
        window.addEventListener("beforeunload", this.onUnload);
        this.state.token = getUserToken();
        this.state.can_access = true;
        this.setState({subfolder_link: this.props.subfolderName});
        this.setState({edit_mode:"false"});
        window.addEventListener("disconnect",() => {this.componentDidMount()});
        this.setState({source_link : this.props.sourceLink});
        axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache'}})
        .then(res => {
            const result = res.data;
            if (res.data.includes("!doctype")){
            this.setState({update_content_again:true});
        }
        else {
            this.setState({next_content:result});
            this.setState({content:result})
            window.scrollTo(window.scrollX+1,window.scrollY+1);window.scrollTo(window.scrollX-1,window.scrollY-1);
        }


        }).catch(err => {});


        if (this.state.token !== "" && this.state.token !== null){
            axios.get('/api/helpadmin/',{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    status:res.data.status
                });
            });
        }
    }

    parseHtml = htmlParser({
        isValidNode: node => node.type  !== 'script',
        processingInstructions: [/* ... */]
      })

    render() {
        let div = null;
        if (document.location.href.includes("#")){
            div = document.location.href.split("#")[1]
            this.jumpInDiv(div);
        }
        return (
            <div style={{position:'relative'}}>
                <div style={{margin:'auto',width:'78%',float:this.state.edit_mode==="true"?'right':'unset'}}>
                    {this.state.edit_mode === "true" &&
                                <div><NavLink to="/help_template"><p style={{fontSize:'21px'}}> Click here to access help template</p></NavLink>
                                     <NavLink to="/documentation"><p style={{fontSize:'21px'}}> Click here to access the help format documentation</p></NavLink>
                                        </div>
                                }
                {
                    this.state.edit_mode === "true" &&
                    <div style={{float:'right',display:"inline"}}><Button type="primary" disabled={this.state.status !== "true"} onClick={this.PostMDChanges} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                        Save changes to file
                    </Button>
                    </div>
                }
                {
                    this.state.status === "true" &&
                <Button type="primary" disabled={this.state.status !== "true"} onClick={() => this.changePageState()} style={{float:'right',borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                    {this.state.edit_mode === "true"?"Cancel":"Edit"}
                </Button>
                }
                {
                    false && this.state.error_message_lock && this.state.token !== null &&
                    <span style={{color:'red',fontSize:'17px'}}>This file is locked by another user</span>
                }
                {
                    this.state.edit_mode === "true" &&
                    <div>
                    <Dragger style={{width:'35%'}} {...this.fileUploaderProps}>
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag image to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single upload, only for .png and .jpg format
                        </p>
                    </Dragger>
                    
                    </div>
                }
                <br></br><br></br>
                {
                    this.state.edit_mode === "true"?
                    <div><span style={{fontSize:'17px'}}>Images path in the md file must be like: helpfiles/images/{this.state.subfolder_link}/<i>imagename.png</i> </span><MdEditor
                    style={{marginTop:'2px'}}
                    value={this.state.next_content}
                    config={{
                        view: {
                            menu: true,
                            md: true,
                            html: true,
                            fullScreen: true,
                        },
                        canView : {
                            menu :true,
                            md: true
                        }
                        }}
                    renderHTML={(text) => this.mdParser.render(text)}
                    onChange={this.handleEditorChange}
                    /></div>
                    :
                    <MorphoMDDisplay newContent={this.state.next_content} sourceLink={this.state.source_link}> </MorphoMDDisplay>
                }
                </div>
                    {
                        this.state.edit_mode === "true"?
                        <div style={{marginTop:'7%',float:'left',position:'relative',height:'50%',width:'18%'}}>
                        <span className='sub-titles-text'> Help file explorer</span>
                        <br></br><br></br>
                        <FileTreeView updateView={this.state.update_child_view} sourceFolder={this.state.subfolder_link} style={{width:'100%'}}></FileTreeView>
                        </div>
                        :
                        <div></div>
                    }
                {
                    document.getElementById(div) !== null &&
                    this.jumpInDiv(div)
                }
        </div>
        );
    }
  }
  
  export default MorphoMDEditor;