import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {getUserToken} from '../store/utility'
import * as actions from '../store/actions/auth';
import {
    Form,
    Input,
    Icon,
    Button,
    InputNumber,
    Typography
  } from 'antd';

  const { Title } = Typography;
                             // Must have digits

  class RegistrationForm extends React.Component {
    constructor(props){
      super(props);
  }
    state = {
      confirmDirty: false,
      institutions: [],
      laboratories: [],
      teams: [],
      newInstitutionDisplay: false,
      newLaboratoryDisplay: false,
      newTeamDisplay: false,
      institution_selected: false,
      choosen_institution: "",
      laboratory_selected: false,
      choosen_laboratory: "",
      team_selected: false,
      choosen_team: "",
      quota : -1,
      user_signed_up: false,
      outline_laboratory: false,
      outline_institution: false,
      outline_team: false,
      email : "",
      email_sent_feedback : "",
      token : "",
      staff :false,
    };
  
   

    //when submit user creation, be sure fields are all filled and ok, ask global system for register, then redirect to index
    handleSubmit = e => {
      this.props.sendTracking(-1,"Web,Signup,create");
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (this.state.institution_selected && this.state.laboratory_selected && this.state.team_selected && !err) {
           this.setState({email:values.email});
           this.props.onAuth(values.username,values.email,values.password,values.name,values.surname,values.phone,values.adress,values.function,values.quota,this.state.choosen_institution,this.state.choosen_laboratory,this.state.choosen_team);
           this.setState({user_signed_up:true});
          }

      });
      if (!this.state.laboratory_selected) {
        this.setState({outline_laboratory:true});
      }
      if (!this.state.institution_selected) {
        this.setState({outline_institution:true});
      }
      if (!this.state.team_selected) {
        this.setState({outline_team:true});
      }
    };

    componentDidUpdate(prevProps,prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });
      }
    }
  
    componentDidMount() {
      //timer to refresh display (button click)
      this.setState({token:getUserToken()});
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
      //get institution laboratory and teams for signup 
      axios.get('/api/institution/')
          .then(res => {
              this.setState({
                institutions: res.data
              });
          })

          axios.get('/api/laboratory')
          .then(res => {
              this.setState({
                laboratories: res.data
              });
          })

          axios.get('/api/team')
          .then(res => {
              this.setState({
                teams: res.data
              });
          })
  }

  //stop timer
  componentWillUnmount() {
    clearInterval(this.interval);
  }

    handleConfirmBlur = e => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  
    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    };
  
    
    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
        const re = /(?=.*\d)(?=.*[a-z]).{6,}/;
        if (value &&  this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }    
      else if (!re.test(value)){
        callback('Enter at least 6 characters, with at least one digit');
      } 
      callback();
    };
    

    //whenchange institution, store value and specify it has been changed to validate signup
    handleInstitutionChange = (e) => {
      this.setState({
        institution_selected:true,
        choosen_institution: e.target.value
      });
    }
    
    //whenchange laboratory, store value and specify it has been changed to validate signup
    handleLaboratoryChange = (e) => {
      this.setState({
        laboratory_selected:true,
        choosen_laboratory: e.target.value
      });
    }

    //whenchange team, store value and specify it has been changed to validate signup
    handleTeamChange = (e) => {
      this.setState({
        team_selected:true,
        choosen_team: e.target.value
      });
    }
    
    //show panel to create instit
    displayInstitutionNamePanel = () => {
      this.setState({newInstitutionDisplay: !this.state.newInstitutionDisplay});
    }

    //show panel to create laboratory
    displayLaboratoryNamePanel = () => {
      this.setState({newLaboratoryDisplay: !this.state.newLaboratoryDisplay});
    }

    //show panel to create team
    displayTeamNamePanel = () => {
      this.setState({newTeamDisplay: !this.state.newTeamDisplay});
    }

    //ask django to create the institution when click on upload button
    uploadInstitution = () => {
      const Instname = document.getElementById('custom_institution_name').value;
      axios({
        method: 'post',
        url: '/api/institution/',
        data: {
          name : Instname,
        },
        headers :{ Authorization: `Token ${this.state.token}` }
      });
      //then refresh list
      this.setState({newInstitutionDisplay:false});
      axios.get('/api/institution',{ headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
              this.setState({
                institutions: res.data
              });
          })
    }

    //ask django to create the laboratory when click on upload button
    uploadLaboratory = () => {
      const Labname = document.getElementById('custom_laboratory_name').value;
      axios({
        method: 'post',
        url: '/api/institution/',
        data: {
          name : Labname,
        },
        headers :{ Authorization: `Token ${this.state.token}` }
      });
      this.setState({newLaboratoryDisplay:false});
      //then refrehs list
      axios.get('/api/laboratory',{ headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
              this.setState({
                laboratories: res.data
              });
          })
    }

    //ask django to create the team when click on upload button
    uploadTeam = () => {
      const Teamname = document.getElementById('custom_team_name').value;
      axios({
        method: 'post',
        url: '/api/team/',
        data: {
          name : Teamname,
        },
        headers :{ Authorization: `Token ${this.state.token}` }
      });
      //then refresh list
      this.setState({newTeamDisplay:false});
      axios.get('/api/team',{ headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
              this.setState({
                teams: res.data
              });
          })
    }

    
  changeQuota = (value) => {
    this.setState({quota:value})
  }

  sendResetPwMail = () => {
    axios.get('/api/forgotpassword',{params:{'email':this.state.email}}).then(this.setState({email_sent_feedback: "Email has been sent"}));
  }

    render() {
      const { getFieldDecorator } = this.props.form;

  
      return (
        <div>
          {
          this.props.error !== null && this.props.error !== undefined && this.props.error.response.statusText.toString().includes('email') ?
          <div style={{marginTop:'7%'}}><span style={{marginTop:'7%',color:'red'}}> {this.props.error.response.statusText}</span><br></br>
          <br></br>
          <Button hidden={this.state.email_sent_feedback !== ""}onClick={this.sendResetPwMail}>Reset password</Button>
          <br></br>
          {this.state.email_sent_feedback !== undefined?<span>{this.state.email_sent_feedback}</span>:<span></span>}
          </div>
          :
          this.props.error !== null  && this.props.error !== undefined && this.props.error.response.statusText.toString().includes('Login') ?
          <div  style={{marginTop:'7%'}} ><span style={{color:'red'}}> {this.props.error.response.statusText}</span></div>
          :
          this.state.user_signed_up?
          <div style={{marginTop:'7%'}} ><span>A confirmation email has been sent to the adress you specified !</span>
          </div>
          :
          <div >
          <Title style={{marginLeft:'43%'}}> User creation </Title>
        <Form style={{marginTop:'7%',marginLeft:'31%'}} onSubmit={this.handleSubmit}>
        <Form.Item>
          Login :
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Login"
            />,
          )}
        </Form.Item>
          <Form.Item>
            E-mail : 
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
            })(<Input autoComplete="new-password" style={{width:'300px',marginLeft: '10px'}} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)'}} />}
            placeholder="Email"/>)}
          </Form.Item>
          <Form.Item hasFeedback>
            Password : 
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password style={{width:'200px',marginLeft: '10px'}} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"/>)}
          </Form.Item>
          <Form.Item hasFeedback>
            Confirm password : 
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password style={{width:'200px',marginLeft: '10px'}} onBlur={this.handleConfirmBlur}  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password" />)}
          </Form.Item>

          <Form.Item>
          Name :
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input your name!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Name"
            />,
          )}
        </Form.Item>

        <Form.Item>
          Surname :
          {getFieldDecorator('surname', {
            rules: [{ required: true, message: 'Please input your surname!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Surname"
            />,
          )}
        </Form.Item>

          <Form.Item>
          Phone number : 
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your phone!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Phone number"
            />,
          )}
        </Form.Item>

        <Form.Item>
          Adress :
          {getFieldDecorator('adress', {
            rules: [{ required: true, message: 'Please input your adress!' }],
          })(
            <Input
              style={{width:'500px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Adress"
            />,
          )}
        </Form.Item>

          <Form.Item>
            Institution :  
            <Input 
            style={this.state.outline_institution && !this.state.institution_selected?{ width: 200,outline:'solid',outlineWidth:1,outlineColor:'red' }:{ width: 200}} 
            onChange={this.handleInstitutionChange}>
            </Input>

          </Form.Item>

          <Form.Item>
          Laboratory :  
            <Input style={ this.state.outline_laboratory && !this.state.laboratory_selected?{ width: 200,outline:'solid',outlineWidth:1,outlineColor:'red' }:{width: 200}} 
            onChange={this.handleLaboratoryChange}>
          </Input>
          </Form.Item>

          <Form.Item>
            Team : 
            <Input style={this.state.outline_team && !this.state.team_selected?{ width: 200,outline:'solid',outlineWidth:1,outlineColor:'red' }:{width: 200}}
             onChange={this.handleTeamChange}>
            </Input>
          </Form.Item>
          
          <Form.Item>
          Function :
          {getFieldDecorator('function', {
            rules: [{ required: true, message: 'Please input your function!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Function"
            />,
          )}
        </Form.Item>

        <Form.Item>
          Quota :
          {getFieldDecorator('quota', {
            initialValue:1073741824,rules: [{ required: true, message: 'Please input the quota!' }],
          })(
            <InputNumber  style={{width:'200px',marginLeft: '10px'}} min={0} max={1073741824} onChange={this.changeQuota} disabled={!this.state.staff} />,
          )}
        </Form.Item>
        
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                Signup
            </Button>
        </Form.Item>


        </Form>
        </div>
        }
        </div>
          
      );
    }
  }
  
  const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

  const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.error
    }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedRegistrationForm);