import {compose, configureStore} from '@reduxjs/toolkit';
import datasetReducer from './reducers/datasetSlice';
import userReducer from './reducers/userSlice';
import tagReducer from './reducers/tagSlice';
import sharingReducer from './reducers/sharingSlice';
import priorityReducer from "./reducers/prioritySlice";
import sessionReducer from "./reducers/sessionSlice";
import infosReducer from "./reducers/infosSlice";
import thunk from "redux-thunk";


export const store = configureStore({
    reducer: {
        dataset: datasetReducer,
        user: userReducer,
        tag: tagReducer,
        sharing: sharingReducer,
        priority: priorityReducer,
        session: sessionReducer,
        infos: infosReducer,
    }, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;