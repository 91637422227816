import React from 'react';
import {List,Form } from 'antd';
import '../App.css';
import axios from 'axios';

import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import {getUserToken} from '../store/utility';
  class LogIntoUser extends React.Component {
    state = {
      token : "",
      staff : false,
      all_users : [],
    };

  
    componentDidMount() {
      this.setState({token : getUserToken()});
  }

  getToken = (userid) => {
    this.props.onAuth(userid,this.state.token); 
/*    axios.get('/api/getusertoken/', {
      params : { user_id: userid},
      headers: {  Authorization: `Token ${this.state.token}` }
  }).then( res => { )} );*/
  }
      //When receiving data, do something
      componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
          axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
              this.setState({staff:res.data['staff']});
          });


          axios.get('/api/people/',{headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
              this.setState({
                all_users: res.data,
              });
              //Sort on name + surname, if not use item login 
              this.state.all_users.sort(function (a,b){
                if (a.name === "" && b.name === ""){
                  return a.login.toLowerCase()<b.login.toLowerCase()? -1 : a.login.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
                }
                else if (a.name === ""){
                  return a.login.toLowerCase()<b.name.toLowerCase()? -1 : a.login.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
                }
                else if (b.name === ""){
                  return a.name.toLowerCase()<b.login.toLowerCase()? -1 : a.name.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
                }
                else {
                return a.name.toLowerCase()<b.name.toLowerCase()? -1 : a.name.toLowerCase()>b.name.toLowerCase() ? 1 : 0;}
              });
          });
      }

      
    }

    render() {
      return (
        <div>
          {
            this.state.staff === true &&
            <div style={{marginTop:'5%'}}>
              <h1 style={{marginLeft:'47%',color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>User list</h1>
              <List
                  itemLayout="horizontal"
                  dataSource={this.state.all_users}
                  renderItem={item => (
                  <List.Item>
                          <List.Item.Meta
                      title={
                      <div>           
                      {
                          item.name || item.surname?
                          <span onClick={() => this.getToken(item.id)}>{item.name} {item.surname} </span>
                          :
                          <span onClick={() => this.getToken(item.id)}>{item.login}</span>
                        } </div>
                      }
                      />
                  </List.Item>
                  )}
                  />
            </div>
          }
        </div>);
    }
  }
  
//export login component, indicate its a form
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(LogIntoUser);

//If bind into the export connect, allow you to load into the page the state
const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.error,
        isAuthenticated: state.token  !== null,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to Login function,it has to call onAuth to login the user in all the site)
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (userid,token) => dispatch(actions.debugLogin(userid,token))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedNormalLoginForm);