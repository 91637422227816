import React from "react";
import {connect} from "react-redux";
import {DatasetSortingInstance} from "./SortingInstance";
import {chooseSorting} from "../store/reducers/datasetSlice";
import store from "../store/store";

class DatasetSortingPanel extends React.Component {


    componentDidMount() {
    }





    render() {
        return <div className={"SortingPanel"}>
            {this.props.dataset.sort_types_list.map(sort =>
                <DatasetSortingInstance key={sort.value} sort={sort}></DatasetSortingInstance>)}
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        chooseSorting: (value) => dispatch(chooseSorting(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetSortingPanel);