import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';
import {getUserID} from '../store/utility'

class HelpTutorialParts extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
		staff : false,
        source_link : "helpfiles/format.md",
        tutorial_part : "",
        connected_user_id : -1,
	}

    componentDidMount(){
        let url_pattern = this.props.location.search;
        url_pattern = url_pattern.replace('?','');
        ////console.log(url_pattern)
        const url_params = url_pattern.split('&');
        for (let params in url_params){
            const values = url_params[params].split('=');
            if (values[0] === "tutorial" && values[1] !== ""){
                this.state.tutorial_part = values[1];
                this.setState({source_link:"helpfiles/"+values[1]+".md"});
            }
        }

        this.state.connected_user_id = getUserID();
        this.props.sendTracking(-1,"Web,Help,"+this.state.tutorial_part+"Format,view");
    }

    render(){
        return(
			<div>
            <div style={{margin:'auto',marginTop:'1%'}}>
                {
                    this.state.source_link !== "" && this.state.source_link !== "helpfiles/format.md" && 
                    <MorphoMDEditor subfolderName={"tutorial_parts"} sourceLink={this.state.source_link} > </MorphoMDEditor>
                }
        	</div>
			</div>
        );
    }
}

export default HelpTutorialParts;