import React from 'react';
import axios from 'axios';
import {  Button } from 'antd';
import {getUserToken,setCookie} from '../store/utility';
import '../App.css';

class TutorialFilter extends React.Component {
  constructor(props){
    super(props);

    this.tutorialdiv = React.createRef();
}
    state = {
        tutorial_displayed : false,
        posx : -1,
        posy : -1,
        node : undefined,
        text: "",
        token : "",
        setting : "",
        display_arrow : "",
        backup_width:-1,
        backup_height:-1,
        width_ratio : -1, 
        height_ratio : -1,
        backup_zindex : undefined,
        backup_transform : undefined,
        backup_filter : undefined,
        backup_position : undefined,
        is_div : undefined,
    }

    getTutorialWidthPosition = () => {
        let result = "35%";
        if (this.state.posx === "left"){
        result = "1%";
      }
      else if (this.state.posx === "center"){
        result = "35%";
      }
      else if (this.state.posx === "right"){
        result = "70%";
      }

      return result;
    }

    getTutorialHeightPosition = () => {
        let result = "50%";
        if (this.state.posy === "top"){
        result = "8%";
      }
      else if (this.state.posy === "center"){
        result = "50%";
      }
      else if (this.state.posy=== "bottom"){
        result = "85%";
      }

      return result;
    }

    componentDidMount() {
      this.state.token = getUserToken();
      this.state.backup_width = window.innerWidth;
      this.state.backup_height = window.innerHeight;

      window.addEventListener('resize', (event) => {
        this.setState({width_ratio:window.innerWidth/this.state.backup_width});
        this.setState({height_ratio:window.innerHeight/this.state.backup_height});
        //console.log(window.innerWidth);
        // do stuff here
      });
    }
    closeTutorialFunc = () => {
      
      if (this.props.tutorialNode !== undefined){
        this.props.tutorialNode.style.transform = this.state.backup_transform;
        this.props.tutorialNode.style.position = this.state.backup_position;

        this.props.tutorialNode.style.zIndex = this.state.backup_zindex;
        this.props.tutorialNode.style.filter = this.state.backup_filter;
      }
      this.state.is_div = undefined;
        const sett = this.state.setting;
        if (sett !== "" && this.state.token !== "" && this.state.token !== null){
        axios({
          method: 'get',
          url: '/api/updatesetting/',
          params: {
              setting_name:"tutorial",
              setting_value:"false",
          },
          headers :{ Authorization: `Token ${this.state.token}` }
        });
      }

      //if user is anonymous, remove tutorials from here
      if (this.state.token === "" || this.state.token === null) {
        setCookie('hide_tutorials',"true",{expires:365});
      }

      this.props.hideTutoSession();
      this.props.flushTutorialList();
      this.props.closeTutorial();
    }
 
    closeTutorialWindow = () => {
      if (this.props.tutorialNode !== undefined){
      this.props.tutorialNode.style.transform = this.state.backup_transform;
      this.props.tutorialNode.style.position = this.state.backup_position;

      this.props.tutorialNode.style.zIndex = this.state.backup_zindex;
      this.props.tutorialNode.style.filter = this.state.backup_filter;
      }
      this.state.is_div = undefined;
      this.props.closeTutorial();
    }
    componentDidUpdate(prevProps,prevState){
      if (this.props.tutorialDisplayed !== this.state.tutorial_displayed){
        this.setState({tutorial_displayed:this.props.tutorialDisplayed});
      }

      if (this.props.clearTutorials !== prevProps.clearTutorials && !this.state.setting.includes("layout")){
        if (this.props.tutorialNode !== undefined){
          this.props.tutorialNode.style.transform = this.state.backup_transform;
          this.props.tutorialNode.style.position = this.state.backup_position;

          this.props.tutorialNode.style.zIndex = this.state.backup_zindex;
          this.props.tutorialNode.style.filter = this.state.backup_filter;
        }
        this.state.is_div = undefined;
        //this.props.flushTutorialList();
        this.props.closeTutorial();
      }

      if (this.props.displayArrow !== this.state.display_arrow){
        this.setState({display_arrow:this.props.displayArrow});
      }

      if (this.props.tutorialX !== this.state.posx){
        this.setState({posx:this.props.tutorialX});
      }

      if (this.props.tutorialY !== this.state.posy){
        this.setState({posy:this.props.tutorialY});
      }

      if (this.props.tutorialIsDiv !== this.state.is_div && this.props.tutorialNode !== undefined){
        this.state.is_div = this.props.tutorialIsDiv;
        this.state.backup_position = this.props.tutorialNode.style.position;
        this.state.backup_transform = this.props.tutorialNode.style.transform;
        if (this.state.is_div === true){
          this.props.tutorialNode.style.position = 'relative';}
        else {
          this.props.tutorialNode.style.transform =  this.props.tutorialNode.style.transform + "scale(2)";
        }
      }

      if (this.props.tutorialNode !== this.state.node && this.props.tutorialNode !== undefined){
        this.setState({node:this.props.tutorialNode});
        this.state.backup_zindex = this.props.tutorialNode.style.zIndex;
        this.state.backup_filter = this.props.tutorialNode.style.filter;
        this.props.tutorialNode.style.zIndex = 40;
        this.props.tutorialNode.style.filter = 'brightness(0) invert(1)';
      }

      if (this.props.tutorialText !== this.state.text){
        this.setState({text:this.props.tutorialText});
      }
      if (this.props.tutorialSetting !== this.state.setting){
        this.setState({setting:this.props.tutorialSetting});
      }
    }

    render() {
        return (
        <div> 
          {
            this.state.tutorial_displayed && 
            <div>
              <div style={{position:'absolute',backgroundColor:'#44D4FF',zIndex:'25'}}></div>
           <div style={{position:'absolute',left:this.state.posx !== undefined?this.getTutorialWidthPosition():'35%',top:this.state.posy !== undefined?this.getTutorialHeightPosition():'50%',width:'30%',zIndex:'30'}}>
          <h1 style={{whiteSpace:'pre-line',marginLeft:'3px',marginRight:'3px',textAlign:'center',fontFamily:'Arial',fontSize:'35px',fontWeight:'bold',color:'white'}}>{this.state.text}</h1><br></br><br></br>
        </div>
      <Button onClick={this.closeTutorialFunc} style={{borderColor:'#44D4FF',borderRadius:'3px',position:'absolute',left:'35%',top:'80%',width:'6%',height:'3%',zIndex:'50'}}><span style={{width:'100%',fontSize:'19px',fontWeight:'bold',color:'#44D4FF'}}>Hide</span></Button>
 <Button onClick={this.closeTutorialWindow} style={{borderColor:'#44D4FF',borderRadius:'3px',position:'absolute',left:'55%',top:'80%',width:'6%',height:'3%',zIndex:'50'}}><span style={{width:'100%',fontSize:'19px',fontWeight:'bold',color:'#44D4FF'}}>Next tip</span></Button>
        </div>
        }
        </div>
        );
    }
}

export default TutorialFilter;