import React from "react";
import {IsAtLeastManager, morphonet_blue} from "../utils/tools";
import {List, Radio} from "antd";
import {useSelector} from "react-redux";
import {ChangePeopleGroupState} from "../utils/api_callbacks"


export const SharingGroupInstance = (props) => {
    const group = props.group;
    const user = props.user;
    const people_groups_by_group = useSelector(state =>
        state.user.people_groups_by_group);
    let value = "norights";
        if (Object.keys(people_groups_by_group).includes(group.id.toString()) && people_groups_by_group[group.id.toString()].length > 0) {

        const filtered_groups = people_groups_by_group[group.id].filter(pg => pg.id_people === user.id)

        if (filtered_groups.length > 0) {
            value = filtered_groups[0].status;

        }
    }
    return (<List.Item>
        <List.Item.Meta
            title={<span style={{color: user.id === group.id_owner?morphonet_blue:'grey', fontSize: user.id === group.id_owner?'16px':'14px'}}
                      className='layout-text'>{user.name} {user.surname}{user.id === group.id_owner?" (owner)":""} </span>}
        />
        <Radio.Group disabled={user.id === group.id_owner} onChange={(e) => {
            ChangePeopleGroupState(group, e, people_groups_by_group[group.id], user.id)
        }}
                     value={value}>
            <Radio value={"manager"}>Manager</Radio>
            <Radio value={"member"}>Reader</Radio>
            <Radio value={"norights"}>No rights</Radio>
        </Radio.Group>
    </List.Item>);
}

