import React from "react";
import {connect} from "react-redux";
import {
    AddTag,
    ChangeDatasetPriority,
    ChooseSetFromUrl,
    CreateLink,
    DeleteLink,
    LoadUserDatasets, ReloadDatasets,
    RemoveTag
} from "../utils/api_callbacks";
import ChosenSetTopPanel from "./ChosenSetTopPanel";
import {IsAdminOrCreator, IsAtLeastManager} from "../utils/tools";
import {AutoComplete, Button, Input, InputNumber, List, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import {getUserToken} from "../utils/CookieManagement";
import {SetRedirectedUrl} from "../store/reducers/userSlice";
import store from "../store/store";

const {Option} = AutoComplete;


class DatasetUpdateView extends React.Component {

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    state = {
        new_name: "",
        new_description: "",
        new_spf: "",
        new_ncbi: "",
        new_type: "",
        new_dt: "",
        new_id_study: "",
        last_selected_tag: null,
        link_creation_open: false,
        newlink_link: "",
        newlink_description: "",
    }


    PatchDatasetChange = (dataset) => {
        const error = false;
        let data = {};
        data["id_dataset"] = dataset.id;
        if (this.state.new_name !== "" && this.state.new_name !== undefined) data["name"] = this.state.new_name; else {
            data["name"] = dataset.name
        }
        if (this.state.new_description !== "" && this.state.new_description !== undefined) data["comments"] = this.state.new_description; else {
            data["comments"] = dataset.comments
        }
        if (this.state.new_spf !== "" && this.state.new_spf !== undefined) data["spf"] = this.state.new_spf; else {
            data["spf"] = dataset.spf
        }
        if (this.state.new_ncbi !== "" && this.state.new_ncbi !== undefined) data["id_ncbi"] = this.state.new_ncbi; else {
            data["id_ncbi"] = dataset.id_ncbi
        }
        if (this.state.new_type !== "" && this.state.new_type !== undefined) data["type"] = this.state.new_type; else {
            data["type"] = dataset.type
        }
        if (this.state.new_dt !== "" && this.state.new_dt !== undefined) data["dt"] = this.state.new_dt; else {
            data["dt"] = dataset.dt
        }
        if (this.state.new_id_study !== "" && this.state.new_id_study !== undefined) data["id_study_id"] = this.state.new_id_study; else {
            data["id_study_id"] = dataset.id_study_id
        }
        // console.log(this.state.update_set_starttime);
        //console.log(this.state.update_set_deltatime);

        if (!error) {
            //post new group to django
            axios({
                method: 'post',
                url: `/api/updatesetwebsite/`,
                data: data,
                headers: {Authorization: `Token ${getUserToken()}`}
            }).then(res => {
                ReloadDatasets();
                this.props.SetRedirectedUrl("/dataset/"+dataset.id);
            });
        }
    }

    DeleteSelectedDataset = () => {
        if (window.confirm('Are you sure you wish to delete this dataset ?')) {
            axios({
                method: 'get',
                url: `/api/deletedatasetweb/`,
                params: {id_dataset: this.props.chosen_set},
                headers: {Authorization: `Token ${getUserToken()}`}
            }).then(res => {
                ReloadDatasets();
                this.props.SetRedirectedUrl("/dataset/");
            });
        }
    }

    displayLinkCreationPanel = () => {
        this.setState({link_creation_open: !this.state.link_creation_open});
    }

    onChangeLinkDescription = ({target: {value}}) => {
        this.setState({newlink_description: value});
    };

    render() {
        const dataset_object = this.props.datasets[this.props.chosen_set];
        if (dataset_object === undefined) {
            return <div></div>
        }
        const groups = Object.values(this.props.user.group_list);
        const studies = this.props.user.studies_by_group;
        let links = undefined;
        if (Object.keys(this.props.set_links).includes(this.props.chosen_set.toString())){
            links = this.props.set_links[this.props.chosen_set.toString()];
        }
        var tags_set = [];
        if (Object.keys(this.props.tags_for_set).includes(this.props.chosen_set.toString())){
            const tag_s =this.props.tags_for_set[this.props.chosen_set.toString()];
            tags_set = Object.values(this.props.tags).filter(tag => tag_s.includes(tag.id));
        }
        const set_prio = Object.entries(this.props.sets_by_priority).filter(([k, v]) => k === this.props.chosen_set.toString());
        var priority = -1;
        if (set_prio.length > 0 && set_prio[0][1] !== 4){
            priority = set_prio[0][1];
        }


        const tags = Object.values(this.props.tags);
        const tags_options = (tags.length === 0 ? [] : tags.map(tag => <Option key={"option_"+tag.id} value={tag.id}>{tag.name}</Option>));

        return (<div>

            <ChosenSetTopPanel {...this.props} set={dataset_object}></ChosenSetTopPanel>
            <br></br>
            {dataset_object!==undefined &&<p className={"DatasetDescriptionName"}>{dataset_object.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${dataset_object.id})` : ""}</p>}
            {
                this.props.connected && IsAtLeastManager(this.props.chosen_set) &&
                <div style={{marginTop:"1%"}}>
                    <span style={{fontSize: '21px', color: 'black'}}>Update a dataset</span>
                    <div>
                        <div style={{marginTop:"1%"}}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Name:</h2>
                            <TextArea
                                autoSize={{minRows: 1, maxRows: 6}}
                                value={this.state.new_name !== "" ? this.state.new_name : dataset_object.name}
                                disabled={!IsAtLeastManager(this.props.chosen_set)}
                                onChange={(e) => this.setState({new_name: e.target.value})}
                                style={{width: '50%', marginLeft: '10px'}}
                                placeholder="Dataset name"
                            />
                        </div>
                        <div style={{marginTop:"1%"}}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Description:</h2>
                            <TextArea
                                autoSize={{minRows: 2, maxRows: 6}}
                                disabled={!IsAtLeastManager(this.props.chosen_set)}
                                onChange={(e) => this.setState({new_description: e.target.value})}
                                value={this.state.new_description !== "" ? this.state.new_description : dataset_object.comments}
                                placeholder="Dataset description"
                            />
                        </div>
                        <div><h2 style={{color: '#44D4FF', fontSize: '20px',marginTop:"1%"}}>Tags :</h2>
                            { tags_set !== undefined && tags_set.length > 0 &&
                                <List
                                itemLayout="horizontal"
                                style={{width: '60%', outline: ' 0.5px solid #44D4FF', outlineOffset: '2px'}}
                            dataSource={tags_set}
                            renderItem={item => (
                                <List.Item style={{backgroundColor: item.color}}>
                                    <List.Item.Meta
                                        title={<span style={{color: 'black', fontSize: '18px', paddingLeft: '15px'}}
                                                  className='layout-text'>{item.name} <Button style={{
                                            float: 'right',
                                            marginRight: '3px',
                                            border: 'solid 1px grey',
                                            borderRadius: '3px'
                                        }} type="danger" onClick={() => {
                                            RemoveTag(this.props.chosen_set, item.id)
                                        }}>Remove</Button></span>}
                                    />
                                </List.Item>
                            )}
                        />
                        }
                            <br></br><br></br>
                            <span style={{marginRight: '5px', fontSize: '16px',marginTop:"1%"}}> Add a tag :</span>
                            <Select style={{width: '200px', marginLeft: '10px'}} onChange={(value) => {
                                this.setState({last_selected_tag: value})
                            }}>
                                {tags_options}
                            </Select>
                            <Button style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginLeft: '5px'}}
                                    type="primary" onClick={() => {
                                AddTag(this.props.chosen_set, this.state.last_selected_tag)
                            }}>Add tag</Button>

                        </div>

                        <div><br></br><br></br><h2
                            style={{
                                display: 'inline',
                                color: '#44D4FF',
                                fontSize: '20px',
                            }}>Priority: </h2><Select style={{width: '200px', marginLeft: '10px'}}
                                                      value={priority}
                                                      onChange={(priority) => ChangeDatasetPriority(priority, this.props.chosen_set)}>
                            <Option value={0}>Very high</Option>
                            <Option value={1}>High</Option>
                            <Option value={2}>Low</Option>
                            <Option value={-1}> None </Option>
                        </Select></div>

                        <div style={{marginTop:"1%"}}><h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Links : </h2>
                            {
                                links !== undefined && links.length > 0 &&
                            <List

                                itemLayout="horizontal"
                                style={{width: '60%'}}
                                dataSource={links}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<span style={{color: 'grey', fontSize: '18px'}} className='layout-text'>
                                                <p><a href={item.link}>{item.link}</a></p></span>}
                                            description={<span style={{color: 'grey', fontSize: '18px'}}
                                                            className='content-text'>{item.description}</span>}
                                        />
                                        <Button type="danger"
                                                onClick={() => {
                                                    if (item.id !== null && item.id !== -1) {
                                                        if (window.confirm('Are you sure you wish to delete this link ?')) {
                                                            DeleteLink(item.id,this.props.chosen_set);
                                                        }
                                                    }
                                                }}
                                                style={{
                                                    borderColor: '#44D4FF',
                                                    backgroundColor: '#44D4FF',
                                                    marginRight: '10px'
                                                }}>
                                            Delete
                                        </Button>
                                    </List.Item>
                                    )}
                                /> }
                                        <span style={{display: 'inline', color: '#44D4FF', fontSize: '20px',marginTop:"1%"}}
                                           className='layout-text'>New link
                                        </span>
                                        <Button type="primary" icon="plus" size={"default"} style={{
                                            borderColor: '#44D4FF',
                                            backgroundColor: '#44D4FF',
                                            marginLeft: '10px'
                                        }} onClick={this.displayLinkCreationPanel}/>
                                        <br></br>
                                        {
                                            this.state.link_creation_open ?
                                                <div style={{marginLeft: '2%'}}>
                                                    <span style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}
                                                       className='layout-text'>Link
                                                    </span>
                                                    <br></br>
                                                    <Input
                                                        onChange={e => {
                                                            this.setState({newlink_link: e.target.value})
                                                        }}
                                                        style={{width: '200px'}}
                                                        placeholder="Link"
                                                    />
                                                    <br></br>
                                                    <span style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}
                                                       className='layout-text'>Description
                                                    </span>
                                                    <br></br>
                                                    <TextArea
                                                        style={{width: '60%'}}
                                                        onChange={this.onChangeLinkDescription}
                                                        autoSize={{minRows: 2, maxRows: 6}}
                                                        placeholder="Link description"
                                                    />
                                                    <br></br>
                                                    <Button type="primary"
                                                            onClick={() => CreateLink(this.state.newlink_link, this.state.newlink_description, this.props.chosen_set)}
                                                            style={{
                                                                borderColor: '#44D4FF',
                                                                backgroundColor: '#44D4FF',
                                                                marginRight: '10px'
                                                            }}>
                                                        Create
                                                    </Button>
                                                </div>
                                                :
                                                <span></span>
                                        }
                        </div>

                        <div style={{marginTop:"1%"}}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Start time post
                                fertilization:</h2>
                            <InputNumber
                                value={this.state.new_spf !== "" ? this.state.new_spf : dataset_object.spf}
                                onChange={value => {
                                    this.setState({new_spf: value});
                                }}
                                style={{width: '200px', marginLeft: '10px'}}
                                placeholder="Dataset start time"
                            />
                            <span className={"DatasetDescriptionValue"}> (in seconds)</span>
                        </div>
                        <div style={{marginTop:"1%"}}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Delta time:</h2>
                            <InputNumber
                                onChange={value => {
                                    this.setState({new_dt: value});
                                }}
                                value={this.state.new_dt !== "" ? this.state.new_dt : dataset_object.dt}
                                style={{width: '200px', marginLeft: '10px'}}
                                placeholder="Dataset delta time"
                            />
                            <span className={"DatasetDescriptionValue"}> (in seconds)</span>
                        </div><br></br><br></br>

                        <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Study </h2>
                        <br/><br/>
                        {
                            groups.map(group =>
                                <div key={group.name}><label style={{
                                    marginRight: '5px',
                                    color: '#44D4FF',
                                    fontSize: '18px'
                                }} className='content-text' >Group : {group.name}</label><br/><br/>
                                    {
                                        studies === undefined || studies[group.id] === undefined || studies[group.id].length <= 0 ?
                                            <div>
                                            </div>
                                            :
                                            <div>{studies[group.id] !== undefined && studies[group.id].map(studie =>
                                                <div key={studie.name}>
                                                    <input onChange={() => {
                                                        this.setState({new_id_study: studie.id})
                                                    }}
                                                           checked={this.state.new_id_study !== "" ? this.state.new_id_study === studie.id : dataset_object.id_study_id === studie.id}
                                                           name={"chosenstudy"} type="radio" style={{marginLeft:"10px"}}></input>
                                                    <label style={{
                                                        marginLeft: '10px',
                                                        fontSize: '16px'
                                                    }}  className='content-text'>{studie.name}</label>

                                                    <br/><br/></div>
                                            )}
                                            </div>

                                    }
                                    <label style={{
                                        marginLeft: '10px',
                                        fontSize: '16px'
                                    }}  className='content-text'> Add a study </label> <img onClick={() => {this.props.SetRedirectedUrl("/group/"+group.id+"/study/create");}}  style={{verticalAlign:"top"}}  alt="study creation page" width="20" src="/images/refonte/add.png"></img>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>)}
                        <div style={{marginTop:"1%"}}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Type :</h2>
                            <Select style={{width: '200px', marginLeft: '10px'}}
                                    value={this.state.new_type !== "" ? this.state.new_type : dataset_object.type}
                                    onChange={value => {
                                        this.setState({new_type: value});
                                    }}>
                                <Option value={0}>Observed</Option>
                                <Option value={1}>Simulated</Option>
                                <Option value={2}>Drawed</Option>
                            </Select>
                        </div>
                        <div style={{marginTop:"1%"}}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>NCBI category id:</h2>
                            <InputNumber
                                value={this.state.new_ncbi !== "" ? this.state.new_ncbi : dataset_object.id_ncbi}
                                onChange={value => {
                                    this.setState({new_ncbi: value});
                                }}
                                style={{width: '200px', marginLeft: '10px'}}
                                placeholder="Dataset ncbi id"
                            />
                        </div>
                        <div style={{marginTop:"1%"}}>
                            <Button type="primary" onClick={() => {
                                this.PatchDatasetChange(dataset_object)
                            }}
                                    style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                                Update dataset
                            </Button>

                            <Button type="danger" disabled={!IsAdminOrCreator(this.props.chosen_set)}
                                    onClick={this.DeleteSelectedDataset} style={{marginRight: '10px'}}>
                                Delete dataset
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>);
    }

}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset.entities,
        connected: state.user.is_connected,
        set_links: state.dataset.links_by_dataset,
        studies: state.user.studies_by_group,
        staff: state.user.is_staff,
        owner_by_id: state.dataset.set_owner_by_id,
        size_by_id: state.dataset.size_by_datasets,
        sets_by_priority: state.priority.dataset_by_proprity,
        priorities: state.priority.entities,
        tags_for_set: state.tag.dataset_by_tag,
        tags: state.tag.entities,
        user: state.user,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DatasetUpdateView);