import {connect} from "react-redux";
import React from "react";
import {SessionInstance} from "./sessionInstanceContainer";
import ChosenSetTopPanel from "../Dataset/ChosenSetTopPanel";
import {IsAdminOrCreator, IsAtLeastManager, IsAtLeastReader} from "../utils/tools";
import {Redirect} from "react-router-dom";
import {ChooseSetFromUrl} from "../utils/api_callbacks";
import {SetRedirectedUrl} from "../store/reducers/userSlice";


function ApplyFiltering(data, filtertype, chosen_set) {
    if (filtertype === "choosenset") {
        return data.filter(key => key.id_dataset === chosen_set);
    }

    return data;
}

class SessionListView extends React.Component {

    state = {
    }

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    handleClickNewSession = () => {
        this.props.SetRedirectedUrl("/dataset/" + this.props.chosen_set + "/sessions/create/")
    }

    render() {
        if (this.props.sessions === undefined) return <div></div>
        const chosen_set = this.props.chosen_set;
        if (chosen_set === undefined) return <div></div>;
        if (this.props.datasets === undefined) return <div></div>;
        const dataset = this.props.datasets[chosen_set];
        let sessions = [];
        if (this.props.sessions !== undefined && this.props.sessions !== []) sessions = ApplyFiltering(Object.values(this.props.sessions), "choosenset", chosen_set);
        return <div>
            <ChosenSetTopPanel {...this.props} set={dataset}></ChosenSetTopPanel>
            <br></br>
            {dataset!==undefined &&<p className={"DatasetDescriptionName"}>{dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${dataset.id})` : ""}</p>}
            {
                this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                    <div style={{marginTop: "1%"}}>
                            <span style={{fontSize: '21px', color: 'black'}}>Sessions</span>
                        <br/>
                        <br/>
                        <span style={{color: '#44D4FF', fontSize: '20px'}}>Create a session</span>
                        <img alt="Create session " style={{cursor: 'pointer',marginLeft:'15px',paddingBottom:"5px",filter:IsAtLeastManager(this.props.chosen_set)?"unset":"grayscale(100%"}} onClick={() => {
                            if (IsAtLeastManager(this.props.chosen_set))this.handleClickNewSession()
                        }} width={30} src="/images/refonte/add.png"></img>
                    </div>
            }
            {this.props.connected && IsAtLeastReader(this.props.chosen_set) && sessions.map(session => <SessionInstance
                key={"session_" + session.id} session={session}></SessionInstance>)}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        connected: state.user.is_connected,
        chosen_set: state.dataset.current_choosen_set,
        sessions: state.session.entities,
        datasets: state.dataset.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionListView);