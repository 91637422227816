import React from "react";
import {LoadPublicSets, LoadUserData, UnityLoadedFunc} from "./utils/api_callbacks";
import store from "./store/store";
import {priorityListAdded} from "./store/reducers/prioritySlice";
import {LoadSortValues} from "./store/reducers/datasetSlice";
import {connect} from "react-redux";
import {getUserToken} from "./utils/CookieManagement";


function LoadPriorities() {
    const priorities = [{id: 0, value: "Very High"}, {id: 1, value: "High"}, {id: 2, value: "Low"}]
    store.dispatch(priorityListAdded(priorities));
}

export const sort_functions = [(a, b) => {

    const x = a.id;
    const y = b.id;
    return ((x < y) ? 1 : ((x > y) ? -1 : 0));
}, (a, b) => {
    const x = a.id;
    const y = b.id;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
}, (a, b) => {
    const x = a.name;
    const y = b.name;
    return x.localeCompare(y);
}, (a, b) => {
    const x = a.name;
    const y = b.name;
    return -x.localeCompare(y);
}];

function LoadDatasetSorting() {
    const sorts = [
        {id: 0, value: "New first", image_path: "/images/refonte/date.png"}, {
            id: 1,
            value: "Old first",
            image_path: "/images/refonte/invdate.png"
        }, {id: 2, value: "Name", image_path: "/images/refonte/sort-by-alphabet.png"}, {
            id: 3,
            value: "Reversed Name",
            image_path: "/images/refonte/sort-reverse-alphabetical-order.png"
        }]

    store.dispatch(LoadSortValues(sorts));
}

class DataLoader extends React.Component {

    componentDidMount() {
        LoadPublicSets();
        UnityLoadedFunc();
        LoadPriorities();
        LoadDatasetSorting();
        if (getUserToken() !== null && getUserToken() !== undefined)
            LoadUserData();
    }

    render() {
        return ("");
    }

}

const mapStateToProps = (state) => {
    return {
        user_is_admin: state.user.is_staff
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(DataLoader);