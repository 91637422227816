import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {chooseSorting} from "../store/reducers/datasetSlice";

export const DatasetSortingInstance = (props) => {
    const dispatch = useDispatch();
    const current_choosen_sort = useSelector(state => state.dataset.current_sort_type);
    return <img onClick={() => {
        dispatch(chooseSorting(props.sort.id))
    }} alt={props.sort.value} src={props.sort.image_path}
                style={{width: "30px", opacity: 1, marginLeft: '30px', backgroundColor:current_choosen_sort===props.sort.id?"#44D4FF70":"unset"}}></img>
}