import React from 'react';

class Funding extends React.Component {
    render(){
                    /* 
            <input type="image" width="150px;"  src="images/logo.png" onclick="window.open('index.php')" />
            */
        return (
    <div>
            <div style={{marginRight: 'auto',  marginLeft: 'auto', marginTop:'10px', marginBottom:'auto'}}>

       <div style={{width:'800px',textAlign:'center',marginLeft:'auto',marginRight:'auto',padding:'10px',border:'3px solid #DBF0FC',fontSize:'20px'}} >
           <p style={{fontSize:'40px',color:'gray',margin:'auto'}} >Funding </p>
                
           <table style={{backgroundColor:'white',textAlign:'left',padding:'50px'}}>
               <tr style={{backgroundColor:'white',marginTop:'50px'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="CNRS" src="http://www.cnrs.fr/themes/custom/cnrs/logo.svg" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => window.open('http://www.cnrs.fr/fr/page-daccueil')}/></td>
                   <td> Centre national de la recherche scientifique  </td>
               </tr>
               <tr style={{backgroundColor:'white',marginTop:'50px'}}>
                   <td style={{padding:'20px'}}> 	<img width="50px" alt="Région Occitanie" src="https://www.laregion.fr/IMG/jpg/carre.jpg" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://www.laregion.fr')}}/></td>
                   <td> La Région Occitanie  </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="70px" alt="INRIA" src="https://www.inria.fr/extension/site_inria/design/site_inria/images/logos/logo_INRIA_FR.svg" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={ () => {window.open('https://www.inria.fr')}} /></td>
                   <td> Institut national de recherche en informatique et en automatique   </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="60px" alt="IBC" src="http://www.ibc-montpellier.fr/images/IBC/ibc_logo_200.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('http://www.ibc-montpellier.fr')}} /></td>
                   <td> Computational Biology Institute  </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="LIRMM" src="/images/Logo_Lirmm.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('http://www.lirmm.fr')}} /></td>
                   <td> Laboratoire d'Informatique, de Robotique et de Microélectronique de Montpelllier  </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="IRIT"  src="https://www.irit.fr/wp-content/uploads/2019/09/Logo_sans_intitule.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://www.irit.fr')}} /></td>
                   <td> Institut de Recherche en Informatique de Toulouse </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="CRBM" src="http://www.crbm.cnrs.fr/wp-content/uploads/2017/01/logo_crbm.jpg" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('http://www.crbm.cnrs.fr')}} /></td>
                   <td> Centre de Recherche en Biologie Cellulaire de Montpellier </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="UM" src="/images/Logo_UM.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://www.umontpellier.fr')}} /></td>
                   <td> Université de Montpellier </td>
               </tr>
               <tr style={{backgroundColor:'white'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="ANR" src="http://www.agence-nationale-recherche.fr/fileadmin/tpl/img/logo.gif" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('http://www.agence-nationale-recherche.fr')}} /></td>
                   <td> Agence Nationale de la Recherche </td>
               </tr>
               <tr style={{backgroundColor:'white',marginTop:'50px'}}>
               <td style={{padding:'20px'}}> 	<img width="50px" alt="AxLR" src="https://axlr.com/wp-content/uploads/2019/01/axlr-satt-montpellier-logo_bleudeprusse.png" style={{marginLeft: 'auto', marginRight: 'auto',marginTop:'10px'}} onClick={() => {window.open('https://axlr.com/')}} /></td>
                   <td> SATT AxLR </td>
               </tr>
               </table>
 
       
       </div>
       </div>
    </div>
        );
    }
}

export default Funding;

