import React, { Component } from "react";
import { List,Button,Tooltip } from 'antd';
import {getUserToken,getUserID} from '../store/utility';
import axios from 'axios';
import '../App.css';

class UserBin extends Component {
    constructor(props){
      super(props);
      this.myRef = React.createRef();
      this.restoreDataset = this.restoreDataset.bind(this);
      this.restoreCorrespondence = this.restoreCorrespondence.bind(this);
      this.eraseDataset = this.eraseDataset.bind(this);
      this.eraseCorrespondence = this.eraseCorrespondence.bind(this);
      this.getDatasetNameByID = this.getDatasetNameByID.bind(this);
    }

    state = {
        id_user : -1,
        token : "",
        all_users : [],
        dataset_list : [],
        public_dataset_list : [],
        //
        existing_datasets : [],
        public_existing_datasets: [],
        existing_found : false,
        public_existing_found : false,
        deleted_infos_got : false,
        infos_by_id : [],
        deleted_infos_got2: false,
        lastDatasetDisplayed : -1
    }

    setLastDatasetDisplay(id){
        if (this.state.lastDatasetDisplayed !== id){
            this.state.lastDatasetDisplayed = id;
        }
    }
    getDatasetNameByID(id){
        let name = "";
        this.state.existing_datasets.map(dataset => {
            if (dataset.id === id){
                name = dataset.name;

            }
        });
        if (name === ""){
            this.state.public_existing_datasets.map(dataset => {
                if (dataset.id === id){
                    name = dataset.name;

                }
            });
        }
        return name;
    }
    componentDidMount(){
        //update view on resize
        this.state.id_user = getUserID();
        this.state.token = getUserToken();
        axios.get('/api/userbindatasets/',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                dataset_list: res.data,
            });
        });

        axios.get('/api/people/',{headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            //console.log(res);
            //console.log(res.data);
            this.setState({
              all_users: res.data,
            }); 
        });
        axios.get('/api/publicdatasetsbin/',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                public_dataset_list: res.data,
            });
        });

        axios.get('/api/userdatasets/',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                existing_datasets: res.data,
            });
            this.setState({
                existing_found:true,
            });
        });
        axios.get('/api/publicdatasets/',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {

            this.setState({
                public_existing_datasets: res.data,
            });
            this.setState({
                public_existing_found:true,
            });
        });
    }

    restoreDataset(set_id){
        axios.get('/api/restoreset/',{ params:{id_dataset:set_id}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            const count = res.data['status'];
            window.location.reload(false);
        });
    }

    eraseDataset(set_id){
        axios.get('/api/erasedataset/',{ params:{id_dataset:set_id}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            const count = res.data['status'];
            //window.location.reload(false);
        });
    }

    eraseCorrespondence(corr_id){
        axios.get('/api/erasecorrespondence/',{ params:{id_correspondence:corr_id}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            const count = res.data['status'];
            window.location.reload(false);
        });
    }

    restoreCorrespondence(corr_id){
        axios.get('/api/restorecorrespondence/',{ params:{id_correspondence:corr_id}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            const count = res.data['status'];
            window.location.reload(false);
        });
    }

    componentDidUpdate(prevProps,prevState){
        if (this.state.public_existing_found && this.state.existing_found && !this.state.deleted_infos_got){
            const private_info = false;
            const fulldatasets = this.state.existing_datasets.concat(this.state.public_existing_datasets);
            fulldatasets.map(set => {
                //FAIRE LA REQUETE (EXISTE PAS ENCORE)
                axios.get('/api/correspondencebin/',{params:{id_dataset:set.id,id_people:this.state.id_user}, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => { 
                    //console.log(res.data);
                    res.data.map(info => this.state.infos_by_id.push(info));
                    this.setState({deleted_infos_got2:!this.state.deleted_infos_got2});
                });
            });
            this.state.infos_by_id.sort(
                function (a,b){
                return (a.id_dataset>b.id_dataset? -1 : a.id_dataset<b.id_dataset ? 1 : 0);
              }.bind(this))
            //console.log(this.state.infos_by_id);
            this.setState({deleted_infos_got:true});
        }
    }

    render(){
        return (
            <div>
                <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px',marginTop:'5%'}}> User Bin </h1>

                <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Private datasets</p>
                    {
                        this.state.dataset_list.length > 0 ?
                        <div>
                        <List
                            itemLayout="horizontal"
                            style={{width:'50%',marginLeft:'15px'}}
                            dataSource={this.state.dataset_list}
                            renderItem={item => (
                                <List.Item>
                                <Tooltip placement="top" title={<span>Restore the deleted dataset</span>}><Button type='primary' onClick={() => {this.props.sendTracking(-1,"Web,Bin,Dataset-Restore");this.restoreDataset(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer',backgroundColor:'#44D4FF'}} >Restore data</Button></Tooltip>
                               <Tooltip placement="top" title={<span>Definitely erase the deleted dataset</span>}><Button type='danger' onClick={() => {this.props.sendTracking(-1,"Web,Bin,Dataset-Erase");this.eraseDataset(item.id)}} style={{display:'inline',marginLeft:'10px',marginRight:'40px',cursor:'pointer'}} > Erase data forever</Button></Tooltip>

                                <List.Item.Meta
                                    avatar={
                                        item.type === 0?                       
                                        <img alt="Observed data" style={{display:'inline',marginRight:'4px'}} width={35} src="/images/refonte/microscope.png"></img>
                                        :           
                                        item.type === 1?                       
                                        <img alt="Simulated data" style={{float:'left',marginRight:'4px'}} width={35} src="/images/refonte/simulated.png"></img>
                                        :
                                        item.type === 2 &&                      
                                        <img alt="Drawing" style={{float:'left',marginRight:'4px'}} width={35} src="/images/refonte/drawing.png"></img>
                                      }
                                    title={<span style={{fontWeight:"bold",fontSize:'19px'}} className='content-text'>{item.name} {this.state.all_users !== undefined && this.state.all_users.length > 0 && this.state.all_users.filter(owner => owner.id === item.id_people).length > 0 && this.state.all_users.filter(owner => owner.id === item.id_people)[0] !== undefined?"owned by "+this.state.all_users.filter(owner => owner.id === item.id_people)[0].name + " " + this.state.all_users.filter(owner => owner.id === item.id_people)[0].surname :"Not found"}</span>}
                                    description={<span style={{fontFamily:'Arial',fontSize:'16px'}}>{item.comments}</span>}
                                />
 
                                </List.Item>
                            )}
                            />
                            </div>
                            :
                            <p style={{fontFamily:'Arial',fontSize:'18px',fontWeight:"bold",marginLeft:'15px'}}> Empty </p>
                        }
                                                <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Public datasets</p>

                    {
                        this.state.public_dataset_list.length > 0 ?
                        <div>
                        <List
                            itemLayout="horizontal"
                            style={{width:'50%',marginLeft:'15px'}}
                            dataSource={this.state.public_dataset_list}
                            renderItem={item => (
                                <List.Item>
                                <Tooltip placement="top" title={<span>Restore the deleted dataset</span>}><Button type='primary'onClick={() => {this.props.sendTracking(item.id,"Web,Bin,Dataset-Restore");this.restoreDataset(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer',backgroundColor:'#44D4FF'}}>Restore data</Button></Tooltip>
                                <Tooltip placement="top" title={<span>Definitely erase the deleted dataset</span>}><Button type='danger' onClick={() => {this.props.sendTracking(item.id,"Web,Bin,Dataset-Erase");this.eraseDataset(item.id)}} style={{display:'inline',marginRight:'40px',marginLeft:'10px',cursor:'pointer'}} >Erase data forever</Button></Tooltip>

                                <List.Item.Meta
                                    avatar={
                                        item.type === 0?                       
                                        <img alt="Observed data" style={{display:'inline',marginRight:'4px'}} width={35} src="/images/refonte/microscope.png"></img>
                                        :           
                                        item.type === 1?                       
                                        <img alt="Simulated data" style={{float:'left',marginRight:'4px'}} width={35} src="/images/refonte/simulated.png"></img>
                                        :
                                        item.type === 2 &&                      
                                        <img alt="Drawing" style={{float:'left',marginRight:'4px'}} width={35} src="/images/refonte/drawing.png"></img>
                                      }
                                      title={<span style={{fontWeight:"bold",fontSize:'19px'}} className='content-text'>{item.name} {this.state.all_users !== undefined && this.state.all_users.length > 0 && this.state.all_users.filter(owner => owner.id === item.id_people).length > 0 && this.state.all_users.filter(owner => owner.id === item.id_people)[0] !== undefined ?"owned by "+this.state.all_users.filter(owner => owner.id === item.id_people)[0].name + " " + this.state.all_users.filter(owner => owner.id === item.id_people)[0].surname :"Not found"}</span>}
                                    description={<span style={{fontFamily:'Arial',fontSize:'14px',fontWeight:"bold"}}>{item.comments}</span>}
                                
                                />
                                </List.Item>
                            )}
                            />
                    </div>
                                                :
                                                <p style={{fontFamily:'Arial',fontSize:'18px',fontWeight:"bold",marginLeft:'15px'}}> Empty </p>
                    }
                                               <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Informations </p>

                    {
                        this.state.infos_by_id.length > 0?
                        <div>
                            <List
                            itemLayout="horizontal"
                            style={{width:'50%',marginLeft:'15px'}}
                            dataSource={this.state.infos_by_id}
                            renderItem={item => (

                                <div>
                                {this.setLastDatasetDisplay(item.id_dataset)}
                                {
                                    this.state.lastDatasetDisplayed !== -1 &&
                                    <span style={{fontFamily:'Arial',fontSize:'18px',fontWeight:"bold"}}>  Dataset : {this.getDatasetNameByID(this.state.lastDatasetDisplayed)} </span>
                                }
                                <List.Item>
                                <Tooltip placement="top" title={<span>Restore the deleted information</span>}><Button type='primary' onClick={() => {this.props.sendTracking(-1,"Web,Bin,Info-Restore");this.restoreCorrespondence(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer',backgroundColor:'#44D4FF'}}>Restore data</Button></Tooltip>
                                <Tooltip placement="top" title={<span>Definitely erase the deleted information</span>}><Button type='danger' onClick={() => {this.props.sendTracking(-1,"Web,Bin,Info-Erase");this.eraseCorrespondence(item.id)}} style={{display:'inline',marginRight:'40px',marginLeft:'10px',cursor:'pointer'}} >Erase data forever</Button></Tooltip>

                                <List.Item.Meta
                                   title={<span style={{fontWeight:"bold",fontSize:'19px',marginLeft:'10px'}} className='content-text'>{item.infos} {this.state.all_users !== undefined && this.state.all_users.length > 0 && this.state.all_users.filter(owner => owner.id === item.id_people).length > 0&& this.state.all_users.filter(owner => owner.id === item.id_people)[0] !== undefined?"owned by "+this.state.all_users.filter(owner => owner.id === item.id_people)[0].name + " " + this.state.all_users.filter(owner => owner.id === item.id_people)[0].surname :"Not found"} </span>}
                                />

                                </List.Item>
                                </div>
                            )}
                            
                            />
                        </div>
                        :
                        !this.state.deleted_infos_got?
                        <p style={{fontFamily:'Arial',fontSize:'18px',fontWeight:"bold",marginLeft:'15px'}}> Loading ... </p>
                        :
                        <p style={{fontFamily:'Arial',fontSize:'18px',fontWeight:"bold",marginLeft:'15px'}}> Empty </p>

                    }
            </div>
        );
    }
}

//      <Tooltip placement="top" title={<span>Restore the deleted information</span>}><img onClick={() => {this.restoreCorrespondence(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer'}} alt="Load from Unity"  width={40} src="/images/valid.png"></img></Tooltip>
//      <Tooltip placement="top" title={<span>Definitely erase the deleted information</span>}><img onClick={() => {this.eraseCorrespondence(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer'}} alt="Load from Unity"  width={30} src="/images/delete.png"></img></Tooltip>
export default UserBin;