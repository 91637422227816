import React from "react";
import {getDefaultQuality, qualityformatter} from "../utils/tools";
import {Slider} from "antd";
import 'antd/dist/antd.css';
import {useDispatch, useSelector} from "react-redux";
import {AddCurrentQualitiesById} from "../store/reducers/datasetSlice";


export const QualitySlider = (props) => {
    let current_quality = useSelector(state => state.dataset.current_quality_by_id[props.item.id]);
    if (current_quality === undefined || current_quality === null) {
        current_quality = getDefaultQuality(props.quality_count);
    }
    const dispatch = useDispatch();
    return (

        <div style={{
            float: "right",
            marginRight: props.item.mintime < props.item.maxtime ? '4%' : (props.is_public) ? '26%' : '23%',
            width: "17%"
        }}>
            <span>
                {qualityformatter(current_quality, props.quality_count)}
            </span>
            <Slider
                style={{
                    WebkitUserSelect: "none",
                    msUserSelect: "none", /* IE 10 and IE 11 */
                    userSelect: "none" /* Standard syntax */
                }}
                tipFormatter={(value) => qualityformatter(value, props.quality_count)}
                min={0}
                onChange={(value) => {
                    const object = {};
                    object[props.id_set] = value;
                    dispatch(AddCurrentQualitiesById(object));
                }}
                max={props.quality_count - 1}
                value={current_quality}
            />
        </div>);
}