import React from "react";
import {MorphoBlue} from "../morphotools"
class Error404 extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <center style={{paddingTop:"10%"}} >
                    <img  alt="LogoMorphoNet" width='75' src="/images/refonte/BigLogoTransparentOmbre.png" style={{minWidth:'250px',display:"inline"}}></img>
                    <h4 style={{color:MorphoBlue,fontSize:"48px",display:"inline"}}>Error 404</h4><h1 style={{fontSize:"36px"}}> <b>Page not found</b></h1>
                </center>
            </div>);
    }
}

export default Error404;