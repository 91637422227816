import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {getUserToken,getUserID} from '../store/utility'
import * as actions from '../store/actions/auth';

import {
    Form,
    Input,
    Icon,
    Button,
    Typography
  } from 'antd';
  

  const { Title } = Typography;

  class ResetPasswordForm extends React.Component {
    state = {
      confirmDirty: false,
      user: {},
      staff : false,
      token : "",
      connected_user_id : -1,
    };
  
    //when you update the pw
    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          //get auth token fromfirefox cache
           axios({
            method: 'put',
            url: `/api/update-password/`,
            data: {               
                new_password:values.password,
                user_id:this.state.user.id
            },
            headers :{ Authorization: `Token ${this.state.token}` }
          })
          //redirect the page
           this.props.history.push('/');
          }
      });
    };
  
    componentDidUpdate(prevProps,prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });
    }
    }
    componentDidMount() {
      //get user from app data
      this.setState({user: this.props.location.aboutProps});
      //timer to refresh display (button click)
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
      //get connected user token, than ask for url parameter ID persons
      this.setState({token : getUserToken()});
      this.setState({connected_user_id: getUserID()});
  }

  //stop timer
  componentWillUnmount() {
    clearInterval(this.interval);
  }

    handleConfirmBlur = e => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  
    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    };
  
    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    };
  

    render() {
      const { getFieldDecorator } = this.props.form;

  
      return (
        <div>
        {
          this.state.user !== undefined?
          <div >
          <Title style={{marginLeft:'43%'}}> Modify user password </Title>
          <input type="text" type='hidden' name="username" value={this.state.user.login} />
        <Form style={{marginTop:'7%',marginLeft:'31%'}} onSubmit={this.handleSubmit}>
          <Form.Item hasFeedback>
            Password : 
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password autoComplete="new-password" style={{width:'200px',marginLeft: '10px'}} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"/>)}
          </Form.Item>
          <Form.Item hasFeedback>
            Confirm password : 
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password autoComplete="new-password" style={{width:'200px',marginLeft: '10px'}} onBlur={this.handleConfirmBlur}  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password" />)}
          </Form.Item>
        
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                Update password
            </Button>
        </Form.Item>


        </Form>
        </div>
        :
        <div>This webpage is only reachable from your user page !</div>
        }
        </div>
      );
    }
  }
  
  const WrappedResetPasswordForm = Form.create({ name: 'resetpassword' })(ResetPasswordForm);

  //get data from app
  const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.error
    }
}

//bind auth method to global auth system
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedResetPasswordForm);