import {connect} from "react-redux";
import React from "react";
import {ChooseSetFromUrl} from "../utils/api_callbacks"
import DatasetGeneralView from "./DatasetGeneralView";
import ChosenSetTopPanel from "./ChosenSetTopPanel";


class DatasetChosenDisplay extends React.Component {

    state = {}

    componentDidMount() {
        // Use the url to extract the dataset to load
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }


    render() {
        const dataset_object = this.props.datasets[this.props.chosen_set]
        // Top Panel of choosen dataset + the general view
        return <div className="SingleSetView">
            <div>
                <ChosenSetTopPanel {...this.props} set={dataset_object}></ChosenSetTopPanel>
                <br/><br/>
                <DatasetGeneralView></DatasetGeneralView>
            </div>

        </div>
    }


}

// bind components props to specific parts of the store
// ex : is_connected : state.user.is_connected
const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset.entities,
        user_is_admin: state.user.is_staff
    }
}

// bind components props to specific actions that will update the store
// ex : function_name : (data) => dispatch(function_in_slice()),
const mapDispatchToProps = dispatch => {
    return {}
}

// connect bind the 2 functions above to the component
export default connect(mapStateToProps, mapDispatchToProps)(DatasetChosenDisplay);