import { Progress,Layout, Menu,Tooltip } from 'antd';
import React from 'react';
import '../App.css';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import { Input  } from 'antd';
import {getUserToken,getUserID, isOnStandalone} from '../store/utility';
import SlackFeedback from 'react-slack-feedback'

import {
  isAndroid,
  isMobile,
  isIOS,
  isTablet,
} from "react-device-detect";
import {SetRedirectedUrl} from "../store/reducers/userSlice";
import {SearchByName} from "../store/reducers/datasetSlice";
const { Search } = Input;
const { Content,Header,Footer } = Layout;


class CustomLayout extends React.Component{
  constructor(props) {
    super(props);
  /*  window.onunload = () => {
      // Clear the local storage
      localStorage.clear()
   }*/
   // window.addEventListener('load', localStorage.clear());
   this.feedback = React.createRef();
   this.custom_input_text = React.createRef();
   this.Myref = React.createRef();
   this.searchfield = React.createRef();
   this.clearSearchValue = this.clearSearchValue.bind(this);
    this.onLogout = this.onLogout.bind(this)
    this.handleLoad = this.handleLoad.bind(this);
    this.sendToServer = this.sendToServer.bind(this);
    //window.addEventListener('load', this.handleLoad);
}

state = {
  connected_user_id : -1,
  active_feedback : true,
  token : "",
  user : {},
  favourite: [],
  user_data_opened : false,
  show : false,
  unity_loaded :false,
  hoverUser : false,
  unity_windowed : false,
  user_size : 0,
  user_group_number : 0,
  user_dataset_number : 0,
  staff : false,
  component_mounted : false,
  tutorial_ready : false,
  unity_progress : -1,
  return_reset:false,

  searched_text : "",
  user_settings : [],
  user_settings_got : false,

  ask_clear : false,
}


getUserSettingsValue = (p_setting) =>
{
  let value = -1;
  if (this.state.user_settings !== undefined){
      this.state.user_settings.map(sett => {
      if (sett.setting === p_setting){
        value = sett.value;
      }
    });
  }
  //console.log(value);
  return value;
}

hoverUser = () => {
  if (this.state.token !== "" && this.state.token !== null){
    this.setState({hoverUser:true});
  }
}

onChangeSearchBar = (value) => {
    this.props.SearchByNameFunc(value.target.value);
}

stopHoverUser = () => {
  if (this.state.token !== "" && this.state.token !== null){
    this.setState({hoverUser:false});
  }
}
//logout when its a new tab (no other opened) and the last was closed at least  5 sec ago
handleLoad(){
  const localStorageTime = JSON.parse(localStorage.getItem('date'));
  let tab_numb = localStorage.getItem('tabs') ? JSON.parse(localStorage.getItem('tabs')) : 1;
  tab_numb++;
  if (localStorage.getItem('fermeture')){
    if ((tab_numb < 2 && (localStorageTime === undefined || new Date().getTime() - localStorageTime   > 1000 * 5)) || (localStorageTime === undefined || new Date().getTime() - localStorageTime   > 1000 * 3600 * 12)){
      const event = new Event('disconnect');
      localStorage.clear();
      this.props.logout();
      this.componentDidMount();
      dispatchEvent(event);
  }
  window.scrollTo(window.scrollX+1,window.scrollY+1);  window.scrollTo(window.scrollX-1,window.scrollY-1);
  localStorage.setItem('tabs',JSON.stringify(tab_numb));
}
}

bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0 || bytes === -1) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

componentDidMount() {
  this.state.connected_user_id =  getUserID();
  const token_handler = getUserToken();
  this.setState({token : getUserToken()});
  //console.log(getCookie('token'));
  //create favourite list instead of user sets list

  if (token_handler !== "" && token_handler !== null){
    axios.get(`/api/settings/`,{ headers: { Authorization: `Token ${token_handler}` } })
    .then(res => {
        this.setState({
            user_settings: res.data,
        });
        this.setState({user_settings_got : true});
        //console.log(this.state.user_settings);
    }).catch(err => {this.setState({user_settings_got : true});});
}else {
  this.setState({user_settings_got : true});
}

  const node = this.feedback.current.children[0].children[0].children[0].children[1];
  //console.log(node);
  node.onclick = null;
  node.onclick=(e) => {e.preventDefault();e.stopPropagation();this.clickCloseFeedBack()};

  this.setState({component_mounted:true});
  this.interval = setInterval(() => {this.renderSwitch();this.setState({ time: Date.now() });}, 1000);
  //setTimeout(() => { this.datasetList = React.createRef(); }, 1000);
 
}

clickCloseFeedBack = () => {
  this.state.active_feedback?this.props.ForceUnityKeyboardState(true):this.props.ForceUnityKeyboardState(false);
  this.setState({active_feedback:!this.state.active_feedback})
}
componentDidUpdate(prevProps,prevState){
  /*if (this.props.ask_clear !== this.state.ask_clear){
        this.setState({ask_clear:this.props.ask_clear});
        console.log("Asked to clear layout");
        console.log(this.custom_input_text);
        if (this.custom_input_text !== null){
          console.log("remove text");
          console.log(this.custom_input_text.input.input.value);
          this.custom_input_text.input.input.value = "";
        }

  }*/
  if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
    axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
        this.setState({staff:res.data['staff']});
    });
    if (this.state.token !== "" && this.state.token !== null){
      axios.get(`/api/user/${this.state.connected_user_id}`,{ headers: { Authorization: `Token ${this.state.token}` } })
      .then(res => {
        res.data.password = "";
          this.setState({
              user: res.data,
          });
      });
  }

    if (prevState.token !== getUserToken() && prevState.token !== null){
      this.componentDidMount();
    }
  if (this.state.token !== "" && this.state.token !== null){
    axios.get(`/api/usersize/`,{ headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
      //console.log(res.data);
        this.setState({
            user_size: res.data,
        });
    });
  }

  if (this.state.token !== "" && this.state.token !== null){
    axios.get(`/api/groupnumber/`,{ headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
        this.setState({
            user_group_number: res.data,
        });
    });
  }

  if (this.state.token !== "" && this.state.token !== null){
    axios.get(`/api/datasetnumber/`,{ headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
        this.setState({
          user_dataset_number: res.data,
        });
    });
  }
}

  if (this.props.unityProgression !== prevProps.unityProgression)
  {
    this.setState({unity_progress:this.props.unityProgression});
  }
  if (this.state.user !== prevState.user){
      axios.get(`/api/favourites/`,{ headers: { Authorization: `Token ${this.state.token}` } })
      .then(res => { 
          this.setState({
              favourite: res.data,
          });
      });
  }

  
  if (this.props.unityWindowed !== prevProps.unityWindowed){
    this.setState({unity_windowed:this.props.unityWindowed});
  }
  if (this.props.unityLoaded !== prevProps.unityLoaded){
    this.setState({unity_loaded:this.props.unityLoaded});
  }

  if (this.props.tutorialReady !== this.state.tutorial_ready && this.props.tutorialReady === true && this.state.component_mounted && !this.state.tutorial_ready){
    this.setState({tutorial_ready:true});
    //this.props.displayTutorial(`Welcome to MorphoNet`,`${window.innerWidth/3}px`,`${window.innerHeight/1.5}px`,`${0}px`,'tutorial-layout',"false");
 /*   setTimeout(() => { 
      var node = ReactDOM.findDOMNode(this.refs.datasetList);
      var feedbackNode = ReactDOM.findDOMNode(this.refs.feedbacksRef);
      var helpNode = ReactDOM.findDOMNode(this.refs.helpRef);
      this.props.displayTutorial(node,'center','top',`Click here to view datasets!`,'tutorial-layout',false);
      this.props.displayTutorial(feedbackNode,'right','top',`Some help ? `,'tutorial-layout',false);
      this.props.displayTutorial(helpNode,'right','top',`Any feedbacks ?`,'tutorial-layout',false);
      //this.props.displayTutorial(`Any feedbacks ? `,`${this.state.token !== null && this.state.token !== undefined?0.81*feedbackNode.getBoundingClientRect().left:0.83*feedbackNode.getBoundingClientRect().left}px`,`8%`,`${feedbackNode.offsetRight}px`,'tutorial-layout','up');
    }, 1000);*/
    
   // this.props.displayTutorial(`This is the twitter feedback \n with a carriage return2`,`${this.twitterdiv.current.offsetLeft}px`,`${this.twitterdiv.current.offsetTop}px`,`${this.twitterdiv.current.offsetRight}px`,'tutorial-layout');
  }
    
  if (this.props.unityActive !== prevProps.unityActive){
    this.setState({show:this.props.unityActive});
  }
  if (this.state.show !== prevState.show){
    this.props.showUnity(this.state.show);
  }

}

componentWillUnmount() {
  clearInterval(this.interval);
}

unloadPage()
{
  let tab_numb = localStorage.getItem('tabs') ? JSON.parse(localStorage.getItem('tabs')) : 1;
  if (tab_numb > 0){
     tab_numb--;
   }
   else {
     tab_numb = 0;
   }
  localStorage.setItem('tabs',JSON.stringify(tab_numb));
  localStorage.setItem('fermeture',true);
  localStorage.setItem('date',JSON.stringify(new Date().getTime()));
}



// logout user
  onLogout(){
    this.props.history.push('/');
    window.location.reload();
    this.props.logout();
  }

onSearchDataset = (value) => {

  this.props.refreshDataset();
  if (window.location.pathname !== "/dataset" && window.location.pathname !== "/dataset/"){
      this.props.SetRedirectedUrl("/dataset/");

  }
}
clearSearchValue()
{
  this.props.sendTracking(-1,"Web,DatasetList,view");
  //this.searchfield.select.rcSelect.state.inputValue = "";
}

renderSwitch(){
  const keys = [];
  switch(window.location.pathname){
      case '/login':
        keys.push('2');
        break;
      case '/login/':
        keys.push('2');
        break;
      case '/dataset':
        keys.push('4');
        break;
      case '/dataset/':
        keys.push('4');
        break;
      case '/help':
        keys.push('6');
        break;
      case '/help/':
        keys.push('6');
        break;
      case '/help_app':
        keys.push('6');
        break;
      case '/help_app/':
        keys.push('6');
        break;
      case '/help_api':
        keys.push('6');
        break;
      case '/help_api/':
        keys.push('6');
        break;
      case '/help_format':
        keys.push('6');
        break;
      case '/help_format/':
        keys.push('6');
        break;
      case '/help_troubleshoot':
        keys.push('6');
        break;
      case '/help_troubleshoot/':
        keys.push('6');
        break;
      case '/help_converter':
        keys.push('6');
        break;
      case '/help_converter/':
        keys.push('6');
        break;
      case '/help_tutorial':
        keys.push('6');
        break;
      case '/help_tutorial/':
        keys.push('6');
        break;
      case '/users':
        keys.push('2');
        break;
      case '/users/':
        keys.push('2');
        break;
      case '/userslist':
        keys.push('14');
      case '/userslist/':
        keys.push('14');
      default:
        keys.push('1');
        break;
    }

    if (this.state.show){
      keys.push('9');
    }
    return keys;
  }

  
  sendToServer(payload, success, error) {
    //console.log(payload);
    const formData = new FormData();
    if (payload.attachments[0].image_url !== undefined)
      formData.append('file_url', payload.attachments[0].image_url);
    formData.append('author', payload.attachments[0].author_name);
    if (this.state.token !== "" && this.state.token !== undefined){
      formData.append('user_id',getUserID());
    }
    formData.append('text', payload.attachments[0].text);
    formData.append('title', payload.attachments[0].title);
    this.props.sendTracking(-1,"Web,Feedback,send");
    return axios.post(`/api/posttoslack/`,formData)
    
    /*return axios.get(`/api/people/${JSON.parse(u_id)}/`,{ headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
        this.setState({user:res.data});
    });       */
  }

  onLogout = () =>{
    this.props.history.push('/');
    window.location.reload();
    this.props.logout();
  }

  uploadImage(image, success, error) 
  {
    const formData = new FormData();
    formData.append('image', image);
    return axios.post(`/api/feedbackimages/`,formData)
   
    /*return fetch('/api/feedbackimages/', { method: 'POST', data: formData })
      .then(({ url }) => success(url))
      .catch(err => error(err))*/
  }

  //render the header (menus) and footer
  render() {
  return (
<Layout >
   {(!this.props.location.pathname.includes("lineage") || this.props.location.pathname.includes("help_lineage")) && !this.props.location.pathname.includes("morphoapi") && ((this.state.unity_loaded && this.state.show  && !this.state.unity_windowed && window.innerHeight >= 2*(window.screen.height/3)) || (!this.state.unity_loaded || !this.state.show  || this.state.unity_windowed)) &&
    <Header style={{backgroundColor:'#fefefe',padding:'0 0'}} className="header">

      <Menu
        mode="horizontal"
        selectedKeys={this.renderSwitch()}
        style={{maxHeight:'64px',paddingTop:'7px',backgroundColor:'#fefefe'}}
      >

        <Menu.Item onClick={() => {if (this.state.show && !this.state.unity_windowed)this.props.sendWindowedMode()}} style={{float:'left',borderBottom:"none"}} className="noSelectedBorder" key="1">
        <Tooltip placement="bottom" title={<span>MorphoNet index page</span>}>
          <Link to = "/">
            <img  alt="Logo" width='75' src="/images/refonte/BigLogoTransparentOmbre.png" style={{minWidth:(!this.state.unity_windowed && this.state.show) || window.innerHeight < 600 || window.innerWidth < 1200 ?'75px':'250px'}}></img>
          </Link>
          </Tooltip>
        </Menu.Item>


        <Menu.Item ref='datasetList'  onClick={() => {this.props.datasetClicked();if (this.state.show && !this.state.unity_windowed)this.props.sendWindowedMode()}} className="removeSelectedBorder" key="4" /*style={{marginLeft:this.state.show || (this.state.unity_windowed && this.state.show)?'225px':'0px',float:'left',verticalAlign:'top'}}*/>
          <Tooltip  placement="bottom" title={<span>List of the datasets</span>}>
            <Link  to="/dataset" >
            <img  alt="Login" width='35' onClick={this.clearSearchValue} src="/images/refonte/list-with-dots.png" style={{mixBlendMode:'multiply'}}></img>
            </Link>
          </Tooltip>
        </Menu.Item>


 
        
        <Menu.Item className='btn-disabled' onClick={() => {if (this.state.show && !this.state.unity_windowed)this.props.sendWindowedMode()}} style={{float:'left',width:'25%',verticalAlign:'top',cursor:'default',pointerEvents: "none"}} key="7" >
        <Tooltip placement="bottom" title={<span>Filter dataset list by name</span>}>

            <Search
            placeholder="Search for one or multiple dataset(s)"
            onSearch={this.onSearchDataset}
            onChange={this.onChangeSearchBar}
            onPressEnter={this.onSearchDataset}
            ref={r => this.custom_input_text=r}
           /* onChange={this.onChangeSearchBar.bind(this)}*/
            id='custom_input_text'
            className='btn-disabled'
            value={this.props.dataset.current_filter_search_name !== undefined ? this.props.dataset.current_filter_search_name : ""}
            style={{pointerEvents:'all',width:'100%'}}
          />
          </Tooltip>
        </Menu.Item>

      {
       !isOnStandalone() && this.getUserSettingsValue("standalone_start") !== "true" &&
       <Menu.Item disabled={!this.state.unity_loaded} onClick={() => {this.setState({show:!this.state.show});}} className="removeSelectedBorder" key="9" style={{verticalAlign:'top'}}>
          <Tooltip placement="bottom" title={<span>{this.state.unity_loaded?"Show or hide dataset viewer":"Viewer is loading, please wait..."}</span>}>
           {
           this.state.unity_loaded?
           <img onClick={() => {
            this.props.sendTracking(-1,"Web,DatasetViewer,open");
            }} alt="Viewer" width='35'  src={!this.state.unity_loaded?"/images/refonte/greycloseeye.png":this.state.show?"/images/refonte/load.png":"/images/refonte/closeeye.png"} style={{mixBlendMode:'multiply'}}></img>
            :
            <Progress width={43} type='circle' percent={parseInt(this.state.unity_progress*100)}></Progress>
            }
            </Tooltip>
        </Menu.Item>
        }
        {
          this.state.token !== null && this.state.token !== "" && (isTablet || (!isMobile && !isIOS && !isAndroid)) ?
          <Menu.Item onMouseEnter={this.hoverUser} onMouseLeave={this.stopHoverUser} onClick={() => {if (this.state.show && !this.state.unity_windowed)this.props.sendWindowedMode()}} className="removeSelectedBorder" key="2" style={{float:'right'}}>
          <Link to = "/users">
          <img alt="Login" width='35' onClick={() => this.setState({user_data_opened : !this.state.user_data_opened})} src="/images/refonte/man-user.png" style={{cursor:'pointer',mixBlendMode:'multiply'}}></img>
          <span onClick={() => {
            this.props.sendTracking(-1,"Web,Users,view");this.setState({user_data_opened : !this.state.user_data_opened})}} style={{color:'#44D4FF',fontSize:'15px',fontWeight:"bold"}}>{this.state.user.login}</span>
          </Link>
          </Menu.Item>
          :
          this.state.token !== null && this.state.token !== "" ?
          <Menu.Item onClick={this.onLogout} className="removeSelectedBorder" key="2" style={{float:'right'}}>
              <Tooltip placement="bottom" title={<span>Log out</span>}>     
            <img alt="Logout" onClick={this.onLogout} width='45' src="/images/refonte/LogOut.png" style={{marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
            </Tooltip>
          </Menu.Item>
          :
          <Menu.Item onClick={() => {this.setState({show:false});}} className="removeSelectedBorder" key="2" style={{float:'right'}}>
            <Tooltip placement="bottom" title={<span>Log in to MorphoNet</span>}>
          <Link to = "/login">       
          <img alt="Login" width='35' src="/images/refonte/man-user.png" style={{mixBlendMode:'multiply'}}></img>
          </Link>
          </Tooltip>
        </Menu.Item>
        }


        {
          isTablet || (!isMobile && !isIOS && !isAndroid) &&
          <Menu.Item ref='helpRef' onClick={() => {
          this.props.sendTracking(-1,"Web,Help,view");
            if (this.state.show && !this.state.unity_windowed)this.props.sendWindowedMode()}} className="removeSelectedBorder" key="6" style={{float:'right',marginLeft:'10px'}}>
          <Link to="/help">
          <Tooltip placement="bottom" title={<span>MorphoNet help page</span>}>
          <img alt="Help" width='38' src="/images/refonte/Question.png" style={{mixBlendMode:'multiply'}}></img>
          </Tooltip>
          </Link>
        </Menu.Item>
        }
        {
          isTablet || (!isMobile && !isIOS && !isAndroid) &&
        <Menu.Item ref='feedbacksRef' className="removeSelectedBorder" key="15" style={{float:'right',marginLeft:'30px'}}>
          <Tooltip placement="bottom" title={<span>Feedback panel</span>}>
          <img alt="Help" onClick={() => {
            this.props.sendTracking(-1,"Web,Feedback,view");this.state.active_feedback?this.props.ForceUnityKeyboardState(true):this.props.ForceUnityKeyboardState(false);this.setState({active_feedback:!this.state.active_feedback})}} width='38' src="/images/chat.png" style={{mixBlendMode:'multiply'}}></img>
          </Tooltip>
        </Menu.Item>
        }

          <div ref='dowloadRef' onClick={() => {
              this.props.sendTracking(-1,"Web,downlaod,view");
              if (this.state.show && !this.state.unity_windowed)this.props.sendWindowedMode()}} className="removeSelectedBorder" key="10" style={{float:'right',marginLeft:'10px'}}>
              <Link to="/downloads">
                  <Tooltip placement="bottom" title={<span>MorphoNet standalones downloads </span>}>
                      <img alt="downloads" width='40' src="/images/refonte/download.png" style={{mixBlendMode:'multiply'}}></img>
                  </Tooltip>
              </Link>
          </div>
      </Menu>


    </Header>
}
<div ref={this.feedback} className={this.state.active_feedback?'hidden':''}>
{
  !this.state.active_feedback && false &&
  <div style={{textAlign:'center',backgroundColor:'white',borderRadius:'25px',border:'2px solid #44D4FF',position:'absolute',float:'right',marginLeft:'30%',marginTop:'3%',marginRight:'10px',width:'40%',zIndex:'30'}}>
    <br></br>
  <span style={{fontSize:'17px',fontWeight:'bold'}}>If a problem appears with the website, try clearing your browser cache, it could fix the problem.</span>
  <br></br><br></br>
  </div>
  }
<SlackFeedback
          open={true}
          ref={this.Myref}
          channel={null}
          icon={() => <img width="40" alt="Logo"  src="/images/refonte/BigLogoTransparentOmbre.png" ></img> }
          user="MorphonNet Website" // The logged in user (default = "Unknown User")
          onImageUpload={(image, success,error) => 
            this.uploadImage(image)
              .then(res => {/*console.log("success");console.log(res);*/ success(res.data)})
              .catch(error => {/*console.log("error");console.log(error)*/})
          }
          onSubmit={(payload, success, error) => 
            this.sendToServer(payload)
              .then(success)
              .catch(error)
          }
        />

</div>
    <Layout>
      <Layout style={{backgroundColor:'white',padding:!this.props.location.pathname.includes("lineage") && this.props.location.pathname.includes("morphoapi")?'0 0 0':'0 24px 24px' }}>
        <Content
          style={{
            background: '#fff',
            padding: (this.state.show && !this.state.unity_windowed) ||(!this.props.location.pathname.includes("lineage") && this.props.location.pathname.includes("morphoapi"))?2:24,
            margin: 0,
            minHeight:!this.props.location.pathname.includes("lineage") && this.props.location.pathname.includes("morphoapi")?window.innerHeight:window.innerHeight-(0.14*window.innerHeight),
          }}
        >  
        {
        this.state.hoverUser &&
        <div style={{backgroundColor:'white',borderRadius:'25px',border:'2px solid #44D4FF',position:'absolute',float:'right',marginLeft:'83%',marginTop:'1px',marginRight:'10px',width:'12%',zIndex:'30'}}>
          <br></br>
          <h1 style={{textAlign:'center',fontFamily:'Arial',fontSize:'17px'}}>{this.state.user.name} {this.state.user.surname}</h1>
          <h1 style={{textAlign:'center',fontFamily:'Arial',fontSize:'17px'}}>{this.state.staff?"Admin":"User"}</h1>
          <h2 style={{fontFamily:'Arial',textAlign:'center',fontSize:'15px'}}> {this.state.user_size?this.bytesToSize(this.state.user_size):"0Mb"}/{this.state.user.quota?this.bytesToSize(this.state.user.quota):"0Mb"}</h2>
          <h2 style={{fontFamily:'Arial',textAlign:'center',fontSize:'15px'}}> {this.state.user.email}</h2>
          <h2 style={{fontFamily:'Arial',textAlign:'center',fontSize:'15px'}}> {this.state.user_group_number} {this.state.user_group_number>1?"groups":"group"} owned</h2>
          <h2 style={{fontFamily:'Arial',textAlign:'center',fontSize:'15px'}}> {this.state.user_dataset_number} {this.state.user_dataset_number>1?"datasets":"dataset"} owned</h2>
          <br></br>
        </div>
        }
          {this.props.children}
        </Content>
      </Layout>
    </Layout>
  { !this.props.location.pathname.includes("lineage") && !this.props.location.pathname.includes("morphoapi") &&
  <Footer style={{ textAlign:'center',width:'100%',bottom:'0%'}}>
    <div style={{width:'100%',color:'#7678ff'}}>
    <Link onClick={() => {
      this.props.sendTracking(-1,"Web,Privacy,view");}} id="emailLnk" className='footer_link' to = "/privacy">Privacy Policy</Link>
    <span style={{marginLeft:'30px',marginRight:'30px'}}> | </span>
    <Link onClick={() => {
      this.props.sendTracking(-1,"Web,Privacy,view");}} id="emailLnk" className='footer_link' to = "/team">Team</Link>
		<span style={{marginLeft:'30px',marginRight:'30px'}}> | </span>
    <Link onClick={() => {
      this.props.sendTracking(-1,"Web,Licence,view");}} id="emailLnk" className='footer_link' to = "/license">License</Link>
		<span style={{marginLeft:'30px',marginRight:'30px'}}> | </span>
    <Link id="emailLnk" onClick={() => {
      this.props.sendTracking(-1,"Web,Funding,view");}} className='footer_link' to = "/funding">Funding</Link>
		<span style={{marginLeft:'30px',marginRight:'30px'}}> | </span>
    <Link id="emailLnk" onClick={() => {
      this.props.sendTracking(-1,"Web,Technos,view");}} className='footer_link' to = "/technology">Technology</Link>
		<span style={{marginLeft:'30px',marginRight:'30px'}}> | </span>
		<a id="emailLnk" className='footer_link' href="mailto:contact@morphonet.org" >Contact</a>
    </div>
		<div>
      <span > Copyright 2018 © Leggio, Laussu, Carlier, Godin, Lemaire, Faure </span>
	  </div>
    </Footer>}
  </Layout>
  );
}
}

const mapStateTopProps = (state) => {
    return {
        dataset: state.dataset,
    }
}
//bind logout to global logout system
const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
        SearchByNameFunc: (name) => dispatch(SearchByName(name)),
    }
}

export default withRouter(connect(mapStateTopProps,mapDispatchToProps)(CustomLayout));
